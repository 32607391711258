import React from 'react'

import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

//import Iframe from 'react-iframe'

const UploadDiv = () => {

 const barra = useContext(AuthContext);

 let usua = barra.cusuario
 let raiz = barra.raizTable 
 let empr = barra.cempresa

// const wFrame = "https://www.portaltax.lead.tax/apicrud/up/atualiza_bx.php?u=" + barra.cusuario
// const wFrame = `${barra.urlbase}/api/subir_arquivos_direto.php?u=${usua}&r=${raiz}&e=${empr}`
// const wFrame = `${barra.urlbase}/subir_arquivos_direto.php?u=${usua}&r=${raiz}&e=${empr}`
// const wFrame = `${barra.urlbase}/apicrud/importar.php?u=${usua}&r=${raiz}&e=${empr}`
const wFrame = "./apicrud/importar.php?u=${usua}&r=${raiz}&e=${empr}"

    return (
        <>

            <div className="col-md-12" style={{ position: "fixed", top: 90, border: "1px solid #bababa"}}>
                <center>
                <h3  style={{textAlign:"center",}}>Importar Arquivos</h3>
                <iframe src={wFrame}  title="IMPORTAR" style={{border: "1px solid #bababa", width: "95Vw", height: "70Vh"}} ></iframe> 
                </center>          
            </div>
        </>
      )
}

export default UploadDiv