import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, ConteudoTitulo,  Titulo } from '../styles';
import Button from 'react-bootstrap/Button';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const DreDestravar = () => {

  const barra = useContext(AuthContext);

  const navigate = useNavigate();
  if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
    barra.setSidebar(false);
    localStorage.clear("token")
    localStorage.setItem("encerrar", JSON.stringify("sim"));
    barra.avisar('Sessão expirou. Por favor, faça um novo login.')
    navigate("/");
}

const [primeira, setPrimeira] = useState(true)

  const anos = [
    {
      label: "Indique o Ano",
      value: "2020",
    },
    {
        label: "2021",
        value: "2021",
    },
    {
      label: "2022",
      value: "2022",
  },
  {
      label: "2023",
      value: "2023",
  },
  { 
      label: "2024",
      value: "2024",
  },
  {
      label: "2025",
      value: "2025",
  },
]

  const meses = [
    {
      label: "Selecione o mês",
      value: "13",
    },
    {
      label: "Janeiro",
      value: "1",
    },
    {
      label: "Fevereiro",
      value: "2",
    },
    {
      label: "Março",
      value: "3",
    },
    {
      label: "Abril",
      value: "4",
    },
    {
      label: "Maio",
      value: "5",
    },
    {
      label: "Junho",
      value: "6",
    },
    {
      label: "Julho",
      value: "7",
    },
    {
      label: "Agosto",
      value: "8",
    },
    {
      label: "Setembro",
      value: "9",
    },
    {
      label: "Outubro",
      value: "10",
    },
    {
      label: "Novembro",
      value: "11",
    },
    {
      label: "Dezembro",
      value: "12",
    },
  ];

    const [inputs, setInputs] = useState([])
    const [lojas, setLojas] = useState([])
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(false)
        navigate("/dreDestrava");
    }, [loading, navigate]); 


    const buscarLojas = async () => {
      try {
  
          //let tk = localStorage.getItem("token")
  
          axios.get(`${barra.urlbase}/apicrud/buscarlojas.php?e=${barra.cempresa}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("LOJAS: ", res)
              console.log("SUCESSO: ", sucesso);
  
              if (sucesso) {
                      console.log(res.data.userlist.userdata);
                      setLojas(res.data.userlist.userdata);
                      setLoading(true);
  
              } else {
                      console.log("Lojas Não encontradas")
                      let motivo    = res.data.motivo;
                      setLoading(true);
                      console.log("NÃO SUCESSO: ", motivo);
                      setLoading(false);
              }
          })
      } catch (error) { throw error;} 
  
      return
  
  }  

    const travarDre = async () => {
        try {

            console.log("NOME: ", inputs.loja)
            console.log("MÊS: ", inputs.mes)
            console.log("ANO: ", inputs.ano)

            let empresa = barra.cempresa
            let usuario = barra.cusuario

            let erro = ''
            if ((inputs.loja === '') || (inputs.loja === undefined) )  {  erro = 's'; }
            if ((inputs.mes  === '') || (inputs.mes  === undefined) )  {  erro = 's'; }
            if ((inputs.ano  === '') || (inputs.ano  === undefined) )  {  erro = 's'; }

            if ((usuario === '') || (usuario === undefined)) {
                barra.avisar("Usuário não identificado. Por favor, faça o login ", 5)
                barra.setSidebar(false);
                localStorage.clear("token")
                localStorage.setItem("encerrar", JSON.stringify("sim"));
                navigate("/");
            } else {
                if (erro === 's')  {
                        barra.avisar("Campos LOJA, MÊS e ANO são obrigatórios. Grato", 4)
                } else {

                        let kpo = "l=" + inputs.loja + "&m=" + inputs.mes + "&a=" + inputs.ano + "&u=" + usuario + "&e=" + empresa
                        console.log(kpo)
                        axios.get(`${barra.urlbase}/apicrud/destravardre.php?${kpo}`, inputs).then(function(res) {
                                let sucesso    = res.data.success;
                                let motivo    = res.data.motivo;

                                console.log("DRE: ", res)
                                console.log("SUCESSO: ", sucesso);
                                console.log("MOTIVO: ", res.data.motivo);
    
                                if (sucesso) {
                                        barra.avisar("D.R.E. Liberado", 1)
                                        setLoading(true);
                                } else {
                                        barra.avisar("Ocorreu um problema na consolidação do DRE. Por favor, tente novamente.", 5)
                                        console.log("NÃO SUCESSO: ", motivo);
                                        setLoading(true);
                                }
                        })
                }
            }

        } catch (error) { throw error;} 
    
        return

    } 


      const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }      

      if (primeira) {
        buscarLojas()
        //buscargrupos()
        setPrimeira(false)
      }
     // console.log("TRAVAR: ", travar)

  return (

    <>


      <form className="Container">

      <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px", }}>D.R.E. - REABRIR</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>
        </Container>        
        <center>
          <div className="form-group">
              <div className="form-group col-md-9">

                      <div className="form-group col-md-11" style={{marginTop: 5,  borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>IDENTIFICAÇÃO</span>
                            </td></tr></tbody></table>
                            <div className="row">
                            <div>&nbsp;</div>
                            <div className="col-md-5" >
                                        <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Loja: </span>
                                        <select className="col" name="loja" id="loja" value={inputs.loja}  onChange={handleChange} 
                                        style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }}>
                                            <option value="">Selecione a loja </option>
                                                {lojas.map((option, index) => {
                                                return <option key={index} >
                                                {option}
                                            </option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                            <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Mês: </span>
                                          <select name="mes"  id="mes" value={inputs.mes}  onChange={handleChange} 
                                          style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }} >
                                              {meses.map((option) => (
                                                  <option value={option.value} style={{color:`${option.value}`}}>{option.label}</option>
                                              ))}
                                          </select>
                                    </div>
                                    <div className="col-md-2">
                                          <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Ano: </span>
                                          <select name="ano"  id="ano" value={inputs.ano}  onChange={handleChange}
                                          style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }} >
                                              {anos.map((option) => (
                                                  <option value={option.value} style={{color:`${option.value}`}}>{option.label}</option>
                                              ))}
                                          </select>
                                    </div>                                     
                                    <div className="col-md-2" >
                                        <Button id="buscar" onClick={travarDre}  style={{display: "block", paddingLeft:"9px", paddingRight:"9px", backgroundColor: barra.corBotao, borderRadius:"5px", }}>LIBERAR D.R.E.</Button>
                                    </div> 
                                    <div>&nbsp;</div>

                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                </div>
          </div>

        </center>
      </form>   

      <hr />


    </>
  )
}

export default DreDestravar

     

