import "./App.css";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import Contatos from "./page/apoio/Contatos";
import Exit from "./page/apoio/Exit"
import Home from "./page/apoio/Home";
import ImportarNotas from "./page/apoio/ImportarNotas";
import Login from "./page/apoio/Login";
import Parametros from "./page/apoio/Parametros";

import Bancos from "./page/cadastro/Bancos";
import Cadastros from "./page/cadastro/Cadastros";
import Credor from "./page/cadastro/Credor";
import Empresas from "./page/cadastro/Empresas";
import Fornecedor from "./page/cadastro/Fornecedor";
import Lojas from "./page/cadastro/Loja";
import Usuarios from "./page/cadastro/Usuarios";

import Calendario from "./page/calendario/Index";

import ConsultaNotas from "./page/consultas/ConsultaNotas";
import ConsultaItens from "./page/consultas/ConsultaIten";
import CriarGraficos from "./page/consultas/CriarGraficos";
import Graficos from "./page/consultas/Graficos";
import PainelDash from "./page/consultas/PainelDash";
import ParametrizarGraficos from "./page/consultas/ParametrizarGraficos";
import ParametrizarGraficosFin from "./page/consultas/ParametrizarGraficosFin";
import ParametrizarGraficosInv from "./page/consultas/ParametrizarGraficosInv";

import ContasPagar from "./page/financeiro/ContasPagar";
import ContasPagarLcto from "./page/financeiro/ContasPagarLcto";
import ContasReceber from "./page/financeiro/ContasReceber";
import ContasReceberLcto from "./page/financeiro/ContasReceberLcto";
import Dre from "./page/financeiro/Dre";
import DreTravar from "./page/financeiro/DreTravar";
import DreDestravar from "./page/financeiro/DreDestravar";
import FluxoCaixa from "./page/financeiro/FluxoCaixa";
import PainelFinan from "./page/financeiro/PainelFinan";
import Royalties from "./page/financeiro/Royalties";

import AbrirNotaFiscal from "./page/inventario/AbrirNotaFiscal";
import ContarEstoque from "./page/inventario/ContarEstoque";
import FecharNotaFiscal from "./page/inventario/FecharNotaFiscal";
import ItemAdicional from "./page/inventario/ItemAdicional";
import LancarEstoque from "./page/inventario/LancarEstoque";
import ListaEstoque from "./page/inventario/ListaEstoque";
import MovimentaEstoque from "./page/inventario/MovimentaEstoque";
import PainelInven from "./page/inventario/PainelInven";
import ReceberMateriais from "./page/inventario/ReceberMaterial";

import Campanhas from "./page/vendas/Campanhas";

import AuthProvider from "./contexts/Auth";
//import Sidebar from "./components/Sidebar";
import OperacoesEspeciais from "./page/apoio/OperacoesEspeciais";
import FechamentoLojas from "./page/financeiro/FechamentoLoja";
import Layout from "./page/menu/Layout";
import AppXML from "./page/apoio/ReadXml";
import FileUploadMultiple from "./page/apoio/MultipleFile.tsx";
import Cadcampan from "./page/cadastro/Cadcampan";
import FechamentoDiario from "./page/financeiro/FechamentoDiario";
import UsuariosV3 from "./page/cadastro/UsuariosV3";
import BancosV3 from "./page/cadastro/BancosV3";
import CadcampanV3 from "./page/cadastro/CadcampanV3";
import CredorV3 from "./page/cadastro/CredorV3";
import FornecedorV3 from "./page/cadastro/FornecedorV3";
import LojaV3 from "./page/cadastro/LojaV3";
import UploadFiles from "./page/apoio/UploadFiles";
import CartaoV3 from "./page/cadastro/CartaoV3";
import GruposV3 from "./page/cadastro/GruposV3";
import ContasV3 from "./page/cadastro/ContasV3";
import ApexChart1 from "./page/consultas/Apexchart1";
import ApexChart2 from "./page/consultas/Apexchart2";
import UploadDiv from "./page/apoio/UploadDiv";
import AssumFecharNotaFiscal from "./page/inventario/FecharNotaFiscalAssum.js";

localStorage.setItem("encerrar", '')
const PrivateRoute = ({ children, redirectTo }) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  console.log("isAuth: ", isAuthenticated);
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};


function App() {


  
  return (
    <>
  

    <Router>
     <AuthProvider>

      {/* 
      
      <Sidebar /> 

          */}

      <Layout />

                   
      <Routes>
        <Route path="/home"            element={<PrivateRoute redirectTo="/"></PrivateRoute>}  />
        <Route path="/"                element={<Login />} />
        <Route path="/menu"            element={<Layout />} />
        <Route path="/cadastros"       element={<Cadastros />} />
        <Route path="/dash"            element={<PainelDash />} />
        <Route path="/finan"           element={<PainelFinan />} />
        <Route path="/graficos"        element={<Graficos />} />
        <Route path="/inventario"      element={<PainelInven />} />
        <Route path="/receber-mat"     element={<ReceberMateriais />} />
        <Route path="/bancos"          element={<BancosV3 />} />
        <Route path="/cartoes"         element={<CartaoV3 />} />
        <Route path="/contas"          element={<ContasV3 />} />
        <Route path="/empresas"        element={<Empresas/>} />
        <Route path='/fornecedor'      element={<FornecedorV3 />} />
        <Route path="/grupos"          element={<GruposV3 />} />
        <Route path='/clientes'        element={<CredorV3 />} />
        <Route path='/lojas'           element={<LojaV3 />} />
        <Route path="/usuarios"        element={<Usuarios />} />
        <Route path="/usuariosv3"      element={<UsuariosV3 />} />
        <Route path="/import"          element={<ImportarNotas />} />
        <Route path="/contas-pagar"    element={<ContasPagar />} />
        <Route path="/contas-pagar-lcto"    element={<ContasPagarLcto />} />
        <Route path="/campanha"        element={<Campanhas />} />
        <Route path="/contas-receber"  element={<ContasReceber />} />
        <Route path="/contas-receber-lcto"  element={<ContasReceberLcto />} />
        <Route path="/services/services1" element={<ConsultaNotas />} />
        <Route path="/services/services2" element={<ConsultaItens />} />
        <Route path="/contact"         element={<Contatos />} />
        <Route path="/calendario"      element={<Calendario />} />
        <Route path="/operacao/especial1" element={<AbrirNotaFiscal />} />
        <Route path="/operacao/especial2" element={<FecharNotaFiscal />} />
        <Route path="/operacao/especial3" element={<ItemAdicional />} />
        <Route path="/operacao/especial4" element={<ConsultaNotas />} />
        <Route path="/importar"        element={<UploadDiv />} />
        <Route path="/dre"             element={<Dre />} />
        <Route path="/dreTrava"        element={<DreTravar />} />
        <Route path="/fluxo"           element={<FluxoCaixa />} />
        <Route path="/dreDestrava"     element={<DreDestravar />} />
        <Route path="/home"            element={<Home />} />
        <Route path="/opespeciais"     element={<OperacoesEspeciais />} />
        <Route path="/estoque-mover"   element={<MovimentaEstoque />} />
        <Route path="/estoque"         element={<ListaEstoque />} />
        <Route path="/estoque-lancar"  element={<LancarEstoque />} />
        <Route path="/estoque-contar"  element={<ContarEstoque />} />
        <Route path="/parametros"      element={<Parametros />} />
        <Route path="/grafico-cria"    element={<CriarGraficos />} />
        <Route path="/grafico-dash"    element={<ParametrizarGraficos />} />
        <Route path="/grafico-finan"   element={<ParametrizarGraficosFin />} />
        <Route path="/grafico-inv"     element={<ParametrizarGraficosInv />} />
        <Route path="/royal"           element={<Royalties />} />
        <Route path="/fechar"          element={<FechamentoDiario />} />
        <Route path="/xml"             element={<AppXML />} />
        <Route path="/multi"           element={<FileUploadMultiple />} />
        <Route path="/fechaespecial"   element={<AssumFecharNotaFiscal />} />
        <Route path="/cadcampan"       element={<CadcampanV3 />} />
        <Route path="/apex1"           element={<ApexChart2  />} />
        <Route path="/sair"            element={<Exit />} />

      </Routes>

        </AuthProvider>

    </Router>
      
    </>
  );
}
export default App; 