import React from "react"
import axios from 'axios'
import Chart from "react-apexcharts"
import { useState, useEffect } from "react";

import { AuthContext } from '../../contexts/Auth';
import { useContext } from 'react';

const ApexChart2 = () => {

  const barra = useContext(AuthContext);

  const [eixox, setEixox] = useState([])
  const [datit, setDatit] = useState([])
  const [datgu, setDatgu] = useState([])
  const [media, setMedia] = useState([])
  const [medi2, setMedi2] = useState([])
  const [atual, setAtual] = useState(true)

  const [eixoxg, setEixoxg] = useState([])
  const [datitg, setDatitg] = useState([])
  const [mediag, setMediag] = useState([])

  const [eixox3, setEixox3] = useState([])
  const [eixox4, setEixox4] = useState([])
  const [eixox5, setEixox5] = useState([])
  const [stat, setStat] = useState([])
  const [diam, setDiam] = useState([])
  const [labe, setLabe] = useState([])
  const [mesa, setMesa] = useState('')

  const leRegistros = async () => {

    let anoBase = 2023

    axios.get(`${barra.urlbase}/apicrud/apexchart1.php?empr=${1}&anob=${anoBase}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO: ", sucesso);

        const mes = []
        const lj1 = []
        const lj2 = []
        const md1 = []
        const md2 = []

        if (sucesso) {
              console.log("APEX1: ", res.data.userlist.userdata);
              res.data.userlist.userdata.map(item=> {
                lj1.push(item.valo0)
                mes.push(item.mese)
                lj2.push(item.valo1)
                md1.push(item.med1)
                md2.push(item.med2)
              })
              setEixox(mes)
              setDatit(lj1)
              setDatgu(lj2)
              setMedia(md1)
              setMedi2(md2)

            } else {
              console.log("Não encontrado (DU)")
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

    axios.get(`${barra.urlbase}/apicrud/apexchart2.php?empr=${1}&anob=${anoBase}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO: ", sucesso);

        const mez = []
        const ger = []
        const mdg = []

        if (sucesso) {
              console.log("APEX2: ", res.data.userlist.userdata);
              res.data.userlist.userdata.map(item=> {
                ger.push(item.valor)
                mez.push(item.mese)
                mdg.push(item.med1)
              })
              setEixoxg(mez)
              setDatitg(ger)
              setMediag(mdg)

            } else {
              console.log("Não encontrado (DU)")
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

    axios.get(`${barra.urlbase}/apicrud/apexchart3.php?loja=1&empr=${1}&anob=${anoBase}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO: ", sucesso);

        const dia = []
        const stt = []
        const dif = []

        if (sucesso) {
              console.log("APEX3: ", res.data.userlist.userdata);
              setEixox3(res.data.userlist.userdata)
              setMesa(res.data.userlist.userdata[0].mesat)
            } else {
              setEixox3([])
              setMesa('')
              console.log("Não encontrado (DU)")
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    }) 

    axios.get(`${barra.urlbase}/apicrud/apexchart3.php?loja=2&empr=${2}&anob=${anoBase}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO: ", sucesso);

        const dia = []
        const stt = []
        const dif = []

        if (sucesso) {
              console.log("APEX3: ", res.data.userlist.userdata);
              setEixox4(res.data.userlist.userdata)
              setMesa(res.data.userlist.userdata[0].mesat)
            } else {
              setEixox4([])
              setMesa('')
              console.log("Não encontrado (DU)")
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    }) 
    
    axios.get(`${barra.urlbase}/apicrud/apexchart3.php?loja=3&empr=${3}&anob=${anoBase}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO: ", sucesso);

        const dia = []
        const stt = []
        const dif = []

        if (sucesso) {
              console.log("APEX3: ", res.data.userlist.userdata);
              setEixox5(res.data.userlist.userdata)
              setMesa(res.data.userlist.userdata[0].mesat)
            } else {
              setEixox5([])
              setMesa('')
              console.log("Não encontrado (DU)")
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })     
  }


  //const cat = ["Jan","Fev","Mar","Abr","Mai","Jun"]
  //const itu = [45, 50, 80, 60, 70, 90]
  //const gua = [10, 20, 30, 40, 50, 60]
  //const med = [40, 40, 40, 40, 40, 40]
  
  const options = {
    title: {
      text: 'Faturamento das lojas',
      align: 'center',
      style: {
        fontSize: '24px',
        fontWeight: '700', 
        
      }
    },
    xaxis: {
      categories: eixox
    }
  };
  const series = [
    {
      name: "Media Ituverava",
      data: media
    },
    {
      name: "Media Guara",
      data: medi2
    },
    {
      name: "Ituverava",
      data: datit
    },
    {
      name: "Guará",
      data: datgu
    }
  ];

 
  const options1 = {
    title: {
      text: 'Contas a pagar',
      align: 'center',
      style: {
        fontSize: '24px',
        fontWeight: '700', 
        
      }
    },
    xaxis: {
      categories: eixoxg
    }
  };
  const series1 = [
    {
      name: "Média do ano",
      data: mediag
    },
    {
      name: "Grupo",
      data: datitg
    },
  ];

  const options3 = {
    title: {
      text: "fechamento " + mesa,
      align: 'center',
      style: {
        fontSize: '24px',
        fontWeight: '700', 
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['blue']
    },
    legend: {
      show: true
    },
    xaxis: {
      categories: eixox3
    },
    yaxis: {
      tickAmount: 3,
      min: 0,
      max: 3,
    }
  };
  const series3 = [
    {
      name: "Situação",
      data: stat
    },
  

  ];

  if (atual) {
    leRegistros()
    setAtual(false)
  }

  return (
  <>
      <Chart options={options} series={series} type="area" style={{width: "30%", marginLeft: "1%", marginTop: "5%"}} />
      <Chart options={options1} series={series1} type="area" style={{width: "30%", marginLeft: "55%", marginTop: "-20%"}} />
      {/*<Chart options={options3} series={series3} type="bar" style={{width: "40%", marginLeft: "5%", marginTop: "5%"}} />*/}

      <div style={{width: "100%", marginTop: "2%", color: "#202020"}}>
        <center>
        <table width="96%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="33%" align="center">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr><td colSpan="11" align="center"><span style={{fontSize: "20px"}}>Ituverava {mesa}</span></td></tr>
                      {Object.values(eixox3).map(item => ( 
                                  
                      <tr key={item.iden} >
                        <td width="5%" align="center">&nbsp;</td>
                        <td width="10%" align="left" >
                          <table width="100%" border={item.col01 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col01 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia01}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt01}</span></td></tr>
                          </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col02 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col02 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia02}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt02}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col03 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col03 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia03}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt03}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col04 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col04 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia04}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt04}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col05 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col05 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia05}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt05}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col06 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col06 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia06}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt06}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col07 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col07 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia07}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt07}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col08 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col08 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia08}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt08}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col09 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col09 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia09}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt09}</span></td></tr>
                            </table>
                        </td>
                        <td width="5%" align="center"></td>
                    </tr>
                  ))}

                    </tbody>
                </table>              
            </td>
            <td width="34%" align="center">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr><td colSpan="11" align="center"><span style={{fontSize: "20px"}}>Guará {mesa}</span></td></tr>
                      {Object.values(eixox4).map(item => ( 
                                  
                      <tr key={item.iden} >
                        <td width="5%" align="center">&nbsp;</td>
                        <td width="10%" align="left" >
                          <table width="100%" border={item.col01 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col01 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia01}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt01}</span></td></tr>
                          </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col02 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col02 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia02}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt02}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col03 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col03 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia03}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt03}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col04 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col04 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia04}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt04}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col05 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col05 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia05}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt05}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col06 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col06 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia06}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt06}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col07 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col07 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia07}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt07}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col08 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col08 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia08}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt08}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col09 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col09 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia09}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt09}</span></td></tr>
                            </table>
                        </td>
                        <td width="5%" align="center"></td>
                    </tr>
                  ))}

                    </tbody>
                </table>               
            </td>
            <td width="33%" align="center">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr><td colSpan="11" align="center"><span style={{fontSize: "20px"}}>Container 1 {mesa}</span></td></tr>
                      {Object.values(eixox5).map(item => ( 
                                  
                      <tr key={item.iden} >
                        <td width="5%" align="center">&nbsp;</td>
                        <td width="10%" align="left" >
                          <table width="100%" border={item.col01 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col01 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia01}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt01}</span></td></tr>
                          </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col02 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col02 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia02}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt02}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col03 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col03 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia03}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt03}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col04 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col04 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia04}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt04}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col05 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col05 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia05}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt05}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col06 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col06 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia06}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt06}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col07 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col07 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia07}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt07}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col08 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col08 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia08}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt08}</span></td></tr>
                            </table>
                        </td>
                        <td width="10%" align="left" bgcolor={item.color}>
                          <table width="100%" border={item.col09 === '#FFFFFF' ? ("0"):("1")} style={{backgroundColor: item.col09 }}>
                            <tr><td align="center"><span  style={{fontSize: "14px", fontWeight: "800", }}>{item.dia09}</span></td></tr>
                            <tr><td align="center"><span  style={{fontSize: "10px", }}>{item.stt09}</span></td></tr>
                            </table>
                        </td>
                        <td width="5%" align="center"></td>
                    </tr>
                  ))}

                    </tbody>
                </table>               
            </td>
          </tr>
        </table>

        </center>

      </div>
  </>
  )

}

export default ApexChart2;
