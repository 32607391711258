import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const MovimentaEstoque = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }


    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        setLoading(false)
        navigate("/estoque-mover");
}, [loading, navigate]);  


    const buscarMovimentaEstoque = async (xv, di, df) => {
        try {

            if (di === undefined)   {  di = ''  }  
            if (df === undefined)   {  df = ''  }  
            if (xv === undefined)   {  xv = ''; }

            let kpo = "i=" + di + "&f=" + df + "&x=" + xv + "&e=" + barra.cempresa
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/buscarmovimentaestoque.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("NF: ", res)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setProdutos(res.data.userlist.userdata);
                        setLoading(true);

                } else {
                        console.log("Não encontrado")

                        let motivo    = res.data.motivo;
                        setLoading(true);
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(false);
                }
            })
        } catch (error) { throw error;} 
    
        return

    }   

    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("buscar títulos", e );

        setLoading(false)
        buscarMovimentaEstoque(inputs.xave,inputs.datai, inputs.dataf);
      }

    const limparCampos = () => {
        setLoading(true)
        setInputs([])
        setProdutos([])
        setLoading(false)
        document.getElementById('xave').value = ''
        document.getElementById('datai').value = ''
        document.getElementById('dataf').value = ''
    }


    return (
        
        
        <>
         
            <div className="form" style={{position: "fixed", top:"2%", left:"75%", zIndex:"999"}}>
                <span style={{color: "white"}}>Usuário: {barra.nusuario} (Cod.: {barra.cusuario}) Empresa: {barra.cempresa}</span>
            </div> 

            <form className="Container" onSubmit={handleSubmit}>
                <Container>
                    <ConteudoTitulo>
                        <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px", }}>CONSULTA MOVIMENTAÇÃO NO INVENTÁRIO</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ConteudoTitulo>
            
                    <div className="contact">
                        <div className="col-md-11">
                            <div className="card" style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1 }}> 
                                    <div className="card-body" style={{marginTop: "1%", backgroundColor: barra.corProdu,  }}>
                                        <div className="row">
                                            <div className="col-md-12" style={{marginTop: "-1%"}}>

                                                <div className="d-inline p-2" >
                                                    <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", color: "black", }}>Produto: </label>
                                                    <input type="text" id="xave" name="xave" placeholder="NOME (ou parte do NOME) / CÓDIGO" value={inputs.xave} onChange={handleChange} style={{width: "20%", borderRadius: "15px"}}/>
                                                </div>                                    
                                                <div className="d-inline p-2">
                                                    <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px",color: "black", }}>Data inicial: </label>
                                                    <input type="date" id="datai" name="datai" value={inputs.datai} onChange={handleChange} style={{ borderRadius: "15px"}}/>
                                                </div>
                                                <div className="d-inline p-2">
                                                    <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px",color: "black", }}>Data Final: </label>
                                                    <input type="date" id="dataf" name="dataf" value={inputs.dataf} onChange={handleChange} style={{ borderRadius: "15px"}}/>
                                                </div>
                                                <div className="d-inline p-2">
                                                    <button type="submit" name="buscar" className="botao-verde" style={{ }} > Pesquisar </button>&nbsp;
                                                    <Button onClick={limparCampos} className="botao-bege" style={{ }} >Limpar</Button>
                                                </div>
                                            </div>                               
                                            <div className="col-md-12">

                                            </div>
                                        </div>
                                        <hr />    
                                    </div>
                            </div>
                        </div>
                    </div>

                    <Row style={{display: "block", position: "relative", marginLeft: "240px", marginTop: "5%"}}>
                        <Col>

                        <div className="col-md-11" >
                                <form className="form">
                                <div style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1}}>
                                    <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: 500, fontsize: 20, color: barra.corLetraBarra}}>MOVIMENTAÇÕES REALIZADAS</span>
                                                        
                                    <table  class="table table-striped" style={{fontSize: 12,}}>
                                        <thead>
                                            <th align="left">código</th>
                                            <th align="left">produto</th>
                                            <th align="left" >data</th>
                                            <th align="left">hora</th>
                                            <th align="left">evento</th>
                                            <th align="center">qtd</th>
                                            <th align="center">saldo</th>
                                            <th align="left">usuário</th>
                                        </thead>
                                        <tbody>
                                            {produtos.map( (item) =>
                                                
                                                <tr key={item.id}>
                                                    <td align="left">{item.codigo}</td>
                                                    <td align="left">{item.produto}</td>
                                                    <td align="left">{item.data}</td>
                                                    <td align="left">{item.hora}</td>
                                                    <td align="left" >{item.evento}</td>
                                                    <td align="center" >{item.qtd}</td>
                                                    <td align="center" >{item.saldo}</td>
                                                    <td align="left">{item.usuario}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                                </form>
                            </div>

                        </Col>

                    </Row>

            </Container>
        </form>

        
        </>
    );
};


export default MovimentaEstoque