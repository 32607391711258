import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const FluxoCaixa = () => {

  const barra = useContext(AuthContext);

  const navigate = useNavigate();
  if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
    barra.setSidebar(false);
    localStorage.clear("token")
    localStorage.setItem("encerrar", JSON.stringify("sim"));
    barra.avisar('Sessão expirou. Por favor, faça um novo login.')
    navigate("/");
}


  const [loading, setLoading] = useState(false);
  const [linhas, setLinhas] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [titulo, setTitulo] = useState('')

  useEffect(() => {
    setLoading(false);
    navigate("/fluxo");
  }, [loading, navigate]);  

const buscarFluxo = async (di, df) => {
    try {

        if (di === undefined)   {  di = ''  }  
        if (df === undefined)   {  df = ''  }  

        let kpo = "i=" + di + "&f=" + df + "&e=" + barra.cempresa
        console.log(kpo)
        axios.get(`${barra.urlbase}/apicrud/buscarlancamentos.php?${kpo}`, inputs).then(function(res) {
            let sucesso    = res.data.success;
            console.log("TITULOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setLinhas(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Não encontrado")

                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}   
  const handleChange = (event) => {
    const nome  = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [nome]: value}));
}

const handleSubmit = (e) => {
    e.preventDefault();
    console.log("buscar lançamentos: ", e );
    if (inputs.datai === undefined || inputs.datai === '' || inputs.dataf === undefined || inputs.dataf === '') { 
        barra.avisar("Por favor, selecione o intervalo de datas desejado", 5)
        return
    }
    let wk = String(inputs.datai)
    let di = wk.substring(8,10) + '-' + wk.substring(5,7) + '-' + wk.substring(0,4)
    wk = String(inputs.dataf)
    let df = wk.substring(8,10) + '-' + wk.substring(5,7) + '-' + wk.substring(0,4)

    setTitulo("LANÇAMENTOS DO DIA " + di + " ATÉ O DIA " + df)
    setLoading(false)
    buscarFluxo(inputs.datai, inputs.dataf);
  }


  const limparCampos = () => {
    setTitulo('')
    setLoading(true)
    setInputs([])
    setLinhas([])
    setLoading(false)
    document.getElementById('datai').value = ''
    document.getElementById('dataf').value = ''

  }

  return (
    <>


        <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 80, backgroundColor: barra.corBarra, textAlign: "left", borderRadius: "5px", }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px"}}>FLUXO DE CAIXA NO PERÍODO</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>
            
            <div className="Container">
                <div className="col-md-12">
                    <div className="form" style={{ backgroundColor: barra.corProdu,  borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1 }}>
                        <div className="card-body">
                            <div className="row">
                                <form className="form"  onSubmit={handleSubmit}>
                                    <div className="col-md-12">
                                        <div className="d-inline p-2">
                                            <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px",}}>Data inicial: </label>
                                            <input type="date" id="datai" name="datai" value={inputs.datai} onChange={handleChange} />
                                        </div>
                                        <div className="d-inline p-2">
                                            <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px",}}>Data Final: </label>
                                            <input type="date" id="dataf" name="dataf" value={inputs.dataf} onChange={handleChange} />
                                        </div>

                                        <div className="d-inline p-2"></div>
                                        <button type="submit" name="buscar" className="btn btn-primary" style={{ marginLeft: "8%", backgroundColor: barra.corBotao}}> Buscar Lançamentos </button>
                                        <Button onClick={limparCampos} className="btn-warning">Limpar</Button>
                                    </div>
                                </form>
                            </div>
                          <hr />    
                        </div>
                    </div>
                    
                    <div className="col-md-12" >
                        <div style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1}}>
                            <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: 500, fontsize: 20, color: "black"}}>{titulo}</span>
                                                   
                            <table  class="table table-striped" style={{fontSize: 12,}}>
                                <thead>
                                    <th align="center">vancimento</th>
                                    <th align="center">número</th>
                                    <th align="left"  >parcela</th>
                                    <th align="center">nome</th>
                                    <th align="left"  >valor</th>
                                    <th align="left"  >oper.</th>
                                    <th align="left"  >crédito</th>
                                    <th align="center">débito</th>
                                    <th align="center">saldo</th>
                                </thead>
                                <tbody>
                                    {linhas.map( (item) =>
                                        
                                        <tr key={item.id}>
                                            <td align="center">{item.vcto}</td>
                                            <td align="center">{item.nume}</td>
                                            <td align="center">{item.parc}</td>
                                            <td align="center">{item.nome}</td>
                                            <td align="right" >{item.valo}</td>
                                            <td align="right" >{item.sent}</td>
                                            <td align="left"  >{item.cred}</td>
                                            <td align="left"  >{item.debi}</td>
                                            <td align="left"  >{item.sald}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>

        </Container>

    
    </>
  )
}

export default FluxoCaixa