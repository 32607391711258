import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Table, Titulo } from '../../page/styles';


const CriarGraficos = () => {

    const barra = useContext(AuthContext);
    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
      barra.setSidebar(false);
      localStorage.clear("token")
      localStorage.setItem("encerrar", JSON.stringify("sim"));
      barra.avisar('Sessão expirou. Por favor, faça um novo login.')
      navigate("/");
  }   

    const [opcao, setOpcao] = useState('')
    const nome_tabela = "ccs_grafico"
    const [titulo_modal, setTituloModal] = useState("Editar Gráfico")
    const [titulo_botao, setTituloBotao] = useState("Salvar Modificações")
    const nome_colunas = [ 
      {id: '1',  ident: 'Nome'}, 
      {id: '2',  ident: 'Eixo Y'}, 
      {id: '3',  ident: 'Eixo X'}, 
      {id: '4',  ident: 'Tipo'}, 
      {id: '5',  ident: 'Ordem'},
      {id: '6',  ident: 'Elementos'},
      {id: '7',  ident: 'Condição'}, 
      {id: '8',  ident: 'Chave'}, 
      {id: '9',  ident: 'Painel'}
    ]

    const numero_colunas = 10
    const grupos = [
        {
          label: "Chamados",
          value: "2",
        },
        {
          label: "Clientes",
          value: "3",
        },
        {
          label: "Compras",
          value: "4",
        },
        {
          label: "Contas a Pagar",
          value: "5",
        },
        {
          label: "Contas a Receber",
          value: "6",
        },
        {
            label: "Despesas",
            value: "7",
          },
          {
            label: "Inventário",
            value: "8",
          },
          {
            label: "Receitas",
            value: "9",
          },
          {
            label: "Vendas",
            value: "10",
          },
    ];  

    const [campos, setCampos] = useState([])

    /*
    const camposx = [
    { id: "0",  grupo: "2", campo: "a.data_hora",                                        label: "Data do Registro",                  tipo: "T", },
    { id: "1",  grupo: "2", campo: "a.data_hora_visto",                                  label: "Data da Leitura",                   tipo: "T", },
    { id: "2",  grupo: "2", campo: "a.data_hora_respondido",                             label: "Data da Solução",                   tipo: "T", },
    { id: "3",  grupo: "2", campo: "a.status",                                           label: "Situação do Chamado",               tipo: "V", },
    { id: "4",  grupo: "2", campo: "DATEDIFF(NOW(), a.data_hora)",                       label: "Dias desde o Registro",             tipo: "I", },
    { id: "5",  grupo: "2", campo: "DATEDIFF(a.data_hora_visto, a.data_hora)",           label: "Dias entre o Registro e a Leitura", tipo: "I", },
    { id: "6",  grupo: "2", campo: "DATEDIFF(a.data_hora_resolvido, a.data_hora)",       label: "Dias entre o Registro e a Solução", tipo: "I", },
    { id: "7",  grupo: "2", campo: "DATEDIFF(a.data_hora_visto, a.data_hora_resolvido)", label: "Dias entre a Leitura e a Solução",  tipo: "I", },
    { id: "8",  grupo: "2", campo: "a.id",                                               label: "Quantidade de Chamados",            tipo: "I", },
    
    { id: "9",  grupo: "3", campo: "a.numero",                                              label: "Número Nota Fiscal",         tipo: "I", },
    { id: "10", grupo: "3", campo: "a.data_emissao",                                        label: "Data da Compra",             tipo: "D", },
    { id: "11", grupo: "3", campo: "a.emitente_nome",                                       label: "fornecedor",                 tipo: "V", },
    { id: "12", grupo: "3", campo: "a.emitente_cpf_cnpj",                                   label: "Doc.Fornecedor",             tipo: "V", },
    { id: "13", grupo: "3", campo: "a.destinatario_nome",                                   label: "Cliente",                    tipo: "V", },
    { id: "14", grupo: "3", campo: "a.destinatario_cpf_cnpj",                               label: "Doc.Cliente",                tipo: "V", },
    { id: "15", grupo: "3", campo: "a.transp_nome",                                         label: "Transportadora",             tipo: "V", },
    { id: "16", grupo: "3", campo: "a.valor_total_nota",                                    label: "Valor Total da Nota",        tipo: "F", },
    { id: "17", grupo: "3", campo: "a.valor_produtos",                                      label: "Valor dos Produtos",         tipo: "F", },
    { id: "18", grupo: "3", campo: "(a.valor_total_nota - a.valor_produtos)",               label: "Valor dos Custos",           tipo: "F", },
    { id: "19", grupo: "3", campo: "a.situacao",                                            label: "Situação da Nota Fiscal",    tipo: "V", },
    { id: "20", grupo: "3", campo: "b.nome_produto",                                        label: "Nome do Produto",            tipo: "V", },
    { id: "21", grupo: "3", campo: "b.codigo_produto",                                      label: "Código do Produto",          tipo: "V", },
    { id: "22", grupo: "3", campo: "b.qtd",                                                 label: "Quantidade Pedida",          tipo: "F", },
    { id: "23", grupo: "3", campo: "b.recebido",                                            label: "Quantidade Recebida",        tipo: "F", },
    { id: "24", grupo: "3", campo: "b.valor_total",                                         label: "Valor do Produto",           tipo: "F", },
    { id: "25", grupo: "3", campo: "COUNT(DISTINCT(b.id_nota))",                            label: "Quantidade de Notas Fiscais",tipo: "I", },
    
    { id: "26", grupo: "4", campo: "a.numero",                                              label: "Número Nota Fiscal",         tipo: "I", },
    { id: "27", grupo: "4", campo: "a.data_emissao",                                        label: "Data de Emissão",            tipo: "D", },
    { id: "28", grupo: "4", campo: "a.emitente_nome",                                       label: "Emitente",                   tipo: "V", },
    { id: "29", grupo: "4", campo: "a.emitente_cpf_cnpj",                                   label: "Doc.Emitente",               tipo: "V", },
    { id: "30", grupo: "4", campo: "a.destinatario_nome",                                   label: "Destinatário",               tipo: "V", },
    { id: "31", grupo: "4", campo: "a.destinatario_cpf_cnpj",                               label: "Doc.Destinatário",           tipo: "V", },
    { id: "32", grupo: "4", campo: "a.transp_nome",                                         label: "Transportadora",             tipo: "V", },
    { id: "33", grupo: "4", campo: "a.valor_total_nota",                                    label: "Valor Total da Nota",        tipo: "F", },
    { id: "34", grupo: "4", campo: "a.valor_produtos",                                      label: "Valor dos Produtos",         tipo: "F", },
    { id: "35", grupo: "4", campo: "(a.valor_total_nota - a.valor_produtos)",               label: "Valor dos Custos",           tipo: "F", },
    { id: "36", grupo: "4", campo: "a.situacao",                                            label: "Situação da Nota Fiscal",    tipo: "V", },
    { id: "37", grupo: "4", campo: "b.nome_produto",                                        label: "Nome do Produto",            tipo: "V", },
    { id: "38", grupo: "4", campo: "b.codigo_produto",                                      label: "Código do Produto",          tipo: "V", },
    { id: "39", grupo: "4", campo: "b.qtd",                                                 label: "Quantidade Pedida",          tipo: "F", },
    { id: "40", grupo: "4", campo: "b.recebido",                                            label: "Quantidade Recebida",        tipo: "F", },
    { id: "41", grupo: "4", campo: "b.valor_total",                                         label: "Valor do Produto",           tipo: "F", },
    { id: "42", grupo: "4", campo: "r.data_recebimento",                                    label: "Data do recebimento",        tipo: "D", },
    { id: "43", grupo: "4", campo: "r.qtd",                                                 label: "Quantidade Recebida",        tipo: "F", },
    { id: "44", grupo: "4", campo: "CONCAT(u.nome,' ',u.sobrenome)",                        label: "Nome do Usuário",            tipo: "V", },
    { id: "45", grupo: "4", campo: "COUNT(DISTINCT(b.id_nota))",                            label: "Quantidade de Notas Fiscais",tipo: "I", },
    
    { id: "46", grupo: "5", campo: "a.data_emissao",                                        label: "Data de Emissão",              tipo: "D", },
    { id: "47", grupo: "5", campo: "a.emitente_nome",                                       label: "Credor",                       tipo: "V", },
    { id: "48", grupo: "5", campo: "a.emitente_cpf_cnpj",                                   label: "Doc. Credor",                  tipo: "V", },
    { id: "49", grupo: "5", campo: "a.destinatario_nome",                                   label: "Cliente",                      tipo: "V", },
    { id: "50", grupo: "5", campo: "a.destinatario_cpf_cnpj",                               label: "Doc. Cliente",                 tipo: "V", },
    { id: "51", grupo: "5", campo: "b.vencimento",                                          label: "Vencimento",                   tipo: "D", },
    { id: "52", grupo: "5", campo: "b.valor",                                               label: "Valor",                        tipo: "F", },
    { id: "53", grupo: "5", campo: "b.valor_recebido",                                      label: "Valor Recebido",               tipo: "F", },
    { id: "54", grupo: "5", campo: "b.id",                                                  label: "Quantidade de Títulos a Pagar",tipo: "I", },
    { id: "55", grupo: "5", campo: "(b.valor - b.valor_recebido)",                          label: "Situação do Título",           tipo: "F", },

    { id: "56", grupo: "6", campo: "a.data_emissao",                                        label: "Data de Emissão",                tipo: "D", },
    { id: "57", grupo: "6", campo: "a.emitente_nome",                                       label: "Credor",                         tipo: "V", },
    { id: "58", grupo: "6", campo: "a.emitente_cpf_cnpj",                                   label: "Doc. Credor",                    tipo: "V", },
    { id: "59", grupo: "6", campo: "a.destinatario_nome",                                   label: "Cliente",                        tipo: "V", },
    { id: "60", grupo: "6", campo: "a.destinatario_cpf_cnpj",                               label: "Doc. Cliente",                   tipo: "V", },
    { id: "61", grupo: "6", campo: "b.vencimento",                                          label: "Vencimento",                     tipo: "D", },
    { id: "62", grupo: "6", campo: "b.valor",                                               label: "Valor",                          tipo: "F", },
    { id: "63", grupo: "6", campo: "b.valor_recebido",                                      label: "Valor Pago",                     tipo: "F", },
    { id: "64", grupo: "6", campo: "b.id",                                                  label: "Quantidade de Títulos a Receber",tipo: "I", },
    { id: "65", grupo: "6", campo: "(b.valor - b.valor_recebido)",                          label: "Situação do Título",             tipo: "F", },

    { id: "66", grupo: "7", campo: "b.nome",                                                label: "Grupo de Despesa",             tipo: "V", },
    { id: "67", grupo: "7", campo: "c.nome",                                                label: "Conta de Despesa",             tipo: "V", },
    { id: "68", grupo: "7", campo: "a.id",                                                  label: "Quantidade de Títulos",        tipo: "I", },
    { id: "69", grupo: "7", campo: "a.vencimento",                                          label: "Vencimento",                   tipo: "D", },
    { id: "70", grupo: "7", campo: "a.valor",                                               label: "Valor",                        tipo: "F", },
    
    { id: "71", grupo: "8", campo: "a.nome",                                                label: "Nome do Produto",              tipo: "V", },
    { id: "72", grupo: "8", campo: "a.codigo_produto",                                      label: "Código do produto",            tipo: "V", },
    { id: "73", grupo: "8", campo: "a.minimo",                                              label: "Estoque Mínimo",               tipo: "F", },
    { id: "74", grupo: "8", campo: "a.atual",                                               label: "Estoque Atual",                tipo: "F", },
    { id: "75", grupo: "8", campo: "b.data",                                                label: "Data",                         tipo: "D", },
    { id: "76", grupo: "8", campo: "b.hora",                                                label: "Hora",                         tipo: "V", },
    { id: "77", grupo: "8", campo: "b.sentido",                                             label: "Código da Operação",           tipo: "V", },
    { id: "78", grupo: "8", campo: "b.qtd",                                                 label: "Quantidade",                   tipo: "F", },
    { id: "79", grupo: "8", campo: "c.nome as operacao",                                    label: "Nome da Operacao",             tipo: "V", },
    { id: "80", grupo: "8", campo: "b.referencia",                                          label: "Referencia",                   tipo: "V", },
    { id: "81", grupo: "8", campo: "b.saldo_anterior",                                      label: "Saldo Anterior",               tipo: "F", },
    { id: "82", grupo: "8", campo: "b.saldo_posterior",                                     label: "Saldo Posterior",              tipo: "F", },
    { id: "83", grupo: "8", campo: "CONCAT(u.nome,' ',u.sobrenome) ",                       label: "Nome do Usuário",              tipo: "V", },
    { id: "84", grupo: "8", campo: "COUNT(DISTINCT(b.id_invoice))",                         label: "Quantidade de Notas Fiscais",  tipo: "I", },
    
    { id: "85", grupo: "9", campo: "b.nome",                                                label: "Grupo de Receita",             tipo: "V", },
    { id: "86", grupo: "9", campo: "c.nome",                                                label: "Forma de Recebimento",         tipo: "V", },
    { id: "87", grupo: "9", campo: "a.id",                                                  label: "Quantidade de Títulos",        tipo: "I", },
    { id: "88", grupo: "9", campo: "a.vencimento",                                          label: "Vencimento",                   tipo: "D", },
    { id: "89", grupo: "9", campo: "a.valor",                                               label: "Valor",                        tipo: "F", },
    
    { id: "90", grupo: "10", campo: "b.nome_produto",                                       label: "Nome do Produto",               tipo: "V", },
    { id: "91", grupo: "10", campo: "b.codigo_produto",                                     label: "Código do produto",             tipo: "V", },
    { id: "92", grupo: "10", campo: "a.data_emissao",                                       label: "Data de Venda",                 tipo: "D", },
    { id: "93", grupo: "10", campo: "a.emitente_nome",                                      label: "Loja",                          tipo: "V", },
    { id: "94", grupo: "10", campo: "a.emitente_cpf_cnpj",                                  label: "Doc. Loja",                     tipo: "V", },
    { id: "95", grupo: "10", campo: "a.destinatario_nome",                                  label: "Cliente",                       tipo: "V", },
    { id: "96", grupo: "10", campo: "a.destinatario_cpf_cnpj",                              label: "Doc. Cliente",                  tipo: "V", },
    { id: "97", grupo: "10", campo: "a.valor_produtos",                                     label: "Valor dos Produtos",            tipo: "F", },
    { id: "98", grupo: "10", campo: "a.valor_total_nota",                                   label: "Valor da Venda",                tipo: "F", },
    { id: "99", grupo: "10", campo: "b.qtd",                                                label: "Quantidade",                    tipo: "F", },
    { id: "100", grupo: "10", campo: "b.valor_total",                                       label: "Valor do Produto",              tipo: "F", },
    { id: "101", grupo: "10", campo: "b.cfop",                                              label: "Classificação Fiscal",          tipo: "V", },
    { id: "102", grupo: "10", campo: "COUNT(DISTINCT(id_nota))",                            label: "Quantidade de Notas de Vendas", tipo: "I", },
    { id: "103", grupo: "10", campo: "(a.valor_total_nota - a.valor_produtos)",             label: "Custos de Vendas",              tipo: "F", },    

    { id: "104", grupo: "4", campo: "b.cfop",                                               label: "Classificação Fiscal",       tipo: "V", },
  ]
    


    const contas = [
        { grupo: "2",  label: "Chamados Recebidos",          value: "1",  Complemento: "N",   },
        { grupo: "2",  label: "Chamados Lidos",              value: "2",  Complemento: "N",   },
        { grupo: "2",  label: "Chamados Solucionados",       value: "3",  Complemento: "N",   },
        { grupo: "2",  label: "Chamados Por Status",         value: "4",  Complemento: "N",   },
        { grupo: "3",  label: "Número de clientes",          value: "5",  Complemento: "N",   },
        { grupo: "3",  label: "Número de Clientes Únicos",   value: "6",  Complemento: "N",   },
        { grupo: "3",  label: "Compras por Cliente",         value: "7",  Complemento: "N",   },
        { grupo: "4",  label: "Por Produto",                 value: "8",  Complemento: "N",   },
        { grupo: "4",  label: "Total de Compras",            value: "9", Complemento: "N",   },
        { grupo: "4",  label: "Por Fornecedor",              value: "10", Complemento: "N",   },
        { grupo: "4",  label: "Por Transportador",           value: "11", Complemento: "N",   },
        { grupo: "4",  label: "Por Tipo de Produto",         value: "12", Complemento: "S",   },
        { grupo: "5",  label: "Total de Contas a Pagar",     value: "13", Complemento: "N",   },
        { grupo: "5",  label: "Pagas",                       value: "14", Complemento: "N",   },
        { grupo: "5",  label: "Em Aberto",                   value: "15", Complemento: "N",   },
        { grupo: "5",  label: "Por Situação",                value: "16", Complemento: "N",   },
        { grupo: "6",  label: "Por Modalidade",              value: "17", Complemento: "N",   },
        { grupo: "6",  label: "Total de Contas a Receber",   value: "18", Complemento: "N",   },
        { grupo: "7",  label: "Por Grupo de Despesa",        value: "19", Complemento: "N",   },
        { grupo: "7",  label: "Total de Despesas",           value: "20", Complemento: "N",   },
        { grupo: "8",  label: "Total de Produtos",           value: "21", Complemento: "N",   },
        { grupo: "8",  label: "Movimentações",               value: "22", Complemento: "N",   },
        { grupo: "9",  label: "Por Forma de Receita",        value: "23", Complemento: "N",   },
        { grupo: "9",  label: "Total de Receitas",           value: "24", Complemento: "N",   },
        { grupo: "10", label: "Por Produto",                 value: "25", Complemento: "N",   },
        { grupo: "10", label: "Por Tipo de Produto",         value: "26", Complemento: "S",   },
        { grupo: "10", label: "Por Faixa de Valor",          value: "27", Complemento: "S",   },
        { grupo: "10", label: "Total de Vendas",             value: "28", Complemento: "N",   },
    ]

    const metrica = [
        { grupo: "2", nome: "Quantidades", value: "2", },
        { grupo: "2", nome: "Percentuais", value: "3", },
        { grupo: "3", nome: "Valores",     value: "1", },
        { grupo: "3", nome: "Quantidades", value: "2", },
        { grupo: "3", nome: "Percentuais", value: "3", },
        { grupo: "4", nome: "Valores",     value: "1", },
        { grupo: "4", nome: "Quantidades", value: "2", },
        { grupo: "4", nome: "Percentuais", value: "3", },
        { grupo: "5", nome: "Valores",     value: "1", },
        { grupo: "5", nome: "Quantidades", value: "2", },
        { grupo: "5", nome: "Percentuais", value: "3", },
        { grupo: "6", nome: "Valores",     value: "1", },
        { grupo: "6", nome: "Quantidades", value: "2", },
        { grupo: "6", nome: "Percentuais", value: "3", },
        { grupo: "7", nome: "Valores",     value: "1", },
        { grupo: "7", nome: "Quantidades", value: "2", },
        { grupo: "7", nome: "Percentuais", value: "3", },
        { grupo: "8", nome: "Valores",     value: "1", },
        { grupo: "8", nome: "Quantidades", value: "2", },
        { grupo: "8", nome: "Percentuais", value: "3", },
        { grupo: "9", nome: "Valores",     value: "1", },
        { grupo: "9", nome: "Quantidades", value: "2", },
        { grupo: "9", nome: "Percentuais", value: "3", },
        { grupo: "10", nome: "Valores",     value: "1", },
        { grupo: "10", nome: "Quantidades", value: "2", },
        { grupo: "10", nome: "Percentuais", value: "3", },
    ]

    const quebra = [
        { grupo: "2", nome: "Ano", value: "1", },  { grupo: "2", nome: "Mês", value: "2", },         { grupo: "2", nome: "Dia", value: "3", },       

        { grupo: "3", nome: "Ano", value: "1", },  { grupo: "3", nome: "Mês", value: "2", },         { grupo: "3", nome: "Dia", value: "3", },       
        { grupo: "3", nome: "Loja", value: "4", }, { grupo: "3", nome: "Produto", value: "6", }, 

        { grupo: "4", nome: "Ano", value: "1", },  { grupo: "4", nome: "Mês", value: "2", },         { grupo: "4", nome: "Dia", value: "3", },       
        { grupo: "4", nome: "Loja", value: "4", }, { grupo: "4", nome: "Responsável", value: "5", }, { grupo: "4", nome: "Produto", value: "6", }, 
        { grupo: "4", nome: "Modalidade", value: "7", },

        { grupo: "5", nome: "Ano", value: "1", },  { grupo: "5", nome: "Mês", value: "2", },         { grupo: "5", nome: "Dia", value: "3", },       
        { grupo: "5", nome: "Loja", value: "4", }, { grupo: "5", nome: "Responsável", value: "5", }, 

        { grupo: "6", nome: "Ano", value: "1", },  { grupo: "6", nome: "Mês", value: "2", },         { grupo: "6", nome: "Dia", value: "3", },       
        { grupo: "6", nome: "Loja", value: "4", }, { grupo: "6", nome: "Responsável", value: "5", }, 

        { grupo: "7", nome: "Ano", value: "1", },  { grupo: "7", nome: "Mês", value: "2", },         { grupo: "7", nome: "Dia", value: "3", },       
        { grupo: "7", nome: "Loja", value: "4", }, { grupo: "7", nome: "Responsável", value: "5", }, { grupo: "7", nome: "Modalidade", value: "7", },

        { grupo: "8", nome: "Ano", value: "1", },  { grupo: "8", nome: "Mês", value: "2", },         { grupo: "8", nome: "Dia", value: "3", },       
        { grupo: "8", nome: "Loja", value: "4", }, { grupo: "8", nome: "Responsável", value: "5", }, { grupo: "8", nome: "Produto", value: "6", }, 
        { grupo: "8", nome: "Modalidade", value: "7", },

        { grupo: "9", nome: "Ano", value: "1", },  { grupo: "9", nome: "Mês", value: "2", },         { grupo: "9", nome: "Dia", value: "3", },       
        { grupo: "9", nome: "Loja", value: "4", }, { grupo: "9", nome: "Responsável", value: "5", }, { grupo: "9", nome: "Modalidade", value: "7", },
        
        { grupo: "10", nome: "Ano", value: "1", },  { grupo: "10", nome: "Mês", value: "2", },         { grupo: "10", nome: "Dia", value: "3", },       
        { grupo: "10", nome: "Loja", value: "4", }, { grupo: "10", nome: "Responsável", value: "5", }, { grupo: "10", nome: "Produto", value: "6", }, 
        { grupo: "10", nome: "Modalidade", value: "7", },

      ]
*/

    const tipo = [
        { nome: "Barra Vertical",        Value: "1",   },
        { nome: "Barra Horizontal",      Value: "2",   },
        { nome: "Linha",                 Value: "3",   },
        { nome: "Área preenchida",       Value: "4",   },
        { nome: "Pizza",                 Value: "5",   },
        { nome: "Donut",                 Value: "6",   },
        { nome: "Painel",                value: "7",   },
    ]
    const [primeira, setPrimeira] = useState(true)
    const [loading, setLoading] = useState(false)


    const [ident, setIdent] = useState(0)
    const [grupo, setGrupo] = useState('')
    const [conta, setConta] = useState('')
    const [metricag, setMetricag] = useState('')
    const [quebrag, setQuebrag] = useState('')
    const [tabela, setTabela] = useState('')
    const [tipog, setTipog]=useState('');
    const [refer, setRefer]=useState('');
    const [complemento, setComplemento] = useState(0)
    const [nomeg, setNomeg]=useState('');
//    const [painel, setPainel] = useState('')

    const [nomeyg, setNomeyg]=useState('');
    const [nomexg, setNomexg]=useState('');
    const [quebracmp, setQuebracmp] = useState('')
    const [quebraval, setQuebraval] = useState('')
    const [ordem, setOrdem] = useState('')
    const [campoK, setCampoK] = useState('')
    const [operaK, setOperaK] = useState('')
    const [chaveK, setChaveK] = useState('')
    const [periodo, setPeriodo] = useState('')


    const [showN, setshowN] = useState(false)
    const [data, setData] = useState([]);

    //const [valorD, setValorD] = useState(0)
    //const [valorT, setValorT] = useState(0)
    //const [observacao, setObservacao] = useState('')
    //const [data, setData] = useState([]);


    useEffect(() => {
        setLoading(false)
        navigate("/grafico-cria");
}, [loading, navigate]);  


const buscarCampos = async (grp) => {
  try {

     console.log("GRUPO:", grp)

      axios.get(`${barra.urlbase}/apicrud/buscarcampos.php?g=${grp}&e=${barra.cempresa}`).then(function(res) {
          let sucesso    = res.data.success;
          if (sucesso) {
                  console.log(res.data.userlist.userdata);
                  setCampos(res.data.userlist.userdata);
                  setLoading(true);
          } else {
                  let motivo    = res.data.motivo;
                  setCampos([])
                  setLoading(true);
                  console.log("NÃO SUCESSO: ", motivo);
                  setLoading(false);
          }
      })
  } catch (error) { throw error;} 

  return

}  


const leRegistros = async (nome_tabela) => {

  console.log("tabela: ", nome_tabela)
  axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=0&t=${nome_tabela}`).then(function(res) {
      let sucesso    = res.data.success;
      console.log("SUCESSO (todos): ", sucesso);

      setLoading(sucesso)

      if (sucesso) {
            console.log(res.data.datalist.userdata);
            setData(res.data.datalist.userdata);

            //avisar("Registro lidos", 1)

      } else {
            console.log("Não encontrado (DU)")

            let motivo    = res.data.motivo;
            console.log("NÃO SUCESSO: ", motivo);
      }
  })                

}

const leUmRegistro = async (nome_tabela, reg) => {

  console.log("tabela: ", nome_tabela, " registro: ", reg)
  axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=${reg}&t=${nome_tabela}`).then(function(res) {
      let sucesso    = res.data.success;
      console.log("SUCESSO (um): ", sucesso);

      setLoading(sucesso)

      if (sucesso) {
            setData(res.data.datalist.userdata);
            console.log("DATA (1)", res.data.datalist.userdata);
            console.log("Parâmetros: ", res.data.datalist.userdata[0].id, " :: ", res.data.datalist.userdata[0].id.nome, "-", res.data.datalist.userdata[0].id.agencia, "-", res.data.datalist.userdata[0].id.conta)
            
            setIdent(res.data.datalist.userdata[0].id)
            setNomeg(res.data.datalist.userdata[0].nome)
            setGrupo(res.data.datalist.userdata[0].area)
            setNomeyg(res.data.datalist.userdata[0].nomex)
            setConta(res.data.datalist.userdata[0].metrica)
            setNomexg(res.data.datalist.userdata[0].nomey)
            setMetricag(res.data.datalist.userdata[0].conta)
            setQuebrag(res.data.datalist.userdata[0].quebra)
            setTabela(res.data.datalist.userdata[0].tabela)
            setTipog(res.data.datalist.userdata[0].tipo)
            setRefer(res.data.datalist.userdata[0].observacao)
            setComplemento(res.data.datalist.userdata[0].elementos)
            setQuebracmp(res.data.datalist.userdata[0].complemento_dat)
            setQuebraval(res.data.datalist.userdata[0].complemento_val)
            setOrdem(res.data.datalist.userdata[0].ordem)
            setCampoK(res.data.datalist.userdata[0].campok)
            setOperaK(res.data.datalist.userdata[0].operak)
            setChaveK(res.data.datalist.userdata[0].chavek)
            setPeriodo(res.data.datalist.userdata[0].periodo)
            //setPainel(res.data.datalist.userdata[0].nome.substring(0,4))
      
            /*
            while (grupo === undefined) {
              const interval = setInterval(() => {
                console.log('This will run every second!');
              }, 1000);
            }
            */

            console.log("GRÁFICO: ", ident, grupo, tipog, conta,nomeg, metricag,  quebrag, tabela, quebracmp, quebraval, complemento, nomeg, refer)

            //avisar("Registro lido", 1)

      } else {
            console.log("Não encontrado (DU)")

            let motivo    = res.data.motivo;
            console.log("NÃO SUCESSO: ", motivo);
      }
  })                

}

const gravaGrafico = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_grafico.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (GRF): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              barra.avisar(res.data.retorno, 2)
              handleCancel()
        } else {
              let motivo    = res.data.retorno;
              console.log("NÃO SUCESSO: ", motivo);
              barra.avisar(motivo, 5)
        }
    })
    leRegistros(nome_tabela)
     

} 


if (primeira) {
    leRegistros(nome_tabela)
    setPrimeira(false)
}


function handleSubmit(e) {
    e.preventDefault();
    let campoy = ''
    let campox = ''
    let tipoy = ''
    let tipox = ''
    try {
      campoy = campos[conta].campo
      tipoy  = campos[conta].tipo
    }
    catch { 
      campoy = 'YY'
      tipoy = 'V'
    }

    
    try {
      campox = campos[metricag].campo
      tipox  = campos[metricag].tipo
    }
    catch { 
      campox = 'XX'
      tipox = 'V'
    }
    
   
    if (opcao !== "E" && opcao !== "D") {

            let errog = '';
            let errom = '';
            let erroq = '';
            let errot = '';
            let erron = '';
            if (opcao === "Ixx") {
                  if (grupo      === undefined || grupo      === '') { errog = 'g' }
                  if (conta      === undefined || conta      === '') { errog = 'm' }
                  if (tipog !== 'Painel') {
                    if (metricag   === undefined || metricag   === '') { errom = 'm' }
                  }
                  //if (quebrag    === undefined || quebrag    === '') { erroq = 'q' }
            }
            if (tipog      === undefined || tipog      === '') { errot = 't' }
            if (nomeg      === undefined || nomeg      === '') { erron = 'n' }

            let mens = '';
            if (errog !== '') {  mens = mens + "<br />O GRUPO são campos obrigatórios."   }
            if (errom !== '') {  mens = mens + "<br />Os EIXOS devem ser definidos."   }
            if (erroq !== '') {  mens = mens + "<br />O GRUPO DE VISÃO deve ser informado."   }
            if (errot !== '') {  mens = mens + "<br />O TIPO DO GRÁFICO deve ser selecionado"   }
            if (erron !== '') {  mens = mens + "<br />O NOME é um campo mandatório"   }

            if (errog !== '' || errom !== '' || erroq !== '' || errot !== '' || erron !== '') {
              barra.avisar(mens, 5)
                return
            }

            if (nomeyg      === undefined || nomeyg      === '') { setNomeyg('NomeY')   }
            if (nomexg      === undefined || nomexg      === '') { setNomeyg('NomeX')   }


            if (complemento === undefined || complemento === '') { setComplemento(5)    }
    }
    let kpo = "ep=" + barra.cempresa 
    kpo = kpo + "&op=" + opcao 
    kpo = kpo + "&rg=" + ident
    kpo = kpo + "&gp=" + grupo 
    kpo = kpo + "&tp=" + tipog
    kpo = kpo + "&ey=" + campoy
    kpo = kpo + "&ny=" + nomeyg
    kpo = kpo + "&ty=" + tipoy
    kpo = kpo + "&ex=" + campox
    kpo = kpo + "&nx=" + nomexg
    kpo = kpo + "&tx=" + tipox
    kpo = kpo + "&qb=" + quebrag 
    kpo = kpo + "&tb=" + tabela
    kpo = kpo + "&ng=" + nomeg 
    kpo = kpo + "&cd=" + quebracmp
    kpo = kpo + "&cv=" + quebraval
    kpo = kpo + "&rf=" + refer
    kpo = kpo + "&cp=" + complemento
    kpo = kpo + "&od=" + ordem
    kpo = kpo + "&ck=" + campoK
    kpo = kpo + "&ok=" + operaK
    kpo = kpo + "&xk=" + chaveK
    kpo = kpo + "&pr=" + periodo
    gravaGrafico(kpo);
    setshowN(false);     
}

function handleCancel() {
    setGrupo('')
    setConta('')
    setMetricag('')
    setQuebrag('')
    setTabela('')
    setRefer('')
    setTipog('')
    setNomeg('')
    setNomexg('')
    setNomeyg('')
    setQuebracmp('')
    setQuebraval('')
    setComplemento('0')
    setCampoK('')
    setOperaK('')
    setChaveK('')
    setCampos([])
    
    setLoading(true)

}

function incluirRegistro() {
  handleCancel()
  console.log("INCLUIR REGISTRO")
  setTituloModal("Incluir Gráfico")
  setTituloBotao("Confirmar Inclusão")
  setOpcao("I")

  setshowN(true)
  return
}


function excluirRegistro(ident) {
  setTituloModal("Excluir Gráfico")
  setTituloBotao("Confirmar Exclusão")
  console.log("REGISTRO (D): ", ident)
  setOpcao('D')
  leUmRegistro(nome_tabela, ident)
  setshowN(true)
  return
}

function editarRegistro(ident) {
  setTituloModal("Editar Gráfico")
  setTituloBotao("Salvar modificações")
  console.log("REGISTRO: ", ident)
  setOpcao('E')
  leUmRegistro(nome_tabela, ident)
  setshowN(true)
  return
}

const handleClose = () => {
  setLoading(false)
  setshowN(false)
  handleCancel()
  leRegistros(nome_tabela)
}

const handleChange = (e) => {
  setGrupo(e.target.value)
  buscarCampos(e.target.value)
}

  return (
    
    <>

  <form className="Container">
        <Container>
            <ConteudoTitulo>
              <div style={{ width: "100%", height: "40px", backgroundColor: barra.corBarra,  }}>
                  <table border="0" width="100%">
                    <tr>
                      <td width="50%" align="left" >
                            <Titulo>
                              <h4 style={{color: barra.corLetraBarra, marginLeft: "5px",}}>COLEÇÃO DE GRÁFICOS</h4>
                            </Titulo>
                        </td>
                      <td width="50%" align="right">
                            <Button vclassName="btn" style={{backgroundColor: barra.corBotao, color: barra.corLetraBotao, padding: "5px", borderRadius: "5px", marginTop: "3px"}} 
                            onClick={() => incluirRegistro()} >
                                Incluir Registro
                            </Button>
                      </td>
                    </tr>
                  </table>
                
                </div>
            </ConteudoTitulo>
            <div style={{ width: "100%", height: "2px", backgroundColor: barra.corFundo,  }}></div>
            <Table>
                <thead>
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>{cabec.ident}</th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(data).map(registro => (
                        <tr key={registro.id}>
                            <td align="left">{registro.nome} </td>
                            <td align="left">{registro.nomey}</td>
                            <td align="left">{registro.nomex}</td>
                            <td align="left">{registro.tipo}</td>
                            <td align="left">{registro.ordem}</td>
                            <td align="left">{registro.elementos}</td>
                            <td align="left">{registro.campok} - {registro.operak}</td>
                            <td align="left">{registro.chavek}</td>
                            {registro.nome !== null && registro.nome !== undefined ? (
                            <td align="left">{registro.nome.substring(0,4)}</td>
                            ) : (
                              <td align="left">{registro.nome}</td>
                            ) }
                            <td>
                                <Button className="btn" style={{backgroundColor: barra.corBotao, color: barra.corLetraBotao, borderRadius: "5px", padding: "5px", }} onClick={() => editarRegistro(registro.id)}>
                                    Editar
                                </Button>
                                <Button className="btn" style={{backgroundColor: barra.corBotaoW, color: barra.corLetraBotaoW, borderRadius: "5px", padding: "5px", }} onClick={() => excluirRegistro(registro.id)}>
                                    Excluir
                                </Button>
                                                                
                            </td>
                        </tr>
                        
                    ))}
                  
                </tbody>
            </Table>
        </Container>
      </form>
<hr />

<Modal size="lg" show={showN} onHide={handleClose} >
  
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title style={{color: barra.corLetraBarra, }}>{titulo_modal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

<input type="hidden" name="ident" id="ident" value={ident} />

<div className="row" style={{backgroundColor: "#EBEBEB"}}>

<div className="col-md-5">
        <div className="form">
            <div><label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" , }}>Nome do gráfico</label></div>
            <input type="text" name="nomeg" value={nomeg} onChange={ng => setNomeg(ng.target.value)} style={{textAlign: "left", width:"100%", borderRadius: "5px", }} />
        </div>
</div>

{opcao !== "Ixx" ?  (
<div className="col-md-4">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Área de Referência</label>
<select className="form-control" name="grupo" value={grupo} onChange={handleChange}>
        <option value="">Selecionar</option>
        {grupos.map((grp) => {
            return <option value={grp.value} >
            {grp.label}
    </option>
    })}
</select>
</div>
) : null }

<div className="col-md-3">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Tipo do Gráfico</label>
<select className="form-control" name="tipog" value={tipog} onChange={t => setTipog(t.target.value)}>
    <option value=''>Selecionar</option>
    {tipo.map((ite) => {
            return <option value={ite.nome} >{ite.nome}</option>
            }
        )}
</select>
</div>
<p></p>
</div>

{opcao !== "Ixx" ?  (
<div className="row">
<div className="col-md-6">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Eixo Y</label>
<select className="form-control" name="conta" value={conta} onChange={texto => setConta(texto.target.value)}>
          <option value="">Selecione o Eixo Y</option>
                  {campos.map((item) => {
                        return <option key={item.index} >
                        {item.label}
                      </option>
                  })}
</select>       

</div>
<div className="col-md-6">
        <div className="form-control">
            <div><label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" , }}>Nome do eixo Y</label></div>
            <input type="text" name="nomeyg" value={nomeyg} onChange={ng => setNomeyg(ng.target.value)} style={{textAlign: "left", width:"100%", borderRadius: "5px", }} />
        </div>
</div>

</div>
) : null }


{tipog !== "Painelxxx" ?  (
<div className="row">
<div className="col-md-6">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Eixo X</label>
<select className="form-control" name="metricag" value={metricag} onChange={m => setMetricag(m.target.value)}>
    <option value=''>Selecionar o Eixo X</option>
        {campos.map((ite) => {
              return <option key={ite.index} >{ite.label}</option>
          }
          )}
</select>
</div>


<div className="col-md-6">
        <div className="form-control">
            <div><label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" , }}>Nome do eixo Y</label></div>
            <input type="text" name="nomexg" value={nomexg} onChange={ng => setNomexg(ng.target.value)} style={{textAlign: "left", width:"100%", borderRadius: "5px", }} />
        </div>
</div>

</div>
) : null }


{opcao !== "IXX" ?  (
<div className="row">
<div className="col-md-6">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Agrupar por</label>
<select className="form-control" name="tabela" value={tabela} onChange={t => setTabela(t.target.value)}>
    <option value=''>Selecionar</option>
    <option value='Y'>Eixo X</option>
    <option value='X'>Eixo Y</option>
</select>
</div>
<div className="col-md-6">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Classificar por</label>
<select className="form-control" name="quebrag" value={quebrag} onChange={q => setQuebrag(q.target.value)}>
    <option value=''>Selecionar</option>
    <option value='X'>Eixo X</option>
    <option value='Y'>Eixo Y</option>
</select>
</div>
</div>
) : null }

<div className="row" style={{backgroundColor: "#EBEBEB"}}>
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700", textAlign: "center" }}>CONDIÇÕES PARA SELEÇÃO</label>
<div className="col-md-4">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Campo</label>
<select className="form-control" name="campok" value={campoK} onChange={q => setCampoK(q.target.value)}>
    <option value=''>Selecionar</option>
    {campos.map((ite) => {
              return <option key={ite.index} >{ite.label}</option>
            }
        )}
</select>
</div>
<div className="col-md-5">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Operação</label>
<select className="form-control" name="operaK" value={operaK} onChange={q => setOperaK(q.target.value)}>
    <option value=''>Selecionar Comparação</option>
    <option value='EQ'>IGUAL</option>
    <option value='DF'>DIFERENTE</option>
    <option value='GT'>MAIOR</option>
    <option value='GE'>MAIOR OU IGUAL</option>
    <option value='LT'>MENOR</option>
    <option value='LE'>MENOR OU IGUAL</option>
    <option value='IN'>INICIAR COM</option>
    <option value='FI'>TERMINAR COM</option>
    <option value='LK'>CONTENHA</option>
    <option value='NI'>NÃO INICIAR COM</option>
    <option value='NT'>NÃO TERMINAR COM</option>
    <option value='NL'>NÃO CONTENHA</option>
</select>
</div>

<div className="col-md-3">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Chave</label>
<input type="text" name="chaveK" value={chaveK} onChange={q => setChaveK(q.target.value)} style={{width:"100%",}} />
</div>
<p></p>
</div>

<div className="row">
<div className="col-md-4">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Para campos com DATAS</label>
<select className="form-control" name="quebracmp" value={quebracmp} onChange={q => setQuebracmp(q.target.value)}>
    <option value=''>Selecionar Complemento</option>
    <option value='ANO'>ANO</option>
    <option value='MES-ANO'>MES-ANO</option>
    <option value='DIA-MES-ANO'>DIA-MES-ANO</option>
</select>
</div>

<div className="col-md-4">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Para campos com VALORES</label>
<select className="form-control" name="quebraval" value={quebraval} onChange={q => setQuebraval(q.target.value)}>
    <option value=''>Selecionar Complemento</option>
    <option value='SEM'>SEM DECIMAIS</option>
    <option value='COM'>COM DECIMAIS</option>
    <option value='MOE'>MOEDA CORRENTE</option>
    <option value='PER'>PERCENTUAL</option>
</select>
</div>

<div className="col-md-4">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Classificação (ordem)</label>
<select className="form-control" name="ordem" value={ordem} onChange={q => setOrdem(q.target.value)}>
    <option value=''>Selecionar</option>
    <option value='ASC'>Ordem Ascendente</option>
    <option value='DESC'>Ordem Descendente</option>
</select>
</div>

</div>

<div className="row" style={{backgroundColor: "#EBEBEB"}}>
    
    <div className="form">
        <label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" , }}>Descrição </label>
        <div><textarea  name="refer"  id="refer"  value={refer} onChange={o => setRefer(o.target.value)} rows="1"  
                style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", width: "100%" }} >
                </textarea>
        </div>
    </div>

    <div className="col-md-5">
<label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Opções de periodicidade</label>
<select className="form-control" name="periodo" value={periodo} onChange={q => setPeriodo(q.target.value)}>
    <option value=''>Selecionar condição</option>
    <option value='PRE'>PERÍODO PRÉ-DEFINIDO</option>
    <option value='ANO'>ANO ATUAL</option>
    <option value='ANT'>**ANO ANTERIOR</option>
    <option value='MES'>MÊS ATUAL</option>
    <option value='MEA'>**2 ÚLTIMOS MESES</option>
    <option value='TRI'>**3 ÚLTIMOS MESES</option>
    <option value='SEM'>SEMANA ATUAL</option>
    <option value='DIA'>DIA ANTERIOR</option>
    <option value='NAO'>SEM DEFINIÇÃO DE DATAS</option>
</select>
</div>   

    <p></p>
</div>

{/* <div className="form-control">&nbsp;</div> */}

<div className="row">
    <div className="col-md-4">
      <label style={{color: barra.corLetraBarra, fontSize: "14px", fontWeight: "700" }}>Nº Colunas</label>
        <div>
              <input type="number" className="form-control" name="complemento" value={complemento} onChange={cp => setComplemento(cp.target.value)} />
        </div>
    </div>


</div>


        </Modal.Body>
        <Modal.Footer>
        <div className="form-control">
    <table width="100%">
        <tbody>
            <tr>
                  <td width="50%" align='left' ><input type="submit" className="btn-primary" 
                  style={{padding:"5px", backgroundColor: barra.corBotao, color: barra.corLetraBotao, borderRadius:"5px", }} value={titulo_botao} /></td>
                  <td width="50%" align='right'><button type="button" className="btn-danger" 
                  style={{padding:"5px", backgroundColor: barra.corBotaoW, color: barra.corLetraBotaoW, borderRadius:"5px", }}onClick={handleClose}>Cancelar</button></td>
            </tr>
        </tbody>
    </table>
    
    
</div>
        </Modal.Footer>
        </form>
      </Modal>   

    </>
  )
}

export default CriarGraficos