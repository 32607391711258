import React from 'react'
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Table, Titulo } from '../styles';


const Cadastros = () => {

  const barra = useContext(AuthContext);

  return (
    <>


    <form className="Container">
    <Container>
            <ConteudoTitulo>
                <div style={{ width: "100%", backgroundColor: barra.corBarra, textAlign: "left", height: 40 }}>
                <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: 10, marginLeft: 2}}>CADASTROS DO SISTEMA</h4></Titulo>
                </div>
            </ConteudoTitulo>
            <Table>
              <thead>
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="50%" valign='top' align='left'><img src="cadastros.fw.png" alt=""/></td>
                  <td width="50%" valign='top' align='left'>
                    Área para manutenção dos principais cadastros da gestão.
                    <br /><br />
                    A manutenção de um cadastro foca nas operações CRUD, sigla derivada do inglês <b>C</b>reate, <b>R</b>ead, <b>U</b>pdate e <b>D</b>elete, respectivamente
                    CRIAR (inserir) novo registro à base de dados, LER esse registro, ATUALIZAR as informações contidas no registro e também EXCLUIR um registro da base de dados.
                    <br /><br />
                    Importante ressaltar que as operações de EXCLUSÃO de registros, nesse sistema todo, são somente operações que tornam o registro "invisível", ou seja, o registro
                    de qualquer uma dessas tabelas básicas não mais será visualizado. Isso significa que ele ainda estará na base de dados, só não acessível aos usuários.
                    <br /><br />
                    Esse procedimento possibilita a recuperação de informações em casos eventuais de erros nas operações que envolvam essas tabelas.
                    <br /><br />

                  </td>
                </tr>
              </tbody>
            </Table>
    </Container>                

    </form>

    </>    
  )
}

export default Cadastros