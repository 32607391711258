import React, { useState, useEffect, createContext }  from 'react'
//import axios from "axios";
import Swal from 'sweetalert2'

export const AuthContext = createContext({})

function AuthProvider({children}){

    const [barra, setBarra] = useState(true)
    const [sidebar, setSidebar] = useState(false);
    const [operador, setOperador] = useState(false);

    var versao = "versão 2.3.112023.09" 

      const urlbase = "https://beagestor.com.br"
      const imagemP = "https://beagestor.com.br/apicrud/paisagem1p.jpg"

      //const urlbase = "https://bea.inf.br"
      //const imagemP = "https://bea.inf.br/apicrud/paisagem1p.jpg"


      //    const address_logo = "https://www.portaltaxad.lead.tax/api/logo_200.fw.png"

//    const [logomarca, setLogomarca] = useState(address_logo)


    //const [pargraf, setPargraf] = useState([])
    const [grf, setGrf] = useState('')
    const [tit, setTit] = useState(')')
    const [cook, setCook] = useState(true)
    const [ nivel, setNivel] = useState('')

    const [flagExit, setFlagExit] = useState('none')

    const [cusuario, setCusuario] = useState('')
    const [nusuario, setNusuario] = useState('')
    const [cempresa, setCempresa] = useState('')
    const [cnivelus, setCnivelus] = useState(1)

    const [corFundo, setCorFundo] = useState('#FFFFFF')
    const [corBarra, setCorBarra] = useState('#055005')
    const [corDivisor, setCorDivisor] = useState('#BFC3C7')
    const [corMargemCalendario, setCorMargemCalendario] = useState('#FEFCEE')
    const [corLetraCalendario, setCorLetraCalendario] = useState('#000066')
    const [corFundoCelula1, setCorFundoCelula1] = useState("#ffffff")
    const [corFundoCelula2, setCorFundoCelula2] = useState("#ffffff")

    const [corFundoLogin, setCorFundoLogin] = useState('#FFFFFF')

    const [corLetraFundo, setCorLetraFundo] = useState('#202020')
    const [corLetraBarra, setCorLetraBarra] = useState('#ffffff')
    const [corDeg1, setCorDeg1] = useState('#88BBEE')
    const [corDeg2, setCorDeg2] = useState('#FFFFFF')
    const [corBola1, setCorBola1] = useState('#1240AC')
    const [corBola2, setCorBola2] = useState('#FF8800')
    const [corBolinha1, setCorBolinha1] = useState('#718BCB')
    const [corBolinha2, setCorBolinha2] = useState('#FF8888')
    const [corHeade, setCorHeade] = useState('#e2e3e4')
    const [corLetraHeade, setCorLetraHeade] = useState('#202020')
    const [corTela, setCorTela] = useState('#FFFCDE')
    const [corBotao, setCorBotao] = useState('#3366CC')
    const [corLetraBotao, setCorLetraBotao] = useState('#FFFFFF')
    const [corBordaBotao, setCorBordaBotao] = useState('#EDD89F')
    const [corBotaoE, setCorBotaoE] = useState('#880000')
    const [corLetraBotaoE, setCorLetraBotaoE] = useState('#FFFFFF')
    const [corBordaBotaoE, setCorBordaBotaoE] = useState('#202020')
    const [corBotaoW, setCorBotaoW] = useState('#696969')
    const [corLetraBotaoW, setCorLetraBotaoW] = useState('#f7f7f7')
    const [corBordaBotaoW, setCorBordaBotaoW] = useState('#352727')

    const [corBordaFundo, setCorBordaFundo] = useState('#202020')
    const [tamBordaFundo, setTamBordaFundo] = useState(3)
    const [tipBordaFundo, setTipBordaFundo] = useState('double')

    const [corProdu, setCorProdu] = useState('#F5E5D5')
    const [corLetraProdu, setCorLetraProdu] = useState('#202020')

    const [tamLetraMenu, setTamLetraMenu] = useState('14px')

    const [corPanel, setCorPanel] = useState('#3399cc')
    const [corLetpn, setCorLetpn] = useState('#000000')
    const [corRealc, setCorRealc] = useState('#0d9EFD')
    const [corFreal, setCorFreal] = useState('#0d6EFD')
    const [imgLogos, setImgLogos] = useState('logo_200.fw.png')

    const [dataInicial, setDataInicial] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [loja, setLoja] = useState('')


    useEffect(() => {
        //console.log("Sidebar AUTH", sidebar)
    }, []);  

    const avisarx = async (mens, tempo) => {
        Swal.fire({
            title: "AVISO",
            html: mens,
            width: 600,
            padding: '1em',
            color: '#016a77',
            background: '#fff',
            backdrop: `
              rgba(0,0,123,0.4)
            `
          })
    }
    
    function avisar(mens) {
    Swal.fire({
        position: 'center',
        icon: 'success',
        title: mens,
        showConfirmButton: false,
        timer: 3500
      })
      return
    }


    return (
        <AuthContext.Provider value={{ barra, sidebar, cusuario, nusuario, cempresa, cnivelus, corFundo, corBarra, corBotao, corProdu, imgLogos, corPanel, corHeade, corLetpn, corRealc, corFreal, 
            grf, tit, nivel, dataInicial, dataFinal, loja, operador, corLetraFundo, corDeg1, corDeg2, corBola1, corBolinha1, corBola2, corBolinha2, corLetraBarra, corTela, corLetraBotao, corBordaBotao,
            corBotaoE, corLetraBotaoE, corBordaBotaoE, corBotaoW, corLetraBotaoW, corBordaBotaoW, corLetraProdu, corLetraHeade, corBordaFundo, tamBordaFundo, tipBordaFundo, corDivisor, 
            tamLetraMenu, corFundoLogin, corMargemCalendario, corLetraCalendario, corFundoCelula1, corFundoCelula2, cook, versao, urlbase, imagemP, 
            setLoja, setDataInicial, setDataFinal, setNivel, setTit, setGrf, setCorFreal, setCorRealc, setCorPanel, setCorHeade, setCorLetpn, setImgLogos, setSidebar, setBarra, 
            setCorProdu, setCorBotao, setCorBarra,  setCusuario, setNusuario, setCempresa, setCnivelus, setCorFundo, setOperador,setCorLetraFundo, setCorDeg1, setCorDeg2, setCorLetraBarra,
            setCorBola1, setCorBolinha1, setCorBola2, setCorBolinha2, avisar, setCorTela, setCorLetraBotao, setCorBordaBotao, setCorBotaoE, setCorLetraBotaoE, setCorBordaBotaoE, setCorBotaoW, 
            setCorLetraBotaoW, setCorBordaBotaoW, setCorLetraProdu, setCorLetraHeade, setCorBordaFundo, setTamBordaFundo, setTipBordaFundo, setCorDivisor, setCorFundoLogin, 
            setCorMargemCalendario, setCorLetraCalendario, setCorFundoCelula1, setCorFundoCelula2, setCook,
            }} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;