import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const ConsultaItens = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        setLoading(false)
        navigate("/services/services2");
}, [loading, navigate]);  


    const buscarItens = async (xv, em, di, df) => {
        try {

            if (di === undefined)   {  di = ''  }  
            if (df === undefined)   {  df = ''  }  
            if (em === undefined)   {  em = ''  }
            if (xv === undefined)   {  xv = ''  }

            let kpo = "i=" + di + "&f=" + df + "&e=" + em + "&xv=" + xv + "&emp=" + barra.cempresa
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/buscaritenscompras.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("NF: ", res)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setProdutos(res.data.userlist.userdata);
                        setLoading(true);
                } else {
                        let motivo    = res.data.motivo;
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(true);
                }
            })
        } catch (error) { throw error;} 
    
        return

    }   

    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("buscar títulos", e );
        buscarItens(inputs.xave,inputs.emitente,inputs.datai, inputs.dataf);
      }

    const limparCampos = () => {
        setInputs([])
        setProdutos([])
        setLoading(true)
        document.getElementById('xave').value = ''
        document.getElementById('emitente').value = ''
        document.getElementById('datai').value = ''
        document.getElementById('dataf').value = ''
    }


    return (
        <>
    <div className="form" style={{position: "fixed", top:"2%", left:"75%", zIndex:"999"}}>
        <span style={{color: "white"}}>Usuário: {barra.nusuario} (Cod.: {barra.cusuario}) Empresa: {barra.cempresa}</span>
    </div> 
            
            <div className="Container">
                <div className="col-md-12">
                    <div className="card" style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1 }}>
                        <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: "500", fontsize: "20px", marginLeft: "10px", color: barra.corLetraBarra}}>CONSULTA PRODUTOS (ENTRADA)</span>
                        <div className="card-body" style={{marginTop: "1%", backgroundColor: barra.corProdu,  }}>
                            <div className="row">
                            <form className="form"  onSubmit={handleSubmit}>
                                <div className="col-md-12" style={{marginTop: "-1%"}}>

                                    <div className="d-inline p-2" >
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", color: "black" }}>Produto: </label>
                                        <input type="text" id="xave" name="xave" placeholder="Informe o nome do produto ou parte dele" value={inputs.xave} onChange={handleChange} style={{borderRadius: "15px",width: "20%", }}/>
                                    </div>                                    
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", marginLeft: "4px", color: "black"}}>Emitente : </label>
                                        <input type="text" id="emitente" name="emitente" placeholder="Razão social ou parte" value={inputs.emitente} onChange={handleChange} 
                                        style={{marginLeft: "14px", width: "15%",borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px", color: "black" }}>Emissao inicial: </label>
                                        <input type="date" id="datai" name="datai" value={inputs.datai} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px", color: "black"}}>Emissão Final: </label>
                                        <input type="date" id="dataf" name="dataf" value={inputs.dataf} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <button type="submit" name="buscar" className="botao-verde" style={{ }} > Pesquisar </button>&nbsp;
                                        <Button onClick={limparCampos} className="botao-bege" style={{}} >Limpar</Button>
                                    </div>
                                </div>                               
                                <div className="col-md-12">

                                </div>
                            </form>
                          </div>
                          <hr />    
                        </div>
                    </div>
                </div>
            </div>

            <Row style={{display: "block", position: "relative", marginLeft: "240px", marginTop: "5%"}}>
                <Col>

                <div className="col-md-11" >
                        <form className="form">
                        <div style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1}}>
                            <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: 500, fontsize: 20, color: barra.corLetraBarra}}>NOTAS FISCAIS ENCONTRADAS</span>
                                                   
                            <table  class="table table-striped" style={{fontSize: 12,}}>
                                <thead>
                                    <th align="center">produto</th>
                                    <th align="center">código</th>
                                    <th align="left"  >unidade</th>
                                    <th align="center">qtd</th>
                                    <th align="center">R$ unitário</th>
                                    <th align="left"  >R$ total</th>
                                    <th align="left"  >emitente</th>
                                    <th align="left"  >destinatario</th>
                                    <th align="center">emissão</th>
                                    <th align="center">número</th>
                                </thead>
                                <tbody>
                                    {produtos.map( (item) =>
                                        
                                        <tr key={item.id}>
                                            <td align="center">{item.codigop}</td>
                                            <td align="center">{item.produto}</td>
                                            <td align="center">{item.unidade}</td>
                                            <td align="center">{item.quantid}</td>
                                            <td align="right" >{item.valorun}</td>
                                            <td align="right" >{item.valortt}</td>
                                            <td align="left"  >{item.emitent.substring(0,25)}</td>
                                            <td align="left"  >{item.destina.substring(0,25)}</td>
                                            <td align="left"  >{item.emissao}</td>
                                            <td align="center">{item.numeron}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                        </form>
                    </div>

                </Col>

            </Row>


        
        </>
    );
};


export default ConsultaItens