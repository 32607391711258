 

import { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';

const Exit = () => {

    const navigate = useNavigate();

    const barra = useContext(AuthContext);
    console.log("CONTEXTO SAIR: ", barra.sidebar);

    return (

    useEffect(() => {
        console.log("Sair")

        barra.setSidebar(false);

        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));

        
        navigate("/");
    })

  )
}


export default Exit