import React from 'react'
import { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const Contatos = () => {

  const barra = useContext(AuthContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([])

    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
      barra.setSidebar(false);
      localStorage.clear("token")
      localStorage.setItem("encerrar", JSON.stringify("sim"));
      barra.avisar('Sessão expirou. Por favor, faça um novo login.')
      navigate("/");
  }

    const enviarMensagem = async (nome, email, telefone, mensagem) => {
        try {

            console.log("NOME: ", nome)
            console.log("EMAIL: ", email)
            console.log("TELEFONE: ", telefone)
            console.log("MENSAGEM: ", mensagem)

            let erro = ''
            if (nome === '')       {  erro = 's'; }
            if (mensagem === '')   {  erro = 's'; }
            if ((telefone === '') && (email === ''))   {  erro = 's'; }

            if (erro === 's') {
                barra.avisar("Campos NOME e MENSAGEM são obrigatórios. Os campos EMAIL e TELEFONE, pelo menos um deles deve ser informado. Grato", 7)
            } else {
                let empresa = localStorage.getItem("codigo_empresa")
                empresa = empresa.replaceAll('"', '')
    
                let kpo = "men=" + mensagem + "&nom=" + nome + "&ema=" + email + "&tel=" + telefone + "&emp=" + empresa
                console.log(kpo)
                axios.get(`${barra.urlbase}/apicrud/enviarmensagem.php?${kpo}`, inputs).then(function(res) {
                    let sucesso    = res.data.success;
                    console.log("NF: ", res)
                    console.log("SUCESSO: ", sucesso);
    
                    if (sucesso) {
                            console.log(res.data.motivo);
                            barra.avisar("Mensagem enviada com SUCESSO!", 5)

                            limparCampos()
    
                    } else {
                            console.log("Não encontrado")
                            barra.avisar("Ocorreu um problema no envio da mensagem. Por favor, tente novamente.", 5)
    
                            let motivo    = res.data.motivo;
                            console.log("NÃO SUCESSO: ", motivo);
                    }
                })
            }

        } catch (error) { throw error;} 
    
        return

    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("campos", e );

        console.log("-NOME: ", e.target.nome.value)
        console.log("-EMAIL: ", e.target.email.value)
        console.log("-TELEFONE: ", e.target.telefone.value)
        console.log("-MENSAGEM: ", e.target.mensagem.value)

        enviarMensagem(e.target.nome.value, e.target.email.value, e.target.telefone.value, e.target.mensagem.value)
      }

      const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }      
    
      const limparCampos = () => {
        setInputs([])
        document.getElementById('nome').value = ''
        document.getElementById('email').value = ''
        document.getElementById('telefone').value = ''
        document.getElementById('mensagem').value = ''
    
      }      
  return (

    <>

        <form className="Container" onSubmit={handleSubmit} >
        <center>
        <div className="form" >
                <div className="col-md-12" >
                    <div className="form" style={{ backgroundColor: barra.corBarra,  borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: 1 }}>
                        <span className="form-control" style={{backgroundColor: barra.corBarra, textAlign: "left", fontWeight: "500", fontsize: "20px", color: barra.corLetraBarra}}>ENTRAR EM CONTATO</span>
                    </div>
                </div>
                <br />
                <br />
                <div className="col-md-8" style={{backgroundColor: "#FFFFFF", }}>
                    <div className="row" style={{   backgroundColor: "#FFFFFF", borderColor: "black", borderStyle: "solid",borderRadius: 10, borderWidth: 1 }}>
                        <div className="card-body">
                            <div className="form-group">
                                <div className="col-md-12" style={{marginTop: "1px"}}>
                                <div className="form-control" >
                                        <label className="row" style={{fontSize: "12px", fontWeight: "700", alignText: "left", marginleft: "5px", }}>Nome do Remetente: </label>
                                        <input className="form-control"  type="text" id="nome" name="nome" placeholder="informe o seu nome" value={inputs.nome} onChange={handleChange} 
                                        style={{marginLeft: "5px", }}/>
                                    </div>                                    
                                    <div className="form-control" >
                                        <label className="row" style={{fontSize: "12px", fontWeight: "700", alignText: "left", marginleft: "5px", }}>Email para retorno: </label>
                                        <input className="form-control"  type="text" id="email" name="email" placeholder="informe o seu email preferido" value={inputs.email} onChange={handleChange} 
                                        style={{marginLeft: "5px", }}/>
                                    </div>                                    
                                    <div className="form-control" >
                                        <label className="row" style={{fontSize: "12px", fontWeight: "700", alignText: "left", marginleft: "5px", }}>Telefone para contato: </label>
                                        <input className="form-control"  type="text" id="telefone" name="telefone" placeholder="se preferir, informe seu telefone" value={inputs.telefone} onChange={handleChange} 
                                        style={{marginLeft: "5px", }}/>
                                    </div>                                    

                                    <div className="form-control" >
                                        <label className="row" style={{fontSize: "12px", fontWeight: "700", alignText: "left", marginleft: "5px", }}>Nome do Remetente: </label>
                                        <textarea className="form-control"  id="mensagem" name="mensagem" rows="4" placeholder="digite sua mensagem" value={inputs.mensagem} 
                                        onChange={handleChange} 
                                        style={{marginLeft: "5px", }} ></textarea>
                                    </div>                                    
                                    <div className="form-control" >
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-inline p-2"></div>
                                        <button type="submit" name="enviar" className="btn btn-primary" style={{paddingLeft:"9px", paddingRight:"9px", backgroundColor: barra.corBotao, borderRadius:"5px", }} > Enviar Mensagem </button>
                                        <Button onClick={limparCampos} className="btn-warning" style={{paddingLeft:"9px", paddingRight:"9px", borderRadius:"5px", }} >Limpar</Button>
                                    </div>
                                </div>                              
                          </div>
                          <hr />    
                        </div>
                    </div>
                </div>
        </div>    
        </center>
        </form>   
    </>
  )
}

export default Contatos