import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"


const ConsultaNotas = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }
    
    const [modalShow, setModalShow] = React.useState(false);

    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [recebido, setRecebido] = useState([]);
    const [nfi, setNfi] = useState('')
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        //console.log("Parâmetros: ", loading)
        setLoading(false)
        navigate("/services/services1");
}, [loading, navigate]);  


    const buscarNotas = async (di, df, si, sf, em, ds, tr, xv) => {
        try {

            if (di === undefined)   {  di = ''  }  
            if (df === undefined)   {  df = ''  }  
            if (si === undefined)   {  si = ''  }  
            if (sf === undefined)   {  sf = ''  }  
            if (em === undefined)   {  em = ''; }
            if (ds === undefined)   {  ds = ''; }
            if (tr === undefined)   {  tr = ''; }
            if (xv === undefined)   {  xv = ''; }

            let kpo = "i=" + di + "&f=" + df + "&s=" + si + "&z=" + sf + "&e=" + em + "&d=" + ds + "&t=" + tr + "&xv=" + xv + "&emp=" + barra.cempresa
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/buscarnotasfiscais.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("NF: ", res)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setProdutos(res.data.userlist.userdata);
                        setLoading(true);

                } else {
                        console.log("Não encontrado")
                        setProdutos([])
                        barra.avisar("Notas Fiscais não encontradas")
                        let motivo    = res.data.motivo;
                        setLoading(true);
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(false);
                }
            })
        } catch (error) { throw error;} 
    
        return

    }   

    const buscarItens = async (it) => {
        try {

            if (it === undefined)   {  it = ''; }


            let kpo = "i=" + it + "&emp=" + barra.cempresa 
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/buscaritensnf.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("NF: ", res)
                console.log("SUCESSO: ", sucesso);


                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setRecebido(res.data.userlist.userdata);
                        setLoading(true);

                } else {
                        console.log("Não encontrado")
                        let motivo    = res.data.motivo;
                        setLoading(true);
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(false);
                }
            })
        } catch (error) { throw error;} 
    
        return

    } 

    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("buscar títulos", e );

        setLoading(false)
        buscarNotas(inputs.datai, inputs.dataf, inputs.datsi, inputs.datsf,inputs.emitente, inputs.destinatario, inputs.transportador, inputs.xave);
      }


      function checkValue(e) {
        var value = e.target.value;
        var identi = e.target.name.replace("selec","");
        setNfi(identi)
        var chk = e.target.checked;
        console.log("checkbox selecionado " + identi + ": " + chk + ":" + value)

        if (chk === true) {
            setRecebido([])
            buscarItens(value);
            setModalShow(true)
            setTimeout(() => {
                e.target.checked = false
              }, 3000);            
            
        }
      }



      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              ITENS DA NOTA FISCAL {nfi}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                <div className="col-md-11" >
                    <form className="form">
                        <div style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1}}>
                            <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: 500, fontsize: 20, color: "black"}}>...</span>
                                                   
                            <table  class="table table-striped" style={{fontSize: 12,}}>
                                <thead>
                                    <th align="center">seq</th>
                                    <th align="center">código</th>
                                    <th align="center">produto</th>
                                    <th align="left"  >qtd</th>
                                    <th align="left"  >valor</th>
                                </thead>
                                <tbody>
                                    {recebido.map( (prod) =>
                                        
                                        <tr key={prod.id}>
                                            <td align="center">{prod.seq}</td>
                                            <td align="center">{prod.codigo}</td>
                                            <td align="center">{prod.produto}</td>
                                            <td align="left"  >{prod.qtd}</td>
                                            <td align="left"  >{prod.valor}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
      }

      const limparCampos = () => {
        setLoading(true)
        setInputs([])
        setProdutos([])
        setLoading(false)
        document.getElementById('xave').value = ''
        document.getElementById('emitente').value = ''
        document.getElementById('destinatario').value = ''
        document.getElementById('transportador').value = ''
        document.getElementById('datai').value = ''
        document.getElementById('dataf').value = ''
        document.getElementById('datsi').value = ''
        document.getElementById('datsf').value = ''

      }

    return (
        <>
         
    <div className="form" style={{position: "fixed", top:"2%", left:"75%", zIndex:"999"}}>
        <span style={{color: "white"}}>Usuário: {barra.nusuario} (Cod.: {barra.cusuario}) Empresa: {barra.cempresa}</span>
    </div> 

            
            <div className="Container">
                <div className="col-md-12">
                    <div className="card" style={{   borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                        <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: "500", fontsize: "20px", color: barra.corLetraBarra}}>CONSULTA NOTAS FISCAIS</span>
                        <div className="card-body" style={{marginTop: "1%", backgroundColor: barra.corProdu,  }}>
                            <div className="row">
                            <form className="form"  onSubmit={handleSubmit}>
                                <div className="col-md-12" style={{marginTop: "-1%"}}>

                                    <div className="d-inline p-2" >
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", color: "black"}}>Chave NFiscal: </label>
                                        <input type="text" id="xave" name="xave" placeholder="Código da chave ou parte" value={inputs.xave} onChange={handleChange} style={{width: "26%", borderRadius: "15px",}}/>
                                    </div>                                    
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", marginLeft: "14px", color: "black"}}>Emitente : </label>
                                        <input type="text" id="emitente" name="emitente" placeholder="Razão social ou parte" value={inputs.emitente} onChange={handleChange} 
                                        style={{marginLeft: "14px", width: "15%", borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", color: "black"}}>Destinatário</label>
                                        <input type="text" id="destinatario" name="destinatario" placeholder="Razão social ou parte" value={inputs.destinatario} onChange={handleChange} style={{width: "15%", borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", color: "black"}}>Transportador</label>
                                        <input type="text" id="transportador" name="transportador" placeholder="Razão social ou parte" value={inputs.transportador} onChange={handleChange} style={{width: "15%", borderRadius: "15px",}} />
                                    </div>
                                </div>
                                <br />                               
                                <div className="col-md-12">
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px", color: "black"}}>Emissao inicial: </label>
                                        <input type="date" id="datai" name="datai" value={inputs.datai} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "0px", marginRight: "5px", color: "black"}}>Emissão Final: </label>
                                        <input type="date" id="dataf" name="dataf" value={inputs.dataf} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginRight: "5px", marginLeft: "14px", color: "black"}}>Saída inicial: </label>
                                        <input type="date" id="datsi" name="datsi" value={inputs.datsi} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2">
                                        <label style={{fontSize: "12px", fontWeight: "700", marginLeft: "80px", marginRight: "5px", color: "black"}}>Saída Final: </label>
                                        <input type="date" id="datsf" name="datsf" value={inputs.datsf} onChange={handleChange} style={{borderRadius: "15px",}} />
                                    </div>
                                    <div className="d-inline p-2"></div>
                                    <button type="submit" name="buscar" className="btn botao-verde" style={{ width: "auto"}} > Pesquisar Notas Fiscais </button>&nbsp;
                                    <Button onClick={limparCampos} className="botao-bege" style={{ }} >Limpar</Button>
                                </div>
                            </form>
                          </div>
                          <hr />    
                        </div>
                    </div>
                </div>
            </div>

            <Row style={{display: "block", position: "relative", marginLeft: "240px", marginTop: "5%"}}>
                <Col>

                <div className="col-md-11" >
                        <form className="form">
                        <div style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1}}>
                            <span class="form-control" style={{backgroundColor: barra.corBarra, fontWeight: 500, fontsize: 20, color: barra.corLetraBarra}}>NOTAS FISCAIS ENCONTRADAS</span>
                                                   
                            <table  class="table table-striped" style={{fontSize: 12,}}>
                                <thead>
                                    <th align="center">Emissão</th>
                                    <th align="center">Saída</th>
                                    <th align="center">Número</th>
                                    <th align="left"  >Emitente</th>
                                    <th align="left"  >Destinatario</th>
                                    <th align="left"  >Transportador</th>
                                    <th align="left"  >Valor</th>
                                    <th align="center">Ver Itens</th>
                                </thead>
                                <tbody>
                                    {produtos &&
                                    produtos.map( (item) =>
                                        
                                        <tr key={item.id}>
                                            <td align="center">{item.emiss}</td>
                                            <td align="center">{item.saida}</td>
                                            <td align="center">{item.numer}</td>
                                            <td align="left"  >{item.emite}</td>
                                            <td align="left"  >{item.desti}</td>
                                            <td align="left"  >{item.trans}</td>
                                            <td align="right" >{item.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                            <td align="center">
                                            <input type="checkbox" id={`selec${item.id}`} name={`selec${item.numer}`} value={item.id} onChange={checkValue} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                        </form>
                    </div>

                </Col>

            </Row>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => 
                    setModalShow(false)
                }
            />
        
        </>
    );
};


export default ConsultaNotas