import React from 'react'
import { useState, useEffect,  } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import moment from "moment"
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"


const Royalties = () => {

  const barra = useContext(AuthContext)
  
  const navigate = useNavigate();
if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
  barra.setSidebar(false);
  localStorage.clear("token")
  localStorage.setItem("encerrar", JSON.stringify("sim"));
  barra.avisar('Sessão expirou. Por favor, faça um novo login.')
  navigate("/");
}

  
  const [primeira, setPrimeira] = useState(true)
  const [dados, setDados] = useState([])
  const [unidades, setUnidades] = useState([])
  const [loading, setLoading] = useState(false)
  
  const [valortit, setValortit] = useState(0)
  const [textoBotao, setTextoBotao] = useState('')

  const [inputs, setInputs] = useState({
    loja: 0,
    di: "",
    df: ""
})


useEffect(() => {
  setLoading(false)
  if (valortit !== 0) { setTextoBotao("Confirmar criação do Título a pagar no valor de: " + valortit) } else { setTextoBotao('')}
  navigate("/royal")
}, [loading, navigate, valortit]);  

const buscarLojas = async () => {
  try {

      //let tk = localStorage.getItem("token")

      axios.get(`${barra.urlbase}/apicrud/buscarlojassn.php?e=${barra.cempresa}`).then(function(res) {
          let sucesso    = res.data.success;
          console.log("LOJAS: ", res)
          console.log("SUCESSO: ", sucesso);

          if (sucesso) {
                  console.log(res.data.userlist.userdata);
                  setUnidades(res.data.userlist.userdata);
                  setLoading(true);

          } else {
                  console.log("Lojas Não encontradas")
                  let motivo    = res.data.motivo;
                  setUnidades([]);
                  setLoading(true);
                  console.log("NÃO SUCESSO: ", motivo);
          }
      })
  } catch (error) { throw error;} 

  return

} 

const buscarRoyal = async (kpo) => {


    try {
  
      axios.get(`${barra.urlbase}/apicrud/buscarroyalties.php?${kpo}`).then(function(res) {
          let sucesso    = res.data.success;
          console.log("DADOS (ROYAL): ", res)
          console.log("SUCESSO: ", sucesso);

          if (sucesso) {
            setValortit(res.data.userlist.userdata[(res.data.userlist.userdata.length - 1)].valorR)
                  console.log("ROYAL (I): ", valortit);
                  setDados(res.data.userlist.userdata);
                  setLoading(true);

          } else {
                  console.log("Lojas Não encontradas")
                  let motivo    = res.data.motivo;
                  setDados([]);
                  setValortit(0)
                  setLoading(true);
                  console.log("NÃO SUCESSO: ", motivo);
          }
      })
  } catch (error) { throw error;} 

  return

} 

function handleSubmit(e) {
    e.preventDefault();

    console.log("royal: ", inputs)

    let lj = inputs.loja
    let di = inputs.di
    let df = inputs.df

    if (lj === undefined || lj === '') {  lj = '' }
    if (di === undefined || di === '') {  di = '' }
    if (df === undefined || df === '') {  df = '' }

    if (lj === '' || di === '' || df === '') {
          barra.avisar("Os parâmetros de pesquisa (LOJA E DATAS) devem ser informados", 5)
          return
    }

    let kpo = "lj=" + lj + "&di=" + di + "&df=" + df + "&ep=" + barra.cempresa

    buscarRoyal(kpo)
    setLoading(true)
}

const handleChange = (event) => {
  const nome  = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [nome]: value}));

}

const handleCancel = () => {
    setInputs([])
    document.getElementById('loja').value = ""
    document.getElementById('di').value = ""
    document.getElementById('df').value = ""
    setLoading(true)
}

if (primeira) {
    buscarLojas()
    setPrimeira(false)
}


const gravaTitulo = async (kpo) => {

  console.log("tabela: ", kpo)
  axios.get(`${barra.urlbase}/apicrud/gravar_ctpagar.php?${kpo}`).then(function(res) {
      let sucesso    = res.data.success;
      console.log("SUCESSO (CTP): ", sucesso);

      setLoading(sucesso)

      if (sucesso) {
            //setData(res.data.retorno);
            console.log("retorno: ", res.data.retorno)
            barra.avisar(res.data.retorno, 2)
            handleCancel()
      } else {
            let motivo    = res.data.motivo;
            console.log("NÃO SUCESSO: ", motivo);
            barra.avisar(motivo, 5)
      }
  })
   

} 

const criarTitulo = () => {
  console.log("CRIAR TITULO: ", valortit)
  let work = ((valortit.replace("R$ ", "")).replace(".","")).replace(",",".")
  let wdi = inputs.di.substring(8,10) + '-' + inputs.di.substring(5,7) + '-' + inputs.di.substring(0,4)
  let wdf = inputs.df.substring(8,10) + '-' + inputs.df.substring(5,7) + '-' + inputs.df.substring(0,4)

  const current = new Date();
  const dateh = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;

  const wvcto = moment().add(15, 'days').format('YYYY-MM-DD')

  console.log("CRIAR TITULO: ", work)
  let grupo = '6'
  let conta = '55'
  let fornecedor = ''
  let hoje = dateh
  let vcto = wvcto
  let valorP = work
  let valorF = 0
  let valorD = 0
  let valorT = work
  let observacao = "PAGAMENTO DE ROYALTIES DO PERíODO DE: " + wdi + " ATÉ: " + wdf
  let saldo = work
  let loja = "NOME DA LOJA (" + inputs.loja + ")"

  console.log("LCTO: ", grupo, conta, fornecedor, hoje, vcto, valorP, valorF, valorD, valorT, observacao)
  let kpo = "e=" + barra.cempresa + "&g=" + grupo + "&c=" + conta + "&f=" + fornecedor + "&d=" + hoje + "&v=" + vcto + "&vp=" + valorP +  "&vf=" + valorF +  "&vd=" + valorD +  "&vt=" + saldo + "&o=" + observacao + "&l=" + loja
  gravaTitulo(kpo);
}  
  
  return (
    <>


    <form className="Cadastros" onSubmit={handleSubmit}>
        <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                          <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="20%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px"}}>ROYALTIES - CONFERÊNCIA</h4></Titulo>
                                        </td>
                                        <td width="20%" align="right">
                                              <label><h6>Loja:</h6></label>
                                              <select name="loja" id="loja" value={inputs.loja}  onChange={handleChange} 
                                              style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                                                <option value="0">Selecione a loja </option>
                                                    {unidades.map((option) => {
                                                    return <option value={option.value} key={option.value} >
                                                          {option.label}
                                                    </option> })}
                                              </select>
                                        </td>
                                        <td width="20%" align="right">
                                                <label><h6>Data Inicial:</h6></label>
                                                <input type="date"   name="di" id="di" value={inputs.di} onChange={handleChange} />
                                        </td>
                                        <td width="20%" align="right">
                                                <label><h6>Data Final:</h6></label>
                                                <input type="date"   name="df" id="df" value={inputs.df} onChange={handleChange} />
                                        </td>
                                        <td width="20%" align="right">
                                                <Button className="btn-primary" style={{paddingLeft:"4px", paddingRight:"4px", backgroundColor: barra.corBotao, borderRadius:"5px", }} onClick={handleSubmit}>BUSCAR</Button>
                                                <Button className="btn-warning" style={{paddingLeft:"4px", paddingRight:"4px", borderRadius:"5px", }} onClick={handleCancel}>LIMPAR</Button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                    </div>
                </ConteudoTitulo>
        
                <div className="row">
                  <div className="App" style={{ width: "100%", height: 800, border: "0px solid #000", borderRadius: "5px" }}>
                          <table className="table table-sniped">
                            <thead>
                              <tr>
                              <td align="left">LOJA</td>
                              <td align="left">NÚMERO NF</td>
                              <td>DATA EMISSÃO</td>
                              <td align="right">VALOR PRODUTOS</td>
                              <td align="right">VALOR IPI</td>
                              <td align="right">ST</td>
                              <td align="right">DESCONTO ZF</td>
                              <td align="right">VALOR TOTAL DA NOTA</td>
                              <td align="right">ROYALTIES</td>
                              </tr>
                            </thead>

                            <tbody>
                            {Object.values(dados).map(registro => (
                              
                                <tr key={registro.id}>
                                    <td align="left">{registro.loja}</td>
                                    <td align="centar">{registro.chave}</td>
                                    <td align="center">{registro.emissao}</td>
                                    <td align="right">{registro.valorP}</td>
                                    <td align="right">{registro.valorI}</td>
                                    <td align="right">{registro.valorS}</td>
                                    <td align="right">{registro.valorD}</td>
                                    <td align="right">{registro.valorT}</td>
                                    <td align="right">{registro.valorR}</td>
                                </tr>
                                
                            ))} 

                            <tr><td colspan="9" align="center">
                                <Button className="btn-primary" style={{paddingLeft:"4px", paddingRight:"4px", backgroundColor: barra.corBotao, borderRadius:"5px", }} onClick={criarTitulo}>
                                  {textoBotao}</Button>
                            </td></tr>                     
                            </tbody>
                          </table>
                  </div>
                </div>
        </Container>
    </form>
    </>
  )
}


export default Royalties