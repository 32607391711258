import ApexChart from 'react-apexcharts';
//import { propTypes } from 'react-bootstrap/esm/Image';

/**
 * props:
 * - data
 */
export default function ChartBarHor(props) {


    const colors = props.data.map(k => (k.k))
    const category = props.data.map(k => (k.x))

    const series = [{
        data: props.data,
    }]


    
    /*
    const options = {
        xaxis: {
            type: 'String'
        },
        yaxis: {
            type: "Float"
        },
        colors: colors,
        chart: {
            type: 'bar',
            height: 350
          },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
        

    }
    */

    var newoptions = {
        chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '50%',
          borderRadius: 10,
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      colors: colors,
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        style: {
            fontSize: '14px',
            fontWeight: 500,
            colors: ['#000']
        },
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: category,
      },
      yaxis: {
        labels: {
          show: false
        }
      },

      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
      };


    return (
        <ApexChart options={newoptions} series={series} type="bar" />
    )
}