import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import Swal from 'sweetalert2'
//import Col from 'react-bootstrap/Col';
//import Row from 'react-bootstrap/Row';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
//import Button from 'react-bootstrap/Button';
import { Container, ConteudoTitulo, Titulo } from '../styles';

const ContarEstoque = () => {

    const barra = useContext(AuthContext);
    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }


    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [primeira, setPrimeira] = useState(true)


    useEffect(() => {
        setLoading(false);
        navigate("/estoque-contar")        
}, [loading, navigate]);  

const gravarProduto = async (prd) => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/contarprodutos.php?p=${prd}&u=${barra.cusuario}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("PRODUTOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log("OK:", res.data.retorno);
                    limparCampos()

            } else {
                    console.log("Produtos Não encontrados")
                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  


    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
        //console.log(document.getElementById('produto').value.length)

        if (document.getElementById('produto').value.length >= 18) {
            gravarProduto(document.getElementById('produto').value)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("produto: ", inputs.produto );

        setLoading(false)
        gravarProduto(inputs.produto)
        //buscarMovimentaEstoque(inputs.xave,inputs.datai, inputs.dataf);
      }

    const limparCampos = () => {
        setInputs([])
        document.getElementById('produto').value = '';
        setLoading(true)
        console.log("Campos limpos")
    } 


    if (primeira) {
        //buscarProdutos()
        setPrimeira(false)
    }

    return (
    <>

            <form className="Container" onSubmit={handleSubmit}>

            <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "5px", marginLeft: "10px", }}>CONTAGEM DE ITENS</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>

                <div className="contact"  style={{ height: "70%", }}>
                        <hr />    
                        <hr />    
                        <div className="col-md-11">
                            <div className="row">
                                <div className="row" style={{marginTop: "-1%"}}>
             
                                    <div id="prod" className="col-md-6">
                                        <label style={{fontSize: "14px", fontWeight: "700" }}>Produto</label>
                                        <div><input type="text" className="form-control" name="produto" id="produto" value={inputs.produto} onChange={handleChange} style={{borderRadius: "15px",}} />
                                        </div>
                                    </div> 

                                    <div className="col-md-6">
                                        <table width="100%">
                                            <tr><td>&nbsp;</td></tr>
                                            <tr>
                                                <td align="center">
                                                    <button type="submit" name="buscar" className="botao-verde" style={{width: "auto", }} > Confirmar Lançamento </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>                               
                                <div className="col-md-12">
                                </div>
                          </div>
                          <hr />    
                          <hr />    
                    </div>
                </div>
            </Container>

        </form>
        
    </>




  )
}

export default ContarEstoque