import React from "react";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import Button from 'react-bootstrap/Button';

import axios from "axios";

import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"

ChartJS.register(ArcElement, Tooltip, Legend);

const UserDatax = [
  {
    id: "1",
    horiz: "2022",
    verti: "1",
    color: "#3399CC",
  },
];



function PieChart({ tit, grf }) {

  const barra = useContext(AuthContext) 
  //console.log(barra.pargraf) 
  
  barra.setGrf(grf)
  
  let lj = barra.pargraf.lojas
  let di = barra.pargraf.datai
  let df = barra.pargraf.dataf
  let li = barra.pargraf.limit

  if (lj === undefined || lj === '') {  lj = '' }
  if (di === undefined || di === '') {  di = '' }
  if (df === undefined || df === '') {  df = '' }
  if (li === undefined || li === '') {  li = '' }

  let kpo = "lj=" + lj + "&di=" + di + "&df=" + df + "&li=" + li + "&ep=" + barra.cempresa + "&gf=" + grf
  console.log("ENTRADA PIE: ", lj, di, df, li, kpo)
  //const [par, setPar] = useState(kpo)


  const [dados, setDados] = useState([])
  //const [loading, setLoading] = useState(false)
  const [primeira, setPrimeira] = useState(true)

  /*
  useEffect(() => {
    setLoading(false)
    console.log("PIZZA:")
  }, [loading]);  
*/

  const buscarDados = async () => {
    try {

        console.log("LER GRAFICO: ", lj, di, df, li, ' :: ', kpo, '->', tit )

        axios.get(`${barra.urlbase}/apicrud/ler_grafico_generico.php?${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("LEU GRAFICO BARRA: ", res)
            console.log("SUCESSO: ", sucesso);
  
            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setDados(res.data.userlist.userdata)
                    //setLoading(true);
            } else {
                    console.log("informações Não encontradas", res)
                    setDados(UserDatax)
                    //setLoading(true);
            }
        })
    } catch (error) { throw error;} 
  
    return
  
  }  

  const UserData = {
    labels: dados.map((data) => data.horiz),
    datasets: [
      {
        label: "Título",
        data: dados.map((data) => data.verti),
        backgroundColor: dados.map((cores) => cores.color),
        borderColor: "black",
        borderWidth: 1,
      },
      
    ],
  };


  const opt = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      title: {
        display: true,
        text: tit,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    chartArea: {
      backgroundColor: 'rgba(251, 85, 85, 0.4)'
    },

  };



  if (primeira) {
    buscarDados()
    setPrimeira(false)
  }

  return (
  
    <>
    {/*
    <div>
        <div style={{position: "relative", textAlign: "right", zIndex: 999}}>
            <Button className="form" onClick={updt}>Atualizar</Button>
        </div>
    </div>
    */}

    <div id="pizza" name="pizza" style={{position: "relative", top: "0%", width:"100%", height:"90%", textAlign: "right"}}>
        <Pie data={UserData}  options={opt} />
    </div>

  </>

  )
}

export default PieChart;
