import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Titulo } from '../styles';

const ContasReceberLcto = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }

    const nome_tabela = "ccs_credor"
    const [primeira, setPrimeira] = useState(true)
    const [loading, setLoading] = useState(false)
    const [fornecedores, setFornecedores]=useState([]);
    const [fornecedor, setFornecedor]=useState('');
    const [state, setState] = useState({
        id: "",
        nome: "",
        endereco: "",
        email: "",
        telefone: "",
        cnpj: ""
      })
    const [showN, setshowN] = useState(false)
    //const [data, setData] = useState([]);
    const [emissao, setEmissao] = useState('')
    const [vencimento, setVencimento] = useState('')
    const [valorP, setValorP] = useState(0)
    const [valorF, setValorF] = useState(0)
    const [valorD, setValorD] = useState(0)
    const [valorT, setValorT] = useState(0)
    const [observacao, setObservacao] = useState('')
    const [loja, setLoja]   = useState('')
    const [lojas, setLojas] = useState([])

    useEffect(() => {
        setLoading(false)
        //console.log("GRUPO:", grupo)
        navigate("/contas-receber-lcto");
}, [loading, navigate]);  



const buscarLojas = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarlojas.php?e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("LOJAS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setLojas(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Lojas Não encontradas")
                    let motivo    = res.data.motivo;
                    setLojas([]);
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  

const leRegistros = async (nome_tabela) => {

    console.log("tabela: ", nome_tabela)
    axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=0&t=${nome_tabela}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (todos): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              console.log(res.data.datalist.userdata);
              setFornecedores(res.data.datalist.userdata);

        } else {
              console.log("Não encontrado (DU)")

              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

}

function incluirRegistro() {
    console.log("INCLUIR REGISTRO")

    setState({
        id: "",
        nome: "",
        endereco: "",
        telefone: "",
        email: "",
        cnpj: ""
      })

    setshowN(true)
    return
}

const gravaUmRegistro = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_arquivo.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (UPD): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              leRegistros(nome_tabela)
        } else {
              console.log("Não encontrado (UPD)")

              let motivo    = res.data.retorno;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })
     

}    

const gravaTitulo = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_ctreceber.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (CTP): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              barra.avisar(res.data.retorno, 2)
              handleCancel()
        } else {
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
              barra.avisar(motivo, 5)
        }
    })
     

} 

const handleClose = () => {
    setLoading(false)
    setshowN(false);
}

function handleSubmit(evt) {
    evt.preventDefault();
    let erro = '';
    if (state.nome === undefined || state.nome === '') { erro = 's'  }
    if (state.email === undefined || state.email === '') { erro = 's'  }

    if (erro === 's') {
        barra.avisar("Campos NOME e EMAIL devem ser preenchidos")
        return
    }

    let wcampo1 = state.nome.replaceAll("&", "e")
    let wcampo4 = state.email.replaceAll("&", "e")
    let wcampo2 = ''
    let wcampo3 = ''
    if (state.endereco !== undefined && state.endereco !== '') { wcampo2 = state.endereco.replaceAll("&", "e") }
    if (state.telefone !== undefined && state.telefone !== '') { wcampo3 = state.telefone.replaceAll("&", "e") }
    let wcampo5 = state.cnpj

    console.log("SUBMIT", )
    let kpo = '';
    kpo = 't=' + nome_tabela + '&o=I&par=0;nome=' + wcampo1 + ';endereco=' + wcampo2 + ";telefone=" + wcampo3 + ";empresa=" + barra.cempresa + ";email=" +  wcampo4 + ";cnpj=" + wcampo5
    gravaUmRegistro(kpo);
    setshowN(false);
}


function handleChange(evt) {
    const value = evt.target.value;
    setState({...state, [evt.target.id]: value  });
}


if (primeira) {
    leRegistros(nome_tabela)
    buscarLojas()
    setPrimeira(false)
}


function handleLcto(e) {
    e.preventDefault();
    let errof = '';
    let errov = '';
    let errol = '';
    if (loja       === undefined || loja       === '') { errol = 'l' }
    //if (fornecedor === undefined || fornecedor === '') { errof = 'f' }
    if (valorP === 0)  { errov = 'v' }

    let mens = '';
    if (errol !== '') {  mens = mens + "<br />A LOJA deve ser indicada."   }
    //if (errof !== '') {  mens = mens + "<br />O CLIENTE deve ser informado."   }
    if (errov !== '') {  mens = mens + "<br />O VALOR DOS PRODUTOS deve ser preenchido"   }

    if (errof !== '' || errov !== ''  || errol !== '') {
        barra.avisar(mens, 5)
        return
    }

    let hoje = '';
    let vcto = '';
    if (emissao === undefined || emissao === '') {
        var data = new Date();
        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        hoje = ano + '-' + mes + '-' + dia;
    } else {
        hoje = emissao
    }
    
    if (vencimento === undefined || vencimento === '') {
        var dataw = new Date();
        var diaw = String(dataw.getDate()).padStart(2, '0');
        var mesw = String(dataw.getMonth() + 1).padStart(2, '0');
        var anow = dataw.getFullYear();
        vcto = anow + '-' + mesw + '-' + diaw;
    } else {
        vcto = vencimento
    }

    let saldo = 0;
    saldo = parseFloat(valorP) + parseFloat(valorF) - parseFloat(valorD)
    setValorT(saldo)

    console.log("LCTO: ", fornecedor, hoje, vcto, valorP, valorF, valorD, valorT, observacao)
    let kpo = "e=" + barra.cempresa + "&g=1&c=6&f=" + fornecedor + "&d=" + hoje + "&v=" + vcto + "&vp=" + valorP +  "&vf=" + valorF +  "&vd=" + valorD +  "&vt=" + saldo + "&o=" + observacao + "&l=" + loja
    gravaTitulo(kpo);
    setshowN(false);     
}

function handleCancel() {
    setFornecedor('')
    setEmissao('')
    setVencimento('')
    setValorP(0)
    setValorD(0)
    setValorF(0)
    setValorT(0)
    setObservacao('')
    document.getElementById('observacao').value = '';
}

  return (
    
    <>
    <form className="Container" onSubmit={handleLcto}>
    <Container>
        <ConteudoTitulo>
            <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align="left" >
                                <Titulo><h4 style={{color: barra.corLetraBarra,}}>CONTAS A RECEBER - LANÇAMENTOS</h4></Titulo>
                            </td>
                            <td width="50%" align="right">
                            </td>
                        </tr>
                    </tbody>
                </table>
                          
            </div>
        </ConteudoTitulo>

        <center>
            <p></p>
            <div className="Container" style={{width: "80%", border:"1px solid #000"}} align="center"> 
                <div className="row">
                    <p></p>
                    <div className="col-md-6" >
                        <select className="form-control" name="loja" value={loja}  onChange={l => setLoja(l.target.value)}
                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                                <option value="">Selecione a loja </option>
                                {lojas.map((option, index) => {
                                    return <option value={option} key={index} >
                                    {option}
                                    </option>
                                })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-8">
                                <select className="form-control" name="fornecedor" value={fornecedor} onChange={f => setFornecedor(f.target.value)}>
                                    <option value="">Selecione o Cliente </option>
                                    {fornecedores.map((fndr) => {
                                        return <option value={fndr.id} >
                                        {fndr.nome}
                                        </option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label style={{fontSize: "14px", fontWeight: "700" }}> </label>
                                <Button className="btn" style={{backgroundColor: barra.corBotao, color: barra.corLetraBotao, padding: "5px", borderRadius: "20px"}} onClick={() => incluirRegistro()}>
                                    Incluir Novo Cliente
                                </Button>
                            </div>
                        </div>
                    </div>

                    <p></p>
                    <center>
                    <div className="form-control"  style={{backgroundColor: barra.corTela, width: "98%"}}>
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-2" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>Data de Emissão</label></div>
                            <div className="col-md-1" align="left" ><input type="date" name="emissao" value={emissao} onChange={e => setEmissao(e.target.value)} /></div>
                                    
                            <div className="col-md-2" align="right"> <label style={{fontSize: "14px", fontWeight: "700" }}>Data do Vencimento</label></div>
                            <div className="col-md-1" align="left" ><input type="date" name="vencimento" value={vencimento} onChange={v => setVencimento(v.target.value)} /></div>
                            <div className="col-md-3"></div>

                            <p></p>
                            <p></p>

                            <div className="row">
                                <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>Valor dos Produtos</label></div>
                                <div className="col-md-6" align="left" ><input type="number" name="valorP" value={valorP} onChange={vp => setValorP(vp.target.value)} style={{textAlign: "right" }} /></div>
                                <p></p>
                                <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>(+) Valores adicionais</label></div>
                                <div className="col-md-6" align="left" ><input type="number" name="valorF" value={valorF} onChange={vf => setValorF(vf.target.value)} style={{textAlign: "right" }} /></div>
                                <p></p>
                                <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>(-)Valor do Desconto</label></div>
                                <div className="col-md-6" align="left" ><input type="number" name="valorD" value={valorD} onChange={vd => setValorD(vd.target.value)} style={{textAlign: "right" }} /></div>
                                <p></p>
                                <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>Valor dos Produtos</label></div>
                                <div className="col-md-6" align="left" ><input type="number" name="valorT" value={valorT} onChange={vt => setValorT(vt.target.value)} style={{textAlign: "right" }} /></div>
                                <p></p>
                            </div>

                            <div className="row">
                                <p></p>
                                <p></p>
                            </div>

                            <center>
                            <div className="form-control" style={{width: "50%"}}>
                                <div className="row" align="left">
                                    <label style={{fontSize: "14px", fontWeight: "700" }}>Observações</label>
                                    <textarea name="observacao" id="observacao" rows="3" onChange={o => setObservacao(o.target.value)} >{observacao}</textarea>
                                </div>
                            </div>
                            </center>

                        </div>

                    </div>
                    </center>
                </div>

                    <center>
                    <div className="form-control" style={{width: "50%"}}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="50%" align='left' >
                                        <input type="submit" className="btn-primary" 
                                        style={{paddingLeft:"7px", paddingRight:"7px", backgroundColor: barra.corBotao, borderRadius:"20px", }} value="SALVAR LANÇAMENTO" />
                                        </td>
                                    <td width="50%" align='right'>
                                        <button type="button" className="btn-danger" 
                                        style={{paddingLeft:"7px", paddingRight:"7px", borderRadius:"20px", }}onClick={handleCancel}>CANCELAR</button>
                                        </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </center>


                </div>
            </center>
        </Container>
    </form>

    
    <hr />

<Modal show={showN} onHide={handleClose}>
<form onSubmit={handleSubmit}>
<Modal.Header closeButton>
  <Modal.Title>Novo cliente</Modal.Title>
</Modal.Header>
<Modal.Body>
    <div className='form-control'>
    <input className="row" type="hidden" name="id"    id="id"    value={state.id}    onChange={handleChange}  placeholder='informe o id da empresa' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="nome"    id="nome"    value={state.nome}    onChange={handleChange}  placeholder='informe o nome ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <textarea className="row" name="endereco" id="endereco" rows="2" value={state.endereco} onChange={handleChange}  placeholder='digite o Endereço' style={{borderRadius: "5px", width: "100%", textAlign: "center"}} />
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="telefone"   id="telefone"   value={state.telefone}   onChange={handleChange}  placeholder='digite seu Telefone  ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="email"  id="email"  value={state.email}  onChange={handleChange}  placeholder='Informe o e-mail preferido' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="cnpj"  id="cnpj"  value={state.cnpj}  onChange={handleChange}  placeholder='Informe o CNPJ/CPF' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>            
    <div className="form-control">
        <table width="100%">
            <tbody>
                <tr>
                    <td width="50%" align='left' ><input type="submit" className="btn-primary" value="SALVAR" /></td>
                    <td width="50%" align='right'><button type="button" className="btn-danger" onClick={handleClose}>Cancelar</button></td>
                </tr>
            </tbody>
        </table>
        
        
    </div>

</Modal.Body>
<Modal.Footer>
    <hr></hr>
</Modal.Footer>
</form>
</Modal>  

    </>
  )
}

export default ContasReceberLcto