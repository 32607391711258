import Navbar from './Navbar';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleDown, FaPowerOff } from 'react-icons/fa';

const Header = () => {

  const barra = useContext(AuthContext);

  const navigate = useNavigate();

  
  function flipExit() {
    console.log(document.getElementById('divsair').style.display)
    if (document.getElementById('divsair').style.display === 'block') {
      document.getElementById('divsair').style.display = 'none'      
    } else {
      document.getElementById('divsair').style.display = 'block'      
    }

  }


  function Sair() {
    document.getElementById('divsair').style.display = 'none' 
    navigate('sair')
  }


  return (
    <header>
      <div className="versao"  style={{fontSize: barra.corLetraMenu, color: barra.corLetraFundo, display: barra.sidebar ? "block" : "none" ,}}></div>
      <div className="nav-area" >
        <Link to="/" className="logo" style={{color: barra.corLetraMenu, display: barra.sidebar ? "block" : "none" ,}}>
          <img src="logo.jpg" width="125" height="60" alt={barra.address_logo} title={barra.address_logo} />
        </Link>
        <Navbar />
        <div className="usuario" style={{fontSize: barra.corLetraMenu, fontWeight: "200",  color: barra.corLetraFundo, display: barra.sidebar ? "block" : "none" ,}}>
          <table>
            <tbody>
              <tr>
                <td width="20%"></td>
                <td width="60%">{barra.nusuario}</td>
                <td width="10%"><span style={{fontSize: "16px" }}><FaArrowAltCircleDown onClick={flipExit} /></span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="divsair" className="form-control" 
        style={{display: 'none', cursor: "pointer", position: "fixed", top: "5.5Vh", left: "92Vw", fontSize: "10px", backgroundColor: "#cacaca", border: "1px solid #000", borderRadius: "5px", padding: "1px"}}>
          <FaPowerOff  onClick={Sair} /><span onClick={Sair}> Sair</span>
        </div>

      </div>
    </header>
  );
};

export default Header;
