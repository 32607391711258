
import React from 'react';
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../css/login.css";
import emailjs from "@emailjs/browser";
import Layout from "../../page/menu/Layout";
import { AuthContext } from '../../contexts/Auth';
import { useCookies } from 'react-cookie';

//import Sidebar from '../../components/Sidebar';

const Login = () => {


  const [emailr, setEmailr] = useState('')

  const barra = useContext(AuthContext);
  //console.log("CONTEXTO LOGIN: ", barra.sidebar);
  const [showN, setshowN] = useState(false);
  const [loading, setLoading] = useState(false);

  
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['user']);
  const handleCookies = () => {
    setCookie('Nameccs', inputs.email, { path: '/' });
    setCookie('Passwordccs', inputs.senha, { path: '/' });
  };
  const limparCookies = () => {
    setCookie('Nameccs', '', { path: '/' });
    setCookie('Passwordccs', '', { path: '/' });
  };

  const [inputs, setInputs] = useState({
    email: cookies.Nameccs,
    senha: cookies.Passwordccs
  });
  
  const [isChecked, setIsChecked] = useState(false)


  function sendEmail(e) {
      e.preventDefault();
      if (emailr === '') {
        alert("Preencha  o campo com seu email favorito")
        return
      }

      const templateParams = {
        from_name: "Equipe de suporte BAGS",
        message: emailr,
        from_email: emailr,
        emailDestino: emailr
      }

      emailjs.send("service_c3yly6k","template_njuheic", templateParams, "MC9Q635azkWcL3-up").then((response) => {
        console.log("EMAIL Enviado ", response.status, response.text)
        setEmailr('')
        setshowN(false)
      }, (err) => {
        console.log("ERRO: ", err)
      })
  }

  useEffect(() => {
    if (!loading && localStorage.getItem("token") !== null) {
        barra.setSidebar(true)
        if (barra.nivel !== 'Administrador') {  navigate('/receber-mat')  }  else  {   navigate('/apex1')   }
     } 
  }, [loading, navigate, barra]);  

  
  //const [num, setNum] = useState(0)
  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const lerParametros = async (empre) => {

    axios.get(`${barra.urlbase}/apicrud/lerparametros.php?e=${empre}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (um): ", sucesso);

        if (sucesso) {
              console.log("DATA (1)", res.data.datalist.userdata);
                barra.setCorFundo(res.data.datalist.userdata[0].p1)
                barra.setCorBarra(res.data.datalist.userdata[0].p2)
                barra.setCorBotao(res.data.datalist.userdata[0].p3)
                barra.setCorProdu(res.data.datalist.userdata[0].p4)
                barra.setCorPanel(res.data.datalist.userdata[0].p5)
                barra.setCorHeade(res.data.datalist.userdata[0].p6)
                barra.setCorLetpn(res.data.datalist.userdata[0].p7)
                barra.setCorRealc(res.data.datalist.userdata[0].p8)
                barra.setCorFreal(res.data.datalist.userdata[0].p9)
                barra.setImgLogos(res.data.datalist.userdata[0].s1)

        } else {
              console.log("Não encontrado (DU)")

              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

}

  const verifyUser = async (email, senha) => {
    try {
        console.log("parâmetros: ", {email, senha});
        
      axios.get(`${barra.urlbase}/apicrud/verifyuser.php?e=${email}&s=${senha}`, inputs).then(function(res) {
      
      let sucesso    = res.data.success;
      console.log("SUCESSO: ", sucesso);

      if (sucesso) {
            let dados      = res.data.userlist.userdata;
            console.log(dados);
            console.log(res);
            let codigo     = res.data.userlist.userdata[0].id;
            let nome       = res.data.userlist.userdata[0].nome;
            let sobrenome  = res.data.userlist.userdata[0].sobrenome;
            let email      = res.data.userlist.userdata[0].email;
            let empresa    = res.data.userlist.userdata[0].empresa;
            let nivel      = res.data.userlist.userdata[0].perfil;
            console.log("CODIGO: ", codigo);
            console.log("NOME: ", nome);
            console.log("SOBRENOME: ", sobrenome);
            console.log("EMAIL: ", email);
            console.log("NIVEL: ", nivel)

            barra.setNivel(res.data.userlist.userdata[0].perfil)
            if (res.data.userlist.userdata[0].perfil !== 'Administrador') {   barra.setNivel("Operador") }
            console.log("NÍVEL DO USUÁRIO: ", barra.nivel)


            barra.setCempresa(empresa)
            barra.setCusuario(codigo)
            barra.setNusuario(nome)
            barra.setCnivelus(nivel)

            //handleCookies()

            //Layout()
            
            //lerParametros(empresa)

            let xave = randomNumberInRange(100000, 999999)
            xave += "_" + empresa + randomNumberInRange(100, 999)

            setLoading(true);

            setTimeout(() => {
                localStorage.setItem("token", JSON.stringify(xave));
                setLoading(false);
            }, 1000);

        } else {
            console.log("Não encontrado")
            setInputs([]);
            window.location.reload(true);
      }

      return
        
       })
    } catch (error) { throw error;}    
  }  

  function recuperar() {

    document.getElementById('emailr').value = ""
    setshowN(true)
    return
  }  

  const handleClose = () => {
    setLoading(false)
    setshowN(false);
  }

  const handleChange = (event) => {
    const email = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [email]: value}));
}

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("login", inputs.email, inputs.senha, isChecked )
    if (isChecked) {
      handleCookies()
    } else {
      limparCookies()
    }
    verifyUser(inputs.email, inputs.senha);
  }

  let nom = cookies.Nameccs
  if (nom !== '') { console.log(nom) }
  if (cookies.Nameccs !== '') { barra.setCook(true) } else { barra.setCook(false) }
  //setIsChecked(barra.cook)

  return (
    <>
    <div style={{position: "fixed", top: "0%", left: "0%", backgroundImage: `url(${barra.imagemP})`, backgroundRepeat: 'no-repeat', opacity: "0.2", width:"100%", height: "100%", }}></div>
    <div style={{position: "fixed", top: "0%", left: "40%"}}>
        <form className='Container' onSubmit={handleSubmit} >
        <div className="auth-wrapper" style={{marginTop: '40px', border: "0px solid #000"}}>
            <div className="auth-content">
                
                <div className="card">
                    <div className="card-body text-center" 
                    style={{backgroundColor: barra.corFundoLogin, color: barra.corLetraFundo, borderWidth: barra.tamBordaFundo, borderStyle: barra.tipBordaFundo,  borderColor: barra.corBordaFundo, borderRadius: "20px",  boxShadow: "0px 6px 8px #cccccc,0px 3px 4px #000000, 0px 1px 16px #000099", }}>
                        <img src="logo.jpg" width="200" height="80" alt={barra.address_logo} title={barra.address_logo} />

                        <center>
                        <div className="form">&nbsp;</div>
                        <div className="form">&nbsp;</div>
                        <div className="form"><h3 style={{ width: "100%", backgroundColor: "Transparent", }}><span style={{color: "#000", fontWeight: 500,}}></span></h3></div>
                        
                        
                        <div className="form-group">
                                    <div  style={{textAlign: "left", marginLeft: "20%", fontWeight: "900"}}><label className="exampleInputEmail1">email</label></div>
                                    <input type="text" className="form-control" id="email" name="email" autoComplete='username' placeholder="Informe o email" value={inputs.email} onChange={handleChange} style={{width: "60%", borderRadius: "10px"}} />
                        </div>
                        <div className="form">&nbsp;</div>
                        <div className="form-group">
                                    <div  style={{textAlign: "left", marginLeft: "20%", fontWeight: "900"}}><label className="exampleInputPassword1">senha</label></div>
                                    <input type="password" className="form-control" id="senha" name="senha" autoComplete="current-password" placeholder="digite sua senha" value={inputs.senha} onChange={handleChange} style={{width: "60%", borderRadius: "10px"}} />
                        </div>
                        <div className="form-group form-check" style={{textAlign: 'left'}}>
                            <small id="emailHelp" style={{color: barra.corLetraFundo}}></small>
                        </div>

                        <p style={{color: barra.corLetraFundo}}>
                            <input type="checkbox" name="lembrar" value="1" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} /> lembrar senha
                        </p>
                        <p className="mb-0 text-muted"></p>

                        <button type="submit" className="botao-verde" style={{ fontSize: "140%", }}>E n t r a r</button>
                        
                        <p>&nbsp;</p>
                        
                        <p style={{color: barra.corLetraFundo}}>esqueceu a senha? 
                              <Button style={{border: "none", backgroundColor: "Transparent",  marginLeft: "5px", textDecoration: "Underline", color: barra.corLetraFundo}} onClick={() => recuperar()}>
                                    Clique aqui
                              </Button>
                        </p>

                        <p><span style={{fontSize: "0.7em", color: "#787878"}}>{barra.versao}</span></p>
                         
                        </center>

                    </div>
                </div>
                {/*}
                <div className="auth-bg">
                    <span className="r" style={{background: barra.corBola1}}></span>
                    <span className="r s" style={{background: barra.corBolinha1}}></span>
                    <span className="r s" style={{background: barra.corBolinha1}}></span>
                    <span className="r s" style={{background: barra.corBolinha2}}></span>
                    <span className="r s" style={{background: barra.corBolinha2}}></span>
                    <span className="r" style={{background: barra.corBola2}}></span>
                </div>
                */}
        </div>



        </div>


        </form>  

        <Modal show={showN} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Redefinir Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className='form' onSubmit={sendEmail}>
            <div className='form-control'>
            <input className="row" type="text" name="emailr"    id="emailr"    value={emailr}    onChange={(e) => setEmailr(e.target.value)}  placeholder='informe o email cadastrado' 
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            </div>
            <div className="form-control">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align='left' ><input type="submit" className="btn-primary" value="Enviar link para redefinição" /></td>
                            <td width="50%" align='right'><button type="button" className="btn-danger" onClick={handleClose}>Cancelar</button></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </Modal>       

    </div>

    </>
  )
}

export default Login