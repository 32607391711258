import React from 'react'

import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

//import Iframe from 'react-iframe'

const ImportarNotas = () => {

 const barra = useContext(AuthContext);

    return (
        <>
            <div className="form" style={{position: "fixed", top:"2%", left:"60%", zIndex:"999"}}>
                <span style={{color: "white"}}>Usuário: {barra.nusuario} (Cod.: {barra.cusuario}) Empresa: {barra.cempresa}</span>
            </div> 
            <div className="form" style={{ position: "fixed", top: 90, left: 280,}}>
                <h3  style={{textAlign:"center",}}>Importar Arquivos</h3>
                <iframe src="${barra.urlbase}/apicrud/importar.php" width="600px" height="500px" title="IMPORTAR" ></iframe>           
            </div>
        </>
      )
}

export default ImportarNotas