import React from 'react'
import axios from "axios";
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth";
import { useState, useEffect  } from 'react';
import { Navigate } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

const options = [
    { label: "FundoPadrao", value: "#3399CC", },
    { label: "BarraPadrao", value: "#66CCFF", },
    { label: "BotaoPadrao", value: "#0D6EFD", },
    { label: "PanelPadrao", value: "#FFFF01", },
    { label: "InterPadrao", value: "#3399CD", },
    { label: "CabecPadrao", value: "#4488BB", },
    { label: "LetraPadrao", value: "#FFFFFF", },
    { label: "SmenuPadrao", value: "#0D9EFD", },
    { label: "RealcPadrao", value: "#0D6EFE", },
    { label: "Snow", value: "#FFFAFA", },
    { label: "GhostWhite", value: "#F8F8FF", },
    { label: "WhiteSmoke", value: "#F5F5F5", },
    { label: "Gainsboro", value: "#DCDCDC", },
    { label: "FloralWhite", value: "#FFFAF0", },
    { label: "OldLace", value: "#FDF5E6", },
    { label: "Linen", value: "#FAF0E6", },
    { label: "AntiqueWhite", value: "#FAEBD7", },
    { label: "PapayaWhip", value: "#FFEFD5", },
    { label: "BlanchedAlmond", value: "#FFEBCD", },
    { label: "Bisque", value: "#FFE4C4", },
    { label: "PeachPuff", value: "#FFDAB9", },
    { label: "NavajoWhite", value: "#FFDEAD", },
    { label: "Moccasin", value: "#FFE4B5", },
    { label: "Cornsilk", value: "#FFF8DC", },
    { label: "Ivory", value: "#FFFFF0", },
    { label: "LemonChiffon", value: "#FFFACD", },
    { label: "Seashell", value: "#FFF5EE", },
    { label: "Honeydew", value: "#F0FFF0", },
    { label: "MintCream", value: "#F5FFFA", },
    { label: "Azure", value: "#F0FFFF", },
    { label: "AliceBlue", value: "#F0F8FF", },
    { label: "lavender", value: "#E6E6FA", },
    { label: "LavenderBlush", value: "#FFF0F5", },
    { label: "MistyRose", value: "#FFE4E1", },
    { label: "White", value: "#FFFFFF", },
    { label: "Black", value: "#000000", },
    { label: "DarkSlateGray", value: "#2F4F4F", },
    { label: "DimGrey", value: "#696969", },
    { label: "SlateGrey", value: "#708090", },
    { label: "LightSlateGray", value: "#778899", },
    { label: "Grey", value: "#BEBEBE", },
    { label: "LightGray", value: "#D3D3D3", },
    { label: "MidnightBlue", value: "#191970", },
    { label: "NavyBlue", value: "#000080", },
    { label: "CornflowerBlue", value: "#6495ED", },
    { label: "DarkSlateBlue", value: "#483D8B", },
    { label: "SlateBlue", value: "#6A5ACD", },
    { label: "MediumSlateBlue", value: "#7B68EE", },
    { label: "LightSlateBlue", value: "#8470FF", },
    { label: "MediumBlue", value: "#0000CD", },
    { label: "RoyalBlue", value: "#4169E1", },
    { label: "Blue", value: "#0000FF", },
    { label: "DodgerBlue", value: "#1E90FF", },
    { label: "DeepSkyBlue", value: "#00BFFF", },
    { label: "SkyBlue", value: "#87CEEB", },
    { label: "LightSkyBlue", value: "#87CEFA", },
    { label: "SteelBlue", value: "#4682B4", },
    { label: "LightSteelBlue", value: "#B0C4DE", },
    { label: "LightBlue", value: "#ADD8E6", },
    { label: "PowderBlue", value: "#B0E0E6", },
    { label: "PaleTurquoise", value: "#AFEEEE", },
    { label: "DarkTurquoise", value: "#00CED1", },
    { label: "MediumTurquoise", value: "#48D1CC", },
    { label: "Turquoise", value: "#40E0D0", },
    { label: "Cyan", value: "#00FFFF", },
    { label: "LightCyan", value: "#E0FFFF", },
    { label: "CadetBlue", value: "#5F9EA0", },
    { label: "MediumAquamarine", value: "#66CDAA", },
    { label: "Aquamarine", value: "#7FFFD4", },
    { label: "DarkGreen", value: "#006400", },
    { label: "DarkOliveGreen", value: "#556B2F", },
    { label: "DarkSeaGreen", value: "#8FBC8F", },
    { label: "SeaGreen", value: "#2E8B57", },
    { label: "MediumSeaGreen", value: "#3CB371", },
    { label: "LightSeaGreen", value: "#20B2AA", },
    { label: "PaleGreen", value: "#98FB98", },
    { label: "SpringGreen", value: "#00FF7F", },
    { label: "LawnGreen", value: "#7CFC00", },
    { label: "Green", value: "#00FF00", },
    { label: "Chartreuse", value: "#7FFF00", },
    { label: "MedSpringGreen", value: "#00FA9A", },
    { label: "GreenYellow", value: "#ADFF2F", },
    { label: "LimeGreen", value: "#32CD32", },
    { label: "YellowGreen", value: "#9ACD32", },
    { label: "ForestGreen", value: "#228B22", },
    { label: "OliveDrab", value: "#6B8E23", },
    { label: "DarkKhaki", value: "#BDB76B", },
    { label: "PaleGoldenrod", value: "#EEE8AA", },
    { label: "LtGoldenrodYello", value: "#FAFAD2", },
    { label: "LightYellow", value: "#FFFFE0", },
    { label: "Yellow", value: "#FFFF00", },
    { label: "Gold", value: "#FFD700", },
    { label: "LightGoldenrod", value: "#EEDD82", },
    { label: "goldenrod", value: "#DAA520", },
    { label: "DarkGoldenrod", value: "#B8860B", },
    { label: "RosyBrown", value: "#BC8F8F", },
    { label: "IndianRed", value: "#CD5C5C", },
    { label: "SaddleBrown", value: "#8B4513", },
    { label: "Sienna", value: "#A0522D", },
    { label: "Peru", value: "#CD853F", },
    { label: "Burlywood", value: "#DEB887", },
    { label: "Beige", value: "#F5F5DC", },
    { label: "Wheat", value: "#F5DEB3", },
    { label: "SandyBrown", value: "#F4A460", },
    { label: "Tan", value: "#D2B48C", },
    { label: "Chocolate", value: "#D2691E", },
    { label: "Firebrick", value: "#B22222", },
    { label: "Brown", value: "#A52A2A", },
    { label: "DarkSalmon", value: "#E9967A", },
    { label: "Salmon", value: "#FA8072", },
    { label: "LightSalmon", value: "#FFA07A", },
    { label: "Orange", value: "#FFA500", },
    { label: "DarkOrange", value: "#FF8C00", },
    { label: "Coral", value: "#FF7F50", },
    { label: "LightCoral", value: "#F08080", },
    { label: "Tomato", value: "#FF6347", },
    { label: "OrangeRed", value: "#FF4500", },
    { label: "Red", value: "#FF0000", },
    { label: "HotPink", value: "#FF69B4", },
    { label: "DeepPink", value: "#FF1493", },
    { label: "Pink", value: "#FFC0CB", },
    { label: "LightPink", value: "#FFB6C1", },
    { label: "PaleVioletRed", value: "#DB7093", },
    { label: "Maroon", value: "#B03060", },
    { label: "MediumVioletRed", value: "#C71585", },
    { label: "VioletRed", value: "#D02090", },
    { label: "Magenta", value: "#FF00FF", },
    { label: "Violet", value: "#EE82EE", },
    { label: "Plum", value: "#DDA0DD", },
    { label: "Orchid", value: "#DA70D6", },
    { label: "MediumOrchid", value: "#BA55D3", },
    { label: "DarkOrchid", value: "#9932CC", },
    { label: "DarkViolet", value: "#9400D3", },
    { label: "BlueViolet", value: "#8A2BE2", },
    { label: "Purple", value: "#A020F0", },
    { label: "MediumPurple", value: "#9370DB", },
    { label: "Thistle", value: "#D8BFD8", },
    { label: "Snow1", value: "#FFFAFA", }
    ];    



const Parametros = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }        

    const [primeira, setPrimeira] = useState(true)
    const [showN, setShowN] = useState(false)
    const [state, setState] = useState({
        color1: "",
        color2: "",
        color3: "",
        color4: "",
        color5: "",
        color6: "",
        color7: "",
        color8: "",
        color9: "",
        logo: ""

      })

      const [cores, setCores] = useState({
        color1: "",
        color2: "",
        color3: "",
        color4: "",
        color5: "",
        color6: "",
        color7: "",
        color8: "",
        color9: "",
        logo: ""

      })

    useEffect(() => {
        setShowN(false)
    }, [showN]);  



    const lerParametros = async () => {

        axios.get(`https://www.bea.inf.br/apicrud/lerparametros.php?e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (um): ", sucesso);

            if (sucesso) {
                  console.log("DATA (1)", res.data.datalist.userdata);
                  setState({
                    color1:  res.data.datalist.userdata[0].p1,
                    color2:  res.data.datalist.userdata[0].p2,
                    color3:  res.data.datalist.userdata[0].p3,
                    color4:  res.data.datalist.userdata[0].p4,
                    color5:  res.data.datalist.userdata[0].p5,
                    color6:  res.data.datalist.userdata[0].p6,
                    color7:  res.data.datalist.userdata[0].p7,
                    color8:  res.data.datalist.userdata[0].p8,
                    color9:  res.data.datalist.userdata[0].p9,
                    logo:    res.data.datalist.userdata[0].s1
                    })

                    setCores({
                        color1:  res.data.datalist.userdata[0].p1,
                        color2:  res.data.datalist.userdata[0].p2,
                        color3:  res.data.datalist.userdata[0].p3,
                        color4:  res.data.datalist.userdata[0].p4,
                        color5:  res.data.datalist.userdata[0].p5,
                        color6:  res.data.datalist.userdata[0].p6,
                        color7:  res.data.datalist.userdata[0].p7,
                        color8:  res.data.datalist.userdata[0].p8,
                        color9:  res.data.datalist.userdata[0].p9,
                        logo:    res.data.datalist.userdata[0].s1
                        })

                    barra.setCorFundo(res.data.datalist.userdata[0].p1)
                    barra.setCorBarra(res.data.datalist.userdata[0].p2)
                    barra.setCorBotao(res.data.datalist.userdata[0].p3)
                    barra.setCorProdu(res.data.datalist.userdata[0].p4)
                    barra.setCorPanel(res.data.datalist.userdata[0].p5)
                    barra.setCorHeade(res.data.datalist.userdata[0].p6)
                    barra.setCorLetpn(res.data.datalist.userdata[0].p7)
                    barra.setCorRealc(res.data.datalist.userdata[0].p8)
                    barra.setCorFreal(res.data.datalist.userdata[0].p9)
                    barra.setImgLogos(res.data.datalist.userdata[0].s1)

                    Navigate("/parametros")

                 

            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }


    const atualizaParametro = async (c1, c2, c3, c4, c5, c6, c7, c8, c9, lg) => {
        try {
            console.log("LOGO: ", lg)
            let kpo = "c1=" + c1 + "&c2=" + c2 + "&c3=" + c3 +"&c4=" + c4 +"&c5=" + c5 +"&c6=" + c6 +"&c7=" + c7 +"&c8=" + c8 +"&c9=" + c9 +"&lg=" + lg + "&ep=" + barra.cempresa
            kpo = kpo.replaceAll("#","")
            console.log(kpo)
            axios.get(`https://www.bea.inf.br/apicrud/atualizarparametros.php?${kpo}`).then(function(res) {
                    let sucesso    = res.data.success;
                    console.log("SUCESSO (PAR): ", sucesso);
    
                    if (sucesso) {
                            console.log(res.data.motivo);
                            barra.avisar(res.data.motivo, 5)
                            setShowN(true)

    
                    } else {
                            console.log("Não encontrado")
                            barra.avisar("Ocorreu um problema no envio da mensagem. Por favor, tente novamente.", 5)
    
                            let motivo    = res.data.motivo;
                            console.log("NÃO SUCESSO: ", motivo);
                    }
            })

        } catch (error) { throw error;} 
    
        return

    } 

    function handleSubmit(evt) {
        evt.preventDefault();
        console.log("Parametros: ", state.color1)
        atualizaParametro(state.color1, state.color2, state.color3, state.color4, state.color5, state.color6, state.color7, state.color8, state.color9, state.logo )
    }

    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setState(values => ({...values, [nome]: value}));
        setCores(values => ({...values, [nome]: value}));

    }  


    if (primeira) {
        setPrimeira(false)
        lerParametros()
    }

  return (
  
  <>

    <form className="Container" onSubmit={handleSubmit}>
        <Container style={{overflow: "hidden", }}>
                <ConteudoTitulo>
                    <div style={{ width: "100%", backgroundColor: barra.corPanel, textAlign: "left", height: 60 }}>
                          <Titulo><h4 style={{color: barra.corLetpn, paddingTop: 10, marginLeft: 4 }}>Customizar telas do sistema</h4></Titulo>
                    </div>
                </ConteudoTitulo>

        <div className="col-md-12">
            <center>

            <table className="table table-striped" style={{ width: "50%", }}>
                <thead>
                    <tr>
                        <th align="left">Parâmetro</th>
                        <th align="left" >valor (conteúdo)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td align="left" >Cor das Barras Superior e Lateral</td>
                        <td align="left" >
                            <select name="color1"  id="color1"  value={state.color1} onChange={handleChange} style={{ backgroundColor: cores.color1}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor da Barra do título da página</td>
                        <td align="left" >
                            <select name="color2"  id="color2"  value={state.color2} onChange={handleChange} style={{ backgroundColor: cores.color2}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor dos botões de confirmação</td>
                        <td align="left" >
                            <select name="color3"  id="color3"  value={state.color3} onChange={handleChange} style={{ backgroundColor: cores.color3}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor de Painel</td>
                        <td align="left" >
                            <select name="color4"  id="color4"  value={state.color4} onChange={handleChange} style={{ backgroundColor: cores.color4}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor de Painel interno</td>
                        <td align="left" >
                            <select name="color5"  id="color5"  value={state.color5} onChange={handleChange} style={{ backgroundColor: cores.color5}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor de Fundo do Cabeçalho</td>
                        <td align="left" >
                            <select name="color6"  id="color6"  value={state.color6} onChange={handleChange} style={{ backgroundColor: cores.color6}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor da Letra no Subpainel</td>
                        <td align="left" >
                            <select name="color7"  id="color7"  value={state.color7} onChange={handleChange} style={{ backgroundColor: cores.color7}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor de fundo do submenú</td>
                        <td align="left" >
                            <select name="color8"  id="color8"  value={state.color8} onChange={handleChange} style={{ backgroundColor: cores.color8}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Cor do realce da opção do menú</td>
                        <td align="left" >
                            <select name="color9"  id="color9"  value={state.color9} onChange={handleChange} style={{ backgroundColor: cores.color9}}>
                                {options.map((option) => (
                                <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                                ))}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" >Logo da tela inicial</td>
                        <td align="left" >
                             <input type="text" name="logo" id="logo" value={state.logo} onChange={handleChange} />
                        </td>
                    </tr>
                    
                    <tr><td colspan="2">&nbsp;</td></tr>
                    <tr>
                            <td width="50%" align='left' ><input type="submit" className="btn-primary" style={{paddingLeft:"4px", paddingRight:"4px", backgroundColor: barra.corBotao, borderRadius:"5px", }} value="SALVAR PARÂMETROS" /></td>
                            <td width="50%" align='right'><button type="button" className="btn-danger" style={{paddingLeft:"4px", paddingRight:"4px", borderRadius:"5px", }}>CANCELAR</button></td>
                        </tr>
                </tbody>
            </table>
            </center>
            
        </div>

        </Container>
        
    </form>

    </>

  )



}

export default Parametros