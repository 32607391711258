import React from 'react'
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Table, Titulo } from '../styles';


const OperacoesEspeciais = () => {

  const barra = useContext(AuthContext);

  return (
    <>
        


    <form className="Container">
    <Container>
            <ConteudoTitulo>
                <div style={{ width: "100%", backgroundColor: barra.corBarra, textAlign: "left", height: 40 }}>
                <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: 10, marginLeft: 2}}>OPERAÇÕES ESPECIAIS</h4></Titulo>
                </div>
            </ConteudoTitulo>

            <Table>
              <thead>
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="50%" valign='top' align='left'><img src="reservado.fw.png" alt=""/></td>
                  <td width="50%" valign='top' align='left'>
                    Área idealmente reservada aos usuários com maior nível de acesso às funcionalidades do sistema, ao menos em sua concepção.
                    <br /><br />
                    A manutenção de algumas tabelas do sistema, como notas fiscais por exemplo, podem requerer, vez ou outra, intervenções menos comuns ao dia-a-dia operacional.
                    Por exemplo, um item que chegou e não estava na nota fiscal original e precisa ser devidamente colocado no estoque físico, ou notas fiscais com itens que não 
                    foram recebidos e, portanto, essa nota ficaria eternamente "em aberto", e outras formas de acerto que se presentes, facilitam os acertos operacionais.
                    <br /><br />
                    Exatamente por serem operações pouco comuns e por seu potencial de alterar situações automatizadas pelo sistema, aconselha-se que sejam restritas aos gestores
                    ou operadores com elevado nível de responsabilidade na empresa
                    <br /><br />
                    <br /><br />

                  </td>
                </tr>
              </tbody>
            </Table>
    </Container>                

    </form>

    </>    
  )
}

export default OperacoesEspeciais