import React from "react";
import { useState } from "react";
import axios from "axios";
import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"


function Panel({ tit, grf }) {

  const barra = useContext(AuthContext) 
  
//  const [dados, setDados] = useState([])
  const [resultado, setResultado] = useState('0')
//  const [loading, setLoading] = useState(false)
  const [primeira, setPrimeira] = useState(true)

//  useEffect(() => {
//    setLoading(false)
//    console.log("BAR.HOR:")
//  }, [loading]);  


  const buscarDados = async () => {
    try {

        axios.get(`${barra.urlbase}/apicrud/ler_grafico_generico.php?ep=${barra.cempresa}&gf=${grf}&x=33`).then(function(res) {
            let sucesso    = res.data.success;
//            console.log("LEU GRAFICO BARRA HORIZ: ", res)
//            console.log("SUCESSO: ", sucesso);
  
            if (sucesso) {
//                    console.log("RES.BARRA H:", res.data.userlist.userdata);
                    //setDados(res.data.userlist.userdata)
                    setResultado(res.data.userlist.userdata[0].horiz)
                    //setLoading(true);
            } else {
//                    console.log("informações Não encontradas", res)
                    //setDados(UserDatax)
                    setResultado('0')
                    //setLoading(true);
            }
        })
    } catch (error) { throw error;} 
  
    return
  
  }  

  if (primeira) {
        buscarDados()
        setPrimeira(false)
  }
    


  return (
    <div>
    
    <div>
      <br />
      <h4>
      <center>{resultado}</center>
      </h4>
    </div>
    
    </div>

  )
}

export default Panel;