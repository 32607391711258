import styled from 'styled-components';
//import { useContext  } from 'react';
//import AuthProvider, { AuthContext } from "../../src/contexts/Auth"

export const Container = styled.section`
    max-width: 100Vw;
    margin-left: 0px;
    margin-top : 1Vh;
    box-shadow: 0 0 1em #6c757d;
    max-height: 85Vh;
    border-radius: 10px;
    overflow: auto;
    text-align: center;
`;

export const botao_padrao_sucesso = styled.section`
    background-color: #336699;
    font-size: 12px;
    border: 1px solid #000;
    font-weight: 100;
    color: white;
    border-radius: 10px;
`;
export const ConteudoTitulo = styled.section`
    display: flex;
    justify-content: space-between;
`;

export const BotaoAcao = styled.section`
    margin: 15px 0px;
`;

export const ButtonSuccess = styled.button`
    background-color: #fff;
    color: #198774;
    padding: 4px 6px;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    :hover{
        background-color: #f57347;
        color: #fff;
    }
`;

export const ButtonPrimary = styled.button`
    background-color: #fff;
    color: #0d6efd;
    padding: 4px 6px;
    border: 1px solid #0d6efd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    :hover{
        background-color: #0d6efd;
        color: #fff;
    }
`;

export const ButtonWarning = styled.button`
    background-color: #fff;
    color: #ffc107;
    padding: 4px 6px;
    border: 1px solid #ffc107;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    :hover{
        background-color: #ffc107;
        color: #fff;
    }
`;

export const Titulo = styled.h1`
    color: #3e3e3e;
    font-size: 18px;
`;

export const Table = 
    styled.table`
    width: 100%;
    th{
        background-color: #ffd219;
        color: #3e3e3e;
        padding: 10px;
    }
    td{
        background-color: #f6f6f6;
        color: #3e3e3e;
        padding: 8px;
    }
`;


