import React from "react";
import { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import { Container, ConteudoTitulo, Table, Titulo } from '../styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
//import Alert from "react-bootstrap/Alert"
import { AuthContext } from "../../contexts/Auth" 
//import { AiFillContainer } from "react-icons/ai";


const ReceberMatgeriais = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }


    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [recebidos, setRecebidos] = useState([]);
    
    const [inputs, setInputs] = useState([]);
    const [chave, setChave] = useState("");
    //const [mostrar, setMostrar] = useState(false)
    //const [aviso, setAviso] = useState('')

    let newXave = ''



    useEffect(() => {
        setInputs([])
        navigate("/receber-mat");
}, [loading, navigate]);  

    const btLimpaNotas = async () => {

        barra.avisar("Notas Fiscais foram retiradas do formulário", 1)


        setChave('')
        setProdutos([])
        setRecebidos([])
        setLoading(false)
    }

    const btUltimo = async () => {

        console.log("DU: ", chave)
        axios.get(`${barra.urlbase}/apicrud/delultimoitem.php?u=${barra.cusuario}&e=${chave}`, inputs).then(function(res) {
            let sucesso    = res.data.success;
            console.log("DU: ", res)
            console.log("SUCESSO (DU): ", sucesso);

            if (sucesso) {
                  console.log(res.data.userlist.vwbxdata);
                  setProdutos(res.data.userlist.vwbxdata);
                  if (res.data.recelist.rcbxdata) { setRecebidos(res.data.recelist.rcbxdata) }
                  setLoading(true);

                  barra.avisar("Último recebimento foi excluído", 1)

            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  setLoading(true);
                  console.log("NÃO SUCESSO (DU): ", motivo);
            }
        })                

    }
    
    const btTodosR = async () => {
        console.log("DU: ", chave)
        axios.get(`${barra.urlbase}/apicrud/deltodositens.php?u=${barra.cusuario}&e=${chave}`, inputs).then(function(res) {
            let sucesso    = res.data.success;
            console.log("DU: ", res)
            console.log("SUCESSO (DU): ", sucesso);

            if (sucesso) {
                  console.log(res.data.userlist.vwbxdata);
                  setProdutos(res.data.userlist.vwbxdata);
                  if (res.data.recelist.rcbxdata) { setRecebidos(res.data.recelist.rcbxdata) }
                  setLoading(true);

                  barra.avisar("TODOS os recebimentos foram excluídos", 1)

                } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  setLoading(true);
                  console.log("NÃO SUCESSO (DU): ", motivo);
            }
        })                

    }

    const concluirRecebimento = async() => {

        if (chave !== '') {

            console.log("Código: ", barra.cempresa, chave, barra.cusuario)

            axios.get(`${barra.urlbase}/apicrud/concluirrecebimento.php?e=${chave}&u=${barra.cusuario}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("bx: ", res)
                console.log("SUCESSO (receb): ", sucesso);
  
                if (sucesso) {
                      console.log(res);
                      setLoading(true);

                      barra.avisar(res.data.retorno, 1)

                } else {
                      console.log("Não encontrado (receb)")
  
                      let motivo    = res.data.motivo;
                      barra.avisar(motivo.substring(0,20), 2)
                      setLoading(true);
                      console.log("NÃO SUCESSO (bx): ", motivo);

                }
            })                
        } else {
            barra.avisar("Não há Notas Fiscais declaradas para a operação.",2)
        }
    }

    const verifyNotaFiscal = async (xave, codpro) => {
    try {
        if (xave) {

          if (chave !== '') {  xave = "," + xave}  
          newXave = chave + xave;
          console.log("Nova: ", newXave)
          setChave(newXave);
          console.log("matriz: ", chave)
          console.log("parâmetros: ", xave);

          axios.get(`${barra.urlbase}/apicrud/verifynotafiscal.php?e=${newXave}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("NF: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setProdutos(res.data.userlist.userdata);
                    if (res.data.recelist.recedata) { setRecebidos(res.data.recelist.recedata) }
                    setLoading(true);

                    barra.avisar("Itens da Nota fiscal carregados", 1)

              } else {
                    console.log("Não encontrado")

                    let motivo    = res.data.motivo;
                    setLoading(true);
                    barra.avisar(motivo, 3)
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
              }
          })
        } else {

            if (codpro) {
                let coduser = localStorage.getItem("codigo_usuario").replace('"','')
                console.log("Código: ", codpro, chave, coduser)

                axios.get(`${barra.urlbase}/apicrud/abaixaitem.php?e=${chave}&i=${codpro}&u=${coduser}`, inputs).then(function(res) {
                    let sucesso    = res.data.success;
                    console.log("bx: ", res)
                    console.log("SUCESSO (bx): ", sucesso);
      
                    if (sucesso) {
                          console.log(res.data.userlist.vwbxdata);
                          setProdutos(res.data.userlist.vwbxdata);
                          if (res.data.recelist.rcbxdata) { setRecebidos(res.data.recelist.rcbxdata) }
                          setLoading(true);

                          barra.avisar("Produto recebido", 1)

                    } else {
                          console.log("Não encontrado (bx)")
      
                          let motivo    = res.data.motivo;
                          barra.avisar(motivo.substring(0,20), 2)
                          setLoading(true);
                          console.log("NÃO SUCESSO (bx): ", motivo);

                    }
                })                
            }

        }
     
        return
       
    } catch (error) { throw error;}    

}



    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("nota fiscal", inputs.xave );
        setLoading(false)
        if (inputs.xave   === undefined)  {  inputs.xave   = '' }
        if (inputs.codpro === undefined)  {  inputs.codpro = '' }

        if ( (inputs.xave !== '') && (inputs.codpro !== '') ) {
            barra.avisar("Selecionar ou Nota Fiscal ou Item. Os 2 não devem ser preenchidos na mesma operação", 3)
            if (chave !== '') {  setLoading(true)  }
        } else {
            if ( (inputs.xave === '') && (inputs.codpro === '') ) {
                barra.avisar("Nota Fiscal ou Item, um os 2 deve ser preenchido", 3)
                if (chave !== '') {  setLoading(true)  }
            } else {
                verifyNotaFiscal(inputs.xave, inputs.codpro);
            }
        }
        
      }

  return (

    <>
        <form className="Container" onSubmit={handleSubmit}>
        <Container>
            <div  style={{ width: "100%" }}>
                <table width="100%" border="1" style={{ backgroundColor: barra.corBarra, }}>
                    <tr>
                        <td>&nbsp;</td>
                        <td width="47%" align="left"><Titulo><span style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "5px"}}>RECEBER MÚLTIPLAS NOTAS FISCAIS</span></Titulo></td>
                        <td width="50%" align="right">                
                            <Button onClick={concluirRecebimento} className="botao-verde"  style={{width: "auto"}}>
                                CONCLUIR RECEBIMENTO
                            </Button>
                        </td>
                    </tr>
                </table>
            </div>

            <div className="row" style={{ width: "98%", height: "98%", }}>
                <div className="col-md-6">
        
                    <div className="row" style={{  marginLeft: "5px", borderColor: "black", borderStyle: "solid", borderRadius: 10, borderWidth: 1}}>
                        <div className="card-body">
                            <span className="form-control" style={{backgroundColor: barra.corProdu   , fontWeight: 700, color: barra.corLetraProdu}}>NOTAS FISCAIS E PRODUTOS</span>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="form">
                                        <input type="Text" className="form-control" id="xave" name="xave" placeholder="digite ou leia o código da nota fiscal" value={inputs.xave} onChange={handleChange} style={{fontSize: "10px", borderRadius: "15px",}} />
                                    </div>
                                    <div className="form"></div>
                                    <button type="submit" name="btNotaFiscal" className="botao-verde" style={{fontSize: "90%", }}>(+) Incluir NF</button>
                                </div>
                                <div className="col-md-5">
                                    <div className="form">
                                        <input type="text" className="form-control" name="codpro" id="codpro" disabled={loading}
                                        placeholder="digite ou leia o código do produto" value={inputs.codpro} onChange={handleChange}  style={{fontSize: "10px",  borderRadius: "15px",}} />
                                    </div>
                    
                                    <div className="form-group"></div>
                                        <div className="form">
                                            <button type="submit" disabled={loading} name="btProduto" className="botao-verde"  style={{fontSize: "90%", }}>(+) Receber Item</button>
                                        </div>
                                    </div>
                                </div>
            
                                <hr />
            
                                <div style={{   borderColor: "black", borderStyle: "solid", borderRadius: 5, borderWidth: 1, backgroundColor: barra.corProdu }}>
                                <div className="card-body">
                                    <span className="form-control" style={{backgroundColor: barra.corProdu   , fontWeight: 700, color: barra.corLetraProdu}}>PRODUTOS RECEBIDOS</span>
                                </div>    
                                    <table  className="table table-striped" style={{fontSize: 10, backgroundColor: "#FFFFFF"}}>
                                        <thead><tr>
                                            <th>nota</th>
                                            <th>código</th>
                                            <th>produto</th>
                                            <th>data</th>
                                            <th>qtd</th>
                                        </tr></thead>
                                    <tbody>
                                        {recebidos.map( (itemr) => <tr key={itemr.id}>
                                            <td align="center">{itemr.nota}-{itemr.seq}</td>
                                            <td>{itemr.item}</td>
                                            <td>{itemr.prod}</td>
                                            <td align="center">{(itemr.data)}</td>
                                            <td align="center">{itemr.qtd}</td>
                                            </tr>
                                        )}
                                    </tbody>                        
                                </table>

                                {(recebidos.length !== 0) && (
                                    <div className="form-group">
                                        <button type="button" onClick={btUltimo}     className="btn-warning" style={{fontSize: "12px", marginRight: "10px", paddingLeft: "2px", paddingRight: "2px", color: "darkgreen", }} >(-)Último Receb.</button>
                                        <button type="button" onClick={btTodosR}     className="btn-warning" style={{fontSize: "12px", marginLeft: "10px", marginRight: "10px", paddingLeft: "2px", paddingRight: "2px", color: "darkblue", }} >(-)Todos Receb.</button>
                                        <button type="button" onClick={btLimpaNotas} className="btn-warning" style={{fontSize: "12px", paddingLeft: "2px", paddingRight: "2px", color: "red", }} >(-)Todas NFiscais</button>
                                    </div>
                                )}
                            </div>
                            <hr />
                
                            <h5 className="mt-5">&nbsp;</h5>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
        
                    <div className="row" style={{   borderColor: "black", borderStyle: "solid", borderRadius: 5, marginLeft: '5px', borderWidth: 1}}>
                        <div className="card-body">
                        <span className="form-control" style={{backgroundColor: barra.corProdu   , fontWeight: 700, color: barra.corLetraProdu}}>PRODUTOS A RECEBER</span>
                        </div>                 
        
                        <table  className="table table-striped" style={{fontSize: 10, backgroundColor: "#FFFFFF"}}>
                            <thead><tr>
                                <th align="center">registro</th>
                                <th>código</th>
                                <th>produto</th>
                                <th align="center">Qtd</th>
                                <th align="center">Recebido</th>
                            </tr></thead>
                            <tbody>
                                {produtos.map( (item) => <tr key={item.id}>
                                    <td align="center">{item.id}-{item.seq}</td>
                                    <td>{item.codigo}</td>
                                    <td>{item.nome}</td>
                                    <td align="center">{(item.qtd)}</td>
                                    <td align="center">{item.recebido}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Container>
        </form>
    </>

    );
};
  
export default ReceberMatgeriais;