import * as React from 'react';
import { ChangeEvent, useState } from 'react';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//axios for xml request
import axios from 'axios';

//xml file reader
import XMLParser from 'react-xml-parser';

const FileUploadMultiple = () => {
  const [fileList, setFileList] = useState<FileList | null>(null);
  
  // 👇 files is not an array, but it's iterable, spread to get an array of files
  const files = fileList ? [...fileList] : [];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
  };

  const Limpar = () => {
    setFileList(null)
  }

  const handleUploadClick = () => {
    if (!fileList) {
      return;
    }


    // 👇 Create new FormData object and append files
    files.forEach((file, i) => {
        let pasta   = "../../apicrud/vendas"
        let arquivo = pasta + file.name
        axios.get(arquivo).then(res => 
            {
            //Storing users detail in state array object
            const jsonDataFromXml = new XMLParser().parseFromString(res.data);
    //        console.log("XML: ",jsonDataFromXml)

            let nume = '---'
            let data = '---'
            let hora = '---'
            let caix = '---'
            let cnpj = '---'
            let loja = '---'
            let cpfe = '---'
            let clie = '---'
            let cMPe = '---'
            let vMPe = '---'
            let cAdm = '---'
            let troc = '---'
            
            try { nume = jsonDataFromXml.getElementsByTagName('nCFe')[0].value  }  catch {}
            try { data = jsonDataFromXml.getElementsByTagName('demi')[0].value  }  catch {}
            try { hora = jsonDataFromXml.getElementsByTagName('hemi')[0].value  }  catch {}
            try { caix = jsonDataFromXml.getElementsByTagName('numeroCaixa')[0].value  }  catch {}
            try { cnpj = jsonDataFromXml.getElementsByTagName('emit')[0].getElementsByTagName('CNPJ')[0].value  }  catch {}
            try { loja = jsonDataFromXml.getElementsByTagName('emit')[0].getElementsByTagName('xnome')[0].value  }  catch {}
            try { cpfe = jsonDataFromXml.getElementsByTagName('dest')[0].getElementsByTagName('CPF')[0].value  }  catch {}
            try { clie = jsonDataFromXml.getElementsByTagName('dest')[0].getElementsByTagName('xNome')[0].value  }  catch {}
    
            try { cMPe = jsonDataFromXml.getElementsByTagName('cMP')[0].value  }  catch {}
            try { vMPe = jsonDataFromXml.getElementsByTagName('vMP')[0].value  }  catch {}
            try { cAdm = jsonDataFromXml.getElementsByTagName('cAdmC')[0].value } catch {}
            try { troc = jsonDataFromXml.getElementsByTagName('vTroco')[0].value } catch {}
    
            let item = ''
            let prod = ''
            let cean = ''
            let cfop = ''
            let qCom = ''
            let uCom = '';
            let vUni = '';
            let vPro = ''
            let regr = ''
            let vTot = ''
            try {
                let idx = 0
                while (idx < 100) {
                    item = jsonDataFromXml.getElementsByTagName('cProd')[idx].value
                    prod = jsonDataFromXml.getElementsByTagName('xProd')[idx].value
                    cean = jsonDataFromXml.getElementsByTagName('cean')[idx].value
                    cfop = jsonDataFromXml.getElementsByTagName('CFOP')[idx].value
                    qCom = jsonDataFromXml.getElementsByTagName('qCom')[idx].value
                    uCom = jsonDataFromXml.getElementsByTagName('uCom')[idx].value
                    vUni = jsonDataFromXml.getElementsByTagName('vUnCom')[idx].value
                    vPro = jsonDataFromXml.getElementsByTagName('vProd')[idx].value
                    regr = jsonDataFromXml.getElementsByTagName('indRegra')[idx].value
                    vTot = jsonDataFromXml.getElementsByTagName('vItem')[idx].value
                    //console.log(item, cean, prod, cfop, qCom, uCom, vUni, vPro, regr, vTot, cpfe, clie)
                    idx = idx + parseInt('1')
                }
            }
            catch {
                item = 'acabou'
            }
    
            // console.log("Venda: ", nume, data, hora, caix, cnpj, loja, cpfe, item, cMPe, vMPe, cAdm, troc)
            console.log("Venda: ", nume, data, hora, caix, cMPe, vMPe, cAdm, troc)
    
          
    });

   
     return
  })
};



  return (
    <div>
      <input type="file" onChange={handleFileChange} multiple />

      <ul>
        {files.map((file, i) => (
          <li style={{color: 'black', }} key={i}>
            {file.name} - {file.type} 
          </li>
        ))}
      </ul>
      

      <button onClick={handleUploadClick}>Upload</button>
      <button onClick={Limpar}>Limpar</button>
    </div>
  );
}

export default FileUploadMultiple;