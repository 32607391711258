import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, ConteudoTitulo, Titulo } from '../styles';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const Dre = () => {

  let grupo1 = 'RECEITAS';
  let grupo2 = 'COMPRA DE PRODUTOS';
  let grupo3 = 'IMPOSTOS';
  let grupo4 = 'DESPESAS COM PESSOAL';
  let grupo5 = 'DESPESAS OPERACIONAIS';
  let grupo6 = 'DESPESAS ADMINISTRATIVAS';
  let grupo7 = 'ITENS DE GESTÃO';

  const barra = useContext(AuthContext);

  const navigate = useNavigate();
  if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
      barra.setSidebar(false);
      localStorage.clear("token")
      localStorage.setItem("encerrar", JSON.stringify("sim"));
      barra.avisar('Sessão expirou. Por favor, faça um novo login.')
      navigate("/");
  }

  const [showN, setshowN] = useState(false);
  
  const [state, setState] = useState({
        totrec: 0,
        totpro: 0,
        totimp: 0,
        totpes: 0,
        totope: 0,
        totadm: 0,
        totbon: 0,
        totdex: 0,
        totrex: 0,
        liqsem: 0,
        liqcom: 0,
  })
  const [primeira, setPrimeira] = useState(true)

  const handleClose = () => {
        setLoading(false)
        setshowN(false);
    }


  const anos = [
    {
      label: "Indique o Ano",
      value: "2020",
    },
    {
        label: "2021",
        value: "2021",
    },
    {
      label: "2022",
      value: "2022",
  },
  {
      label: "2023",
      value: "2023",
  },
  { 
      label: "2024",
      value: "2024",
  },
  {
      label: "2025",
      value: "2025",
  },
]

  const meses = [
    {
      label: "Selecione o mês",
      value: "13",
    },
    {
      label: "Janeiro",
      value: "1",
    },
    {
      label: "Fevereiro",
      value: "2",
    },
    {
      label: "Março",
      value: "3",
    },
    {
      label: "Abril",
      value: "4",
    },
    {
      label: "Maio",
      value: "5",
    },
    {
      label: "Junho",
      value: "6",
    },
    {
      label: "Julho",
      value: "7",
    },
    {
      label: "Agosto",
      value: "8",
    },
    {
      label: "Setembro",
      value: "9",
    },
    {
      label: "Outubro",
      value: "10",
    },
    {
      label: "Novembro",
      value: "11",
    },
    {
      label: "Dezembro",
      value: "12",
    },
  ];

    const [inputs, setInputs] = useState([])
    const [lojas, setLojas] = useState([])
    const [travar, setTravar] = useState('')
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(false)
        navigate("/dre");
    }, [loading, navigate, showN]); 

    function formatReal( int )
{
        var tmp = int+'';
        tmp = tmp.replace(".",",");
        if( tmp.length > 6 )
                tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        tmp = "R$ " + tmp
        return tmp;
}

    function verResumo() {

//        state.totrec = parseFloat(document.getElementById('dinheiro').value) + parseFloat(document.getElementById('cheque').value) + parseFloat(document.getElementById('ccredito').value) + parseFloat(document.getElementById('cdebito').value) + parseFloat(document.getElementById('calimenta').value) + parseFloat(document.getElementById('outro').value)

        if ((inputs.dinheiro  === undefined) || (inputs.dinheiro  === '')) {  inputs.dinheiro  = 0   }
        if ((inputs.cheque    === undefined) || (inputs.cheque    === '')){  inputs.cheque    = 0   }
        if ((inputs.ccredito  === undefined) || (inputs.ccredito  === '')) {  inputs.ccredito  = 0   }
        if ((inputs.cdebito   === undefined) || (inputs.cdebito   === '')) {  inputs.cdebito   = 0   }
        if ((inputs.calimenta === undefined) || (inputs.calimenta === '')) {  inputs.calimenta = 0   }
        if ((inputs.outro     === undefined) || (inputs.outro     === '')) {  inputs.outro     = 0   }

        if ( (inputs.simples   === undefined) || (inputs.simples   === '') ) {  inputs.simples     = 0   }
        if ( (inputs.icms      === undefined) || (inputs.icms      === '') ) {  inputs.icms        = 0   }
        if ( (inputs.ipi       === undefined) || (inputs.ipi       === '') ) {  inputs.ipi         = 0   }
        if ( (inputs.outrosi   === undefined) || (inputs.outrosi   === '') ) {  inputs.outrosi     = 0   }
        if ( (inputs.alvara    === undefined) || (inputs.alvara    === '') ) {  inputs.alvara      = 0   }
        if ( (inputs.substitui === undefined) || (inputs.substitui === '') ) {  inputs.substitui   = 0   }

        if ( (inputs.aluguel    === undefined) || (inputs.aluguel    === '') ) {  inputs.aluguel    = 0   }
        if ( (inputs.condominio === undefined) || (inputs.condominio === '') ) {  inputs.condominio = 0   }
        if ( (inputs.iptu       === undefined) || (inputs.iptu       === '') ) {  inputs.iptu       = 0   }
        if ( (inputs.fundop     === undefined) || (inputs.fundop     === '') ) {  inputs.fundop     = 0   }
        if ( (inputs.luz        === undefined) || (inputs.luz        === '') ) {  inputs.luz        = 0   }
        if ( (inputs.agua       === undefined) || (inputs.agua       === '') ) {  inputs.agua       = 0   }
        if ( (inputs.gas        === undefined) || (inputs.gas        === '') ) {  inputs.gas        = 0   }
        if ( (inputs.arcond     === undefined) || (inputs.arcond     === '') ) {  inputs.arcond     = 0   }
        if ( (inputs.telefone   === undefined) || (inputs.telefone   === '') ) {  inputs.telefone   = 0   }
        if ( (inputs.outrosa    === undefined) || (inputs.outrosa    === '') ) {  inputs.outrosa    = 0   }

        if ( (inputs.principal  === undefined) || (inputs.principal  === '') ) {  inputs.principal  = 0   }
        if ( (inputs.auxiliar   === undefined) || (inputs.auxiliar   === '') ) {  inputs.auxiliar   = 0   }
        if ( (inputs.apoio      === undefined) || (inputs.apoio      === '') ) {  inputs.apoio      = 0   }
        if ( (inputs.diversos   === undefined) || (inputs.diversos   === '') ) {  inputs.diversos   = 0   }

        if ( (inputs.contador     === undefined) || (inputs.contador     === '') ) {  inputs.contador    = 0   }
        if ( (inputs.limpeza      === undefined) || (inputs.limpeza      === '') ) {  inputs.limpeza     = 0   }
        if ( (inputs.dedetizacao  === undefined) || (inputs.dedetizacao  === '') ) {  inputs.dedetizacao = 0   }
        if ( (inputs.informatica  === undefined) || (inputs.informatica  === '') ) {  inputs.informatica = 0   }
        if ( (inputs.escritorio   === undefined) || (inputs.escritorio   === '') ) {  inputs.escritorio  = 0   }
        if ( (inputs.manutencao   === undefined) || (inputs.manutencao   === '') ) {  inputs.manutencao  = 0   }
        if ( (inputs.uniforme === undefined)|| (inputs.uniforme === '')){  inputs.uniforme = 0 }
        if ( (inputs.propaganda   === undefined) || (inputs.propaganda   === '') ) {  inputs.propaganda  = 0   }
        if ( (inputs.frete        === undefined) || (inputs.frete        === '') ) {  inputs.frete       = 0   }
        if ( (inputs.taxasa       === undefined) || (inputs.taxasa       === '') ) {  inputs.taxasa      = 0   }
        if ( (inputs.financeiras  === undefined) || (inputs.financeiras  === '') ) {  inputs.financeiras = 0   }

        if ( (inputs.bonificacao  === undefined) || (inputs.bonificacao  === '') ) {  inputs.bonificacao = 0   }
        if ( (inputs.extraeo      === undefined) || (inputs.extraeo      === '') ) {  inputs.extraeo      = 0   }
        if ( (inputs.entradaeo    === undefined) || (inputs.entradaeo    === '') ) {  inputs.entradaeo    = 0   }

        if ( (inputs.salario     === undefined) || (inputs.salario     === '') ) {  inputs.salario      = 0   }
        if ( (inputs.gratifica  === undefined) || (inputs.gratifica  === '') ) {  inputs.gratifica = 0   }
        if ( (inputs.fgts        === undefined) || (inputs.fgts        === '') ) {  inputs.fgts        = 0   }
        if ( (inputs.transporte  === undefined) || (inputs.transporte  === '') ) {  inputs.transporte  = 0   }
        if ( (inputs.alimentacao === undefined) || (inputs.alimentacao === '') ) {  inputs.alimentacao = 0   }
        if ( (inputs.amedica     === undefined) || (inputs.amedica     === '') ) {  inputs.amedica     = 0   }
        if ( (inputs.seguro      === undefined) || (inputs.seguro      === '') ) {  inputs.seguro      = 0   }
        if ( (inputs.inss        === undefined) || (inputs.inss        === '') ) {  inputs.inss        = 0   }
        if ( (inputs.ferias      === undefined) || (inputs.ferias      === '') ) {  inputs.ferias      = 0   }
        if ( (inputs.hextra      === undefined) || (inputs.hextra      === '') ) {  inputs.hextra      = 0   }
        if ( (inputs.recisao     === undefined) || (inputs.recisao     === '') ) {  inputs.recisao     = 0   }
        if ( (inputs.outrosp     === undefined) || (inputs.outrosp     === '') ) {  inputs.outrosp     = 0   }

        let receitas = parseFloat(inputs.dinheiro) + parseFloat(inputs.cheque) + parseFloat(inputs.ccredito) + parseFloat(inputs.cdebito) 
        receitas = (receitas + parseFloat(inputs.calimenta) + parseFloat(inputs.outro)).toFixed(2)
        state.totrec = formatReal(receitas)

        let produtos = (parseFloat(inputs.principal) + parseFloat(inputs.auxiliar) + parseFloat(inputs.apoio) + parseFloat(inputs.diversos)).toFixed(2)
        state.totpro = formatReal(produtos)

        let impostos = parseFloat(inputs.simples) + parseFloat(inputs.icms) + parseFloat(inputs.ipi)
        impostos = (impostos + parseFloat(inputs.outrosi) + parseFloat(inputs.alvara) + parseFloat(inputs.substitui)).toFixed(2)
        state.totimp = formatReal(impostos)

        let operacional = parseFloat(inputs.aluguel) + parseFloat(inputs.condominio) + parseFloat(inputs.iptu) + parseFloat(inputs.fundop) + parseFloat(inputs.luz) + parseFloat(inputs.agua) 
        operacional = (operacional + parseFloat(inputs.gas) + parseFloat(inputs.arcond) + parseFloat(inputs.telefone) + parseFloat(inputs.outrosa)).toFixed(2)
        state.totope = formatReal(operacional)

        let administ = parseFloat(inputs.contador) + parseFloat(inputs.limpeza) + parseFloat(inputs.dedetizacao) + parseFloat(inputs.informatica) + parseFloat(inputs.escritorio)
        administ = administ + parseFloat(inputs.manutencao) + parseFloat(inputs.uniforme) + parseFloat(inputs.propaganda) + parseFloat(inputs.frete) + parseFloat(inputs.taxasa)
        administ = (administ + parseFloat(inputs.financeiras)).toFixed(2)
        state.totadm = formatReal(administ)

        let pessoal = parseFloat(inputs.salario) + parseFloat(inputs.gratifica) + parseFloat(inputs.fgts) + parseFloat(inputs.transporte) + parseFloat(inputs.alimentacao)
        pessoal = pessoal + parseFloat(inputs.amedica) + parseFloat(inputs.seguro) + parseFloat(inputs.inss) + parseFloat(inputs.ferias) + parseFloat(inputs.hextra)
        pessoal = (pessoal + parseFloat(inputs.recisao) + parseFloat(inputs.outrosp)).toFixed(2)
        state.totpes = formatReal(pessoal)

        let bonifica = (parseFloat(0) + parseFloat(inputs.bonificacao)).toFixed(2)
        state.totbon = formatReal(bonifica)

        let saidae  = (parseFloat(0) + parseFloat(inputs.extraeo)).toFixed(2)
        state.totdex = formatReal(saidae)

        let entradae = (parseFloat(0) + parseFloat(inputs.entradaeo)).toFixed(2)
        state.totrex = formatReal(entradae)

        let totreceitas = parseFloat(receitas) + parseFloat(entradae)
        let totdespesas = parseFloat(produtos) + parseFloat(impostos) + parseFloat(pessoal) + parseFloat(operacional) + parseFloat(administ) + parseFloat(saidae)

        let liquidoSem = (parseFloat(totreceitas) - parseFloat(totdespesas)).toFixed(2)
        let liquidoCom = (parseFloat(liquidoSem) + parseFloat(bonifica)).toFixed(2)

        state.liqcom = formatReal(liquidoCom)
        state.liqsem = formatReal(liquidoSem)

        let medio = (parseFloat(receitas) / parseFloat(inputs.tc)).toFixed(2)
        state.tm  = formatReal(medio)

        console.log("RESULTADO: ", state.liqcom)
        setshowN(true)
      
    
        return
    }

    const buscarLojas = async () => {
      try {
  
          //let tk = localStorage.getItem("token")
  
          axios.get(`${barra.urlbase}/apicrud/buscarlojas.php?e=${barra.cempresa}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("LOJAS: ", res)
              console.log("SUCESSO: ", sucesso);
  
              if (sucesso) {
                      console.log(res.data.userlist.userdata);
                      setLojas(res.data.userlist.userdata);
                      setLoading(true);
  
              } else {
                      console.log("Lojas Não encontradas")
                      let motivo    = res.data.motivo;
                      setLoading(true);
                      console.log("NÃO SUCESSO: ", motivo);
                      setLoading(false);
              }
          })
      } catch (error) { throw error;} 
  
      return
  
  }  

  /*
  const buscargrupos = async () => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/buscargrupos.php?e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("LOJAS: ", res)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log("GRP: ", res.data.userlist.userdata);
                        setGrupo(res.data.userlist.userdata);
                        setLoading(true);
    
                } else {
                        console.log("Grupos Não encontrados")
                        let motivo    = res.data.motivo;
                        setLoading(true);
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(false);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  
  */

    const buscarDre = async () => {
        try {

            console.log("NOME: ", inputs.loja)
            console.log("MÊS: ", inputs.mes)
            console.log("ANO: ", inputs.ano)

            let empresa = barra.cempresa
            let usuario = barra.cusuario

            let erro = ''
            if ((inputs.loja === '') || (inputs.loja === undefined) )  {  erro = 's'; }
            if ((inputs.mes  === '') || (inputs.mes  === undefined) )  {  erro = 's'; }
            if ((inputs.ano  === '') || (inputs.ano  === undefined) )  {  erro = 's'; }

            if ((usuario === '') || (usuario === undefined)) {
                barra.avisar("Usuário não identificado. Por favor, faça o login ", 5)
                barra.setSidebar(false);
                localStorage.clear("token")
                localStorage.setItem("encerrar", JSON.stringify("sim"));
                navigate("/");
            } else {
                if (erro === 's')  {
                        barra.avisar("Campos LOJA, MÊS e ANO são obrigatórios. Grato", 4)
                } else {

                        let kpo = "l=" + inputs.loja + "&m=" + inputs.mes + "&a=" + inputs.ano + "&u=" + usuario + "&e=" + empresa
                        console.log(kpo)
                        axios.get(`${barra.urlbase}/apicrud/buscardre.php?${kpo}`, inputs).then(function(res) {
                                let sucesso    = res.data.success;
                                let motivo    = res.data.motivo;

                                console.log("DRE: ", res)
                                console.log("SUCESSO: ", sucesso);
                                console.log("MOTIVO: ", res.data.motivo);
    
                                if (sucesso) {
                                        setInputs(res.data.userlist.userdata)
                                        document.getElementById('botoes').style.display = "block";
                                        document.getElementById('corpo').style.display = "block";
                                        document.getElementById('calculo').style.display = "block";
                                        document.getElementById('buscar').value = "Buscar ou Recarregar";
                                        document.getElementById('buscar').style.display = "block";


                                        setInputs({
                                                loja:    res.data.userlist.userdata[0].loja,
                                                mes:    res.data.userlist.userdata[0].mes,
                                                ano:    res.data.userlist.userdata[0].ano,

                                                dinheiro:    res.data.userlist.userdata[0].dinheiro,
                                                ccredito:    res.data.userlist.userdata[0].ccredito,
                                                cdebito:    res.data.userlist.userdata[0].cdebito,
                                                calimenta:    res.data.userlist.userdata[0].calimenta,
                                                cheque:    res.data.userlist.userdata[0].cheque,
                                                outro:    res.data.userlist.userdata[0].outro,
                                                principal:    res.data.userlist.userdata[0].principal,
                                                auxiliar:    res.data.userlist.userdata[0].auxiliar,
                                                apoio:    res.data.userlist.userdata[0].apoio,
                                                diversos:    res.data.userlist.userdata[0].diversos,
                                                simples:    res.data.userlist.userdata[0].simples,
                                                icms:    res.data.userlist.userdata[0].icms,
                                                ipi:    res.data.userlist.userdata[0].ipi,
                                                outrosi:    res.data.userlist.userdata[0].outrosi,
                                                alvara:    res.data.userlist.userdata[0].alvara,
                                                substitui:    res.data.userlist.userdata[0].substitui,
                                                salario:    res.data.userlist.userdata[0].salario,
                                                gratifica:    res.data.userlist.userdata[0].gratifica,
                                                fgts:    res.data.userlist.userdata[0].fgts,
                                                transporte:    res.data.userlist.userdata[0].transporte,
                                                alimentacao:    res.data.userlist.userdata[0].alimentacao,
                                                amedica:    res.data.userlist.userdata[0].amedica,
                                                seguro:    res.data.userlist.userdata[0].seguro,
                                                inss:    res.data.userlist.userdata[0].inss,
                                                ferias:    res.data.userlist.userdata[0].ferias,
                                                hextra:    res.data.userlist.userdata[0].hextra,
                                                recisao:    res.data.userlist.userdata[0].recisao,
                                                outrosp:    res.data.userlist.userdata[0].outrosp,
                                                aluguel:    res.data.userlist.userdata[0].aluguel,
                                                condominio:    res.data.userlist.userdata[0].condominio,
                                                iptu:    res.data.userlist.userdata[0].iptu,
                                                fundop:    res.data.userlist.userdata[0].fundop,
                                                luz:    res.data.userlist.userdata[0].luz,
                                                agua:    res.data.userlist.userdata[0].agua,
                                                gas:    res.data.userlist.userdata[0].gas,
                                                arcond:    res.data.userlist.userdata[0].arcond,
                                                telefone:    res.data.userlist.userdata[0].telefone,
                                                outrosa:    res.data.userlist.userdata[0].outrosa,
                                                contador:    res.data.userlist.userdata[0].contador,
                                                limpeza:    res.data.userlist.userdata[0].limpeza,
                                                dedetizacao:    res.data.userlist.userdata[0].dedetizacao,
                                                informatica:    res.data.userlist.userdata[0].informatica,
                                                escritorio:    res.data.userlist.userdata[0].escritorio,
                                                manutencao:    res.data.userlist.userdata[0].manutencao,
                                                uniforme:    res.data.userlist.userdata[0].uniforme,
                                                propaganda:    res.data.userlist.userdata[0].propaganda,
                                                frete:    res.data.userlist.userdata[0].frete,
                                                taxasa:    res.data.userlist.userdata[0].taxasa,
                                                financeiras:    res.data.userlist.userdata[0].financeiras,
                                                bonificacao:    res.data.userlist.userdata[0].bonificacao,
                                                extraeo:    res.data.userlist.userdata[0].extraeo,
                                                entradaeo:    res.data.userlist.userdata[0].entradaeo,
                                                tc:    res.data.userlist.userdata[0].tc,
                                                observacao:    res.data.userlist.userdata[0].observacao
                                                })

                                        setTravar(res.data.userlist.userdata[0].trava)
                                        setLoading(true);
                                } else {
                                        barra.avisar("Ocorreu um problema na leitura do DRE. Por favor, tente novamente.", 5)
                                        console.log("NÃO SUCESSO: ", motivo);
                                        setLoading(true);
                                }
                        })
                }
            }

        } catch (error) { throw error;} 
    
        return

    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("campos",  inputs);

        let kpo = "1=" + inputs.loja
        kpo += "&2=" + inputs.mes
        kpo += "&3=" + inputs.ano
        kpo += "&4=" + inputs.dinheiro
        kpo += "&5=" + inputs.ccredito
        kpo += "&6=" + inputs.cdebito
        kpo += "&7=" + inputs.calimenta
        kpo += "&8=" + inputs.cheque
        kpo += "&9=" + inputs.outro
        kpo += "&10=" + inputs.principal
        kpo += "&11=" + inputs.auxiliar
        kpo += "&12=" + inputs.apoio
        kpo += "&13=" + inputs.diversos
        kpo += "&14=" + inputs.simples
        kpo += "&15=" + inputs.icms
        kpo += "&16=" + inputs.ipi
        kpo += "&17=" + inputs.outrosi
        kpo += "&18=" + inputs.alvara
        kpo += "&19=" + inputs.substitui
        kpo += "&20=" + inputs.salario
        kpo += "&21=" + inputs.gratifica
        kpo += "&22=" + inputs.fgts
        kpo += "&23=" + inputs.transporte
        kpo += "&24=" + inputs.alimentacao
        kpo += "&25=" + inputs.amedica
        kpo += "&26=" + inputs.seguro
        kpo += "&27=" + inputs.inss
        kpo += "&28=" + inputs.ferias
        kpo += "&29=" + inputs.hextra
        kpo += "&30=" + inputs.recisao
        kpo += "&31=" + inputs.outrosp
        kpo += "&32=" + inputs.aluguel
        kpo += "&33=" + inputs.condominio
        kpo += "&34=" + inputs.iptu
        kpo += "&35=" + inputs.fundop
        kpo += "&36=" + inputs.luz
        kpo += "&37=" + inputs.agua
        kpo += "&38=" + inputs.gas
        kpo += "&39=" + inputs.arcond
        kpo += "&40=" + inputs.telefone
        kpo += "&41=" + inputs.outrosa
        kpo += "&42=" + inputs.contador
        kpo += "&43=" + inputs.limpeza
        kpo += "&44=" + inputs.dedetizacao
        kpo += "&45=" + inputs.informatica
        kpo += "&46=" + inputs.escritorio
        kpo += "&47=" + inputs.manutencao
        kpo += "&48=" + inputs.uniforme
        kpo += "&49=" + inputs.propaganda
        kpo += "&50=" + inputs.frete
        kpo += "&51=" + inputs.taxasa
        kpo += "&52=" + inputs.financeiras
        kpo += "&53=" + inputs.bonificacao
        kpo += "&54=" + inputs.extraeo
        kpo += "&55=" + inputs.entradaeo
        kpo += "&56=" + inputs.tc
        kpo += "&57=" + barra.cempresa

        axios.get(`${barra.urlbase}/apicrud/gravardre.php?${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (UPD): ", sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  console.log("retorno: ", res.data.retorno)
                  barra.avisar(res.data.retorno, 1)
            } else {
                  console.log("Não encontrado (DRE)")

                  let motivo    = res.data.retorno;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })        

        //enviarMensagem(e.target.nome.value, e.target.email.value, e.target.telefone.value, e.target.mensagem.value)
      }

      const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }      


    const handleChangeRes = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setState(values => ({...values, [nome]: value}));
    }   

   
      const limparCampos = () => {
        setInputs([])
        document.getElementById('botoes').style.display = "none";
        document.getElementById('corpo').style.display = "none";
        document.getElementById('buscar').value = "Buscar";

        document.getElementById('buscar').style.display = "block";
        document.getElementById('calculo').style.display = "none";

        document.getElementById('loja').value = '';
        document.getElementById('mes').value = '13';
        document.getElementById('ano').value = '2020';

        setLoading(true)

    
      }      

      if (primeira) {
        buscarLojas()
        //buscargrupos()
        setPrimeira(false)
      }
     // console.log("TRAVAR: ", travar)

  return (

    <>

      <form className="Container" onSubmit={handleSubmit}>

        <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px"}}>DEMONSTRATIVO DE RESULTADO DO EXERCÍCIO (D.R.E)</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                            <Button id="calculo" onClick={verResumo} style={{ display: "none", backgroundColor: barra.corBotao }}>RESUMO E CÁLCULOS</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>
        </Container>

        <center>
          <div className="form-group">
              <div className="form-group col-md-9">

                      <div className="form-group col-md-11" style={{marginTop: 5, borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%" style={{backgroundColor: barra.corPanel, color: barra.corLetpn, }}><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>IDENTIFICAÇÃO</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div>&nbsp;</div>
                                    <div className="col-md-5" >
                                        <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Loja: </span>
                                        <select className="col" name="loja" id="loja" value={inputs.loja}  onChange={handleChange} 
                                        style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }}>
                                            <option value="">Selecione a loja </option>
                                                {lojas.map((option, index) => {
                                                return <option key={index} >
                                                {option}
                                            </option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                            <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Mês: </span>
                                          <select name="mes"  id="mes" value={inputs.mes}  onChange={handleChange} 
                                          style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }} >
                                              {meses.map((option) => (
                                                  <option value={option.value} style={{color:`${option.value}`}}>{option.label}</option>
                                              ))}
                                          </select>
                                    </div>
                                    <div className="col-md-2">
                                          <span style={{fontSize:"20px", color: "#000", fontWeight:"500", textAlign:"top"}}>Ano: </span>
                                          <select name="ano"  id="ano" value={inputs.ano}  onChange={handleChange}
                                          style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px",  }} >
                                              {anos.map((option) => (
                                                  <option value={option.value} style={{color:`${option.value}`}}>{option.label}</option>
                                              ))}
                                          </select>
                                    </div>                                   
                                    <div className="col-md-2" >
                                        <Button id="buscar" style={{ display: "block", paddingLeft:"7px", paddingRight:"7px", backgroundColor: barra.corBotao, borderRadius:"5px",}} onClick={buscarDre}>BUSCAR D.R.E.</Button>
                                    </div> 
                                    <div>&nbsp;</div>

                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                <div id="corpo" style={{ display: "none" }} >

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                                <table width="100%"><tbody><tr><td align="left">
                                <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline" }}>{grupo1}</span>
                                </td></tr></tbody></table>
                            
                                <div className="form-group">
                                    <div className="row" >

                                        <div className="col-md-4"  align="right">
                                                <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Dinheiro: </span>
                                                <input type="number" id="dinheiro" name="dinheiro" placeholder="Dinheiro" value={inputs.dinheiro} onChange={handleChange} 
                                                style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right" }} />
                                        </div>
                                        <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>C.Crédito: </span>
                                            <input type="number" id="ccredito" name="ccredito" placeholder="Cartão Crédito" value={inputs.ccredito} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                        </div>
                                        <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>C.Débito: </span>
                                            <input type="number" id="cdebito" name="cdebito" placeholder="Cartão de Débito" value={inputs.cdebito} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                        </div>
                                        </div>
                                  <div>&nbsp;</div>
                                  <div className="row" >
                                        <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>VR / VA: </span>
                                            <input type="number" id="calimenta" name="calimenta" placeholder="Vale refeição ou alimentação" value={inputs.calimenta} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                        </div>
                                        <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Cheque: </span>
                                            <input type="number" id="cheque" name="cheque" placeholder="Cheques" value={inputs.cheque} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                        </div>
                                        <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Outras Rec.: </span>
                                            <input type="number" id="outro" name="outro" placeholder="Outros meios" value={inputs.outro} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                        </div> 
                                     </div> 
                            

                                  <div>&nbsp;</div>

                                </div>
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo2}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Principais produtos: </span>
                                            <input type="number" id="principal" name="principal" placeholder="Principais Fornecedores" value={inputs.principal} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" ></div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Auxiliar: </span>
                                            <input type="number" id="auxiliar" name="auxiliar" placeholder="Produtos auxiliares" value={inputs.auxiliar} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                    
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Produtos de Apoio: </span>
                                            <input type="number" id="apoio" name="apoio" placeholder="Complementares" value={inputs.apoio} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" ></div>                                    
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Diversos: </span>
                                            <input type="number" id="diversos" name="diversos" placeholder="Outros - Diversos" value={inputs.diversos} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>
                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo3}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Simples: </span>
                                            <input type="number" id="simples" name="simples" placeholder="Simples apurado" value={inputs.simples} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>ICMS: </span>
                                            <input type="number" id="icms" name="icms" placeholder="icms dos produtos" value={inputs.icms} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>IPI: </span>
                                            <input type="number" id="ipi" name="ipi" placeholder="ipi dos produtos" value={inputs.ipi} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Outros: </span>
                                            <input type="number" id="outrosi" name="outrosi" placeholder="Outro impostos" value={inputs.outrosi} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Alvará: </span>
                                            <input type="number" id="alvara" name="alvara" placeholder="Alvarás e patentes" value={inputs.alvara} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Substituição: </span>
                                            <input type="number" id="substitui" name="substitui" placeholder="Substituição Trib." value={inputs.substitui} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>                                    
                                    <div>&nbsp;</div>

                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo4}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Salários: </span>
                                            <input type="number" id="salario" name="salario" placeholder="Conta salário" value={inputs.salario} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Gratificações: </span>
                                            <input type="number" id="gratifica" name="gratifica" placeholder="Gratificações pagas" value={inputs.gratifica} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>F G T S : </span>
                                            <input type="number" id="fgts" name="fgts" placeholder="Valor apurado" value={inputs.fgts} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>V.Transp.: </span>
                                            <input type="number" id="transporte" name="transporte" placeholder="Valor dos VT" value={inputs.transporte} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Alimentação: </span>
                                            <input type="number" id="alimentacao" name="alimentacao" placeholder="VR (+) VA" value={inputs.alimentacao} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Assit.Médica: </span>
                                            <input type="number" id="amedica" name="amedica" placeholder="Valor mensal" value={inputs.amedica} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Seguro Vida: </span>
                                            <input type="number" id="seguro" name="seguro" placeholder="Valor mensal" value={inputs.seguro} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>I N S S : </span>
                                            <input type="number" id="inss" name="inss" placeholder="Valor da guia" value={inputs.inss} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Férias: </span>
                                            <input type="number" id="ferias" name="ferias" placeholder="Férias + 1/3" value={inputs.ferias} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Horas Extras: </span>
                                            <input type="number" id="hextra" name="hextra" placeholder="valor horas extras" value={inputs.hextra} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Recisões Trab.: </span>
                                            <input type="number" id="recisao" name="recisao" placeholder="pagto.desligamentos" value={inputs.recisao} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Outros: </span>
                                            <input type="number" id="outrosp" name="outrosp" placeholder="Outras desp. pessoal" value={inputs.outrosp} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>                                                                       
                                    <div>&nbsp;</div>

                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo5}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Aluguel: </span>
                                            <input type="number" id="aluguel" name="aluguel" placeholder="Somente aluguel" value={inputs.aluguel} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" align="right" >
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Condomínio: </span>
                                            <input type="number" id="condominio" name="condominio" placeholder="Condomínio sem melhorias" value={inputs.condominio} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>IPTU: </span>
                                            <input type="number" id="iptu" name="iptu" placeholder="Valor mensal" value={inputs.iptu} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                                                    
                                    <div className="col-md-4" align="right" >
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Promoção: </span>
                                            <input type="number" id="fundop" name="fundop" placeholder="Valor mensal" value={inputs.fundop} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Luz: </span>
                                            <input type="number" id="luz" name="luz" placeholder="Energia elétrica" value={inputs.luz} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Água: </span>
                                            <input type="number" id="agua" name="agua" placeholder="Valor mensal" value={inputs.agua} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>                                    
                                    <div>&nbsp;</div>                                                                    

                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Gás: </span>
                                            <input type="number" id="gas" name="gas" placeholder="Gás, se houver" value={inputs.gas} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Ar condicionado: </span>
                                            <input type="number" id="arcond" name="arcond" placeholder="Manutenção Ar condicionado" value={inputs.arcond} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Telefone: </span>
                                            <input type="number" id="telefone" name="telefone" placeholder="Valor total telefonia" value={inputs.telefone} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                                                    
                                    <div className="col-md-4" ></div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Outras desp.: </span>
                                            <input type="number" id="outrosa" name="outrosa" placeholder="Outras desp. administrativas" value={inputs.outrosa} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" ></div>

                                    <div>&nbsp;</div>                                                                    
                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo6}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Contador: </span>
                                            <input type="number" id="contador" name="contador" placeholder="Honorários contábeis" value={inputs.contador} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Mat.limpeza/higiene: </span>
                                            <input type="number" id="limpeza" name="limpeza" placeholder="Valor das compras" value={inputs.limpeza} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Dedetização: </span>
                                            <input type="number" id="dedetizacao" name="dedetizacao" placeholder="Valor mensal" value={inputs.dedetizacao} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                                                    
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Informática: </span>
                                            <input type="number" id="informatica" name="informatica" placeholder="Programas e equipam" value={inputs.informatica} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Material escritório: </span>
                                            <input type="number" id="escritorio" name="escritorio" placeholder="Valor mensal" value={inputs.escritorio} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Manutenção: </span>
                                            <input type="number" id="manutencao" name="manutencao" placeholder="Manutenção civil" value={inputs.manutencao} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                                                    
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Uniformes: </span>
                                            <input type="number" id="uniforme" name="uniforme" placeholder="Compra ou confecção" value={inputs.uniforme} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Propaganda/Public: </span>
                                            <input type="number" id="propaganda" name="propaganda" placeholder="Investimento em divulgação" value={inputs.propaganda} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Fretes: </span>
                                            <input type="number" id="frete" name="frete" placeholder="Despesas com entregas" value={inputs.frete} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>     
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Taxas admin.: </span>
                                            <input type="number" id="taxasa" name="taxasa" placeholder="Taxas e licenças" value={inputs.taxasa} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"  align="right"></div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Desp.financ.: </span>
                                            <input type="number" id="financeiras" name="financeiras" placeholder="Tx.bancária, cartões etc" value={inputs.financeiras} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>

                                    <div>&nbsp;</div>                                                                    
                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>

                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                      <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo7}</span>
                            </td></tr></tbody></table>
                            <div className="row">
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", }}>Despesas Extra Operacionais: </span>
                                            <input type="number" id="extraeo" name="extraeo" placeholder="Valor pagos sem conta definida" value={inputs.extraeo} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Entradas Extra Operacionais: </span>
                                            <input type="number" id="entradaeo" name="entradaeo" placeholder="Recebimentos sem conta definida" value={inputs.entradaeo} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div>&nbsp;</div>                                                                    
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Bonificação: </span>
                                            <input type="number" id="bonificacao" name="bonificacao" placeholder="Bonificações recebidas" value={inputs.bonificacao} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"  align="right">
                                            <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Número de TC: </span>
                                            <input type="number" id="tc" name="tc" placeholder="Qtidade de tickets emitidos" value={inputs.tc} onChange={handleChange} 
                                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "right"  }} />
                                    </div>
                                    <div className="col-md-4" >&nbsp;</div>
                                    <div>&nbsp;</div>                                                                    
                            </div>                                   
                      </div>
                      <div className="form-group col-md-11" >&nbsp;</div>


                      
                      {/*
                      <div className="form-group col-md-11" style={{borderWidth:"1px", borderRadius:"5px", borderStyle:"solid", borderColor:"black", backgroundColor:"lightgrey"}}>
                            <table width="100%"><tbody><tr><td align="left">
                            <span style={{ fontWeight:"700", textAlign:"right", textDecoration:"underline"}}>{grupo8}</span>
                            </td></tr></tbody></table>
                            <textarea className="form-control"  id="observacao" name="observacao" rows="4" placeholder="digite as observações dessa D.R.E." onChange={handleChange}                                         onChange={handleChange} 
                                style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", textAlign: "left" }} >
                                {inputs.mensagem}
                                </textarea>
                      </div>  
                      */}
                
                      <div className="form-group col-md-11" >&nbsp;</div>
                </div>

                      <div id="botoes" className="form-group col-md-11" style={{ display: "none" }}>
                                        <div className="col-md-3"></div>
                                        {
                                        
                                        travar === '1' ? (
                                        <button type="submit" name="enviar" className="btn btn-primary" style={{ marginLeft: "8%",paddingLeft:"7px", paddingRight:"7px", backgroundColor: barra.corBotao, borderRadius:"5px",}}> SALVAR DRE </button>
                                        ) : null}
                                        <Button onClick={limparCampos} className="btn-warning" style={{paddingLeft:"7px", paddingRight:"7px", borderRadius:"5px",}}>LIMPAR</Button>
                      </div>
                </div>
          </div>

        </center>
      </form>   

      <hr />

        <Modal show={showN} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>RESUMO e CÁLCULOS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Receitas: </span>
        <input className="row" type="text" name="totrec"  id="totrec" value={state.totrec}  onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Ticket médio: </span>
        <input className="row" type="text" name="tm"  id="tm" value={state.tm}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>        
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Compra de Produtos: </span>
        <input className="row" type="text" name="totpro"  id="totpro" value={state.totpro}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Despesas com Impostos: </span>
        <input className="row" type="text" name="totimp"  id="totimp" value={state.totimp}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Despesa com Pessoal: </span>
        <input className="row" type="text" name="totpes"  id="totpes" value={state.totpes}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Despesas Operacionais: </span>
        <input className="row" type="text" name="totope"  id="totope" value={state.totope}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Despesas Administrativas: </span>
        <input className="row" type="text" name="totadm"  id="totadm" value={state.totadm}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Bonificações: </span>
        <input className="row" type="text" name="totbon"  id="totbon" value={state.totbon}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Despesas Extras: </span>
        <input className="row" type="text" name="totdex"  id="totdex" value={state.totdex}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Receitas Extras: </span>
        <input className="row" type="text" name="totrex"  id="totrex" value={state.totrex}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Líquido SEM bonificação: </span>
        <input className="row" type="text" name="liqsem"  id="liqsem" value={state.liqsem}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>        
        <div className='form-control'>
        <span style={{fontSize:"12px", fontWeight:"500", textAlign:"top"}}>Líquido COM bonificação: </span>
        <input className="row" type="text" name="liqcom"  id="liqcom" value={state.liqcom}   onChange={handleChangeRes} style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
        </div>   

        <div className="form-control">
                <table width="100%">
                <tbody>
                        <tr>
                        <td width="1%" align='left' >&nbsp;</td>
                        <td width="99%" align='center'><button type="button" className="btn-primary" onClick={handleClose} 
                        style={{ paddingLeft:"5px", paddingRight:"5px" }}>Fechar resumo</button></td>
                        </tr>
                </tbody>
                </table>
                
                
        </div>

        </Modal.Body>
        <Modal.Footer>
        <hr></hr>
        </Modal.Footer>
        </Modal> 


    </>
  )
}

export default Dre


