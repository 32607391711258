import React from 'react'
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, Table, Titulo } from '../styles';


//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaEdit, FaTrash, FaSort, FaSortUp, FaSortDown, FaPlus, FaFilter, FaEraser } from 'react-icons/fa';

const CadcampanV3 = () => {

    const barra = useContext(AuthContext);
    

    const navigate = useNavigate()

    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }    

    
    const titulo_pagina = "CADASTRO DE CAMPANHAS"
    const nome_tabela = 'ccs_campanha'
    const modulo = 'Campanha'
    const [titulo_modal, setTituloModal] = useState("Editar " + modulo)
    const [titulo_botao, setTituloBotao] = useState("Salvar Modificações")
    const nome_colunas = [ 
        {id: '1', ident: 'Índice',       campo:  'id',              tamanho: '10%',    },  
        {id: '2', ident: 'Nome',         campo:  'nome',            tamanho: '15%',    },  
        {id: '3', ident: 'Descrição',    campo:  'descricao',       tamanho: '35%',    }, 
        {id: '4', ident: 'Data Inicial', campo:  'inicio',          tamanho: '10%',    }, 
        {id: '5', ident: 'Data Final',   campo:  'fim',             tamanho: '10%',    },  
    ]
    const numero_colunas = 6
    const places = ['índice', 'informe o nome', 'descrição da campanha', 'data de início', 'data final']

    const [chave, setChave] = useState('nome')
    const [sentido, setSentido] = useState('ASC')
    const [filtro, setFiltro] = useState('')

    const [opcao, setOpcao] = useState('X')

    const [loading, setLoading] = useState(false);
    //const [isDisabled, setIsDisabled] = useState(true);
    //const [registro, setRegistro] = useState([])
    //const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [state, setState] = useState({
        id: "",
        nome: "",
        descricao: "",
        inicio: "",
        fim: "",
      })


    const [showN, setshowN] = useState(false);

    const handleClose = () => {
        setshowN(false);
        setOpcao('X')
    }


    useEffect(() => {
        setLoading(false)
        if (opcao === "X") { 
            //buscarEmpresas()
            leRegistros(nome_tabela) 
            setOpcao('')
        }
    }, [loading, opcao]);  


    function excluirRegistro(ident) {
        //document.getElementById(1).value = ident
        setTituloModal("Excluir " + modulo)
        setTituloBotao("Confirmar Exclusão")
        console.log("REGISTRO (D): ", ident)
        setOpcao('D')
        leUmRegistro(nome_tabela, ident)
        setshowN(true)
        return
    }

    function editarRegistro(ident) {
        //document.getElementById(1).value = ident
        setTituloModal("Editar " + modulo)
        setTituloBotao("Salvar modificações")
        console.log("REGISTRO: ", ident)
        setOpcao('E')
        leUmRegistro(nome_tabela,ident)
        setshowN(true)
        return
    }

    function incluirRegistro() {
        console.log("Incluir " + modulo)
        setTituloModal("Incluir " + modulo)
        setTituloBotao("Confirmar Inclusão")
        setOpcao("I")

        setState([])
    
        setshowN(true)
        return
    }


    const leRegistros = async (nome_tabela) => {

        console.log("comando: ", `${barra.urlbase}/apicrud/ler_arquivo.php?f=${filtro}&r=0&t=${nome_tabela}&e=${barra.cempresa}`)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?f=${filtro}&r=0&t=${nome_tabela}&e=${barra.cempresa}&c=${chave}&s=${sentido}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO: ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  console.log(res.data.datalist.userdata);
                  setData(res.data.datalist.userdata);

            } else {
                  console.log("Não encontrado (DU)")
                  setData([])
                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const leUmRegistro = async (nome_tabela, reg) => {

        console.log("tabela: ", nome_tabela, " registro: ", reg)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?f=&r=${reg}&t=${nome_tabela}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (um): ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.datalist.userdata);
                  console.log("DATA (1)", res.data.datalist.userdata);
                  setState(res.data.datalist.userdata[0])
/*
                  setState({
                    id:      res.data.datalist.userdata[0].id,
                    nome:    res.data.datalist.userdata[0].nome, 
                    codigo:  res.data.datalist.userdata[0].codigo, 
                    agencia: res.data.datalist.userdata[0].agencia,
                    conta:   res.data.datalist.userdata[0].conta,
                    })
*/
            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const gravaUmRegistro = async (kpo) => {

        console.log("tabela: ", kpo)
        axios.get(`${barra.urlbase}/apicrud/gravar_arquivo.php?t=${nome_tabela}&${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (UPD): ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.retorno);
                  console.log("retorno: ", res.data.retorno)
            } else {
                  console.log("Não encontrado (UPD)")

                  let motivo    = res.data.retorno;
                  console.log("NÃO SUCESSO: ", motivo);
            }
            setOpcao('X')
        })
         

    }     

    function handleChange(evt) {
        const value = evt.target.value;
        setState({...state, [evt.target.id]: value  });
    }


    function handleSubmit(evt) {
        evt.preventDefault();
        let empresa = barra.cempresa
        let wco1  = state.nome.replaceAll("&", "e")
        let wco2  = state.descricao.replaceAll("&", "e")
        let wco3  = state.inicio
        let wco4  = state.fim

        if (wco1 === undefined || wco2 === undefined || wco3 === undefined || wco4 === undefined ) {
            console.log(wco1, wco2, wco3, wco4)
            barra.avisar("<p>&nbsp;</p>Todos os campos precisam ser definidos")
            return
        } else {
            if (wco1 === '' || wco2 === '' || wco3 === '' || wco4 === '' ) {
                barra.avisar("<p>&nbsp;</p>Todos os campos precisam ser preenchidos")
                return
            }  else  {
                if (state.inicio > state.fim) {
                    barra.avisar("<p>&nbsp;</p>Data final deve ser maior que data inicial")
                    return
                }
            }
        }

        console.log("SUBMIT", state)
        let kpo = '';
        kpo = kpo + ";empresa=" + empresa
        kpo = kpo + ';nome=' + wco1
        kpo = kpo + ';descricao=' + wco2 
        kpo = kpo + ";inicio=" + wco3
        kpo = kpo + ";fim=" + wco4

        if (opcao === 'D') {
            kpo = 'o=D&par=' + state.id + kpo
        } else {
            if (opcao === 'I') {
                kpo = 'o=I&par=0' + kpo
            } else {
                kpo = 'o=U&par=' + state.id + kpo
            }
        }
        console.log("KPO: ", kpo)
        gravaUmRegistro(kpo);
        setshowN(false);
        //setOpcao('')
    }

    function Buscar() {
        let t = numero_colunas
        let i = 1
        let str = ''
        while (i < t) {

            if (nome_colunas[i-1].campo === 'id') {
                if (document.getElementById(i).value !== '') {
                    if (str !== '') {  str = str + " AND " }
                    if (isNaN(document.getElementById(i).value)) {
                        str = str + "id >= 0"
                    } else {
                        str = str + "id >= " + document.getElementById(i).value
                    }
                }
            } else {
                if (document.getElementById(i).value !== '') {
                    if (str !== '') {  str = str + " AND " }
                    let chave = document.getElementById(i).value
                    if (nome_colunas[i-1].campo === 'inicio') { chave = dataDB(document.getElementById(i).value)  }
                    if (nome_colunas[i-1].campo === 'fim')    { chave = dataDB(document.getElementById(i).value)  }
                        str = str + nome_colunas[i-1].campo + " like (" + chave + ") "
                }
            }
            i = parseInt(i) + parseInt('1')
        }
        setFiltro(str)
        console.log(str)
        setOpcao('X')
        return
    }

    function Limpar() {
        let t = numero_colunas
        let i = 1
        while (i < t) {
            document.getElementById(i).value = ''
            i = parseInt(i) + parseInt('1')
        }
        setFiltro('')
        setOpcao('X')
        return
    }

    function Classificar(indx) {
        console.log("Índice: ", indx)
        setChave(indx)
        if (sentido === 'ASC') { setSentido('DESC') } else { setSentido('ASC') }
        setOpcao('X')
    }

    function dataBR(dt) {
        let retorno = dt.substring(8,10) + '-' + dt.substring(5,7) + '-' + dt.substring(0,4)
        return retorno
    }

    function dataDB(dt) {
        let retorno = dt.substring(6,10) + '-' + dt.substring(3,5) + '-' + dt.substring(0,2)
        return retorno
    }


  return (
    <>

    <form className="cadastros">
        <Container>
            <div  style={{ width: "100%" }}>
                <table width="100%" border="1" style={{ backgroundColor: barra.corBarra, }}>
                    <tr>
                        <td>&nbsp;</td>
                        <td width="47%" align="left"><Titulo><span style={{color: barra.corLetraBarra, }}>{titulo_pagina}</span></Titulo></td>
                        <td width="50%" align="right">                
                            <button type="button"  onClick={() => incluirRegistro()} className="botao-normal"  style={{}}>
                                <FaPlus /> incluir
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <div style={{width: "100%",}}>
            <Table>
                <thead>
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th width={cabec.tamanho} style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>
                                <input type="text" name={cabec.id} id={cabec.id} style={{width: "80%"}} /> 
                            </th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>
                            <button type="button"  className="botao-normal" 
                                style={{}} 
                                onClick={() => Buscar()}>
                                    <FaFilter /> filtrar
                            </button>
                            <button type="button"  className="botao-limpar" 
                                style={{marginLeft:"1Vw"}} 
                                onClick={() => Limpar()}>
                                    <FaEraser /> limpar
                            </button>
                        </th>
                    </tr>                    
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>
                                <button type="button"  style={{backgroundColor: "transparent", border: "0px", color: barra.corLetraFundo, textDecoration: cabec.campo === chave ? "underline" : "none" }    } onClick={() => Classificar(cabec.campo)}>
                                    {cabec.ident}{cabec.campo !== chave ? (<FaSort />) : (sentido === 'ASC' ? (<FaSortUp />) : (<FaSortDown />))}
                                </button>
                            </th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>Ações</th>
                    </tr>
                    <tr><td colSpan={numero_colunas}>&nbsp;</td></tr>

                </thead>
            </Table>
            </div>
            </Container>


            <Container>
            <div style={{width: "100%", height: "60Vh", overflow: "scrool"}}>
            <Table>
                <tbody>
                    {Object.values(data).map(registro => (
                        <tr key={registro.id}>
                            <td width="10%" align="center">{registro.id}</td>
                            <td width="15%" align="left">{registro.nome}</td>
                            <td width="35%" align="left">{registro.descricao}</td>
                            <td width="10%" align="left">{dataBR(registro.inicio)}</td>
                            <td width="10%" align="left">{dataBR(registro.fim)}</td>
                            <td>

                            <button type="button" 
                                className="botao-normal" style={{}} 
                                onClick={() => editarRegistro(registro.id)}>
                                    <FaEdit /> editar
                                </button>
                                
                                <button type="button"  
                                className="botao-delete" style={{marginLeft:"1Vw"}} 
                                onClick={() => excluirRegistro(registro.id)}>
                                    <FaTrash /> excluir
                                </button>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
            </div>
        
        </Container>


        <hr />

        <Modal show={showN} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title style={{color: barra.corLetraFundo}}>{titulo_modal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='form-control'>
            <input className="row" type="hidden" name="id"  id="id"   value={state.id}  onChange={handleChange}  placeholder={places[0]}     
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            
           
            <div className='form-control'>
            <input className="row" type="text"   name="nome"  id="nome"   value={state.nome}  onChange={handleChange}  placeholder={places[1]}     
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            
            <div className='form-control'>
            <input className="row" type="text"   name="descricao"  id="descricao"   value={state.descricao}  onChange={handleChange}  placeholder={places[2]}     
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            
            <div className='form-control'>
            <input className="row" type="date"   name="inicio"  id="inicio"   value={state.inicio}  onChange={handleChange}  placeholder={places[3]} 
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}} />
            </div>

            <div className='form-control' style={{textAlign: "center"}}>
            <input className="row" type="date"   name="fim"  id="fim"   value={state.fim}  onChange={handleChange}  placeholder={places[4]}  
            style={{borderRadius: "5px", width: "100%", textAlign: "center"}} />
            </div>

            <div className="form-control">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align='left' ><input type="submit" className="botao-normal"  style={{width: "10em" }} value={titulo_botao} /></td>
                            <td width="50%" align='right'><button type="button" className="botao-delete" style={{width: "10em" }} onClick={handleClose}>Cancelar</button></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>

        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </form>
      </Modal>       


    </form>
    </>

  )
  
}

export default CadcampanV3