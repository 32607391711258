import axios from 'axios'
import Pontogrf from './Dados_grafico';

 
export async function getPontos(axisx = '2022-01-01', axisy = '2022-12-31', empresa = '1', graf = '1', tipo = 'pie', loja = '') {

    const urlbase = "https://beagestor.com.br"

    //console.log("parâmetros: ", axisx, axisy, empresa, graf, tipo, loja)
    var html = `${urlbase}/apicrud/ler_grafico_generico.php?rg=0&ep=${empresa}&gf=${graf.grf}&lj=${loja}&di=${axisx}&df=${axisy}`
    if (tipo === 'excecao') {
        html = `${urlbase}/apicrud/ler_grafico_genericox.php?rg=0&ep=${empresa}&gf=${graf.grf}&lj=${loja}&di=${axisx}&df=${axisy}`
    }
    const response = await axios.get(html);
    const pontos = response.data.userlist.userdata.map(k => {
        return new Pontogrf(k.horiz, k.verti, k.color)
    }) 
return pontos;
}