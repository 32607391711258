import React from 'react';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//axios for xml request
import axios from 'axios';

//xml file reader
import XMLParser from 'react-xml-parser';

//import listReactFiles from 'list-react-files'

class AppXML extends React.Component {
  constructor(props) {
    super(props)
      this.state = {
     
        name: [],
      
              }
             
      }
    componentDidMount() {
     //get data request
      axios.get('AD35221242397258000113590011157100171315527837.xml', { 
        "Content-Type": "application/xml; charset=utf-8"
       }).then(res => 
        {
        //Storing users detail in state array object
        const jsonDataFromXml = new XMLParser().parseFromString(res.data);
//        console.log("XML: ",jsonDataFromXml)
        
        let nume = jsonDataFromXml.getElementsByTagName('nCFe')[0].value
        let data = jsonDataFromXml.getElementsByTagName('demi')[0].value
        let hora = jsonDataFromXml.getElementsByTagName('hemi')[0].value
        let caix = jsonDataFromXml.getElementsByTagName('numeroCaixa')[0].value
        let cnpj = jsonDataFromXml.getElementsByTagName('emit')[0].getElementsByTagName('CNPJ')[0].value
        let loja = jsonDataFromXml.getElementsByTagName('emit')[0].getElementsByTagName('xnome')[0].value
        let cpfe = jsonDataFromXml.getElementsByTagName('dest')[0].getElementsByTagName('CPF')[0].value

        let cMPe = jsonDataFromXml.getElementsByTagName('cMP')[0].value
        let vMPe = jsonDataFromXml.getElementsByTagName('vMP')[0].value
        let cAdm = jsonDataFromXml.getElementsByTagName('cAdmC')[0].value
        let troc = jsonDataFromXml.getElementsByTagName('vTroco')[0].value

        let item = ''
        let prod = ''
        let cean = ''
        let cfop = ''
        let qCom = ''
        let uCom = '';
        let vUni = '';
        let vPro = ''
        let regr = ''
        let vTot = ''
        try {
            let idx = 0
            while (idx < 100) {
                item = jsonDataFromXml.getElementsByTagName('cProd')[idx].value
                prod = jsonDataFromXml.getElementsByTagName('xProd')[idx].value
                cean = jsonDataFromXml.getElementsByTagName('cean')[idx].value
                cfop = jsonDataFromXml.getElementsByTagName('CFOP')[idx].value
                qCom = jsonDataFromXml.getElementsByTagName('qCom')[idx].value
                uCom = jsonDataFromXml.getElementsByTagName('uCom')[idx].value
                vUni = jsonDataFromXml.getElementsByTagName('vUnCom')[idx].value
                vPro = jsonDataFromXml.getElementsByTagName('vProd')[idx].value
                regr = jsonDataFromXml.getElementsByTagName('indRegra')[idx].value
                vTot = jsonDataFromXml.getElementsByTagName('vItem')[idx].value
                console.log(item, cean, prod, cfop, qCom, uCom, vUni, vPro, regr, vTot)
                idx = parseInt(idx) + parseInt('1')
            }
        }
        catch {
            item = 'acabou'
        }

        console.log("Venda: ", nume, data, hora, caix, cnpj, loja, cpfe, item, cMPe, vMPe, cAdm, troc)

         
        //listReactFiles('/').then(files => console.log(files))
       
        }); 
    }
 
  render() {
  
    return (
    
      <div className="container p-5">
       
        <ul class="list-group">
      
        {(
      this.state.name.map((item, index) => {
        return (
          <li class="list-group-item">{item.name} - {item.value}</li>
        )
        }
      ))}
    
    </ul>
    </div>
     
)
};
}

export default AppXML;
