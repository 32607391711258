import React from 'react'
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useContext  } from 'react';
import { AuthContext } from "../../../src/contexts/Auth"
import { Container, ConteudoTitulo, Table, Titulo } from '../styles';

const Empresas = () => {

    const barra = useContext(AuthContext);
    

    const navigate = useNavigate();

    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }    
    const nome_tabela = "ccs_empresa"
    const titulo_pagina = "CADASTRO DE EMPRESAS"
    const [titulo_modal, setTituloModal] = useState("Editar Empresa")
    const [titulo_botao, setTituloBotao] = useState("Salvar Modificações")
    const nome_colunas = [ {id: '1', ident: 'Código'}, {id: '2', ident: 'Nome'}, {id: '3', ident: 'Endereço'}, {id: '4', ident: 'email'}, ]
    const numero_colunas = 5

    const [opcao, setOpcao] = useState('X')

    const [loading, setLoading] = useState(false);
    //const [isDisabled, setIsDisabled] = useState(true);
    //const [registro, setRegistro] = useState([])
    //const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [state, setState] = useState({
        id: "",
        nome: "",
        endereco: "",
        email: "",
        cnpj: ""
      })


    const [showN, setshowN] = useState(false);

    const handleClose = () => {
        setshowN(false);
    }


    useEffect(() => {
        setLoading(false)
        if (opcao === "X") { 
            leRegistros(nome_tabela) 
            setOpcao('')
        }
    }, [loading, opcao]);  


    function excluirRegistro(ident) {
        setTituloModal("Excluir Empresa")
        setTituloBotao("Confirmar Exclusão")
        console.log("REGISTRO (D): ", ident)
        setOpcao('D')
        leUmRegistro(nome_tabela, ident)
        setshowN(true)
        return
    }

    function editarRegistro(ident) {
        setTituloModal("Editar Empresa")
        setTituloBotao("Salvar modificações")
        console.log("REGISTRO: ", ident)
        setOpcao('E')
        leUmRegistro(nome_tabela, ident)
        setshowN(true)
        return
    }

    function incluirRegistro() {
        console.log("INCLUIR REGISTRO")
        setTituloModal("Incluir Empresa")
        setTituloBotao("Confirmar Inclusão")
        setOpcao("I")

        setState({
            id: "",
            nome: "",
            endereco: "",
            cnpj: "",
            email: ""
          })
    
        setshowN(true)
        return
    }
    
    const leRegistros = async (nome_tabela) => {

        console.log("tabela: ", nome_tabela)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=0&t=${nome_tabela}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (todos): ", sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  console.log(res.data.datalist.userdata);
                  setData(res.data.datalist.userdata);

            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const leUmRegistro = async (nome_tabela, reg) => {

        console.log("tabela: ", nome_tabela, " registro: ", reg)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=${reg}&t=${nome_tabela}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (um): ", sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.datalist.userdata);
                  console.log("DATA (1)", res.data.datalist.userdata);
                  console.log("Parâmetros: ", res.data.datalist.userdata[0].id, " :: ", res.data.datalist.userdata[0].id.nome, "-", res.data.datalist.userdata[0].id.endereco, "-", res.data.datalist.userdata[0].id.cnpj)
                  setState({
                    id:      res.data.datalist.userdata[0].id,
                    nome:    res.data.datalist.userdata[0].nome, 
                    endereco: res.data.datalist.userdata[0].endereco, 
                    cnpj:   res.data.datalist.userdata[0].cnpj,
                    email:  res.data.datalist.userdata[0].email
                    })

            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const gravaUmRegistro = async (kpo) => {

        console.log("tabela: ", kpo)
        axios.get(`${barra.urlbase}/apicrud/gravar_arquivo.php?${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (UPD): ", sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.retorno);
                  console.log("retorno: ", res.data.retorno)
                  barra.avisar("Registro gravado", 1)
            } else {
                  console.log("Não encontrado (UPD)")

                  let motivo    = res.data.retorno;
                  console.log("NÃO SUCESSO: ", motivo);
            }
            setOpcao('X')
        })
         

    }    

    function handleChange(evt) {
        const value = evt.target.value;
        setState({...state, [evt.target.id]: value  });
    }

    function handleSubmit(evt) {
        evt.preventDefault();
        let wcampo1 = state.nome.replaceAll("&", "e")
        let wcampo2 = state.endereco.replaceAll("&", "e")
        let wcampo3 = state.cnpj.replaceAll("&", "e")
        let wcampo4 = state.email.replaceAll("&", "e")

        console.log("SUBMIT", )
        let kpo = '';
        if (opcao === 'D') {
            kpo = 't=' + nome_tabela + '&o=D&par=' + state.id + ';nome=' + wcampo1 + ';endereco=' + wcampo2 + ";cnpj=" + wcampo3 + ";email=" + wcampo4
        } else {
            if (opcao === 'I') {
                kpo = 't=' + nome_tabela + '&o=I&par=0;nome=' + wcampo1 + ';endereco=' + wcampo2 + ";cnpj=" + wcampo3 + ";empresa=1; email=" +  wcampo4
            } else {
                kpo = 't=' + nome_tabela + '&o=U&par=' + state.id + ';nome=' + wcampo1 + ';endereco=' + wcampo2 + ";cnpj=" + wcampo3 + ";email=" + wcampo4
            }
        }
        gravaUmRegistro(kpo);
        setshowN(false);
        //setOpcao('')
    }


  return (
    <>

    <form className="cadastros">
        <Container>
            <div  style={{ width: "100%" }}>
                <table width="100%" border="1" style={{ backgroundColor: barra.corBarra, }}>
                    <tr>
                        <td>&nbsp;</td>
                        <td width="47%" align="left"><Titulo><span >{titulo_pagina}</span></Titulo></td>
                        <td width="50%" align="right">                
                            <Button onClick={() => incluirRegistro()} className="btn"  style={{backgroundColor: barra.corBotao, border: "1px solid", borderColor: barra.corBordaBotao, color: barra.corLetraBotao}}>
                                INCLUIR REGISTRO
                            </Button>
                        </td>
                    </tr>
                </table>
            </div>

            <Table>
                <thead>
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>{cabec.ident}</th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>Ações</th>
                    </tr>
                    <tr><td colSpan={numero_colunas}>&nbsp;</td></tr>

                </thead>
                <tbody>
                    {Object.values(data).map(registro => (
                        <tr key={registro.id}>
                            <td align="left">{registro.id}</td>
                            <td align="left">{registro.nome}</td>
                            <td align="left">{registro.endereco}</td>
                            <td align="left">{registro.email}</td>
                            <td>
                            <Button 
                                className="btn" style={{backgroundColor: barra.corBotao, border:'1px solid', borderColor: barra.corBrdaBotao, color: barra.corFonteBotao, padding: "5px", borderRadius: "5px",}} 
                                onClick={() => editarRegistro(registro.id)}>
                                    Editar
                                </Button>
                                <Button 
                                className="btn" style={{backgroundColor: barra.corBotaoE, border:'1px solid', borderColor: barra.corBordaBotaoE, color: barra.corFonteBotaoE, padding: "5px", borderRadius: "5px"}} 
                                onClick={() => excluirRegistro(registro.id)}>
                                    Excluir
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>


        <hr />

        <Modal show={showN} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{titulo_modal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='form-control'>
            <input className="row" type="hidden" name="id"    id="id"    value={state.id}    onChange={handleChange}  placeholder='informe o id da Empresa' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="nome"    id="nome"    value={state.nome}    onChange={handleChange}  placeholder='informe o nome da empresa' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <textarea className="row" name="endereco" id="endereco" rows="2" value={state.endereco} onChange={handleChange}  placeholder='informe o Endereço' style={{borderRadius: "5px", width: "100%", textAlign: "center"}} />
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="cnpj"   id="cnpj"   value={state.cnpj}   onChange={handleChange}  placeholder='digite seu CNPJ  ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="email"  id="email"  value={state.email}  onChange={handleChange}  placeholder='Informe o e-mail preferido' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className="form-control">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align='left' ><input type="submit" className="btn"  style={{padding:"5px", borderRadius:"5px", backgroundColor: barra.corBotao,  color: barra.corLetraBotao,  }} value={titulo_botao} /></td>
                            <td width="50%" align='right'><button type="button" className="btn" style={{padding:"5px", borderRadius:"5px", backgroundColor: barra.corBotaoE, color: barra.corLetraBotaoE, }} onClick={handleClose}>Cancelar</button></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>

        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </form>
      </Modal>       


    </form>
    </>

  )
  
}

export default Empresas