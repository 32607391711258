import React from "react";
import { useState, useEffect, useContext } from "react";
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../contexts/Auth"

const ItemAdicional = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        setLoading(false)
        setInputs([])
        navigate("/operacao/especial3");
}, [loading, navigate]);  


    const itemAdicional = async (xave, codpro) => {
    try {
        if ((xave !== '') && (xave !== undefined) && (codpro !== '') && (codpro !== undefined)) {

          let kpo = "e=" + barra.cempresa + "&u=" + barra.cusuario + "&x=" + xave + "&p=" + codpro
      
          axios.get(`${barra.urlbase}/apicrud/itemadicional.php?${kpo}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("NF: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.retorno);
                    barra.avisar(res.data.retorno, 2)
                    setLoading(true);
              } else {
                    let motivo    = res.data.motivo;
                    barra.avisar(motivo, 3)
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
              }
          })

        }
     
        return
       
    } catch (error) { throw error;}    

}



    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("nota fiscal", inputs.xave, '-', inputs.codpro );
        setLoading(false)

        if ((inputs.xave === '') || (inputs.codpro === '')) {
                barra.avisar("Chave da Nota Fiscal e Código do Produto devem ser informados!", 3)
        } else {
                itemAdicional(inputs.xave, inputs.codpro);
        }
        
      }

  return (

    <>
    
            <form className="Container" onSubmit={handleSubmit}>
                <Container>
                    <ConteudoTitulo>
                        <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px"}}>RECEBER ITEM ADICIONAL</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ConteudoTitulo>


                    <div className="card" style={{   borderColor: "black", borderStyle: "solid", borderRadius: 5, borderWidth: 1, }}>

                        <hr />
                        <div className="row">
                            <div className="col-md-10">
                                <div className="form-group">
                                    <label>Informe a Chave da Nota Fiscal</label>
                                    <input type="Text" className="form-control" id="xave" name="xave" size="50" placeholder="digite ou leia o código da nota fiscal" value={inputs.xave} onChange={handleChange} />
                                </div>

                                <div className="form-group">
                                    <label>Informe o código do produto</label>
                                    <input type="Text" className="form-control" id="codpro" name="codpro" size="50" placeholder="digite ou leia o código da nota fiscal" value={inputs.codpro} onChange={handleChange} />
                                </div>

                                <div className="form-group form-check">
                                </div>
                                <button type="submit" name="btNotaFiscal" className="btn btn-primary" style={{paddingLeft:"9px", paddingRight:"9px", backgroundColor: barra.corBotao, borderRadius:"5px", }} >Receber Item Adicional</button>
                            </div>

                        </div>
                        <hr />
                        <hr />
                    </div>
                </Container>
            </form>

    </>


    );
};
  
export default ItemAdicional;