import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, ConteudoTitulo, Titulo } from '../styles';


const options = [
    { label: "Snow", value: "#FFFAFA", },
    { label: "GhostWhite", value: "#F8F8FF", },
    { label: "WhiteSmoke", value: "#F5F5F5", },
    { label: "Gainsboro", value: "#DCDCDC", },
    { label: "FloralWhite", value: "#FFFAF0", },
    { label: "OldLace", value: "#FDF5E6", },
    { label: "Linen", value: "#FAF0E6", },
    { label: "AntiqueWhite", value: "#FAEBD7", },
    { label: "PapayaWhip", value: "#FFEFD5", },
    { label: "BlanchedAlmond", value: "#FFEBCD", },
    { label: "Bisque", value: "#FFE4C4", },
    { label: "PeachPuff", value: "#FFDAB9", },
    { label: "NavajoWhite", value: "#FFDEAD", },
    { label: "Moccasin", value: "#FFE4B5", },
    { label: "Cornsilk", value: "#FFF8DC", },
    { label: "Ivory", value: "#FFFFF0", },
    { label: "LemonChiffon", value: "#FFFACD", },
    { label: "Seashell", value: "#FFF5EE", },
    { label: "Honeydew", value: "#F0FFF0", },
    { label: "MintCream", value: "#F5FFFA", },
    { label: "Azure", value: "#F0FFFF", },
    { label: "AliceBlue", value: "#F0F8FF", },
    { label: "lavender", value: "#E6E6FA", },
    { label: "LavenderBlush", value: "#FFF0F5", },
    { label: "MistyRose", value: "#FFE4E1", },
    { label: "White", value: "#FFFFFF", },
    { label: "Black", value: "#000000", },
    { label: "DarkSlateGray", value: "#2F4F4F", },
    { label: "DimGrey", value: "#696969", },
    { label: "SlateGrey", value: "#708090", },
    { label: "LightSlateGray", value: "#778899", },
    { label: "Grey", value: "#BEBEBE", },
    { label: "LightGray", value: "#D3D3D3", },
    { label: "MidnightBlue", value: "#191970", },
    { label: "NavyBlue", value: "#000080", },
    { label: "CornflowerBlue", value: "#6495ED", },
    { label: "DarkSlateBlue", value: "#483D8B", },
    { label: "SlateBlue", value: "#6A5ACD", },
    { label: "MediumSlateBlue", value: "#7B68EE", },
    { label: "LightSlateBlue", value: "#8470FF", },
    { label: "MediumBlue", value: "#0000CD", },
    { label: "RoyalBlue", value: "#4169E1", },
    { label: "Blue", value: "#0000FF", },
    { label: "DodgerBlue", value: "#1E90FF", },
    { label: "DeepSkyBlue", value: "#00BFFF", },
    { label: "SkyBlue", value: "#87CEEB", },
    { label: "LightSkyBlue", value: "#87CEFA", },
    { label: "SteelBlue", value: "#4682B4", },
    { label: "LightSteelBlue", value: "#B0C4DE", },
    { label: "LightBlue", value: "#ADD8E6", },
    { label: "PowderBlue", value: "#B0E0E6", },
    { label: "PaleTurquoise", value: "#AFEEEE", },
    { label: "DarkTurquoise", value: "#00CED1", },
    { label: "MediumTurquoise", value: "#48D1CC", },
    { label: "Turquoise", value: "#40E0D0", },
    { label: "Cyan", value: "#00FFFF", },
    { label: "LightCyan", value: "#E0FFFF", },
    { label: "CadetBlue", value: "#5F9EA0", },
    { label: "MediumAquamarine", value: "#66CDAA", },
    { label: "Aquamarine", value: "#7FFFD4", },
    { label: "DarkGreen", value: "#006400", },
    { label: "DarkOliveGreen", value: "#556B2F", },
    { label: "DarkSeaGreen", value: "#8FBC8F", },
    { label: "SeaGreen", value: "#2E8B57", },
    { label: "MediumSeaGreen", value: "#3CB371", },
    { label: "LightSeaGreen", value: "#20B2AA", },
    { label: "PaleGreen", value: "#98FB98", },
    { label: "SpringGreen", value: "#00FF7F", },
    { label: "LawnGreen", value: "#7CFC00", },
    { label: "Green", value: "#00FF00", },
    { label: "Chartreuse", value: "#7FFF00", },
    { label: "MedSpringGreen", value: "#00FA9A", },
    { label: "GreenYellow", value: "#ADFF2F", },
    { label: "LimeGreen", value: "#32CD32", },
    { label: "YellowGreen", value: "#9ACD32", },
    { label: "ForestGreen", value: "#228B22", },
    { label: "OliveDrab", value: "#6B8E23", },
    { label: "DarkKhaki", value: "#BDB76B", },
    { label: "PaleGoldenrod", value: "#EEE8AA", },
    { label: "LtGoldenrodYello", value: "#FAFAD2", },
    { label: "LightYellow", value: "#FFFFE0", },
    { label: "Yellow", value: "#FFFF00", },
    { label: "Gold", value: "#FFD700", },
    { label: "LightGoldenrod", value: "#EEDD82", },
    { label: "goldenrod", value: "#DAA520", },
    { label: "DarkGoldenrod", value: "#B8860B", },
    { label: "RosyBrown", value: "#BC8F8F", },
    { label: "IndianRed", value: "#CD5C5C", },
    { label: "SaddleBrown", value: "#8B4513", },
    { label: "Sienna", value: "#A0522D", },
    { label: "Peru", value: "#CD853F", },
    { label: "Burlywood", value: "#DEB887", },
    { label: "Beige", value: "#F5F5DC", },
    { label: "Wheat", value: "#F5DEB3", },
    { label: "SandyBrown", value: "#F4A460", },
    { label: "Tan", value: "#D2B48C", },
    { label: "Chocolate", value: "#D2691E", },
    { label: "Firebrick", value: "#B22222", },
    { label: "Brown", value: "#A52A2A", },
    { label: "DarkSalmon", value: "#E9967A", },
    { label: "Salmon", value: "#FA8072", },
    { label: "LightSalmon", value: "#FFA07A", },
    { label: "Orange", value: "#FFA500", },
    { label: "DarkOrange", value: "#FF8C00", },
    { label: "Coral", value: "#FF7F50", },
    { label: "LightCoral", value: "#F08080", },
    { label: "Tomato", value: "#FF6347", },
    { label: "OrangeRed", value: "#FF4500", },
    { label: "Red", value: "#FF0000", },
    { label: "HotPink", value: "#FF69B4", },
    { label: "DeepPink", value: "#FF1493", },
    { label: "Pink", value: "#FFC0CB", },
    { label: "LightPink", value: "#FFB6C1", },
    { label: "PaleVioletRed", value: "#DB7093", },
    { label: "Maroon", value: "#B03060", },
    { label: "MediumVioletRed", value: "#C71585", },
    { label: "VioletRed", value: "#D02090", },
    { label: "Magenta", value: "#FF00FF", },
    { label: "Violet", value: "#EE82EE", },
    { label: "Plum", value: "#DDA0DD", },
    { label: "Orchid", value: "#DA70D6", },
    { label: "MediumOrchid", value: "#BA55D3", },
    { label: "DarkOrchid", value: "#9932CC", },
    { label: "DarkViolet", value: "#9400D3", },
    { label: "BlueViolet", value: "#8A2BE2", },
    { label: "Purple", value: "#A020F0", },
    { label: "MediumPurple", value: "#9370DB", },
    { label: "Thistle", value: "#D8BFD8", },
    { label: "Snow1", value: "#FFFAFA", },
    { label: "FundoPadrao", value: "#3399CC", },
    { label: "BarraPadrao", value: "#66CCFF", },
    { label: "BotaoPadrao", value: "#0D6EFD", },
    { label: "PanelPadrao", value: "#FFFF01", },
    { label: "InterPadrao", value: "#3399CD", },
    { label: "CabecPadrao", value: "#4488BB", },
    { label: "LetraPadrao", value: "#FFFFFF", },
    { label: "SmenuPadrao", value: "#0D9EFD", },
    { label: "RealcPadrao", value: "#0D6EFE", }
    ];    


const ParametrizarGraficosInv = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }


    const [primeira, setPrimeira] = useState(true)
    const [loading, setLoading] = useState(false)
    //const [metricag, setMetricag] = useState('')
    //const [quebrag, setQuebrag] = useState('')
    //const [tipog, setTipog]=useState('');
    //const [grupo, setGrupo] = useState('')
    //const [refer, setRefer]=useState('');
    //const [conta, setConta] = useState('')
    //const [nomeg, setNomeg]=useState('');

    const [state, setState] = useState({
        iden:"",
        grafico: "",
        ordem: "",
        superior: "",
        esquerda: "",
        largura: "",
        altura: "",
        fundo: "",
        palete: ""
      })

      const [opcao, setOpcao] = useState('')

      /*
      const [inputs, setInputs] = useState({
        grafico: "",
        elementos: "",
        observacoes: [],
        nome: ""
      })
      */
    //const [data, setData] = useState([]);
    const [graficos, setGraficos] = useState([])
    //const [observacao, setObservacao] = useState('')
    //const [flag1, setFlag1] = useState('none')
    //const [flag2, setFlag2] = useState('none')
    //const [flag3, setFlag3] = useState('none')
    //const [flagCmp, setFlagCmp] = useState('none')

    //const [complemento, setComplemento] = useState('X')
    //const [tabela, setTabela] = useState('0')
    //const [ nomeBotao, setNomeBotao] = useState('Incluir Gráfico no Painel')

    //const nome_tabela = "ccs_grafico_tabela_elemento"
    const [titulo_modal, setTituloModal] = useState("Editar Painel")
    const [titulo_botao, setTituloBotao] = useState("Salvar Modificações")
    const [showN, setshowN] = useState(false);
    //const [showE, setshowE] = useState(false);


    const [seq, setSeq] = useState([])      


    useEffect(() => {
        setLoading(false)
        //console.log("GRUPO:", grupo, complemento, tabela)
        navigate("/grafico-Inv");
}, [loading, navigate, showN]);  

   

const buscarVisoes = async () => {
    try {
  
        //let tk = localStorage.getItem("token")
  
        axios.get(`${barra.urlbase}/apicrud/buscarvisoes.php?t=dash&r=0&t=inv&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("PADRÃO: ", res)
            console.log("SUCESSO: ", sucesso);
  
            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setSeq(res.data.userlist.userdata)
//                    barra.setGrf(res.data.userlist.userdata[0].grf)
//                    setProps(res.data.userlist.userdata[0].tit)
                    setLoading(true);
                    console.log("G: ", seq)
  
            } else {
                    let motivo    = res.data.motivo;
                    //inputs.lojas = ""
                    //inputs.datai = ""
                    //inputs.dataf = ""
                    //inputs.limit = ""
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
            }
        })
    } catch (error) { throw error;} 
  
    return
  
  } 


const buscarGraficos = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscargraficos.php?e=${barra.cempresa}&t=inv`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("GRÁFICOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log("GRÁFICOS: ", res.data.userlist.userdata);
                    setGraficos(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Gráficos Não encontrados")
                    let motivo    = res.data.motivo;
                    setGraficos([])
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  


/*
const buscarUmGrafico = async (evt) => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarumgraficos.php?i=${evt}&t=inf&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("GRÁFICOS: ", evt, ' - ', res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setComplemento(res.data.userlist.userdata[0].complemento)
                    setTabela(res.data.userlist.userdata[0].idtabela)
                    setLoading(true);

            } else {
                    console.log("Gráficos Não encontrados")
                    let motivo    = res.data.motivo;
                    setComplemento('N')
                    setTabela('0')
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  


const gravarVisao = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_grafico.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (GRF): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              handleCancel()
        } else {
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
              barra.avisar(motivo, 5)
        }
    })
     

} 
*/


const gravarElemento = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_grafico_visao.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (GRF): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              handleCancel()
        } else {
              let motivo    = res.data.retorno;
              console.log("NÃO SUCESSO: ", motivo);
              barra.avisar(motivo, 5)
        }
    })
     

} 

const leUmRegistro = async (reg) => {

    try {
  
        axios.get(`${barra.urlbase}/apicrud/buscarvisoes.php?t=inv&r=${reg}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (um): ", sucesso);
            if (sucesso) {
                //setData(res.data.userlist.userdata);
                console.log("DATA (1)", res.data.userlist.userdata);

                setState({
                  iden:      res.data.userlist.userdata[0].id,
                  grafico:   res.data.userlist.userdata[0].grf,
                  ordem:     res.data.userlist.userdata[0].ord, 
                  superior:  res.data.userlist.userdata[0].top, 
                  esquerda:  res.data.userlist.userdata[0].lef,
                  largura:   res.data.userlist.userdata[0].wid,
                  altura:    res.data.userlist.userdata[0].hei,
                  fundo:     res.data.userlist.userdata[0].bck,
                  palete:    res.data.userlist.userdata[0].pal
                  })

                setLoading(true);
 
            } else {
                let motivo    = res.data.motivo;
                console.log("NÃO SUCESSO: ", motivo);
                setState([])
                setLoading(true);
            }
        })
    } catch (error) { throw error;}     

}


if (primeira) {
    buscarGraficos()
    buscarVisoes()
    setPrimeira(false)
}


/*
function handleSubmit(e) {
    e.preventDefault();
    let errog = '';
    let errom = '';
    let erroq = '';
    let errot = '';
    let erron = '';
    if (grupo      === undefined || grupo      === '') { errog = 'g' }
    if (conta      === undefined || conta      === '') { errog = 'g' }
    if (metricag   === undefined || metricag   === '') { errom = 'm' }
    if (quebrag    === undefined || quebrag    === '') { erroq = 'q' }
    if (tipog      === undefined || tipog      === '') { errot = 't' }
    if (nomeg      === undefined || nomeg      === '') { erron = 'n' }

    let mens = '';
    if (errog !== '') {  mens = mens + "<br />O GRUPO e a CONTA são campos obrigatórios."   }
    if (errom !== '') {  mens = mens + "<br />A MÉTRICA deve ser indicada."   }
    if (erroq !== '') {  mens = mens + "<br />O GRUPO DE VISÃO deve ser informado."   }
    if (errot !== '') {  mens = mens + "<br />O TIPO DO GRÁFICO deve ser selecionado"   }
    if (erron !== '') {  mens = mens + "<br />O NOME é um campo mandatório"   }

    if (errog !== '' || errom !== '' || erroq !== '' || errot !== '' || erron !== '') {
        barra.avisar(mens, 5)
        return
    }

    console.log("GRÁFICO: ", grupo, conta, metricag, quebrag, tipog, nomeg, refer)
    let kpo = "e=" + barra.cempresa + "&g=" + grupo + "&c=" + conta + "&m=" + metricag + "&q=" + quebrag + "&t=" + tipog +  "&n=" + nomeg + "&r=" + refer
    gravarVisao(inputs.tipo,inputs.datam, inputs.produto, inputs.refer, inputs.qtd);
    setLoading(false);     
}
*/

function handleChangeM1(evt) {
    const value = evt.target.value;
    setState({...state, [evt.target.id]: value  });
}


function handleCancel() {
    //setGrupo('')
    //setConta('')
    //setMetricag('')
    //setQuebrag('')
    //setRefer('')
    //setTipog('')
    //setNomeg(')')
}



const handleClose = () => {
    setLoading(false)
    setshowN(false);
}

const handleSubmitModal = (evt) => {
    evt.preventDefault();
    if (state.grafico  === undefined || state.grafico  === '') {  state.grafico  = '1'       }
    if (state.ordem   === undefined  || state.ordem   === '' ) {  state.ordem    = 1         }
    if (state.superior === undefined || state.superior === '') {  state.superior = '10px'    }
    if (state.esquerda === undefined || state.esquerda === '') {  state.esquerda = '10px'    }
    if (state.largura  === undefined || state.largura  === '') {  state.largura  = '200px'   }
    if (state.altura   === undefined || state.altura   === '') {  state.altura   = '200px'   }
    if (state.fundo    === undefined || state.fundo    === '') {  state.fundo    = '#FFFFFF' }
    if (state.palete   === undefined || state.palete   === '') {  state.palete   = '1'       }

    let kpo = "ep=" + barra.cempresa + "&rp=" + barra.cusuario + "&op=" + opcao + "&gf=" + state.grafico + "&ix=" + state.ordem + "&tp=" + state.superior + "&lf=" + state.esquerda + "&wd=" + state.largura + "&hg=" + state.altura + "&bk=" + state.fundo.replace("#","") + "&pl=" + state.palete + "&rg=" + state.iden + "&pn=inv"
    gravarElemento(kpo)
    handleClose()
}

/*
function incluirRegistro() {
    setTituloModal("Incluir gráfico do Painel")
    setTituloBotao("Confirmar Inclusão")
    setOpcao('I')
    setState([])

    setshowN(true)
    return
}

function padrao() {
    setFlag2('none')
    inputs.elementos = 0
}
*/

function excluirRegistro(ident) {
    setTituloModal("Excluir gráfico do Painel")
    setTituloBotao("Confirmar Exclusão")
    console.log("REGISTRO (D): ", ident)
    setOpcao('D')
    leUmRegistro(ident)
    setshowN(true)
    return
}

function editarRegistro(ident) {
    setTituloModal("Editar gráfico do Painel")
    setTituloBotao("Salvar modificações")
    console.log("REGISTRO: ", ident)
    setOpcao('E')
    leUmRegistro(ident)
    setshowN(true)
    return
}



  return (
    
    <>


    <form className="Container" >
        <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", backgroundColor: barra.corBarra, textAlign: "left", }}>
                          <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "5px", marginLeft: "4px" }}>Definição do Painel Inventário</h4></Titulo>
                    </div>
                </ConteudoTitulo>


        <div className="App" style={{ position: "absolute", top: "12Vh", left: "2Vw", width: "96Vw", height: "85Vh", border: "1px solid #000", borderRadius: "5px" }}>
                { Object.values(seq).map(graf => ( 
                    <>
                    
                                        
                    <div  style={{ position: "absolute", top: graf.top, left: graf.lef, width: graf.wid, height: graf.hei, backgroundColor: graf.bck, border: "1px solid #000", borderRadius: "10px"}}>
                                <span style={{position: "absolute", top: "1%", left: "1%", fontSize:"12px", fontWeight: 500, backgroundColor: barra.corBotaoW, color: barra.corLetraBotaoW, padding: "3px", borderRadius: "5px"}} >{graf.tit}</span>
                                <Button ClassName="btn" style={{position: "absolute", top: "1%", left: "70%", fontSize:"12px", backgroundColor: barra.corBotao, color: barra.corLetraBotao, padding: "3px", borderRadius: "5px"}} onClick={() => editarRegistro(graf.id)}>editar</Button>
                                <Button ClassName="btn" style={{position: "absolute", top: "1%", left: "83%", fontSize:"12px", backgroundColor: barra.corBotaoE, color: barra.corLetraBotaoE, padding: "3px", borderRadius: "5px"}} onClick={() => excluirRegistro(graf.id)}>excluir</Button>
                                <br />
                                <span  style={{position: "absolute", top: "30%", left: "1%", fontSize:"12px", color: barra.corLetraBarra, }} >Tipo:{graf.tip} </span>
                                <span  style={{position: "absolute", top: "45%", left: "1%", fontSize:"12px", color: barra.corLetraBarra, }} >Sup:{graf.top} - Esq:{graf.lef} - Larg:{graf.wid} - Alt:{graf.hei}</span>
                    </div>
                    </>
                ))}
            
          </div>

        </Container>
        
    </form>

    
    <hr />


    <Modal show={showN} onHide={handleClose}>
        <form onSubmit={handleSubmitModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{color: barra.corLetraBarra}}>{titulo_modal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <input type="hidden" name="iden" id="iden" value={state.iden} />
            
            <div className='row'>
                <div className="col-md-8">
                    <label><h6 style={{color: barra.corLetraBarra}}>Nome do Gráfico</h6></label>
                    <div>
                        <select className="form-control" name="grafico" id="grafico" value={state.grafico} onChange={handleChangeM1} >
                            <option value="">Selecionar</option>
                                {graficos.map((option) => {
                                return <option value={option.value} key={option.id} >{option.label}</option>
                            })}
                        </select>
                    </div>
                </div> 

                <div className="col-md-4">
                    <label><h6 style={{color: barra.corLetraBarra}}>Sequência</h6></label>
                    <input className="form-control" type="number" name="ordem"   id="ordem"   value={state.ordem}   onChange={handleChangeM1}  placeholder='digite a ordem de apresentação' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
                </div>
            </div>

            <div className="row">
                <div className='col-md-6'>
                    <label><h6 style={{color: barra.corLetraBarra}}>Margem Superior</h6></label>
                    <input className="form-control" type="text" name="superior"   id="superior"   value={state.superior}   onChange={handleChangeM1}  placeholder='espaço a partir do topo da área' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
                </div>

                <div className='col-md-6'>
                    <label><h6 style={{color: barra.corLetraBarra}}>Margem Esquerda</h6></label>
                    <input className="form-control" type="text" name="esquerda"   id="esquerda"   value={state.esquerda}   onChange={handleChangeM1}  placeholder='espaço a partir da lateral esquerda' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
                </div>
            </div>

            <div className="row">
                <div className='col-md-6'>
                    <label><h6 style={{color: barra.corLetraBarra}}>Largura do Gráfico</h6></label>
                    <input className="form-control" type="text" name="largura"   id="largura"   value={state.largura}   onChange={handleChangeM1}  placeholder='tamanho na área de gráficos' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
                </div>

                <div className='col-md-6'>
                    <label><h6 style={{color: barra.corLetraBarra}}>Altura do Gráfico</h6></label>
                    <input className="form-control" type="text" name="altura"   id="altura"   value={state.altura}   onChange={handleChangeM1}  placeholder='tamanho na área de gráficos' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
                </div>
            </div>



            <div className='form-control' >
                <label><h6 style={{color: barra.corLetraBarra}}>Cor de Fundo</h6></label>
                <select name="fundo"  id="fundo"  value={state.fundo} onChange={handleChangeM1} style={{ backgroundColor: state.fundo}}>
                        {options.map((option) => (
                        <option value={option.value} style={{ backgroundColor:  `${option.value}` }}>{option.label}</option>
                        ))}
                </select>
            </div>
        
            <div className='form-control' >
                <label><h6 style={{color: barra.corLetraBarra}}>Jogo de Cores para o Gráfico</h6></label>
                <table width="100%" border="0">
	<tbody>
	<tr>
	<td width="10%">
            <center><input type="radio" name="palete" id="palete" value="1" onChange={handleChangeM1} checked={state.palete === "1"}/></center>
                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#044DF7"></td></tr>
                <tr height="7px"><td bgcolor="#089C35"></td></tr>
                <tr height="7px"><td bgcolor="#F58C8C"></td></tr>
                <tr height="7px"><td bgcolor="#FDEC05"></td></tr>
                <tr height="7px"><td bgcolor="#85A7F5"></td></tr>
                <tr height="7px"><td bgcolor="#ff8000"></td></tr>
                <tr height="7px"><td bgcolor="#C703FC"></td></tr>
                <tr height="7px"><td bgcolor="#9C9B9C"></td></tr>
                <tr height="7px"><td bgcolor="#A5EBBA"></td></tr>
                <tr height="7px"><td bgcolor="#5FB5FC"></td></tr>
                <tr height="7px"><td bgcolor="#F0B67B"></td></tr>
                <tr height="7px"><td bgcolor="#D44040"></td></tr>
                <tr height="7px"><td bgcolor="#DB85F3"></td></tr>
                <tr height="7px"><td bgcolor="#F8DA99"></td></tr>
                <tr height="7px"><td bgcolor="#F579AF"></td></tr>
                <tr height="7px"><td bgcolor="#BFBCBD"></td></tr>
                <tr height="7px"><td bgcolor="#02551B"></td></tr>
                <tr height="7px"><td bgcolor="#00BCBF"></td></tr>
                <tr height="7px"><td bgcolor="#FB0505"></td></tr>
                <tr height="7px"><td bgcolor="#585758"></td></tr>
                </tbody>
                </table>

	</td>
	<td width="3%"></td>
	<td width="10%">
            <center><input type="radio" name="palete" id="palete" value="2" onChange={handleChangeM1} checked={state.palete === "2"}/></center>

                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#F0B67B"></td></tr>
                <tr height="7px"><td bgcolor="#D44040"></td></tr>
                <tr height="7px"><td bgcolor="#DB85F3"></td></tr>
                <tr height="7px"><td bgcolor="#F8DA99"></td></tr>
                <tr height="7px"><td bgcolor="#F579AF"></td></tr>
                <tr height="7px"><td bgcolor="#BFBCBD"></td></tr>
                <tr height="7px"><td bgcolor="#02551B"></td></tr>
                <tr height="7px"><td bgcolor="#00BCBF"></td></tr>
                <tr height="7px"><td bgcolor="#FB0505"></td></tr>
                <tr height="7px"><td bgcolor="#585758"></td></tr>
                <tr height="7px"><td bgcolor="#044DF7"></td></tr>
                <tr height="7px"><td bgcolor="#089C35"></td></tr>
                <tr height="7px"><td bgcolor="#F58C8C"></td></tr>
                <tr height="7px"><td bgcolor="#FDEC05"></td></tr>
                <tr height="7px"><td bgcolor="#85A7F5"></td></tr>
                <tr height="7px"><td bgcolor="#ff8000"></td></tr>
                <tr height="7px"><td bgcolor="#C703FC"></td></tr>
                <tr height="7px"><td bgcolor="#9C9B9C"></td></tr>
                <tr height="7px"><td bgcolor="#A5EBBA"></td></tr>
                <tr height="7px"><td bgcolor="#5FB5FC"></td></tr>
                </tbody>
                </table>

	</td>
	<td width="3%"></td>
	<td width="10%">
        <center><input type="radio" name="palete" id="palete" value="3" onChange={handleChangeM1} checked={state.palete === "3"}/></center>

                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#FF4444"></td></tr>
                <tr height="7px"><td bgcolor="#774444"></td></tr>
                <tr height="7px"><td bgcolor="#44FF44"></td></tr>
                <tr height="7px"><td bgcolor="#447744"></td></tr>
                <tr height="7px"><td bgcolor="#4444FF"></td></tr>
                <tr height="7px"><td bgcolor="#444477"></td></tr>
                <tr height="7px"><td bgcolor="#FFFF44"></td></tr>
                <tr height="7px"><td bgcolor="#777744"></td></tr>
                <tr height="7px"><td bgcolor="#FF44FF"></td></tr>
                <tr height="7px"><td bgcolor="#774477"></td></tr>
                <tr height="7px"><td bgcolor="#44FFFF"></td></tr>
                <tr height="7px"><td bgcolor="#447777"></td></tr>
                <tr height="7px"><td bgcolor="#FF8888"></td></tr>
                <tr height="7px"><td bgcolor="#778888"></td></tr>
                <tr height="7px"><td bgcolor="#88FF88"></td></tr>
                <tr height="7px"><td bgcolor="#887788"></td></tr>
                <tr height="7px"><td bgcolor="#8888FF"></td></tr>
                <tr height="7px"><td bgcolor="#888877"></td></tr>
                <tr height="7px"><td bgcolor="#FFFF88"></td></tr>
                <tr height="7px"><td bgcolor="#007788"></td></tr>
                </tbody>
                </table>
	</td>
	<td width="3%"></td>
	<td width="10%">
        <center><input type="radio" name="palete" id="palete" value="4" onChange={handleChangeM1} checked={state.palete === "4"}/></center>


                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#FF0000"></td></tr>
                <tr height="7px"><td bgcolor="#770000"></td></tr>
                <tr height="7px"><td bgcolor="#00FF00"></td></tr>
                <tr height="7px"><td bgcolor="#007700"></td></tr>
                <tr height="7px"><td bgcolor="#0000FF"></td></tr>
                <tr height="7px"><td bgcolor="#000077"></td></tr>
                <tr height="7px"><td bgcolor="#FFFF00"></td></tr>
                <tr height="7px"><td bgcolor="#777700"></td></tr>
                <tr height="7px"><td bgcolor="#FF00FF"></td></tr>
                <tr height="7px"><td bgcolor="#770077"></td></tr>
                <tr height="7px"><td bgcolor="#00FFFF"></td></tr>
                <tr height="7px"><td bgcolor="#007777"></td></tr>
                <tr height="7px"><td bgcolor="#FFBBBB"></td></tr>
                <tr height="7px"><td bgcolor="#77BBBB"></td></tr>
                <tr height="7px"><td bgcolor="#BBFFBB"></td></tr>
                <tr height="7px"><td bgcolor="#BB77BB"></td></tr>
                <tr height="7px"><td bgcolor="#BBBBFF"></td></tr>
                <tr height="7px"><td bgcolor="#BBBB77"></td></tr>
                <tr height="7px"><td bgcolor="#FFFFBB"></td></tr>
                <tr height="7px"><td bgcolor="#7777BB"></td></tr>
                </tbody>
                </table>
	</td>
	<td width="3%"></td>
	<td width="10%">
        <center><input type="radio" name="palete" id="palete" value="5" onChange={handleChangeM1} checked={state.palete === "5"}/></center>


                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#F8B195"></td></tr>
                <tr height="7px"><td bgcolor="#C06C84"></td></tr>
                <tr height="7px"><td bgcolor="#F5B5FC"></td></tr>
                <tr height="7px"><td bgcolor="#FFE3B0"></td></tr>
                <tr height="7px"><td bgcolor="#F0D3E9"></td></tr>
                <tr height="7px"><td bgcolor="#ECEBEC"></td></tr>
                <tr height="7px"><td bgcolor="#75B79E"></td></tr>
                <tr height="7px"><td bgcolor="#74C7BF"></td></tr>
                <tr height="7px"><td bgcolor="#DD576F"></td></tr>
                <tr height="7px"><td bgcolor="#6C6B6C"></td></tr>
                <tr height="7px"><td bgcolor="#516091"></td></tr>
                <tr height="7px"><td bgcolor="#93C28B"></td></tr>
                <tr height="7px"><td bgcolor="#FCB1B1"></td></tr>
                <tr height="7px"><td bgcolor="#F0EA96"></td></tr>
                <tr height="7px"><td bgcolor="#6A8CAF"></td></tr>
                <tr height="7px"><td bgcolor="#F19371"></td></tr>
                <tr height="7px"><td bgcolor="#BB88C0"></td></tr>
                <tr height="7px"><td bgcolor="#9C9B9C"></td></tr>
                <tr height="7px"><td bgcolor="#D1E6BF"></td></tr>
                <tr height="7px"><td bgcolor="#A6D6EA"></td></tr>
                </tbody>
                </table>
	</td>
	<td width="3%"></td>
	<td width="10%">
        <center><input type="radio" name="palete" id="palete" value="6" onChange={handleChangeM1} checked={state.palete === "6"}/></center>


                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#516091"></td></tr>
                <tr height="7px"><td bgcolor="#93C28B"></td></tr>
                <tr height="7px"><td bgcolor="#FCB1B1"></td></tr>
                <tr height="7px"><td bgcolor="#F0EA96"></td></tr>
                <tr height="7px"><td bgcolor="#6A8CAF"></td></tr>
                <tr height="7px"><td bgcolor="#F19371"></td></tr>
                <tr height="7px"><td bgcolor="#BB88C0"></td></tr>
                <tr height="7px"><td bgcolor="#9C9B9C"></td></tr>
                <tr height="7px"><td bgcolor="#D1E6BF"></td></tr>
                <tr height="7px"><td bgcolor="#A6D6EA"></td></tr>
                <tr height="7px"><td bgcolor="#F8B195"></td></tr>
                <tr height="7px"><td bgcolor="#C06C84"></td></tr>
                <tr height="7px"><td bgcolor="#F5B5FC"></td></tr>
                <tr height="7px"><td bgcolor="#FFE3B0"></td></tr>
                <tr height="7px"><td bgcolor="#F0D3E9"></td></tr>
                <tr height="7px"><td bgcolor= "#ECEBEC"></td></tr>
                <tr height="7px"><td bgcolor="#75B79E"></td></tr>
                <tr height="7px"><td bgcolor="#74C7BF"></td></tr>
                <tr height="7px"><td bgcolor="#DD576F"></td></tr>
                <tr height="7px"><td bgcolor="#6C6B6C"></td></tr>
                </tbody>
                </table>
	</td>
	<td width="3%"></td>
	<td width="10%">
        <center><input type="radio" name="palete" id="palete" value="7" onChange={handleChangeM1} checked={state.palete === "7"}/></center>


                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#007777"></td></tr>
                <tr height="7px"><td bgcolor="#FFBBFF"></td></tr>
                <tr height="7px"><td bgcolor="#9EFDF7"></td></tr>
                <tr height="7px"><td bgcolor="#FFF83D"></td></tr>
                <tr height="7px"><td bgcolor="#FF44FF"></td></tr>
                <tr height="7px"><td bgcolor="#887788"></td></tr>
                <tr height="7px"><td bgcolor="#33FF00"></td></tr>
                <tr height="7px"><td bgcolor="#FF4444"></td></tr>
                <tr height="7px"><td bgcolor="#4444FF"></td></tr>
                <tr height="7px"><td bgcolor="#FEFECC"></td></tr>
                <tr height="7px"><td bgcolor="#FFBBFF"></td></tr>
                <tr height="7px"><td bgcolor="#344556"></td></tr>
                <tr height="7px"><td bgcolor="#449944"></td></tr>
                <tr height="7px"><td bgcolor="#0011FF"></td></tr>
                <tr height="7px"><td bgcolor="#78899A"></td></tr>
                <tr height="7px"><td bgcolor="#99FF88"></td></tr>
                <tr height="7px"><td bgcolor="#9988FF"></td></tr>
                <tr height="7px"><td bgcolor="#F88000"></td></tr>
                <tr height="7px"><td bgcolor="#880000"></td></tr>
                <tr height="7px"><td bgcolor="#0088FF"></td></tr>
                </tbody>
                </table>
	</td>
	<td width="2%"></td>
	<td width="10%">
       <center><input type="radio" name="palete" id="palete" value="8" onChange={handleChangeM1} checked={state.palete === "8"}/></center>


                <table className="form" width="100%" border="1">
                <tbody>
                <tr height="7px"><td bgcolor="#8600AE"></td></tr>
                <tr height="7px"><td bgcolor="#FF9500"></td></tr>
                <tr height="7px"><td bgcolor="#00B800"></td></tr>
                <tr height="7px"><td bgcolor="#FF0079"></td></tr>
                <tr height="7px"><td bgcolor="#FFCB00"></td></tr>
                <tr height="7px"><td bgcolor="#00A4C5"></td></tr>
                <tr height="7px"><td bgcolor="#FF0000"></td></tr>
                <tr height="7px"><td bgcolor="#FAFF00"></td></tr>
                <tr height="7px"><td bgcolor="#0058BE"></td></tr>
                <tr height="7px"><td bgcolor="#FF5B00"></td></tr>
                <tr height="7px"><td bgcolor="#0FD200"></td></tr>
                <tr height="7px"><td bgcolor="#2600B7"></td></tr>
                <tr height="7px"><td bgcolor="#F6CFCA"></td></tr>
                <tr height="7px"><td bgcolor="#9CFF6E"></td></tr>
                <tr height="7px"><td bgcolor="#9EFDF7"></td></tr>
                <tr height="7px"><td bgcolor="#D4AAFE"></td></tr>
                <tr height="7px"><td bgcolor="#FE3D42"></td></tr>
                <tr height="7px"><td bgcolor="#FFF83D"></td></tr>
                <tr height="7px"><td bgcolor="#954C21"></td></tr>
                <tr height="7px"><td bgcolor="#F8A2A0"></td></tr>
                </tbody>
                </table>
	</td>
	</tr>
    </tbody></table>



            </div>

            <div className="form-control">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align='left' ><input type="submit" className="btn-primary" style={{paddingLeft:"4px", paddingRight:"4px", backgroundColor: barra.corBotao, borderRadius:"5px", }} value={titulo_botao} /></td>
                            <td width="50%" align='right'><button type="button" className="btn-danger" style={{paddingLeft:"4px", paddingRight:"4px", borderRadius:"5px", }}onClick={handleClose}>Cancelar</button></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>

        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </form>
      </Modal>       

    
    </>
  )
}

export default ParametrizarGraficosInv