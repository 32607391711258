import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const LancarEstoque = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [primeira, setPrimeira] = useState(true)
    const [flagPrd, setFlagPrd] = useState('none')
    const [flagDat, setFlagDat] = useState('none')
    const [flagBot, setFlagBot] = useState('none')

    useEffect(() => {
        setLoading(false)
        navigate("/estoque-lancar");
}, [loading, navigate, flagBot, flagDat, flagPrd]);  

const buscarProdutos = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarprodutos.php?e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("PRODUTOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setProdutos(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Produtos Não encontrados")
                    let motivo    = res.data.motivo;
                    setProdutos([])
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  

const gravarlancamento = async (tp,dt,pd,rf,qt) => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/gravarlancamento.php?t=${tp}&d=${dt}&p=${pd}&r=${rf}&q=${qt}&u=${barra.cusuario}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("PRODUTOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setProdutos(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Produtos Não encontrados")
                    let motivo    = res.data.motivo;
                    setProdutos([])
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  

    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));

        if (event.target.name === 'tipo')      {   setFlagDat('block') }
        if (event.target.name === 'datam')     {   
            inputs.qtd = 1
            document.getElementById('qtd').value = '1'
            setFlagPrd('block')         
            buscarProdutos()
        }
        if (event.target.name === 'produto')   {   
            setFlagBot('block') 
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("buscar títulos", e );

        setLoading(false)
        gravarlancamento(inputs.tipo,inputs.datam, inputs.produto, inputs.refer, inputs.qtd);
      }


    const options = [
        {
            label: "Selecione o tipo da Movimentação",
            value: "0",
          },
        {
          label: "Saída por Consumo Interno",
          value: "5",
        },
        {
          label: "Saída por Devolução",
          value: "6",
        },
        {
          label: "Acerto do Item no Inventário",
          value: "7",
        },
        {
            label: "Estorno de Saída por Consumo Internno",
            value: "8",
          },
          {
            label: "Estorno de Saída por Devolução",
            value: "9",
          },        
      ];    


    if (primeira) {
        buscarProdutos()
        setPrimeira(false)
    }

    return (
    <>


            <form className="Container" onSubmit={handleSubmit}>
            <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left", borderRadius: "5px",  }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "5px", marginLeft: "4px"}}>LANÇAMENTOS DO INVENTÁRIO</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>
            
                <center>
                <div className="Container" style={{ height: "100Vh", width: "80Vw", border: "1px solid", borderColor: barra.corBordaFundo}}>
                    <p></p>
                    <p></p>
                    <div className="form-control" style={{width: "60%", }}>
                                <p></p>
                                <div className="form-control">
                                        <select name="tipo"  id="tipo" value={inputs.tipo}  onChange={handleChange} 
                                        style={{textAlign: "left", borderColor: "black", width: "100%",  borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px", borderRadius: "15px", }}>
                                            {options.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                </div>
                                <p></p>
                                <p></p>
                                <div id="dat" className="form-control" style={{ textAlign: "left", fontSize: "20px", display: flagDat }}>
                                        <label><h6 style={{marginRight: "10px", fontSize: "20px", color: barra.corLetraFundo}}>Data da Movimentação</h6></label>
                                        <input type="date" name="datam"  id="datam" value={inputs.datam}  onChange={handleChange} 
                                        style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", borderRadius: "15px", }} />
                                </div>
                                
                                <p></p>
                                <p></p>
                                
                                <div className="row">


                                    <div id="prd" className="form-control"  style={{ display: flagPrd }}>
                                        <select name="produto" id="produto" value={inputs.produto} onChange={handleChange} style={{ width: "100%", fontSize: "20px", borderRadius: "15px", }}>
                                            <option value="">Selecione o produto para operação</option>
                                                {produtos.map((option) => {
                                                    return <option value={option.value} key={option.id} >{option.label}</option>
                                                })}
                                        </select>
                                        
                                    </div> 
                                    <p></p>
                                    <p></p>

                                    <div id="ref" className="form-control" style={{ textAlign: "left", fontSize: "20px", display: flagPrd }}>
                                        <div><textarea  name="refer"  id="refer"  onChange={handleChange} rows="2" placeholder='Descrição da movimentação (referências)' 
                                        style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", fontSize: "20px", width: "100%", borderRadius: "15px",}} >{inputs.refer}
                                        </textarea>
                                        </div>
                                    </div>

                                    <div id="dat" className="form-control" style={{ display: flagPrd }}>
                                        <label><h6 style={{marginRight: "10px", fontSize: "20px", color: barra.corLetraFundo}}>Quantidade:</h6></label>
                                        <input type="number" name="qtd"  id="qtd" value={inputs.qtd}  onChange={handleChange}  placeholder="Informe a quantidade"
                                        style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", width: "40%", textAlign: "center", fontSize: "16px", height: "30px", borderRadius: "15px", }} />
                                    </div>

                                    <div id="bot" className="row" style={{ display: flagBot }}>
                                                    <button type="submit" name="buscar" className="botao-verde" style={{width: "auto"}} > Confirmar Lançamento </button>
                                    </div>
                                </div>                               
                                <p></p>
                                <p></p>
                                <p></p>
                            
                                <hr />    
                    </div>
                </div>
                </center>
            </Container>
        </form>
        
    </>




  )
}

export default LancarEstoque