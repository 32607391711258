import { useState, useEffect } from 'react';
import { getPontos } from './Ler_dados';
//import Pontogrf from './Dados_grafico';
//import ChartBar from './ChartBar';
import ChartPie from "./ChartPie";
import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"

function Pizza1( grf ) {

    const barra = useContext(AuthContext)

    const [data, setData] = useState([]);
    //const [dim, setDim] = useState([])
    //barra.setGrf(grf.idgrf)

  useEffect(() => {
    getPontos(barra.dataInicial, barra.dataFinal, barra.cempresa, grf, "PIZZA", barra.loja)
      .then(data => setData(data)) 
      .catch(err => alert(err.response ? err.response.data : err.message))
  }, [barra, grf])

  /*
  function onDiChange(event) {
    barra.setDataInicial(event.target.value);
  }

  function onDfChange(event) {
    barra.setDataFinal(event.target.value);
  }
  */

  return (
    <>
      {/*
      <input type="date" onChange={onDiChange} value={di} />
      <input type="date" onChange={onDfChange} value={df} />
      <ChartBar data={data} /> 
      */}

      <ChartPie data={data}/>
    </>
  );
}

export default Pizza1;