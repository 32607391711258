import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Titulo } from '../styles';

const FecharNotaFiscal = () => {

    const barra = useContext(AuthContext);
    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        setLoading(false)
        setInputs([])
        navigate("/operacao/especial2");
}, [loading, navigate]);  


    const abrirNotaFiscal = async (xave) => {
    try {
        if (xave) {

          axios.get(`${barra.urlbase}/apicrud/fecharnotafiscal.php?e=${xave}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("NF: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.retorno);
                    barra.avisar("Nota Fiscal ENCERRADA", 2)
                    setLoading(true);
              } else {
                    let motivo    = res.data.motivo;
                    barra.avisar(motivo, 3)
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
              }
          })

        }
     
        return
       
    } catch (error) { throw error;}    

}



    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("nota fiscal", inputs.xave );
        setLoading(false)

        if (inputs.xave === '') {
                barra.avisar("Chave da Nota Fiscal deve ser informada!", 3)
        } else {
                abrirNotaFiscal(inputs.xave);
        }
        
      }

  return (

    <>


        <form className="Container" onSubmit={handleSubmit}>
        <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" >
                                            <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: "10px", marginLeft: "10px",}}>CONSOLIDAR NOTAS FISCAIS (FECHAR)</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </ConteudoTitulo>

                <center>
                    <hr />             
                    <div className="row">
                        <table className="Table">
                            <tbody>
                                <tr><td colspan="3">&nbsp;</td></tr>
                                <tr>
                                    <td width="30%">&nbsp;</td>
                                    <td width="20%" align="right">
                                        <span class="input-group-text" id="inputGroup-sizing-default" style={{ height: 50, borderRadius: 0,backgroundColor: "#9a9a9a", textAlign: "center", }}>
                                             Informe a Chave da Nota Fiscal ....
                                        </span>
                                    </td>
                                    <td width="50%" align="left">
                                        <input type="Text" className="form-control" id="xave" name="xave" size="50" placeholder="digite ou leia o código da nota fiscal" value={inputs.xave} onChange={handleChange} 
                                        style={{ height: 50, backgroundColor: "#FFFFFF", borderRadius: 0, maxWidth: "60%", }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="form-group form-check">
                    </div>
                    <button type="submit" name="btNotaFiscal" className="btn btn-primary" style={{paddingLeft:"9px", paddingRight:"9px", backgroundColor: barra.corBotao, borderRadius:"10px", }} >Encerrar Nota Fiscal</button>
                    <hr />             
                </center>
            </Container>
        </form>
    </>   


    );
};
  
export default FecharNotaFiscal;