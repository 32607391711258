import React from 'react'
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, Table, Titulo } from '../styles';


//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaEdit, FaTrash, FaSort, FaSortUp, FaSortDown, FaPlus, FaFilter, FaEraser } from 'react-icons/fa';

const CartaoV3 = () => {

    const barra = useContext(AuthContext);
    

    const navigate = useNavigate()

    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }    

    
    const titulo_pagina = "CADASTRO DE BANCOS"
    const nome_tabela = 'ccs_cartao'
    const modulo = 'Cartão'
    const [titulo_modal, setTituloModal] = useState("Editar " + modulo)
    const [titulo_botao, setTituloBotao] = useState("Salvar Modificações")
    const nome_colunas = [ 
        {id: '1', ident: '#'},   
        {id: '2', ident: 'Nome'}, 
        {id: '3', ident: 'Código'}, 
        {id: '4', ident: 'Operação'}, 
        {id: '5', ident: 'MDR'}, 
        {id: '6', ident: 'RR'},   
        {id: '7', ident: 'Dias'},
    ]
    const numero_colunas = 8
    const places = ['índice', 'informe o nome', 'código XML', 'Operação XML', 'Taxa MDR (utilização)', 'Taxa RR (Recebimento Rápido)', 'Nº de dias para recebimento', 
                    'Taxa juros da 1ª parcela', 'Taxa juros da 2ª parcela', 'Taxa juros da 3ª parcela', 'Taxa juros da 4ª parcela', 'Taxa juros da 5ª parcela', 
                    'Taxa juros da 6ª parcela', 'Taxa juros da 7ª parcela', 'Taxa juros da 8ª parcela', 'Taxa juros da 9ª parcela', 
                    'Taxa juros da 10ª parcela', 'Taxa juros da 11ª parcela', 'Taxa juros da 12ª parcela',
                ]

    const [chave, setChave] = useState('nome')
    const [sentido, setSentido] = useState('ASC')
    const [filtro, setFiltro] = useState('')

    const [opcao, setOpcao] = useState('X')

    const [loading, setLoading] = useState(false);
    //const [isDisabled, setIsDisabled] = useState(true);
    //const [registro, setRegistro] = useState([])
    //const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [state, setState] = useState({
        id: "",
        nome: "",
        codigo: "",
        operacao: "",
        mdr: "",
        rr: "",
        dias: "",
        taxa01: "",
        taxa02: "",
        taxa03: "",
        taxa04: "",
        taxa05: "",
        taxa06: "",
        taxa07: "",
        taxa08: "",
        taxa09: "",
        taxa10: "",
        taxa11: "",
        taxa12: "",
      })


    const [showN, setshowN] = useState(false);

    const handleClose = () => {
        setshowN(false);
        setOpcao('X')
    }


    useEffect(() => {
        setLoading(false)
        if (opcao === "X") { 
            //buscarEmpresas()
            leRegistros(nome_tabela) 
            setOpcao('')
        }
    }, [loading, opcao]);  


    function excluirRegistro(ident) {
        //document.getElementById(1).value = ident
        setTituloModal("Excluir " + modulo)
        setTituloBotao("Confirmar Exclusão")
        console.log("REGISTRO (D): ", ident)
        setOpcao('D')
        leUmRegistro(nome_tabela, ident)
        setshowN(true)
        return
    }

    function editarRegistro(ident) {
        //document.getElementById(1).value = ident
        setTituloModal("Editar " + modulo)
        setTituloBotao("Salvar modificações")
        console.log("REGISTRO: ", ident)
        setOpcao('E')
        leUmRegistro(nome_tabela,ident)
        setshowN(true)
        return
    }

    function incluirRegistro() {
        console.log("Incluir " + modulo)
        setTituloModal("Incluir " + modulo)
        setTituloBotao("Confirmar Inclusão")
        setOpcao("I")

        setState([])
    
        setshowN(true)
        return
    }


    const leRegistros = async (nome_tabela) => {

        console.log("comando: ", `${barra.urlbase}/apicrud/ler_arquivo.php?f=${filtro}&r=0&t=${nome_tabela}&e=${barra.cempresa}`)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?f=${filtro}&r=0&t=${nome_tabela}&e=${barra.cempresa}&c=${chave}&s=${sentido}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO: ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  console.log(res.data.datalist.userdata);
                  setData(res.data.datalist.userdata);

            } else {
                  console.log("Não encontrado (DU)")
                  setData([])
                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const leUmRegistro = async (nome_tabela, reg) => {

        console.log("tabela: ", nome_tabela, " registro: ", reg)
        axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?f=&r=${reg}&t=${nome_tabela}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (um): ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.datalist.userdata);
                  console.log("DATA (1)", res.data.datalist.userdata);
                  setState(res.data.datalist.userdata[0])
/*
                  setState({
                    id:      res.data.datalist.userdata[0].id,
                    nome:    res.data.datalist.userdata[0].nome, 
                    codigo:  res.data.datalist.userdata[0].codigo, 
                    agencia: res.data.datalist.userdata[0].agencia,
                    conta:   res.data.datalist.userdata[0].conta,
                    })
*/
            } else {
                  console.log("Não encontrado (DU)")

                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        })                

    }

    const gravaUmRegistro = async (kpo) => {

        console.log("tabela: ", kpo)
        axios.get(`${barra.urlbase}/apicrud/gravar_arquivo.php?t=${nome_tabela}&${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (UPD): ", modulo, sucesso);

            setLoading(sucesso)

            if (sucesso) {
                  setData(res.data.retorno);
                  console.log("retorno: ", res.data.retorno)
            } else {
                  console.log("Não encontrado (UPD)")

                  let motivo    = res.data.retorno;
                  console.log("NÃO SUCESSO: ", motivo);
            }
            setOpcao('X')
        })
         

    }     

    function handleChange(evt) {
        const value = evt.target.value;
        setState({...state, [evt.target.id]: value  });
    }


    function handleSubmit(evt) {
        evt.preventDefault();
        let empresa  = barra.cempresa
        let wcampo1  = state.nome.replaceAll("&", "e")
        let wcampo2  = state.codigo
        let wcampo3  = state.operacao
        let wcampo4  = state.mdr
        let wcampo5  = state.rr
        let wcampo6  = state.dias
        let wcampo7  = state.taxa01
        let wcampo8  = state.taxa02
        let wcampo9  = state.taxa03
        let wcampo10 = state.taxa04
        let wcampo11 = state.taxa05
        let wcampo12 = state.taxa06
        let wcampo13 = state.taxa07
        let wcampo14 = state.taxa08
        let wcampo15 = state.taxa09
        let wcampo16 = state.taxa10
        let wcampo17 = state.taxa11
        let wcampo18 = state.taxa12
        console.log("submit", state)

        if (wcampo1 === '' || wcampo2 === '' || wcampo3 === '' || wcampo4 === '' || wcampo5 === '' || wcampo6 === '' ) {
            barra.avisar("<p>&nbsp;</p>Campos NOME, CÓDIGO, OPERAÇÃO, MDR, RR e DIAS precisam ser preenchidos")
            return
        }

        let kpo = '';

        if (opcao === 'D') {
            kpo = 't=' + nome_tabela + '&o=D&par=' + state.id + ';nome=' + wcampo1 + ';codigo=' + wcampo2 + ";operacao=" + wcampo3 + ";mdr=" + wcampo4
        } else {
            if (opcao === 'I') {
                kpo = 't=' + nome_tabela + '&o=I&par=0;nome=' + wcampo1 + ';codigo=' + wcampo2 + ";operacao=" + wcampo3 + ";empresa=1; mdr=" +  wcampo4 + "&rr=" + wcampo5 + "&dias=" + wcampo6 + 
                "&taxa01=" + wcampo7 + "&taxa02=" + wcampo8 + "&taxa03=" + wcampo9 + "&taxa04=" + wcampo10 + "&taxa05=" + wcampo11 + "&taxa06=" + wcampo12 +
                "&taxa07=" + wcampo13 + "&taxa08=" + wcampo14 + "&taxa09=" + wcampo15 + "&taxa10=" + wcampo16 + "&taxa11=" + wcampo17 + "&taxa12=" + wcampo18
            } else {
                kpo = 't=' + nome_tabela + '&o=U&par=' + state.id + ';nome=' + wcampo1 + ';codigo=' + wcampo2 + ";operacao=" + wcampo3 + ";mdr=" + wcampo4 + "&rr=" + wcampo5 + "&dias=" + wcampo6 +
                "&taxa01=" + wcampo7 + "&taxa02=" + wcampo8 + "&taxa03=" + wcampo9 + "&taxa04=" + wcampo10 + "&taxa05=" + wcampo11 + "&taxa06=" + wcampo12 +
                "&taxa07=" + wcampo13 + "&taxa08=" + wcampo14 + "&taxa09=" + wcampo15 + "&taxa10=" + wcampo16 + "&taxa11=" + wcampo17 + "&taxa12=" + wcampo18
            }
        }
        console.log("KPO: ", kpo)
        gravaUmRegistro(kpo);
        setshowN(false);
        //setOpcao('')
    }

    function Buscar() {
        let t = numero_colunas
        let i = 1
        let str = ''
        while (i < t) {

            if (nome_colunas[i-1].campo === 'id') {
                if (document.getElementById(i).value !== '') {
                    if (str !== '') {  str = str + " AND " }
                    if (isNaN(document.getElementById(i).value)) {
                        str = str + "id >= 0"
                    } else {
                        str = str + "id >= " + document.getElementById(i).value
                    }
                }
            } else {
                if (document.getElementById(i).value !== '') {
                    if (str !== '') {  str = str + " AND " }
                    str = str + nome_colunas[i-1].campo + " like (" + document.getElementById(i).value + ") "
                }
            }
            i = parseInt(i) + parseInt('1')
        }
        setFiltro(str)
        console.log(str)
        setOpcao('X')
        return
    }

    function Limpar() {
        let t = numero_colunas
        let i = 1
        while (i < t) {
            document.getElementById(i).value = ''
            i = parseInt(i) + parseInt('1')
        }
        setFiltro('')
        setOpcao('X')
        return
    }

    function Classificar(indx) {
        console.log("Índice: ", indx)
        setChave(indx)
        if (sentido === 'ASC') { setSentido('DESC') } else { setSentido('ASC') }
        setOpcao('X')
    }

  return (
    <>

    <form className="cadastros">
        <Container>
            <div  style={{ width: "100%" }}>
                <table width="100%" border="1" style={{ backgroundColor: barra.corBarra, }}>
                    <tr>
                        <td>&nbsp;</td>
                        <td width="47%" align="left"><Titulo><span style={{color: barra.corLetraBarra, }}>{titulo_pagina}</span></Titulo></td>
                        <td width="50%" align="right">                
                            <button type="button" onClick={() => incluirRegistro()} className="botao-normal"  style={{}}>
                                <FaPlus /> incluir
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <div style={{width: "100%",}}>
            <Table>
                <thead>
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th width={cabec.tamanho} style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>
                                <input type="text" name={cabec.id} id={cabec.id} style={{width: "80%"}} /> 
                            </th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>
                            <button type="button"  className="botao-normal" style={{}}
                                onClick={() => Buscar()}>
                                    <FaFilter /> filtrar
                            </button>
                            
                            <button type="button"  className="botao-limpar" style={{marginLeft: "1Vw"}}
                                onClick={() => Limpar()}>
                                    <FaEraser /> limpar
                            </button>                            


                        </th>
                    </tr>                    
                    <tr>{Object.values(nome_colunas).map(cabec => (
                            <th style={{textAlign: "left", backgroundColor: barra.corHeade, color: barra.corLetpn, }} key={cabec.id}>
                                <button type="button"  style={{backgroundColor: "transparent", border: "0px", color: barra.corLetraFundo, textDecoration: cabec.campo === chave ? "underline" : "none" }    } onClick={() => Classificar(cabec.campo)}>
                                    {cabec.ident}{cabec.campo !== chave ? (<FaSort />) : (sentido === 'ASC' ? (<FaSortUp />) : (<FaSortDown />))}
                                </button>
                            </th>
                        ))
                        }
                        <th  style={{backgroundColor: barra.corHeade, color: barra.corLetpn, }}>Ações</th>
                    </tr>
                    <tr><td colSpan={numero_colunas}>&nbsp;</td></tr>

                </thead>
            </Table>
            </div>
            </Container>


            <Container>
            <div style={{width: "100%", height: "60Vh", overflow: "scrool"}}>
            <Table>
                <tbody>
                    {Object.values(data).map(registro => (
                        <tr key={registro.id}>
                            <td width="10%" align="center">{registro.id}</td>
                            <td width="20%" align="left">{registro.nome}</td>
                            <td width="10%" align="left">{registro.codigo}</td>
                            <td width="20%" align="left">{registro.operacao}</td>
                            <td width="20%" align="left">{registro.mdr}</td>
                            <td width="20%" align="left">{registro.rr}</td>
                            <td width="20%" align="left">{registro.dias}</td>
                            <td>

                            <button type="button" 
                                className="botao-normal" style={{}} 
                                onClick={() => editarRegistro(registro.id)}>
                                    <FaEdit /> editar
                                </button>
                                
                                <button type="button"  
                                className="botao-delete" style={{marginLeft: "1Vw"}} 
                                onClick={() => excluirRegistro(registro.id)}>
                                    <FaTrash /> excluir
                                </button>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </Table>
            </div>
        
        </Container>


        <hr />

        <Modal show={showN} onHide={handleClose} size="lg">
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title style={{color: barra.corLetraFundo}}>{titulo_modal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row'>
            <input className="row" type="hidden" name="id"  id="id"   value={state.id}  onChange={handleChange}  placeholder={places[0]}     
            style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
            </div>
            
           
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                    <input className="row" type="text"   name="nome"  id="nome"   value={state.nome}  onChange={handleChange}  placeholder={places[1]}     
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>
                
                    <div className='col-md-4'>
                    <input className="row" type="text"   name="codigo"  id="codigo"   value={state.codigo}  onChange={handleChange}  placeholder={places[2]}     
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>
                
                    <div className='col-md-4'>
                    <input className="row" type="text"   name="operacao"  id="operacao"   value={state.operacao}  onChange={handleChange}  placeholder={places[3]} 
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}} />
                    </div>
                </div>
            </div>
            <br />

            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                    <input className="row" type="number"   name="mdr"  id="mdr"   value={state.mdr}  onChange={handleChange}  placeholder={places[4]}  
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}} />
                    </div>

                    <div className='col-md-4'>
                    <input className="row" type="number" name="rr"  id="rr"  value={state.rr}  onChange={handleChange}  placeholder={places[5]}   
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>

                    <div className='col-md-4'>
                    <input className="row" type="number" name="dias"  id="dias"  value={state.mdr}  onChange={handleChange}  placeholder={places[6]}   
                    style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>
                </div>
            </div>

            <hr style={{color: "#202020", marginTop: "6px"}}/>
            <hr style={{color: "#202020", marginTop: "-12px"}}/>


            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>
                        <div className='col-md-3'>
                            <input className="row" type="number" name="taxa01"  id="taxa01"  value={state.taxa01}  onChange={handleChange}  placeholder={places[7]}   
                            style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                        </div>            
                        <div className='col-md-3    '>
                            <input className="row" type="number" name="taxa02"  id="taxa02"  value={state.taxa02}  onChange={handleChange}  placeholder={places[8]}   
                            style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                        </div>            
                    <div className='col-md-3'></div>                    
                </div>
            </div>

            <br />

            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa03"  id="taxa03"  value={state.taxa03}  onChange={handleChange}  placeholder={places[9]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa04"  id="taxa04"  value={state.taxa04}  onChange={handleChange}  placeholder={places[10]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'></div>
                </div>
            </div>

            <br />

            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa05"  id="taxa05"  value={state.taxa05}  onChange={handleChange}  placeholder={places[11]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa06"  id="taxa06"  value={state.taxa06}  onChange={handleChange}  placeholder={places[12]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>  
                    <div className='col-md-3'></div>
                </div>
            </div>

            <br />

            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>             
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa07"  id="taxa07"  value={state.taxa07}  onChange={handleChange}  placeholder={places[13]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa08"  id="taxa08"  value={state.taxa08}  onChange={handleChange}  placeholder={places[14]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'></div>
                </div>
            </div>

            <br />



            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>  
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa09"  id="taxa09"  value={state.taxa09}  onChange={handleChange}  placeholder={places[15]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa10"  id="taxa10"  value={state.taxa10}  onChange={handleChange}  placeholder={places[16]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>  
                    <div className='col-md-3'></div>
                </div>
            </div>

            <br />


            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'></div>                      
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa11"  id="taxa11"  value={state.taxa11}  onChange={handleChange}  placeholder={places[17]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'>
                        <input className="row" type="number" name="taxa12"  id="taxa12"  value={state.taxa12}  onChange={handleChange}  placeholder={places[18]}   
                        style={{borderRadius: "20px", width: "100%", textAlign: "center"}}/>
                    </div>            
                    <div className='col-md-3'></div>
                </div>
            </div>

            <br />

            <div className="col-md-12">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="50%" align='left' ><input type="submit"  className="botao-normal" style={{ width: "9Vw"}} value={titulo_botao} /></td>
                            <td width="50%" align='right'><button type="button" className="botao-delete" style={{ width: "9Vw"}} onClick={handleClose}>Cancelar</button></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>

        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </form>
      </Modal>       


    </form>
    </>

  )
  
}

export default CartaoV3