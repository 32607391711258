import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const Home = () => {

  const barra = useContext(AuthContext);

  return (

    <>


    <div className="form" style={{position: "fixed", top:"2%", left:"75%", zIndex:"999"}}>
      <span style={{color: "white"}}>Usuário: {barra.nusuario} (Cod.: {barra.cusuario}) Empresa: {barra.cempresa}</span>
    </div>  

    <form className="form-control">
      <center>
        <div className="form-group" style={{ position: "fixed", left: "35%", top: "30%", fontSize: "30px", color: "#FF0000"}}>
          Usuário sem permissão para essa rotina. <br /><br />Por favor, verifique com o Administrador do sistema. <br /><br /><br />Grato
        </div>
      </center>
    </form>

    </>          

  )
}

export default Home