//import { parseDragMeta } from '@fullcalendar/core';
import {useState} from 'react';
import Calendar from 'react-calendar';
import '../../css/Calendar.css';
import Time from '../../components/Time'

function Calendario() {

  // Array to store month string values
  //const allMonthValues = [
  //  "Janeiro",
  //  "Fevereiro",
  //  "Março",
  //  "Abril",
  //  "Maio",
  //  "Junho",
  //  "Julho",
  //  "Agosto",
  //  "Setembro",
  //  "Outubro",
  //  "Novembro",
  //  "Dezembrio"
  //];    

// State for date selected by user
//const [selectedDate, setSelectedDate] = useState();

// State for text above calander
//const [calendarText, setCalendarText] = useState(`No Date is selected`);

 
const [date, setDate] = useState(new Date());
const [showTime, setShowTime] = useState(false) 

const mudou = (e) => {
  setDate(e.activeStartDate)
  setShowTime(true)
}


 return (
 <div className='row' style={{ marginTop: "30px", }}>
    <p></p>
    <div className='calendar-container'>
        <Calendar onChange={setDate} value={date} 
                  onClickDay={() => setShowTime(true)} 
                  onViewChange={mudou}  
                  onActiveStartDateChange={mudou}
                  />
    </div>
 
   <Time showTime={showTime} date={date} />

 </div>
  )
}

export default Calendario;