import React from 'react'
import { useState, useEffect  } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import '../css/Calendar.css';
//import ContasPagar from '../page/financeiro/ContasPagar';
import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"


function Times(props) {

const barra = useContext(AuthContext);

const navigate = useNavigate();
if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
    barra.setSidebar(false);
    localStorage.clear("token")
    localStorage.setItem("encerrar", JSON.stringify("sim"));
    barra.avisar('Sessão expirou. Por favor, faça um novo login.')
    navigate("/");
}

const [color, setColor] = useState('')
const titulo_botao = "SALVAR"
const [loading, setLoading] = useState(false)
const [evento, setEvento] = useState([])
//const [pagamento, setPagamento] = useState([])

const [primeira, setPrimeira] = useState(true)

const [state, setState] = useState({
    id: "",
    nome: "",
    local: "",
    inicio: "",
    hora: "",
    fim: "",
    horf: "",
    envolvidos: ""
})

 const [showN, setshowN] = useState(false);

 const handleClose = () => {
    setState([])
    setshowN(false);
}

useEffect(() => {
    setEvento([])
    //console.log("Parâmetros TIME: ", loading)
    setPrimeira(true)
    navigate("/calendario");
}, [loading, navigate, props.date]);  

const options = [
    {
    label: "Seleciona a cor",
    value: "000000",
    },
    {
    label: "Amarelo",
    value: "FFFF00",
    },
    {
    label: "Azul",
    value: "3366cc",
    },
    {
    label: "Cinza",
    value: "777777",
    },
    {
    label: "Laranja",
    value: "FF8000",
    },
    {
    label: "Roxo",
    value: "A909F9",
    },
    {
    label: "Verde",
    value: "278747",
    },
    {
    label: "Vermelho",
    value: "FF0000",
    },
  ];    


const leRegistros = async (tipo, base) => {

    if (tipo === 'Mês')     {  tipo = 'Mes'     }
    if (tipo === 'Década')  {  tipo = 'Decada'  }

    console.log("BUSCAR REG:", tipo, " e ", base)

    let kpo = "tp=" + tipo + "&pr=" + base + "&ep=" + barra.cempresa + "&us=" + barra.cusuario

    axios.get(`https://www.bea.inf.br/apicrud/list_eventos.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (todos): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              console.log("EVT: ", res.data.userlist.userdata);
              //console.log("PGT: ", res.data.pagalist.userdata);

              setLoading(true)
              setEvento(res.data.userlist.userdata);
              //let sorted = evento.sort((a, b) => new Date(...a.data.split('/').reverse()) - new Date(...b.data.split('/').reverse()));
              //console.log('SORt: ', sorted)
              //setEvento(sorted)
              //evento.push(res.data.pagalist.userdata);

        } else {
            setEvento([])
              let motivo    = res.data.motivo;
              barra.avisar(motivo, 3)
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

}

 //const [event, setEvent] = useState(null)
 //const [info, setInfo] = useState(false)

 function handleChange(evt) {
    const value = evt.target.value;
    setState({...state, [evt.target.id]: value  });
}

function excluirRegistro(yw) {
    if ((yw !== undefined) && (yw !== '')) {
        console.log("EXCLUIR: ",yw)
        axios.get(`https://www.bea.inf.br/apicrud/gravar_evento.php?del=${yw}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (delete): ", sucesso, " - ", yw);

            setLoading(sucesso)
            setshowN(false);

            if (sucesso) {
                console.log("EVT: ", res.data.userlist.userdata);
                barra.avisar("Evento excluído!")
                setState([])
            } else {
                console.log("Não encontrado (DEL)")

                let motivo    = res.data.motivo;
                console.log("NÃO SUCESSO: ", motivo);
            }
        })        
    }

}

function handleSubmit(evt) {
    evt.preventDefault();
    console.log(evt)

    if (evt.nativeEvent.submitter.value ==="DELETAR") {
        excluirRegistro(state.id)
    } else {

        setshowN(false);
        let erroMens = '';
        let yw = 0;
        if (state.id > 0)       { yw = state.id }
        if (state.nome === undefined)     {  erroMens += "NOME do evento"   }
        if (state.inicio === undefined)   {  if (erroMens !== '') { erroMens += ", "  }   erroMens += "DATA do evento"   } 
        
        if (erroMens !== '') { barra.avisar(erroMens, 5) } else {
            let kpo = "yw=" + yw + "&nm=" + state.nome + "&di=" + state.inicio + "&hi="
            if (state.hora === undefined)         {  kpo += ''  } else {  kpo += state.hora  }
            if (state.fim  === undefined)         {  kpo += '&df='  } else {  kpo += "&df=" + state.inicio   }
            if (state.horf === undefined)         {  kpo += '&hf='  } else {  kpo += "&hf=" + state.hora   }
            if (state.local === undefined)        {  kpo += '&lc='  } else {  kpo += "&lc=" + state.local  }
            if (state.envolvidos === undefined)   {  kpo += '&ob='  } else {  kpo += "&ob=" + state.envolvidos  }
            if (state.cor === undefined)          {  kpo += '&cl=000000'  }  else {  kpo += "&cl=" + state.cor   }

            kpo += "&ep=" + barra.cempresa + "&us=" + barra.cusuario

            console.log("KPO: ", kpo)

            axios.get(`https://www.bea.inf.br/apicrud/gravar_evento.php?${kpo}`).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO (todos): ", sucesso);
                console.log(res)
    
                setLoading(sucesso)
    
                if (sucesso) {
                    console.log("EVT: ", res.data.userlist.userdata);
                    barra.avisar("Evento incluído/atualizado <br/> com sucesso!", 3)
                    setState([])
                    //console.log("PGT: ", res.data.pagalist.userdata);
    
                    //setEvento(res.data.userlist.userdata);
                    //let sorted = evento.sort((a, b) => new Date(...a.data.split('/').reverse()) - new Date(...b.data.split('/').reverse()));
                    //console.log('SORt: ', sorted)
                    //setEvento(sorted)
                    //evento.push(res.data.pagalist.userdata);
    
                } else {
                    console.log("Não encontrado (DU)")
    
                    let motivo    = res.data.motivo;
                    console.log("NÃO SUCESSO: ", motivo);
                }
            })        
        }
    }
}

function novoRegistro(ident) {
    console.log("NOVO REGISTRO: ")
    // leUmRegistro(nome_tabela, ident)
    setshowN(true)
    return
}

const handleViewChange = (value) => {
    setEvento([])
    console.log("Mudou visão", value.target.innerText, " => ", `${props.date.toLocaleDateString()}`)
    leRegistros(value.target.innerText, `${props.date.toLocaleDateString()}`)
    setLoading(true)
}


function checkValueP(e) {
    console.log("checkbox selecionado " + e.target.name)
    let partes = e.target.name.split('_')
    let tp = partes[1]
    let yw = partes[2]
    let dt = partes[3]
    console.log("PARTES:", tp, ":", yw, "=", dt)

    let datas = dt.split('-')
    let dia = datas[0]
    let mes = datas[1]
    let ano = datas[2]
    dt = ano + '-' + mes + '-' + dia

    localStorage.setItem("DATAPGT", dt)
    navigate('/contas-pagar')

    //if (tp === 'P1') {
       // leUmTitulo(yw)
    //} else {
        //leDivTitulos(dt)
    //}
}

function checkValue(e) {

    console.log("checkbox selecionado " + e.target.name)
    let partes = e.target.name.split('_')
    let tp = partes[1]
    let yw = partes[2]
    let dt = partes[3]
    console.log("PARTES:", tp, ":", yw, "=", dt)

    if (tp === 'E') {
        //leUmEvento(yw)
        axios.get(`https://www.bea.inf.br/apicrud/le_evento.php?yw=${yw}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("SUCESSO (todos): ", sucesso);
    
            setLoading(sucesso)
    
            if (sucesso) {
                  console.log("EVT: ", res.data.datalist.userdata);
                  //console.log("PGT: ", res.data.pagalist.userdata);
    
                  //setLoading(true)
                  //setEvento(res.data.userlist.userdata);
                  
                  setState({
                    id:      res.data.datalist.userdata[0].id,
                    nome:    res.data.datalist.userdata[0].nome, 
                    local: res.data.datalist.userdata[0].local, 
                    inicio:   res.data.datalist.userdata[0].data,
                    fim:  res.data.datalist.userdata[0].fim,
                    hora: res.data.datalist.userdata[0].hora,
                    horf: res.data.datalist.userdata[0].horf,
                    envolvidos:  res.data.datalist.userdata[0].envolvidos,
                    cor: res.data.datalist.userdata[0].cor.replace('#','')
                    })

                    setColor(res.data.datalist.userdata[0].cor.replace('#',''))
                    setshowN(true)
    
            } else {
                  console.log("Não encontrado (DU)")
    
                  let motivo    = res.data.motivo;
                  console.log("NÃO SUCESSO: ", motivo);
            }
        }) 

    }


  }

  if (primeira) {
    leRegistros("Mês", `${props.date.toLocaleDateString()}`)
    setPrimeira(false)
  }
  

return (
 
  

 <div className="times" style={{marginTop: "0px"}}>


    <div className="form-control" style={{ overflow: "auto", textAlign: "left", backgroundColor: barra.corBarra, fontWeight: "200", color: barra.corLetraBarra, fontSize: "120%", marginLeft:"1Vw", width:"60Vw", borderRadius:"5px"}}>
        <span style={{fontSize: "120%"}}>{`Dia: ${props.date.toLocaleDateString()}`}</span>
        <span style={{marginLeft:"3Vw", marginRight:"1Vw", color: barra.corLetraBarra, fontSize: "120%"}}>Visão por:</span>
        <button type="button" onClick={handleViewChange} className="botao-normal" style={{width: "6em"}}>Dia</button> 
        <button type="button" onClick={handleViewChange} className="botao-normal" style={{width: "6em"}}>Mês</button>
        <button type="button" onClick={handleViewChange} className="botao-normal" style={{width: "6em"}}>Ano</button>
        <button type="button" onClick={handleViewChange} className="botao-normal" style={{width: "6em"}}>Década</button>
        <button type="button" onClick={novoRegistro}     className="botao-normal" style={{width: "12em"}}>(+) Compromisso </button>
    </div>
    <table className="table table-bordered" style={{marginLeft:"1Vw", width:"60Vw", borderStyle:"solid", borderWidth:"1px", borderColor:"#352727",  borderRadius: "10px"}}>
        <thead>
            <tr>
                <th colSpan="3" align="center"></th>
            </tr>
            <tr>
                <th style={{backgroundColor: barra.corLetraBarra, color: "black", fontSize: "140%", textDecoration: "underline", fontWeight: "700"}}>COMPROMISSOS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <table className="table table-hover" style={{color: "black", fontWeight: "400", }}>
                        <thead>
                            <tr height="5px">
                                <th>Data</th>
                                <th>Nome</th>
                                <th>Referência</th>
                                <th>Observações</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {evento.map( (prod) =>
                                
                                <tr  height="5px" key={prod.yw} style={{color: prod.cor, fontWeight: "500"}}>
                                    <td align="center">{prod.data}</td>
                                    <td align="left">{prod.nome}</td>
                                    <td align="left"  >{prod.local}</td>
                                    <td align="left">{prod.envolvidos}</td>
                                    { prod.tipo === 'E' ?
                                        <td align="left">
                                            <Button onClick={checkValue} className="btn" 
                                            style={{backgroundColor: barra.corBotao, color: barra.corLetraBotao, borderColor: barra.corBordadBotao, padding: '5px', }} 
                                            name={`selec_${prod.tipo}_${prod.id}_${prod.data}`} >
                                            Ver Evento
                                            </Button>
                                        </td>
                                    :
                                        <td align="left">
                                            <Button onClick={checkValueP} className="btn" 
                                            style={{backgroundColor: barra.corBotaoE, color: barra.corLetraBotaoE, borderColor: barra.corBordaBotaoE, padding: '5px', }} 
                                            name={`selec_${prod.tipo}_${prod.id}_${prod.data}`} >
                                            Pagamento
                                            </Button>
                                        </td>
                                    }
                                    </tr>
                            )}
                        </tbody>
                    </table>
                </td>

            </tr>


    
        </tbody>
    </table>

    <div>
      {/* {info ? `Selecionado o horário ${event} ${props.date.toDateString()}` : null} */}
    </div>


    <Modal show={showN} onHide={handleClose}>
        <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='form-control'>
            <input className="row" type="hidden" name="id"    id="id"    value={state.id}    onChange={handleChange}  placeholder='id' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="nome"    id="nome"    value={state.nome}    onChange={handleChange}  placeholder='informe o nome do Evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="local" id="local" value={state.local} onChange={handleChange}  placeholder='informe o local do evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="date" name="inicio"   id="inicio"   value={state.inicio}   onChange={handleChange}  placeholder='informe a data do evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="time" name="hora"   id="hora"   value={state.hora}   onChange={handleChange}  placeholder='informe a hora do evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="date" name="fim"   id="fim"   value={state.fim}   onChange={handleChange}  placeholder='informe a data final do evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="time" name="horf"   id="horf"   value={state.horf}   onChange={handleChange}  placeholder='informe a hora final do evento' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className='form-control'>
            <input className="row" type="text" name="envolvidos"  id="envolvidos"  value={state.envolvidos}  onChange={handleChange}  placeholder='Observações sobre evento, envolvidos etc  ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
            </div>
            <div className="form-control">
                <center>
                    <select name="cor"  id="cor" value={state.color}  onChange={handleChange}>
                        {options.map((option) => (
                        <option value={option.value} key={option.value} style={{color:`${option.value}`}}>{option.label}</option>
                        ))}
                    </select>
                </center>
            </div>  

            { state.id === undefined || state.id === '' ?
                <div className="form-control">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="50%" align='left' ><input type="submit"  name="salvar" className="btn" 
                                style={{padding: "5px", borderRadius: '5px', backgroundColor: barra.corBotao,  color: barra.corLetraBotao,  border: "1px solid", borderColor: barra.corBordaBotao  }} value={titulo_botao} /></td>
                                <td width="50%" align='right'><button type="button" className="btn"  
                                style={{padding: "5px", borderRadius: '5px', backgroundColor: barra.corBotaoW, color: barra.corLetraBotaoW, border: "1px solid", borderColor: barra.corBordaBotaoW,}} onClick={handleClose}>CANCELAR</button></td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            :
                <div className="form-control">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="33%" align='left'>  
                                    <input type="submit"  name="deletar" className="btn"  
                                    style={{padding: "5px",borderRadius: '5px', backgroundColor: barra.corBotaoE, color: barra.corLetraBotaoE, borderColor: barra.corBordaBotaoE, }} value="DELETAR" />
                                </td>
                                <td width="34%" align='center'>
                                    <input type="submit"  name="atualiz" className="btn" 
                                    style={{padding: "5px",borderRadius: '5px', backgroundColor: barra.corBotao, color: barra.corLetraBotao, borderColor: barra.corBordaBotao,  }} value="ATUALIZAR" />
                                </td>
                                <td width="33%" align='right'> 
                                    <button type="button" className="btn" 
                                    style={{padding: "5px",borderRadius: '5px', backgroundColor: barra.corBotaoW, color: barra.corLetraBotaoW, borderColor: barra.corBordaBotaoW,   }} onClick={handleClose}>CANCELAR</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
                
                

        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </form>
      </Modal>   



 </div>
  )
}

export default Times;