import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Titulo } from '../styles';


const ContasPagarLcto = () => {

    const barra = useContext(AuthContext);

    
    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }
    
    const grupos = [
        {
          label: "COMPRA DE PRODUTOS",
          value: "2",
        },
        {
          label: "IMPOSTOS",
          value: "3",
        },
        {
          label: "DESPESAS COM PESSOAL",
          value: "4",
        },
        {
          label: "DESPESAS OPERACIONAIS",
          value: "5",
        },
        {
          label: "DESPESAS ADMINISTRATIVAS",
          value: "6",
        },
    ];  
      
    const [linhas, setLinhas] = useState([])

    /*
    const contas = [
        { grupo: "1", label: "Selecionar",  value: "1", },
        { grupo: "2", label: "Produtos principais", value: "7", },
        { grupo: "2", label: "Produtos auxiliares", value: "8", },
        { grupo: "2", label: "Produtos de apoio", value: "9", },
        { grupo: "2", label: "Outros produtos", value: "10", },
        { grupo: "3", label: "Simples / IVA", value: "11", },
        { grupo: "3", label: "ICMS", value: "12", },
        { grupo: "3", label: "IPI", value: "13", },
        { grupo: "3", label: "Outros Impostos", value: "14", },
        { grupo: "3", label: "Alvarás e PAtentes", value: "15", },
        { grupo: "3", label: "SUbstituição tributária", value: "16", },
        { grupo: "4", label: "Salários", value: "17", },
        { grupo: "4", label: "Gratificações", value: "18", },
        { grupo: "4", label: "FGTS", value: "19", },
        { grupo: "4", label: "Vale transporte", value: "20", },
        { grupo: "4", label: "Vale Alimentação", value: "21", },
        { grupo: "4", label: "Assistência Médica", value: "22", },
        { grupo: "4", label: "Seguro de vida", value: "23", },
        { grupo: "4", label: "INSS", value: "24", },
        { grupo: "4", label: "Férias", value: "25", },
        { grupo: "4", label: "Horas extras", value: "26", },
        { grupo: "4", label: "Recisões trabalhistas", value: "27", },
        { grupo: "4", label: "Outros", value: "28", },
        { grupo: "5", label: "Aluguel", value: "29", },
        { grupo: "5", label: "Condomínio", value: "30", },
        { grupo: "5", label: "IPTU", value: "31", },
        { grupo: "5", label: "Fundo de Promoção", value: "32", },
        { grupo: "5", label: "Energia elétrica", value: "33", },
        { grupo: "5", label: "Água e esgoto", value: "34", },
        { grupo: "5", label: "Gás", value: "35", },
        { grupo: "5", label: "Ar Condicionado", value: "36", },
        { grupo: "5", label: "Telefones", value: "37", },
        { grupo: "5", label: "Outros", value: "38", },
        { grupo: "6", label: "Honorários contábeis", value: "39", },
        { grupo: "6", label: "Limpeza e Hgiene", value: "40", },
        { grupo: "6", label: "Dedetização", value: "41", },
        { grupo: "6", label: "Informática", value: "42", },
        { grupo: "6", label: "Material de Escritório", value: "43", },
        { grupo: "6", label: "Manutenção e reposição", value: "44", },
        { grupo: "6", label: "Uniformes", value: "45", },
        { grupo: "6", label: "Propaganda e Publicidade", value: "46", },
        { grupo: "6", label: "Frete para entregas", value: "47", },
        { grupo: "6", label: "Taxas administrativas", value: "48", },
        { grupo: "6", label: "Despesas financeiras", value: "49", },
        { grupo: "6", label: "Royalties", value: "50", },
    ]
    */


    const nome_tabela = "ccs_fornecedor"
    const [primeira, setPrimeira] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loja,  setLoja] = useState('')
    const [grupo, setGrupo] = useState('')
    const [conta, setConta] = useState('')
    const [fornecedores, setFornecedores]=useState([]);
    const [fornecedor, setFornecedor]=useState('');
    const [state, setState] = useState({
        id: "",
        nome: "",
        endereco: "",
        email: "",
        telefone: "",
        cnpj: ""
      })
    const [showN, setshowN] = useState(false)
    //const [data, setData] = useState([]);
    const [emissao, setEmissao] = useState('')
    const [vencimento, setVencimento] = useState('')
    const [valorP, setValorP] = useState(0)
    const [valorF, setValorF] = useState(0)
    const [valorD, setValorD] = useState(0)
    const [valorT, setValorT] = useState(0)
    const [observacao, setObservacao] = useState('')
    const [lojas, setLojas] = useState([])

        
    useEffect(() => {
        let saldo = 0
        if (valorP === '' || isNaN(valorP) ) { setValorP(0) }
        if (valorD === '' || isNaN(valorD) ) { setValorD(0) }
        if (valorF === '' || isNaN(valorF) ) { setValorF(0) }
        saldo = parseFloat(valorP) + parseFloat(valorF) - parseFloat(valorD)
        setValorT(saldo)
        setLoading(false)
        navigate("/contas-pagar-lcto");
}, [loading, navigate, valorP, valorD, valorF]);  

const buscarLinhas = async (grp) => {
    try {

       console.log("GRUPO:", grp)

        axios.get(`${barra.urlbase}/apicrud/buscarlinhas.php?g=${grp}&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setLinhas(res.data.userlist.userdata);
                    setLoading(true);
            } else {
                    let motivo    = res.data.motivo;
                    setLinhas([])
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  


const buscarLojas = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarlojas.php?e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
            console.log("LOJAS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setLojas(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Lojas Não encontradas")
                    let motivo    = res.data.motivo;
                    setLojas([]);
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  

const leRegistros = async (nome_tabela) => {

    console.log("tabela: ", nome_tabela)
    axios.get(`${barra.urlbase}/apicrud/ler_arquivo.php?r=0&t=${nome_tabela}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (todos): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              console.log(res.data.datalist.userdata);
              setFornecedores(res.data.datalist.userdata);


        } else {
              console.log("Não encontrado (DU)")

              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })                

}

function incluirRegistro() {
    console.log("INCLUIR REGISTRO")

    setState({
        id: "",
        nome: "",
        endereco: "",
        telefone: "",
        email: "",
        cnpj: ""
      })

    setshowN(true)
    return
}

const gravaUmRegistro = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_arquivo.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (UPD): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              leRegistros(nome_tabela)
        } else {
              console.log("Não encontrado (UPD)")

              let motivo    = res.data.retorno;
              console.log("NÃO SUCESSO: ", motivo);
        }
    })
     

}    

const gravaTitulo = async (kpo) => {

    console.log("tabela: ", kpo)
    axios.get(`${barra.urlbase}/apicrud/gravar_ctpagar.php?${kpo}`).then(function(res) {
        let sucesso    = res.data.success;
        console.log("SUCESSO (CTP): ", sucesso);

        setLoading(sucesso)

        if (sucesso) {
              //setData(res.data.retorno);
              console.log("retorno: ", res.data.retorno)
              barra.avisar(res.data.retorno, 2)
              handleCancel()
        } else {
              let motivo    = res.data.motivo;
              console.log("NÃO SUCESSO: ", motivo);
              barra.avisar(motivo, 5)
        }
    })
     

} 

const handleClose = () => {
    setLoading(false)
    setshowN(false);
}

function handleSubmit(evt) {
    evt.preventDefault();
    let erro = '';
    if (state.nome === undefined || state.nome === '') { erro = 's'  }
    if (state.email === undefined || state.email === '') { erro = 's'  }

    if (erro === 's') {
        barra.avisar("Campos NOME e EMAIL devem ser preenchidos")
        return
    }

    let wcampo1 = state.nome.replaceAll("&", "e")
    let wcampo4 = state.email.replaceAll("&", "e")
    let wcampo2 = ''
    let wcampo3 = ''
    if (state.endereco !== undefined && state.endereco !== '') { wcampo2 = state.endereco.replaceAll("&", "e") }
    if (state.telefone !== undefined && state.telefone !== '') { wcampo3 = state.telefone.replaceAll("&", "e") }
    let wcampo5 = state.cnpj

    console.log("SUBMIT", )
    let kpo = '';
    kpo = 't=' + nome_tabela + '&o=I&par=0;nome=' + wcampo1 + ';endereco=' + wcampo2 + ";telefone=" + wcampo3 + ";empresa=" + barra.cempresa + ";email=" +  wcampo4 + ";cnpj=" + wcampo5
    gravaUmRegistro(kpo);
    setshowN(false);
}


function handleChange(evt) {
    const value = evt.target.value;
    setState({...state, [evt.target.id]: value  });
}


if (primeira) {
    leRegistros(nome_tabela)
    buscarLojas()
    setPrimeira(false)
}


function handleLcto(e) {
    e.preventDefault();
    let errog = '';
    let errof = '';
    let errov = '';
    let errol = '';
    if (loja       === undefined || loja       === '') { errol = 'l' }
    if (grupo      === undefined || grupo      === '') { errog = 'g' }
    if (conta      === undefined || conta      === '') { errog = 'g' }
    //if (fornecedor === undefined || fornecedor === '') { errof = 'f' }
    if (valorP === 0)  { errov = 'v' }

    let mens = '';
    if (errol !== '') {  mens = mens + "<br />A LOJA deve ser indicada."   }
    if (errog !== '') {  mens = mens + "<br />O GRUPO e a CONTA são campos obrigatórios."   }
   // if (errof !== '') {  mens = mens + "<br />O FORNECEDOR deve ser informado."   }
    if (errov !== '') {  mens = mens + "<br />O VALOR DOS PRODUTOS deve ser preenchido"   }

    if (errog !== '' || errof !== '' || errov !== '' || errol !== '') {
        barra.avisar(mens, 5)
        return
    }

    let hoje = '';
    let vcto = '';
    if (emissao === undefined || emissao === '') {
        var dataw = new Date();
        var diaw = String(dataw.getDate()).padStart(2, '0');
        var mesw = String(dataw.getMonth() + 1).padStart(2, '0');
        var anow = dataw.getFullYear();
        hoje = anow + '-' + mesw + '-' + diaw;
    } else {
        hoje = emissao
    }
    
    if (vencimento === undefined || vencimento === '') {
        var datax = new Date();
        var diax = String(datax.getDate()).padStart(2, '0');
        var mesx = String(datax.getMonth() + 1).padStart(2, '0');
        var anox = datax.getFullYear();
        vcto = anox + '-' + mesx + '-' + diax;
    } else {
        vcto = vencimento
    }

    let saldo = 0;
    saldo = parseFloat(valorP) + parseFloat(valorF) - parseFloat(valorD)
    setValorT(saldo)

    console.log("LCTO: ", grupo, conta, fornecedor, hoje, vcto, valorP, valorF, valorD, valorT, observacao)
    let kpo = "e=" + barra.cempresa + "&g=" + grupo + "&c=" + conta + "&f=" + fornecedor + "&d=" + hoje + "&v=" + vcto + "&vp=" + valorP +  "&vf=" + valorF +  "&vd=" + valorD +  "&vt=" + saldo + "&o=" + observacao + "&l=" + loja
    gravaTitulo(kpo);
    setshowN(false);     
}

function handleCancel() {
    setGrupo('')
    setConta('')
    setFornecedor('')
    setEmissao('')
    setVencimento('')
    setValorP(0)
    setValorD(0)
    setValorF(0)
    setValorT(0)
    setObservacao('')
}

function handleGroup(e) {
    setGrupo(e.target.value)
    buscarLinhas(e.target.value)
}

  return (
    
    <>
    <form className="Container" onSubmit={handleLcto}>
    <Container>
        <ConteudoTitulo>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left", color: barra.corLetraBarra, }}>
                    <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" align="left" valign='top'>
                                            <Titulo><h4 style={{color: barra.corLetraBarra}}>CONTAS A PAGAR - LANÇAMENTOS</h4></Titulo>
                                        </td>
                                        <td width="50%" align="right">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
        </ConteudoTitulo>

    
        <center>
        <p></p>
        <div className="Container" style={{width: "80%", border:"1px solid #000"}} align="center"> 
                <div className="row">
                <p></p>
                    <div className="col-md-6" >
                        <select className="form-control" name="loja" value={loja}  onChange={l => setLoja(l.target.value)}
                            style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                            <option value="">Selecione a loja </option>
                            {lojas.map((option, index) => {
                            return <option value={option} key={index} >{option}</option>
                            })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-8">
                                <select className="form-control" name="fornecedor" value={fornecedor} onChange={f => setFornecedor(f.target.value)}>
                                    <option value="">Selecione o Fornecedor </option>
                                    {fornecedores.map((fndr) => {
                                    return <option value={fndr.id} >
                                    {fndr.nome}
                                    </option>
                                    })}
                                </select>
                            </div>
           
                            <div className="col-md-4">
                                <Button className="btn" style={{backgroundColor: barra.corBotao, color: barra.corLetraBotao, padding: "7px", borderRadius: "20px"}} onClick={() => incluirRegistro()}>
                                    Incluir Novo Fornecedor
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                                
                <div className="row">
                    <div className="col-md-6">
                        <select className="form-control" name="grupo" value={grupo} onChange={handleGroup}>
                            <option value="">Selecione o Grupo de Despesa </option>
                            {grupos.map((grp) => {
                            return <option value={grp.value} >
                            {grp.label}
                            </option>
                            })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <select className="form-control" name="conta" value={conta} onChange={texto => setConta(texto.target.value)}>
                                        <option value="">Selecione a conta específica</option>
                                            {linhas.map((item) => {
                                                return <option value={item.index} key={item.index} >
                                                {item.label}
                                        </option>
                                        })}
                        </select>                    
                    </div>
                </div>

                <p></p>                                        


                <div className="form-control" style={{backgroundColor: barra.corTela, }}>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-2" align="right"><label style={{fontSize: "110%", fontWeight: "700" }}>Data de Emissão</label></div>
                        <div className="col-md-1" align="left"><input type="date" name="emissao" value={emissao} onChange={e => setEmissao(e.target.value)} /></div>
                        
                        <div className="col-md-2" align="right"><label style={{fontSize: "110%", fontWeight: "700" }}>Data do Vencimento</label></div>
                        <div className="col-md-1" align="left"><input type="date" name="vencimento" value={vencimento} onChange={v => setVencimento(v.target.value)} /></div>
                        <div className="col-md-3"></div>
                    </div>
                    
                    <p></p>
                    <p></p>
            
                    <div  className="row">
                        <div className="col-md-6" align="right"><label style={{fontSize: "110%", fontWeight: "700" }}>Valor dos Produtos</label></div>
                        <div className="col-md-6" align="left"><input type="number" name="valorP" value={valorP} onChange={vp => setValorP(vp.target.value)} style={{textAlign: "right" }} /></div>
                        <p></p>
                        <div className="col-md-6" align="right"><label style={{fontSize: "110%", fontWeight: "700" }}>(+) Valor do Frete</label></div>
                        <div className="col-md-6" align="left" ><input type="number" name="valorF" value={valorF} onChange={vf => setValorF(vf.target.value)} style={{textAlign: "right" }}  /></div>
                        <p></p>
                        <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>(-) Valor do Desconto</label></div>
                        <div className="col-md-6" align="left" ><input type="number" name="valorD" value={valorD} onChange={vd => setValorD(vd.target.value)} style={{textAlign: "right" }}  /></div>
                        <p></p>
                        <div className="col-md-6" align="right"><label style={{fontSize: "14px", fontWeight: "700" }}>Cálculo do Valor do Total</label></div>
                        <div className="col-md-6" align="left" ><input type="number" name="valorT" value={valorT} readOnly onChange={vt => setValorT(vt.target.value)} style={{textAlign: "right", backgroundColor: "#eaeaea" }}  /></div>
                    </div>

                    <div className="row">
                        <p></p>
                        <p></p>
                    </div>

                    <div className="form-control" style={{width: "50%"}}>
                        <div className="row" align="left">
                            <label style={{fontSize: "14px", fontWeight: "700" }}>Observações</label>
                            <input type="tex" name="observacao" value={observacao} onChange={o => setObservacao(o.target.value)} style={{ backgroundColor: "#eaeaea" }}  />
                        </div>
                    </div>
                </div>
        </div>
        </center>

        <center>
        <div className="form-control"  style={{width: "50%"}}>
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="50%" align='left' >
                            <input type="submit" className="btn-primary" 
                            style={{paddingLeft:"7px", paddingRight:"7px", backgroundColor: barra.corBotao, borderRadius:"20px", }} value="SALVAR LANÇAMENTO" />
                        </td>
                        <td width="50%" align='right'>
                            <button type="button" className="btn-danger" 
                            style={{paddingLeft:"7px", paddingRight:"7px", borderRadius:"20px", }}onClick={handleCancel}>CANCELAR</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </center>
    </Container>
    </form>

    
    <hr />

<Modal show={showN} onHide={handleClose}>
<form onSubmit={handleSubmit}>
<Modal.Header closeButton>
  <Modal.Title>Novo fornecedor</Modal.Title>
</Modal.Header>
<Modal.Body>
    <div className='form-control'>
    <input className="row" type="hidden" name="id"    id="id"    value={state.id}    onChange={handleChange}  placeholder='informe o id da empresa' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="nome"    id="nome"    value={state.nome}    onChange={handleChange}  placeholder='informe o nome da empresa' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <textarea className="row" name="endereco" id="endereco" rows="2" value={state.endereco} onChange={handleChange}  placeholder='informe o Endereço' style={{borderRadius: "5px", width: "100%", textAlign: "center"}} />
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="telefone"   id="telefone"   value={state.telefone}   onChange={handleChange}  placeholder='digite seu Telefone  ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="email"  id="email"  value={state.email}  onChange={handleChange}  placeholder='Informe o e-mail preferido' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>
    <div className='form-control'>
    <input className="row" type="text" name="cnpj"  id="cnpj"  value={state.cnpj}  onChange={handleChange}  placeholder='Informe o CNPJ' style={{borderRadius: "5px", width: "100%", textAlign: "center"}}/>
    </div>            
    <div className="form-control">
        <table width="100%">
            <tbody>
                <tr>
                    <td width="50%" align='left' ><input type="submit" className="btn-primary" value="SALVAR" /></td>
                    <td width="50%" align='right'><button type="button" className="btn-danger" onClick={handleClose}>Cancelar</button></td>
                </tr>
            </tbody>
        </table>
        
        
    </div>

</Modal.Body>
<Modal.Footer>
    <hr></hr>
</Modal.Footer>
</form>
</Modal>  

    </>
  )
}

export default ContasPagarLcto