import { useState, useEffect } from 'react';
import { getPontos } from './Ler_dados';
//import Pontogrf from './Dados_grafico';
//import ChartBar from './ChartBar';
import ChartDonut from "./ChartDonut";
import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"

function Donut1( grf) {

    const barra = useContext(AuthContext)

    const [data, setData] = useState([]);

  useEffect(() => {
    //console.log("vePontos:", tit)
    getPontos(barra.dataInicial, barra.dataFinal, barra.cempresa, grf, "DONUT", barra.loja)
      .then(data => setData(data))
      .catch(err => alert(err.response ? err.response.data : err.message))
  }, [barra.dataInicial,barra.dataFinal, barra.loja,barra.cempresa, grf])

  /*
  function onDiChange(event) {
    barra.setDataInicial(event.target.value);
  }

  function onDfChange(event) {
    barra.setDataFinal(event.target.value);
  }
  */

  return (
    <>
      {/*
      <input type="date" onChange={onDiChange} value={di} />
      <input type="date" onChange={onDfChange} value={df} />
      <ChartBar data={data} /> 
      */}
      <ChartDonut data={data} />
    </>
  );
}

export default Donut1;