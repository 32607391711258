import React from 'react'
import { useState, useEffect } from "react";
import { Container, ConteudoTitulo, Titulo } from '../styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const Campanhas = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }
    
    
    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [dados, setDados] = useState([])
    const [recebidos, setRecebidos] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [primeira, setPrimeira] = useState(true)

    useEffect(() => {
        console.log("Parâmetros: ", loading)
        setLoading(false)
        setInputs([])
        navigate("/campanha");
}, [loading, navigate]);  

const lerCampanhas = async (xave) => {
    try {
          axios.get(`${barra.urlbase}/apicrud/lercampanhas.php?ep=${barra.cempresa}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("CAMPANHAS: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setDados(res.data.userlist.userdata);
                    setLoading(true);

              } else {
                    let motivo    = res.data.motivo;
                    setLoading(true);
                    setDados([])
                    console.log("NÃO SUCESSO: ", motivo);
              }
          })
        return
       
    } catch (error) { throw error;}    

}


    const verCampanhas = async (xave) => {
    try {
        if (xave) {

          //barra.avisar("Lendo Notas Fiscais", 1)
          axios.get(`${barra.urlbase}/apicrud/buscarcampanhas.php?ep=${barra.cempresa}&xv=${xave}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("CAMPANHAS: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setProdutos(res.data.userlist.userdata);
                    setLoading(true);

              } else {
                    console.log("Não encontrado")

                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
              }
          })
        } else {

        }
     
        return
       
    } catch (error) { throw error;}    

}

const verUmaCampanha = async (xave, nota) => {
    try {
        if (xave) {

          //barra.avisar("Lendo Notas Fiscais", 1)
          let chavec  = localStorage.getItem("CHAVEC")
          axios.get(`${barra.urlbase}/apicrud/buscaritensdacampanha.php?xc=${chavec}&ep=${barra.cempresa}&xv=${xave}&nt=${nota}`, inputs).then(function(res) {
              let sucesso    = res.data.success;
              console.log("CAMPANHAS: ", res)
              console.log("SUCESSO: ", sucesso);

              if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setRecebidos(res.data.userlist.userdata);
                    setLoading(true);
              } else {
                    console.log("Não encontrado")

                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
              }
          })
        } else {

        }
     
        return
       
    } catch (error) { throw error;}    

}


    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if ( (inputs.campanha === '' || inputs.campanha === undefined) && (inputs.nota === '' || inputs.nota === undefined)) {
            barra.avisar("Por favor, selecione a campanha ou a nota para consulta.")
            return
        }
        console.log("campanha: ", inputs.campanha, inputs.nota );
        verUmaCampanha(inputs.campanha, inputs.nota);
        setLoading(false)
      }


      /*
      function checkValue(e) {
        var value = e.target.value;
        var identi = e.target.name;
        var chk = e.target.checked;
        console.log("checkbox selecionado " + value + '-' + chk, identi)
        if (chk === false) { 
            setRecebidos([])
        } else {
            localStorage.setItem("CAMPANHA", value)
            verUmaCampanha(value)
        }
      }
      
      if (primeira) {
        lerCampanhas()
        setPrimeira(false)
      }
      */


  return (

    <>
    
        <form className="Container" onSubmit={handleSubmit}>
            <Container style={{overflow: "auto"}}>
                <ConteudoTitulo>
                    <div style={{ width: "100%", backgroundColor: barra.corBarra, textAlign: "left", }}>
                        <table width="100%" border="0">
                                <tbody>
                                    <tr>
                                        <td width="30%" align="left" ><Titulo><span style={{ color: barra.corLetraBarra}}>ACOMPANHAMENTO DE CAMPANHAS</span></Titulo></td>
                                        <td width="70%" align="right">
                                            <div style={{ textAlign: "right",  }}>
                                                <table width="100%" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="right" width="10%" valign="top">
                                                                <h5>NOME: </h5>
                                                            </td>
                                                            <td align="right" width="1%" valign="top">
                                                                &nbsp;
                                                            </td>
                                                            <td align="left" width="20%" valign="top">
                                                                <select name="campanha" id="campanha" value={inputs.campanha}  onChange={handleChange} 
                                                                    style={{border: "px solid #000", borderRadius: "15px", fontSize: "150%", marginLeft: "5px",}} >
                                                                        <option value="0">Selecione a campanha </option>
                                                                        {dados.map((option) => {
                                                                            return <option value={option.iden} key={option.iden} >
                                                                        {option.nome}
                                                                        </option> })}
                                                                </select>
                                                            </td>
                                                            <td align="right" width="5%" valign="top">
                                                                &nbsp;
                                                            </td>
                                                            <td align="right" width="10%" valign="top">
                                                                <h5>NOTA FISCAL: </h5>
                                                            </td>
                                                            <td align="right" width="1%" valign="top">
                                                                &nbsp;
                                                            </td>
                                                            <td align="left" width="10%" valign="top">
                                                                <input type="text" name="nota" id="nota" value={inputs.nota} onChange={handleChange}
                                                                style={{border: "px solid #000", borderRadius: "15px", fontSize: "150%", marginLeft: "5px", width: "70%"}} />
                                                            </td>
                                                            <td align="center"  width="20%">
                                                                <button type="submit" name="btNotaFiscal" className="botao-normal">Buscar Vendas</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                    

                </ConteudoTitulo>


                <div className="row" style={{ width: "98%", height: "98%", }}>
                    <center>
                    <div className="col-md-10">
                        <div style={{   borderColor: "black", borderStyle: "solid", borderRadius: 5, borderWidth: 1,  width: "100%", marginRight: "2px" }}>
                            <span className="form-control" 
                            style={{backgroundColor: barra.corFundo, fontSize: "150%", fontWeight: "700", color: barra.corLetraFundo}}>PRODUTOS VENDIDOS NA CAMPANHA</span>
                            <table  className="table table-striped" style={{fontSize: "120%",}}>
                                <thead>
                                    <th align="center"><u>número nf</u></th>
                                    <th><u>data</u></th>
                                    <th><u>código</u></th>
                                    <th><u>produto</u></th>
                                    <th align="center"><u>valor</u></th>
                                </thead>
                                <tbody>
                                    {recebidos.map( (item) => <tr key={item.id}>
                                        <td>{item.numero}</td>
                                        <td>{item.data}</td>
                                        <td>{item.codigo}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </center>
                </div>
            </Container>                

        </form>
    </>   


    );
};
  

export default Campanhas