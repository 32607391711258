import "./App.css";
import { menuItems } from './lmenuItems';
//import { menuItems1 } from './lmenuItems1';
import MenuItems from './MenuItems';
import { useContext, useEffect } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {

  const barra = useContext(AuthContext);
  const funcoes = menuItems
  const [completo, setCompleto] = useState(false)
  const navigate = useNavigate();

  if (barra.cnivelus === '1') {  barra.setOperador(true)  } else {  barra.setOperador(false) }

  useEffect(() => {
    if (completo) {
      if (barra.cnivelus === '1') {
        navigate("/receber-mat")
      }  else  {
        navigate("/dash")
      } 
    }
  }, [ navigate, barra, completo]);  

  return (
    <nav style={{display: barra.sidebar ? "block" : "none", }}>
      <ul className="menus">
        {
        funcoes.map((menu, index) => {
            //console.log("NIVEL: ", menu.title,  menu.tipo)
            const depthLevel = 0;
            if ((barra.operador === false) || (barra.operador === true && menu.tipo === 'operador')) {
          return (
            <MenuItems 
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
            }
        })
      }
      </ul>
    </nav>
    
    /* setCompleto(true) */

  );

};


export default Navbar;
