import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";


export const menuItems_original = [
  {
    title: 'Receber Produto',
    url: '/receber-mat',
  },
  {
    title: 'Cadastros',
    url: '/cadastros',
    submenu: [
      {
        title: 'web design',
        url: 'web-design',
      },
      {
        title: 'web development',
        url: 'web-dev',
        submenu: [
          {
            title: 'Frontend',
            url: 'frontend',
          },
          {
            title: 'Backend',
            submenu: [
              {
                title: 'NodeJS',
                url: 'node',
              },
              {
                title: 'PHP',
                url: 'php',
              },
            ],
          },
        ],
      },
      {
        title: 'SEO',
        url: 'seo',
      },
    ],
  },
  {
    title: 'About',
    url: '/about',
    submenu: [
      {
        title: 'Who we are',
        url: 'who-we-are',
      },
      {
        title: 'Our values',
        url: 'our-values',
      },
    ],
  },
];

export const menuItems = [

  {
    id: "2",
    title: "Cadastros",
    url: "/cadastros",
    icon: <FaIcons.FaFolder />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    tipo: "super",
  
    submenu: [
      {
        id: "21",
        title: "Bancos",
        url: "/bancos",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "28",
        title: "Cartões",
        url: "/cartoes",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "29",
        title: "Campanhas",
        url: "/cadcampan",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },      
      {
        id: "22",
        title: "VD (Venda direta)",
        url: "/clientes",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "26",
        title: "Contas Contábeis",
        url: "/contas",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      /*
      {
        id: "23",
        title: "Empresas",
        url: "/empresas",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      */      
      {
        id: "23",
        title: "Fornecedores",
        url: "/fornecedor",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "24",
        title: "Grupos Contábeis",
        url: "/grupos",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "25",
        title: "Lojas",
        url: "/lojas",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
      {
        id: "27",
        title: "Usuários",
        url: "/usuariosv3",
        icon: <FaIcons.FaFolderOpen />,
        tipo: "super",
      },
    ],
  },
  {
    id: "3",
    title: "Calendário",
    url: "/calendario",
    icon: <FaIcons.FaCalendar />,
    tipo: "operador",
  },   
 
  {
    id: "5",
    title: "Financeiro",
    url: "/finan",
    icon: <FaIcons.FaDollarSign />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    tipo: "operador",

    submenu: [
      {
        id: "4",
        title: "Campanhas",
        url: "/campanha",
        icon: <FaIcons.FaTrophy />,
        tipo: "operador",
      }, 
      {
      id: "51",
      title: "Contas a Pagar",
      url: "/contas-pagar",
      icon: <FaIcons.FaMoneyBillWave />,
      tipo: "operador",
    },
    {
      id: "52",
      title: "Lançar Contas a Pagar",
      url: "/contas-pagar-lcto",
      icon: <FaIcons.FaMoneyBill />,
      tipo: "operador",
    },
    {
      id: "53",
      title: "Contas a Receber",
      url: "/contas-receber",
      icon: <FaIcons.FaMoneyCheckAlt />,
      tipo: "super",
    },
    {
      id: "54",
      title: "Lançar Contas a Receber",
      url: "/contas-receber-lcto",
      icon: <FaIcons.FaMoneyCheck />,
      tipo: "super",
    },

    {
      id: "58",
      title: "Fechamento diário",
      url: "/fechar",
      icon: <FaIcons.FaCheckDouble/>,
      tipo: "operador",
    },
  ]
  },
  {
    id: "6",
    title: "Gráficos",
    url: "/apex1",
    icon: <FaIcons.FaChartPie />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    tipo: "super",

    submenu: [
    {
      id: "61",
      title: "Definição de gráficos",
      url: "/grafico-cria",
      icon: <FaIcons.FaChartBar />,
      tipo: "super",
    },
    {
      id: "62",
      title: "Definição do Dashboard",
      url: "/grafico-dash",
      icon: <FaIcons.FaDashcube />,
      tipo: "super",
    },
    {
      id: "63",
      title: "Painel Financeiro",
      url: "/grafico-finan",
      icon: <FaIcons.FaDollarSign />,
      tipo: "super",
    },
    {
      id: "64",
      title: "Painel do Inventário",
      url: "/grafico-inv",
      icon: <FaIcons.FaBoxOpen />,
      tipo: "super",
    },
  ]
  },
  
  /*
  {
    id: "7",
    title: "IA",
    url: "/import",
    icon: <FaIcons.FaReceipt />,
    tipo: "super",
  },
  */
  
  {
    id: "8",
    title: "Inventário",
    url: "/inventario",
    icon: <FaIcons.FaBoxOpen />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    tipo: "operador",

    submenu: [
      {
        id: "1",
        title: "Receber Produtos",
        url: "/receber-mat",
        icon: <FaIcons.FaArchive />,
        tipo: "operador",
      },
      {
        id: "81",
        title: "Lançar Movimentação",
        url: "/estoque-lancar",
        icon: <FaIcons.FaPallet />,
        tipo: "operador",
      },   
      {
        id: "82",
        title: "Contagem de itens",
        url: "/estoque-contar",
        icon: <FaIcons.FaPallet />,
        tipo: "operador",
      },  
      {
        id: "83",
        title: "Posição do Inventário",
        url: "/estoque",
        icon: <FaIcons.FaPallet />,
        tipo: "operador",
      },      
      {
        id: "84",
        title: "Consulta Movimentações",
        url: "/estoque-mover",
        icon: <FaIcons.FaPallet />,
        tipo: "operador",
      },
      {
        id: "85",
        title: "Consulta Itens",
        url: "/services/services2",
        icon: <FaIcons.FaList />,
        cName: "sub-nav",
        tipo: "operador",
      },
      {
        id: "86",
        title: "Consulta Notas",
        url: "/services/services1",
        icon: <FaIcons.FaList />,
        cName: "sub-nav",
        tipo: "operador",
      },
  ]
  },
  {
    id: "12",
    title: "Operações Especiais",
    url: "/opespeciais",
    icon: <FaIcons.FaMagic />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    tipo: "super",
  
    submenu: [
      {
        id: "9",
        title: "Painel de Controle",
        url: "/dash",
        icon: <FaIcons.FaChartBar />,
        tipo: "super",
      }, 
      {
        id: "55",
        title: "Cash Flow",
        url: "/fluxo",
        icon: <FaIcons.FaCashRegister/>,
        tipo: "super",
      },
      {
        id: "56",
        title: "Royalties",
        url: "/royal",
        icon: <FaIcons.FaTrademark />,
        tipo: "super",
      },    
      {
        id: "57",
        title: "D.R.E.",
        url: "/dre",
        icon: <FaIcons.FaStackExchange />,
        tipo: "super",
      },      {
        id: "121",
        title: "Abrir Nota Fiscal",
        url: "/operacao/especial1",
        icon: <FaIcons.FaFolderPlus />,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "122",
        title: "Fechar Nota Fiscal",
        url: "/fechaespecial",
        icon: <FaIcons.FaFolderMinus />,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "123",
        title: "Item adicional",
        url: "/operacao/especial3",
        icon: <FaIcons.FaPlusSquare />,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "124",
        title: "Consolidar D.R.E.",
        url: "/dreTrava",
        icon: <FaIcons.FaLock />,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "125",
        title: "Liberar D.R.E.",
        url: "/dreDestrava",
        icon: <FaIcons.FaUnlock />,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "10",
        title: "Suporte",
        url: "/contact",
        icon: <FaIcons.FaEnvelope />,
        tipo: "operador",
      },  

      {
        id: "127",
        title: "Importar arquivos",
        url: "/importar",
        icon: <FaIcons.FaEnvelope />,
        tipo: "operador",
      }, 

      {
        id: "1271",
        title: "Lançar Extrato",
        url: "/extrato",
        icon: <FaIcons.FaEnvelope />,
        tipo: "operador",
      }, 

      {
        id: "128",
        title: "Teste gráfico",
        url: "/apex1",
        icon: <FaIcons.FaEnvelope />,
        tipo: "operador",
      }, 
      {
        id: "129",
        title: "Fechar Nota Fiscal(Esp)",
        url: "/fechaespecial",
        icon: <FaIcons.FaFolderMinus />,
        cName: "sub-nav",
        tipo: "super",
      },   
      /*
      {
        id: "126",
        title: "Parâmetros",
        url: "/parametros",
        icon: <FaIcons.FaList/>,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "127",
        title: "XML",
        url: "/xml",
        icon: <FaIcons.FaList/>,
        cName: "sub-nav",
        tipo: "super",
      },
      {
        id: "128",
        title: "Multiple File",
        url: "/multi",
        icon: <FaIcons.FaList/>,
        cName: "sub-nav",
        tipo: "super",
      },
      */
    ],
  },
/*
  {
    id: "13",
    title: "Sair",
    url: "/sair",
    icon: <FaIcons.FaEnvelope />,
    tipo: "operador",
  },  
  */

];
