//import { useState } from 'preact/hooks';
import ApexChart from 'react-apexcharts';
//import { propTypes } from 'react-bootstrap/esm/Image';

/**
 * props:
 * - data
 */
export default function ChartDonut(props) {


    const series = props.data.map(k => (k.y))
    const colors = props.data.map(k => (k.k))


    const options = {
      chart: {
          width: 5000,
          type: 'donut',
      },

      plotOptions: {
          pie: {
            customScale: 1,
          }
      },

      dataLabels: {
          enabled: true,
          style: {
              fontSize: '15px',
              fontFamily: 'Verdana',
              color: "#FFFFFF",
          },
          dropShadow: {
              enabled: true,
              top: 3,
              left: 3,
              blur: 1,
              color: '#000',
              opacity: 0.85
          },
      },

      labels: props.data.map(k => (k.x)),

      colors: colors,
      
      responsive: [{
          breakpoint: 1000,
          options: {
              chart: {
                  width: 200
              },
              legend: {
                  position: "top"
              }
          }
      }]

  }

    return (
        <ApexChart options={options} series={series} type="donut" />
    )
}