import ApexChart from 'react-apexcharts';
//import { propTypes } from 'react-bootstrap/esm/Image';

/**
 * props:
 * - data
 */
export default function ChartBar(props) {


    //const colors = props.data.map(k => (k.k))

    const series = [{
        data: props.data,
    }]

    var options = {
        chart: {
            type: 'bar',
            height: undefined,
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: true
        },
    }


    return (
        <ApexChart options={options} series={series} type="bar" />
    )
}