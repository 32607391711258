import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, ConteudoTitulo, Table, Titulo } from '../styles';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const Graficos = () => {

  const barra = useContext(AuthContext);

    return (

    <>
    <form className="Container">
    <Container>
            <ConteudoTitulo>
                <div style={{ width: "100%", backgroundColor: barra.corBarra, textAlign: "left", height: 40 }}>
                <Titulo><h4 style={{color: barra.corLetraBarra, paddingTop: 10, marginLeft: 2}}>GESTÃO DE GRÁFICOS</h4></Titulo>
                </div>
            </ConteudoTitulo>

            <Table>
              <thead>
                <tr>
                  <td colspan="2">&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="50%" valign='top' align='left'><img src="imagens.fw.png" alt=""/></td>
                  <td width="50%" valign='top' align='left'>
                    Área para definição dos gráficos, parametrização e as áreas onde serão disponibilizados para análises.
                    <br /><br />
                    Os gráficos podem obedecer ao período pré definido, que se encontra no topo dos painéis DASHBOARD, FINANCEIRO e INVENTÁRIO, podem ter um período 
                    específico definido, como por exemplo "MÊS ANTERIOR", "DIA ANTERIOR", "ANO ATUAL", "ÚLTIMOS 3 MESES" e também podem não abranger período algum, capturando
                    tudo que se tem nas bases de dados sobre o assunto tratado pelo gráfico.
                    <br /><br />
                    Temos 3 painéis possíveis para os gráficos, a saber: 
                    <br /><br />DASHBOARD: tela onde podemos concentrar os principais índices do sistema possibilitanto, dessa forma, uma visão ampla das condições gerais do empreendimento.
                    <br /><br />FINANCEIRO: Sugerímos mostrar paíneis com os acumulados no mês e no ano dos pontos mais relevantes à gestão financeira
                    <br /><br />INVENTÁRIO: Podemos utilizar essa área para as análises gráficas dos pontos importantes em relação aos estoques. Estoques mínimos, produtos mais e menos vendidos,
                    estoques sem movimentação entre outros.
                    <br /><br />

                  </td>
                </tr>
              </tbody>
            </Table>
    </Container>                

    </form>

    </>          

  )
}

export default Graficos