import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
//import Button from 'react-bootstrap/Button';
import { Container, ConteudoTitulo, Titulo } from '../styles';

const ContasPagar = () => {

    const barra = useContext(AuthContext);

    const navigate = useNavigate();
    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
        barra.setSidebar(false);
        localStorage.clear("token")
        localStorage.setItem("encerrar", JSON.stringify("sim"));
        barra.avisar('Sessão expirou. Por favor, faça um novo login.')
        navigate("/");
    }


    const [primeira, setPrimeira] = useState(true)
    const [loading, setLoading] = useState(false);
    //const [isDisabled, setIsDisabled] = useState(true);
    const [produtos, setProdutos] = useState([]);
    const [banco, setBanco] = useState('');
    const [inputs, setInputs] = useState([]);
    const [bancos, setBancos]=useState([]);
    const [lojas, setLojas] = useState([])

    //const [mostrar, setMostrar] = useState(false)
    //const [aviso, setAviso] = useState('')

    const [lote, setLote] = useState("")
    //const [pagar, setPagar] = useState(0);

    const isAuthenticated = localStorage.getItem("token") !== null
    if (!isAuthenticated) { navigate("/") }

    const [valotit, setValotit] = useState('')

    const options = [
        {
          label: "TODOS",
          value: "1",
        },
        {
          label: "PAGOS",
          value: "2",
        },
        {
          label: "EM ABERTO",
          value: "3",
        },
      ];    


    useEffect(() => {
        /*
        let work = localStorage.getItem("DATAPGT")
        localStorage.removeItem("DATAPGT")
        console.log("WORK: ", work)
        if (( work !== undefined) && ( work !== '')  && ( work !== null)) {
            setInputs({
                datai: work,
                dataf: work,
                emitente: '',
                destinatario: '',
                tipo: 3,
                loja: ''
            })
            document.getElementById('mostra').style.display = 'block';
            document.getElementById('mostrab').style.display = 'block';
            buscarTitulos(work, work, '', '', '3');
            buscarBancos()
        }
        console.log("Parâmetros: ", loading)
        */
        setLoading(false)
        navigate("/contas-pagar");
}, [loading, navigate]);  

const buscarLojas = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarlojas.php?e=${barra.cempresa}`, inputs).then(function(res) {
            let sucesso    = res.data.success;
            console.log("LOJAS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setLojas(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Lojas Não encontradas")
                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  

const buscarBancos = async () => {
    try {

        //let tk = localStorage.getItem("token")

        axios.get(`${barra.urlbase}/apicrud/buscarbancos.php?e=${barra.cempresa}`, inputs).then(function(res) {
            let sucesso    = res.data.success;
            console.log("BANCOS: ", res)
            console.log("SUCESSO: ", sucesso);

            if (sucesso) {
                    console.log(res.data.userlist.userdata);
                    setBancos(res.data.userlist.userdata);
                    setLoading(true);

            } else {
                    console.log("Bancos Não encontrados")
                    let motivo    = res.data.motivo;
                    setLoading(true);
                    console.log("NÃO SUCESSO: ", motivo);
                    setLoading(false);
            }
        })
    } catch (error) { throw error;} 

    return

}  


    const buscarTitulos = async (di, df, em, ds, tp, lj) => {
        try {

            if (di === '')        {  di = new Date('Jan 01 2022')  }  
            if (df === '')        {  df = Date.now()  }  
            if (em === undefined) { em = ''; }
            if (ds === undefined) { ds = ''; }
            if (tp === undefined) { tp = '1';}
            if (lj === undefined) { lj = ''; }

            let tk = localStorage.getItem("token")
            tk = tk.replaceAll('"','')

            let kpo = "i=" + di + "&f=" + df + "&e=" + em + "&d=" + ds + "&tp=" + tp + "&emp=" + barra.cempresa + "&tk=" + tk + "&lj=" + lj
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/buscartitulos.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("NF: ", res)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setProdutos(res.data.userlist.userdata);
                        setLoading(true);

                } else {
                        console.log("Não encontrado")

                        let motivo    = res.data.motivo;
                        setLoading(true);
                        console.log("NÃO SUCESSO: ", motivo);
                        setLoading(false);
                }
            })
        } catch (error) { throw error;} 
    
        return

    }   

    function processa (i, v, o)  {
        try {


            let tk = localStorage.getItem("token")
            tk = tk.replaceAll('"','')

            let kpo = "i=" + i + "&v=" + v + "&o=" + o + "&tk=" + tk
            console.log(kpo)
            axios.get(`${barra.urlbase}/apicrud/processatitulo.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                localStorage.setItem("VALDB", res.data.userlist.userdata[0].saldo)

                //console.log("SALDO: ", saldo)
                console.log("SUCESSO: ", sucesso);

                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setLoading(true);
                } else {
                        console.log("Não encontrado")
                        let motivo    = res.data.motivo;
                        console.log("NÃO SUCESSO: ", motivo);
                }
            })
        } catch (error) { throw error;} 
    
        return
       }   


    const handleChange = (event) => {
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("BUSCAR TITULOS: ", document.getElementById('datai').value)

        if (document.getElementById('datai').value !== '') {
            if (inputs.dataf === undefined) {  inputs.dataf = '2022-12-31'  }
            console.log("buscar títulos", e );
            setLoading(false)
            localStorage.setItem("aPagar", 0)
            document.getElementById('valorPago').value = "R$ 0,00"
            setLote('')
            document.getElementById('lote').value = lote
            document.getElementById('mostra').style.display = 'block';
            document.getElementById('mostrab').style.display = 'block';
            buscarBancos()
            buscarTitulos(inputs.datai, inputs.dataf, inputs.emitente, inputs.destinatario, inputs.tipo, inputs.loja);
        }  else {
            barra.avisar("por favor, selecione o período desejado", 3)
            document.getElementById('mostra').style.display  = 'none';
            document.getElementById('mostrab').style.display = 'none';
        }
    }

      //const [num, setNum] = useState(0)
      function randomNumberInRange(min, max) {
        // 👇️ get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      const handleSubmit_sel = event => {
        event.preventDefault();
        
        processa(0, 0, "S")

        let val1 = localStorage.getItem("aPagar")
        let val2 = localStorage.getItem("VALDB")

        if ( (Math.round(val1 * 100) / 100).toFixed(2) !== (Math.round(val2 * 100) / 100).toFixed(2)  )  {
            console.log("VALORES DIFERENTES", val1, " --- ", val2)
            barra.avisar("Ocorreu uma inconsistência nos valores. <br><br>Por favor, recomece toda a operação", 5)
        } else {
            if (val2 === '0.00') {
                barra.avisar("Não foram selecionados títulos a pagar. <br><br>Por favor, verifique a operação", 5)
            } else {
                let xave = ''
                if (lote === '') {
                    xave =  "LOTE_" + randomNumberInRange(100000, 999999)
                    //console.log("XAVE: ", xave)
                    setLote(xave)
                    document.getElementById('lote').value = lote
                } else {
                    xave = lote
                }
                console.log("VALORES IGUAIS", banco, '===', xave)
                try {


                    let tk = localStorage.getItem("token")
                    tk = tk.replaceAll('"','')
        
                    let kpo = "tk=" + tk + "&lt=" + xave + "&bc=" + banco + "&ep=" + barra.cempresa + "&us=" + barra.cusuario
                    console.log(kpo)
                    axios.get(`${barra.urlbase}/apicrud/pagartitulo.php?${kpo}`, inputs).then(function(res) {
                        let sucesso    = res.data.success;
      
                        //console.log("SALDO: ", saldo)
                        console.log("SUCESSO PG: ", sucesso);
        
                        if (sucesso) {
                                console.log(res.data.retorno);
                                setLoading(true);
                                setLote('')
                                document.getElementById('lote').value = ''
                                document.getElementById('valorPago').value = "R$ 0,00"
                                barra.avisar("Operação de PAGAMENTO concluída com sucesso!", 5)
                                buscarBancos()
                                buscarTitulos(inputs.datai, inputs.dataf, inputs.emitente, inputs.destinatario, inputs.tipo);
                       } else {
                                console.log("Não encontrado")
                                let motivo    = res.data.motivo;
                                console.log("NÃO SUCESSO: ", motivo);
                        }
                    })
                } catch (error) { throw error;}                 
            }
        }
        
      }

      function checkValue(e) {
        var value = e.target.value;
        var identi = e.target.name;
        var chk = e.target.checked;
        let idreg = identi.replace("selec", "")

        let orige = identi.replace("selec", "orige")
        console.log("checkbox selecionado " + identi + ": " + orige + ":" + value)
        let valor = document.getElementById(orige).value;

        if (valor === '') {  valor = 0 }
        
        let provisorio = 0
        let aPagar = localStorage.getItem("aPagar")
        console.log("ANTES: ", valor, " = ", aPagar)

        valor = valor.replace(",",".")
        let opera = 'S';
        if (chk === false) { 
            opera = 'M' 
            provisorio = parseFloat(aPagar) - parseFloat(valor)
        } else {
            opera = 'S'
            provisorio = parseFloat(aPagar) + parseFloat(valor)
        }
        console.log("Campos", idreg, ":", orige, "-", valor, "-", "=>", provisorio)
        localStorage.setItem("aPagar", JSON.stringify(provisorio))

        document.getElementById('valorPago').value = provisorio.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        setLoading(true)

        processa(idreg, valor, opera)
      }

      const limparCampos = () => {
        setInputs([])
        document.getElementById('loja').value = "";
        document.getElementById('datai').value = "";
        document.getElementById('dataf').value = "";

        document.getElementById('emitente').value = "";
        document.getElementById('tipo').value = "";

        document.getElementById('mostra').style.display  = 'none';
        document.getElementById('mostrab').style.display = 'none';

        setLoading(true)

    
      }  

      if (primeira) {
        buscarLojas()
        //buscargrupos()
        setPrimeira(false)
      }      


    return (
        <>
            <Container>
                <ConteudoTitulo>
                <form className="Container" onSubmit={handleSubmit} style={{width: "100%" }}>
                    <div style={{ width: "100%", height: 40, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                    <table width="99%">
                                <tbody>
                                    <tr>
                                        <td width="20%" align="left" valign='top'>
                                            <Titulo><h4 style={{color: barra.corLetraBarra}}>CONTAS A PAGAR - PESQUISAS</h4></Titulo>
                                        </td>
                                        <td width="5%" align="right" valign='top' >
                                            <label><h6 style={{marginRight: "2px",}}>Loja: </h6></label>
                                        </td>
                                        <td width="15%" align="left" valign='top'>
                                            <select className="col" name="loja" id="loja" value={inputs.loja}  onChange={handleChange} 
                                            style={{width: "90%", borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px" }}>
                                                <option value="">Selecione a loja </option>
                                                    {lojas.map((option, index) => {
                                                    return <option key={index} >
                                                    {option}
                                                </option>
                                                })}
                                            </select>
                                        </td>
                                        <td width="5%" align="right" valign='top' >
                                            <label><h6 style={{marginRight: "2px",}}>Vcto.inicial: </h6></label>
                                        </td>
                                        <td width="7%" align="left" valign='top'>
                                            <input type="date" id="datai" name="datai" placeholder="selecione a data inicial" 
                                            value={inputs.datai} onChange={handleChange} style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px" }}/>
                                        </td>
                                        <td width="5%" align="right" valign='top' >
                                            <label><h6 style={{marginRight: "2px",}}>Vcto.final: </h6></label>
                                        </td>
                                        <td width="7%" align="left" valign='top' >
                                            <input type="date" id="dataf" name="dataf" placeholder="selecione a data final" value={inputs.dataf} onChange={handleChange} style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px" }}/>
                                        </td>
                                        <td width="5%" align="right" valign='top' >
                                            <label><h6 style={{marginRight: "2px",}}>Credor: </h6></label>
                                        </td>
                                        <td width="10%" align="left" valign='top' >
                                            <input type="text" id="emitente" name="emitente" size="38" placeholder="Razão social ou parte" value={inputs.emitente} onChange={handleChange} 
                                            style={{width: "90%", borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px" }}/>
                                        </td>
                                        <td width="5%" align="right"  valign='top' >
                                            <label><h6 style={{marginRight: "2px",}}>Tipo: </h6></label>
                                        </td>
                                        <td width="7%" align="left"  valign='top' >
                                            <select name="tipo"  id="tipo" value={inputs.tipo}  onChange={handleChange} 
                                            style={{width: "90%", borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                                                {options.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td width="4%" align="right"  valign='top' >
                                            <button type="submit" name="buscar" id="buscar"  
                                            style={{backgroundColor: barra.corBotao, borderRadius:"20px", paddingLeft:"10px", paddingRight:"10px", color: barra.corLetraBotao, border: '1px solid #000',  }}>
                                                BUSCAR</button>
                                        </td>
                                        <td width="5%" align="right"  valign='top' >                
                                            <button onClick={limparCampos}  
                                            style={{backgroundColor: barra.corBotaoE,borderRadius:"20px", color: barra.corLetraBotaoE, border: '1px solid #000', paddingLeft:"10px", paddingRight:"10px", }}>
                                            LIMPAR</button></td>
                                    </tr>
                                </tbody>
                            </table>
                          
                    </div>
                </form>
                </ConteudoTitulo>


                <form className="form" onSubmit={handleSubmit_sel}>
                    <div id="mostrab" className="row" style={{position: "relative", display: "none", marginTop: "1px"}}>
                        <div className="row" style={{backgroundColor: barra.corHeade, color: barra.corFonteHeade, borderRadius: "5px",width: "100%", }}>
                                <div className="col-md-3">
                                    <select className="form" name="banco" value={banco} onChange={texto => setBanco(texto.target.value)} style={{marginTop: "5px" }}>
                                        <option value="">Selecione o banco</option>
                                            {bancos.map((option, index) => {
                                                return <option key={index} >
                                                {option}
                                        </option>
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-3">
                                    <input className="form" type="text" id="lote" name="lote" placeholder="Identif. do Lote" value={lote} onChange={e => setLote(e.target.value)} style={{marginTop: "5px" }}/>
                                </div>
                                
                                <div className="col-md-3">
                                    <input className="form" type="text" name="valorPago" id="valorPago" placeholder="valor a pagar" onChange={v => setLote(v.target.value)} style={{marginTop: "5px" }} />
                                </div>
                                

                                <div className="col-md-3">
                                    <button type="submit" name="confirmar" id="confirmar" className="btn" 
                                    style={{backgroundColor: barra.corBotao, color:  barra.corLetraBotao, padding: "5px", marginTop: "5px", borderRadius: "20px" }}>
                                        Confirmar Pagamento
                                    </button>
                                </div>
                        </div>
                    </div>
                </form>

                <center>
                    <input type="hidden" name="lixao" value={valotit} />
                <div id="mostra" style={{display: "none", overflow: "scroll"}} className="col-md-10" >
                                <div style={{  borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1, }}>
                                                        
                                    <table  className="table table-striped" style={{fontSize: 12,}}>
                                        <thead bgcolor="#E3ECEE" style={{textAlign: "left",}}><th colSpan="9"><span style={{textAlign: "left", fontSize: "12px", fontWeight: "700", textDecoration: "underline"}}>TÍTULOS ENCONTRADOS</span></th></thead>
                                        <thead>
                                            <th align="center">Número</th>
                                            <th align="center">Parc.</th>
                                            <th align="center">Vcto.</th>
                                            <th align="right" >Valor</th>
                                            <th align="right" >Pago</th>
                                            <th align="left"  >Emitente</th>
                                            <th align="left"  >Destinatario</th>
                                            <th align="left"  >R$ Pagar</th>
                                            <th align="center">Marcar</th>
                                        </thead>
                                        <tbody>
                                            {produtos.map( (item) =>
                                                
                                                <tr key={item.id}>
                                                    <td align="center">{item.nota}</td>
                                                    <td align="center">{item.parcela}</td>
                                                    <td align="center">{item.vencimento}</td>
                                                    <td align="right" >{item.valor.replace(".", ",")}</td>
                                                    <td align="right" >{item.valor_recebido.replace(".", ",")}</td>
                                                    <td align="left"  >{item.emitente.substring(0,15)}</td>
                                                    <td align="left"  >{item.destinatario.substring(0,15)}</td>
                                                    <td align="right" >
                                                    <input type="text" style={{textAlign: "right", width: "100%", fontSize: "10px",}} name={`orige${item.id}`}   id={`orige${item.id}`} value={item.saldo.replace(".", ",")}  
                                                    onChange={wv => setValotit(wv.target.value)}/>
                                                    </td>
                                                    <td align="center"> 
                                                        <input type="checkbox" id={`selec${item.id}`} name={`selec${item.id}`} value='1' onChange={checkValue} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                </div>
                </center>
            </Container>
        </>
    );
    
};


export default ContasPagar