import React from 'react'
import { useState,  } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import { Container, ConteudoTitulo, Titulo } from '../styles';
import LineChart from "../../components/LineChart";
import AreaChart from '../../components/AreaChart';
import Panel from '../../components/Panel';
import Pizza1 from '../../components/Pizza1';
import Barra1 from '../../components/Barra1';
import Donut1 from '../../components/Donut1';
import BarraHor1 from '../../components/BarraHor1';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"

const PainelDash = () => {

  const barra = useContext(AuthContext)

  const navigate = useNavigate();
  if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
    barra.setSidebar(false);
    localStorage.clear("token")
    localStorage.setItem("encerrar", JSON.stringify("sim"));
    barra.avisar('Sessão expirou. Por favor, faça um novo login.')
    navigate("/");
  }

  const [primeira, setPrimeira] = useState(true)
  const [dados, setDados] = useState([])
//  const [loading, setLoading] = useState(false)

  const [inputs, setInputs] = useState({
    lojas: "",
    datai: "",
    dataf: "",
    limit: "0"
})

const [seq, setSeq] = useState([])


/*
useEffect(() => {
  setLoading(false)
  navigate("/dash")
}, [loading, navigate]);  
*/

const buscarVisoes = async () => {
    try {
  
        //let tk = localStorage.getItem("token")
  
        axios.get(`${barra.urlbase}/apicrud/buscarvisoes.php?r=0&t=dash&e=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
  
            if (sucesso) {
//                    console.log(res.data.userlist.userdata);
                    setSeq(res.data.userlist.userdata)
//                    barra.setGrf(res.data.userlist.userdata[0].grf)
//                    setProps(res.data.userlist.userdata[0].tit)
                    //setLoading(true);
//                    console.log("G: ", seq)
  
            } else {
//                    console.log("Lojas Não encontradas")
//                    let motivo    = res.data.motivo;
                    setInputs([])
                    inputs.lojas = ""
                    inputs.datai = ""
                    inputs.dataf = ""
                    inputs.limit = ""
                    //setLoading(true);
//                    console.log("NÃO SUCESSO: ", motivo);
            }
        })
    } catch (error) { throw error;} 
  
    return
  
  } 
  

const buscarParametros = async () => {
    try {
  
        //let tk = localStorage.getItem("token")
  
        axios.get(`${barra.urlbase}/apicrud/buscargraficopadrao.php?ep=${barra.cempresa}`).then(function(res) {
            let sucesso    = res.data.success;
//            console.log("PADRÃO: ", res)
//            console.log("SUCESSO: ", sucesso);
  
            if (sucesso) {
//                    console.log(res.data.userlist.userdata);
                    barra.setLoja(res.data.userlist.userdata[0].lojas)
                    barra.setDataInicial(res.data.userlist.userdata[0].data_inicial)
                    barra.setDataFinal(res.data.userlist.userdata[0].data_final)

                    setInputs({
                      lojas: res.data.userlist.userdata[0].lojas,
                      datai: res.data.userlist.userdata[0].data_inicial,
                      dataf: res.data.userlist.userdata[0].data_final,
                    })

                    //barra.setPargraf(inputs);

                    //setLoading(true);
  
            } else {
//                    console.log("Lojas Não encontradas")
//                    let motivo    = res.data.motivo;
                    barra.setLoja("")
                    barra.setDataInicial("")
                    barra.setDataFinal("")

                    setInputs({
                      lojas: '',
                      datai: '',
                      dataf: '',
                    })
                    //setLoading(true);
//                    console.log("NÃO SUCESSO: ", motivo);
            }
        })
    } catch (error) { throw error;} 
  
    return
  
  } 
  

const buscarLojas = async () => {
  try {

      //let tk = localStorage.getItem("token")

      axios.get(`${barra.urlbase}/apicrud/buscarlojassn.php?e=${barra.cempresa}`).then(function(res) {
          let sucesso    = res.data.success;
//          console.log("LOJAS: ", res)
//          console.log("SUCESSO: ", sucesso);

          if (sucesso) {
//                  console.log(res.data.userlist.userdata);
                  setDados(res.data.userlist.userdata);
                  //setLoading(true);

          } else {
//                  console.log("Lojas Não encontradas")
//                  let motivo    = res.data.motivo;
                  setDados([]);
                  //setLoading(true);
//                  console.log("NÃO SUCESSO: ", motivo);
          }
      })
  } catch (error) { throw error;} 

  return

} 


function handleSubmit(e) {
    e.preventDefault();

    if (inputs.lojas   === undefined || inputs.lojas === '') { inputs.lojas = '' }
    if (inputs.datai   === undefined || inputs.datai === '') { inputs.datai = '' }
    if (inputs.dataf   === undefined || inputs.dataf === '') { inputs.dataf = '' }
    if (inputs.limit   === undefined || inputs.limit === '') { inputs.limit = '' }

    try {
  
        let lj = inputs.lojas
        let di = inputs.datai
        let df = inputs.dataf
        let li = inputs.limit

        if (lj === undefined || lj === '') {  lj = '' }
        if (di === undefined || di === '') {  di = '' }
        if (df === undefined || df === '') {  df = '' }
        if (li === undefined || li === '') {  li = '' }

        let kpo = "lj=" + lj + "&di=" + di + "&df=" + df + "&li=" + li + "&ep=" + barra.cempresa

  
        axios.get(`${barra.urlbase}/apicrud/gravagraficopadrao.php?${kpo}`).then(function(res) {
            let sucesso    = res.data.success;
//            console.log("PADRÃO: ", res)
//            console.log("SUCESSO: ", sucesso);
  
            if (sucesso) {
//                    console.log(res);
                    barra.avisar("Padrão salvo com SUCESSO! <br> Na próxima carga os novos padrões aparecerão", 5)
                    navigate("/home")
                    
  
            } else {
//                    console.log("Lojas Não encontradas")
//                    let motivo    = res.data.motivo;
                    //setLoading(true);
//                    console.log("NÃO SUCESSO: ", motivo);
            }
        })
    } catch (error) { throw error;} 


    buscarVisoes()
    buscarParametros()
    buscarParametros()
    //setLoading(true)
  
}

const handleChange = (event) => {
  const nome  = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [nome]: value}));
  //console.log(nome, value)
  if (nome === 'lojas') {  barra.setLoja(value)  }
  if (nome === 'datai') {  barra.setDataInicial(value)  }
  if (nome === 'dataf') {  barra.setDataFinal(value)  }

}


if (primeira) {
    buscarVisoes()
    buscarParametros()
    buscarLojas()
    setPrimeira(false)
}

  
  
  return (
    <>


    <form className="Container" onSubmit={handleSubmit}>
          <Container style={{overflow: "hidden"}}>
              <div  style={{ width: "100%" }}>
                  <table width="100%" border="1" style={{ backgroundColor: barra.corBarra, height: "4Vh"}}>
                        <tr>
                            <td width="20%" align="left" ><Titulo><span style={{marginLeft: "2Vw", color: barra.corLetraBarra}}>DASHBOARD</span></Titulo></td>
                            <td width="20%" align="right">
                                <label><h6>Loja:</h6></label>
                                <select name="lojas" id="lojas" value={inputs.lojas}  onChange={handleChange} 
                                      style={{borderColor: "black", borderStyle: "solid",borderRadius: "5px", borderWidth: "1px", }}>
                                      <option value="0">Selecione a loja </option>
                                          {dados.map((option) => {
                                          return <option value={option.value} key={option.value} >
                                          {option.label}
                                      </option> })}
                                  </select>
                            </td>
                            <td width="20%" align="right">
                                  <label><h6>Data Inicial:</h6></label>
                                  <input type="date"   name="datai" id="datai" value={inputs.datai} onChange={handleChange} />
                            </td>
                            <td width="20%" align="right">
                                  <label><h6>Data Final:</h6></label>
                                  <input type="date"   name="dataf" id="dataf" value={inputs.dataf} onChange={handleChange} />
                            </td>
                            <td width="20%" align="right">
                                <button type="button"  onClick={() => handleSubmit()} className="botao-normal"  
                                    style={{ }}>
                                    SALVAR
                                </button>
                            </td>
                        </tr>
                  </table>
              </div>
          </Container>

      <div className="row">
          
          <div className="App" style={{ position: "absolute", top: "12Vh", left: "2Vw", width: "96Vw", height: "85Vh", border: "1px solid #000", borderRadius: "5px" }}>
                { seq.map(graf => (
                    
                    <div style={{ position: "absolute", top: graf.top, left: graf.lef, width: graf.wid, height: graf.hei, backgroundColor: graf.bck, border: "1px solid #000", borderRadius: "10px"}}>
                      <div><span style={{fontFamily:"Verdana", fontSize:"14px", fontWeight:"800", color: barra.corBarra }}>{graf.tit}{graf.per}</span></div>
                      <div style={{position: 'relative', top: '-1%',}}>
                        { 
                            graf.tip === 'Barra Vertical'   ? ( <Barra1 grf={graf.id} /> ) : (
                                  graf.tip === 'Barra Horizontal' ? ( <BarraHor1 grf={graf.id} /> ) : (
                                      graf.tip === 'Linha'            ? ( <LineChart grf={graf.id} /> ) : (
                                          graf.tip === 'Area'             ? ( <AreaChart grf={graf.id} /> ) : (
                                              graf.tip === 'Pizza'            ? ( <Pizza1  grf={graf.id} /> ) : (
                                                  graf.tip === 'Donut'            ? ( <Donut1  grf={graf.id} /> ) : (
                                                       graf.tip === "Painel"            ? (  <Panel grf={graf.id} /> )  : (null)
                            ))))))
                        }
                    </div>
                    </div>
                    
                ))}
            
          </div>
      </div>
    </form>
    </>
  )
}


export default PainelDash