import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useContext  } from 'react';
import { AuthContext } from "../../contexts/Auth"
import { Container, ConteudoTitulo, Titulo } from '../styles';
import Modal from 'react-bootstrap/Modal';


const FechamentoDiario= () => {

  const barra = useContext(AuthContext);
  const navigate = useNavigate();

  var corReceita = "#BBCCFF";
  var corDespesa = "#FFEEDD";

  const [mostrarPix, setMostrarPix] = useState(false)
  const [pix, setPix] = useState([])
  const [valorpix, setValorpix] = useState('')

  const [inputs, setInputs] = useState({
    loja: "",
    data: "",
    credito: "",
    funcionario: "",
    debito: "",
    grpcredito: "",
    grpdebito: "",
    vlrcre: 0,
    vlrdeb: 0,
    obscre: "",
    obsdeb: "",
    abertura: "0",
    dinheiro: "0",
    debito: "0",
    credito: "0",
    parcelado: "0",
    cheque: "0",
    pix: "0",
    cash: "0",
    presente: "0",
    ifood: "0",
    diversos: "0"
  })
  const [lojas, setLojas] = useState([])
  const [primeira, setPrimeira] = useState(true)

  const [flg1, setFlg1] = useState('none')
  const [flg2, setFlg2] = useState('none')
  const [flg3, setFlg3] = useState('none')
  const [flg4, setFlg4] = useState('none')
  const [flg5, setFlg5] = useState('none')

  const [funcionarios, setFuncionarios] = useState([])


  const [dados, setDados] = useState([])
  const [dadosR, setDadosR] = useState([])
  const [dadoc, setDadoc] = useState([])
  const [dadod, setDadod] = useState([])
  const [dadogc, setDadogc] = useState([])
  const [dadogd, setDadogd] = useState([])
  const [lanca, setLanca] = useState('n')
  const [verif, setVerif] = useState('n')
  const [conso, setConso] = useState('n')

  const [mudou, setMudou] = useState(false)

    if (barra.cempresa === '' || barra.cempresa === null || barra.cusuario === '' || barra.cusuario === null) {
      barra.setSidebar(false);
      localStorage.clear("token")
      localStorage.setItem("encerrar", JSON.stringify("sim"));
      barra.avisar('Sessão expirou. Por favor, faça um novo login.')
      navigate("/");
    }

    useEffect(() => {
        buscar()
        resumo()
        setMudou(false)
        //navigate('/fechar')
    }, [mudou]);  

    const buscarLojas = async () => {
        try {
    
            //let tk = localStorage.getItem("token")
            console.log(`${barra.urlbase}/apicrud/buscarlojassn.php?e=${barra.cempresa}`);
            axios.get(`${barra.urlbase}/apicrud/buscarlojassn.php?e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setLojas(res.data.userlist.userdata);
                        legrupoc()
                        legrupod()
                        lecontac()
                        lecontad()
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setLojas([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  

    const lefunc = async () => {
        try {
    
            //let tk = localStorage.getItem("token")
            console.log("lergrupoc")
    
            axios.get(`${barra.urlbase}/apicrud/lerfuncionarios.php?e=${barra.cempresa}&u=${barra.cusuario}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setFuncionarios(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setFuncionarios([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }     
    
    const legrupoc = async () => {
        try {
    
            //let tk = localStorage.getItem("token")
            console.log("lergrupoc")
    
            axios.get(`${barra.urlbase}/apicrud/lergrupoc.php?e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setDadogc(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDadogc([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  
    const legrupod = async () => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/lergrupod.php?e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setDadogd(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDadogd([]);
                }
            })
        } catch (error) { throw error;} 

        return
    
    } 
    const lecontac = async (grp) => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/lercontac.php?g=${grp}&e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setDadoc(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDadoc([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  
    const lecontad = async (grp) => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/lercontad.php?g=${grp}&e=${barra.cempresa}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setDadod(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDadod([]);
                }
            })
        } catch (error) { throw error;} 

        return
    
    }  

    const fecharLancamentos = async (dat, loj) => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/fecharlancamento.php?d=${dat}&l=${loj}&e=${barra.cempresa}&u=${barra.cusuario}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setFlg2('block')
                        //setDadod(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        barra.avisar(motivo)
                        setFlg2('none')
                        //setDadod([]);
                }
            })
        } catch (error) { throw error;} 

        return
    
    }      
    

    const verificarLancamentos = async (dat, loj) => {
        try {
    
            //let tk = localStorage.getItem("token")
    
            axios.get(`${barra.urlbase}/apicrud/verificarlancamento.php?d=${dat}&l=${loj}&e=${barra.cempresa}&u=${barra.cusuario}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO LOJAS: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setFlg3('block')
                        //setDadod(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        barra.avisar(motivo)
                        setFlg3('none')
                        //setDadod([]);
                }
            })
        } catch (error) { throw error;} 

        return
    
    }     
    
    const resumo = async () => {
        try {

            if (document.getElementById('loja').value !== '' && document.getElementById('loja').value !== undefined && 
            document.getElementById('data').value !== '' && document.getElementById('data').value !== undefined) {  
                
            }  else  {
                //barra.avisar("A loja e a data devem ser informados para a realização da busca")  
                return
            }
    
            let kpo = `e=${barra.cempresa}&d=${inputs.data}&l=${inputs.loja}&u=${barra.cusuario}&n=${barra.nivel}`
            axios.get(`${barra.urlbase}/apicrud/resumofechamento.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO - DIA: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data);
                        setDadosR(res.data.userlist.userdata);
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDadosR([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  

    const buscar = async () => {
        try {

            if (document.getElementById('loja').value !== '' && document.getElementById('loja').value !== undefined && 
            document.getElementById('data').value !== '' && document.getElementById('data').value !== undefined) {  
                
            }  else  {
                //barra.avisar("A loja e a data devem ser informados para a realização da busca")  
                return
            }
    
            let kpo = `e=${barra.cempresa}&d=${inputs.data}&l=${inputs.loja}&u=${barra.cusuario}&n=${barra.nivel}`
            axios.get(`${barra.urlbase}/apicrud/buscarfechamento.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO - DIA: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data);
                        setDados(res.data.userlist.userdata);
                        setLanca(res.data.lancar)
                        setVerif(res.data.verificar)
                        setConso(res.data.Consolidar)
                        
                        if (res.data.lancar == 's') {  
                            setFlg1('block')
                            setFlg5('none')   
                            setFlg4('block')  
                        }  else  {  
                            setFlg1('none')
                            setFlg5('block')   
                            setFlg4('none')   
                        }
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setDados([]);
                        setFlg1('none')
                        setFlg5('none')   
                        setFlg4('none')

                }
            })
        } catch (error) { throw error;} 
    
        return
    
    }  


    const lerPix = async () => {
        try {
            let dia = document.getElementById('data').value
            let loja = document.getElementById('loja').value

            let kpo = `e=${barra.cempresa}&l=${loja}&d=${dia}&u=${barra.cusuario}&n=${barra.nivel}`
            console.log("PIX: ", kpo)
            axios.get(`${barra.urlbase}/apicrud/buscarpix.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO - DIA: ", sucesso);
    
                if (sucesso) {
                        console.log(res.data);
                        setPix(res.data.userlist.userdata);
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setPix([]);
                }
            })
        } catch (error) { throw error;} 
    
        return
    
    } 

    function salvarReceitaAC(event) {
        event.preventDefault();
        //console.log(inputs.grpcredito, inputs.credito, inputs.vlrcre)
        let verificado = 's'

        if (inputs.grpcredito === '' || inputs.grpcredito === undefined) {   verificado = 'n'  }
        if (inputs.credito    === '' || inputs.credito    === undefined) {   verificado = 'n'  }
        if (inputs.vlrcre     === '' || inputs.vlrcre     === undefined) {   verificado = 'n'  }

        if (verificado === 'n') {
            barra.avisar("A conta, o grupo e o valor da receita devem ser informados")            
            return
        }

        gravarReceita(inputs.grpcredito, inputs.credito, inputs.vlrcre, inputs.obscre, 'AC', inputs.funcionario)

        inputs.grpcredito = ''
        inputs.credito    = ''
        inputs.vlrcre     = 0
        inputs.obscre     = ''
        document.getElementById('grpcredito').value = '';
        document.getElementById('credito').value    = '';
        document.getElementById('vlrcre').value     = '0';
        document.getElementById('obscre').value    = '';


    }    

    function salvarReceita(event) {
        event.preventDefault();
        //console.log(inputs.grpcredito, inputs.credito, inputs.vlrcre)
        let verificado = 's'

        if (inputs.grpcredito === '' || inputs.grpcredito === undefined) {   verificado = 'n'  }
        if (inputs.credito    === '' || inputs.credito    === undefined) {   verificado = 'n'  }
        if (inputs.vlrcre     === '' || inputs.vlrcre     === undefined) {   verificado = 'n'  }

        if (verificado === 'n') {
            barra.avisar("A conta, o grupo e o valor da receita devem ser informados")            
            return
        }

        gravarReceita(inputs.grpcredito, inputs.credito, inputs.vlrcre, inputs.obscre, 'NM', inputs.funcionario)

        inputs.grpcredito = ''
        inputs.credito    = ''
        inputs.vlrcre     = 0
        inputs.obscre     = ''
        document.getElementById('grpcredito').value = '';
        document.getElementById('credito').value    = '';
        document.getElementById('vlrcre').value     = '0';
        document.getElementById('obscre').value    = '';


    }

    const gravarReceita = (grp, cnt, wal, obs, tip, fun) => {
        //console.log("campos", grp, cnt );

        let kpo = `empr=${barra.cempresa}&data=${inputs.data}&loja=${inputs.loja}&cont=${inputs.credito}&grup=${inputs.grpcredito}&valo=${wal}&tipo=${tip}&obse=${obs}&func=${fun}&usua=${barra.cusuario}`
        try {
            axios.get(`${barra.urlbase}/apicrud/gravarreceita.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO GRAVAR RECEITA: ", sucesso);
                console.log("RETORNO: ", res.data);
    
                if (sucesso) {
                        console.log(res.data.motivo);
                        setMudou(true)
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                }
            })
        } catch (error) { throw error;} 
    }    

    function salvarDespesaAC(event) {
        event.preventDefault();
        console.log(inputs.grpdebito, inputs.debito, inputs.vlrdeb)
        let verificado = 's'

        if (inputs.grpdebito === '' || inputs.grpdebito === undefined) {   verificado = 'n'  }
        if (inputs.debito    === '' || inputs.debito    === undefined) {   verificado = 'n'  }
        if (inputs.vlrdeb    === '' || inputs.vlrdeb    === undefined) {   verificado = 'n'  }

        if (verificado === 'n') {
            barra.avisar("A conta, o grupo e o valor da despesa devem ser informados")            
            return
        }

        gravarDespesa(inputs.grpdebito, inputs.debito, inputs.vlrdeb, inputs.obsdeb, 'AC')

        inputs.grpdebito = ''
        inputs.debito = ''
        inputs.vlrdeb = 0
        inputs.obsdeb = ''
        document.getElementById('grpdebito').value = '';
        document.getElementById('debito').value    = '';
        document.getElementById('vlrdeb').value    = '0';
        document.getElementById('obsdeb').value    = '';


    }    
    function salvarDespesa(event) {
        event.preventDefault();
        console.log(inputs.grpdebito, inputs.debito, inputs.vlrdeb)
        let verificado = 's'

        if (inputs.grpdebito === '' || inputs.grpdebito === undefined) {   verificado = 'n'  }
        if (inputs.debito    === '' || inputs.debito    === undefined) {   verificado = 'n'  }
        if (inputs.vlrdeb    === '' || inputs.vlrdeb    === undefined) {   verificado = 'n'  }

        if (verificado === 'n') {
            barra.avisar("A conta, o grupo e o valor da despesa devem ser informados")            
            return
        }

        gravarDespesa(inputs.grpdebito, inputs.debito, inputs.vlrdeb, inputs.obsdeb, 'NM')

        inputs.grpdebito = ''
        inputs.debito = ''
        inputs.vlrdeb = 0
        inputs.obsdeb = ''
        document.getElementById('grpdebito').value = '';
        document.getElementById('debito').value    = '';
        document.getElementById('vlrdeb').value    = '0';
        document.getElementById('obsdeb').value    = '';


    }

    const gravarDespesa = (grp, cnt, wal, obs, tip) => {
        console.log("campos", grp, cnt );

        let kpo = `empr=${barra.cempresa}&data=${inputs.data}&loja=${inputs.loja}&cont=${inputs.debito}&grup=${inputs.grpdebito}&valo=${wal}&tipo=${tip}&obse=${obs}&usua=${barra.cusuario}`
        try {
            axios.get(`${barra.urlbase}/apicrud/gravardespesa.php?${kpo}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO GRAVAR DESPESA: ", sucesso);
                console.log("RETORNO: ", res.data);
    
                if (sucesso) {
                        console.log(res.data.motivo);
                        setMudou(true)
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                }
            })
        } catch (error) { throw error;} 
    }

    function somar() {
        let receitas = 0
        let despesas = 0
        let txreceitas = 0
        let txdespesas = 0

        let liquidos = 0
        let txliquidos = 0
        let saldos = 0
        let txsaldos = 0;

        liquidos   = parseFloat(receitas)   - parseFloat(despesas)
        txliquidos = parseFloat(txreceitas) - parseFloat(txdespesas)

        saldos     = parseFloat(liquidos)   - parseFloat(document.getElementById('deposito').value)
        txsaldos   = parseFloat(txliquidos) - parseFloat(document.getElementById('txdeposito').value)

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("campos", e );
        let param = ''

        try {
            axios.get(`${barra.urlbase}/apicrud/gravarfechamento.php?${param}`, inputs).then(function(res) {
                let sucesso    = res.data.success;
                console.log("SUCESSO GRAVAR FECHAMENTO: ", sucesso);
                console.log("RETORNO: ", res.data);
    
                if (sucesso) {
                        console.log(res.data.userlist.userdata);
                        setLojas(res.data.userlist.userdata);
    
                } else {
                        let motivo    = res.data.motivo;
                        console.log("Lojas Não encontradas", motivo)
                        setLojas([]);
                }
            })
        } catch (error) { throw error;} 
        somar()

      }

      const handleChange = (event) => {
        console.log("aqui")
        const nome  = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [nome]: value}));
        setMudou(true)
        somar()
    }      
    
      const limparCampos = () => {
        setInputs([])
        document.getElementById('loja').value = ''
        document.getElementById('data').value = ''
        setFlg2('none')
        setFlg3('none')
        setFlg4('none')
        setMudou(true)

      }   

      const salvarCampos = () => {
        let vdat = document.getElementById('data').value
        let vloj = document.getElementById('loja').value
        fecharLancamentos(vdat, vloj)
        setMudou(true)
      }

      const handleClose = () => {
        setMostrarPix(false)
      }
    
      const verDia  = (n, v) => {
        if (n === 'PIX') {
            setValorpix(v)
            lerPix()
            setMostrarPix(true)
        }
      }      

      const verificarCampos = () => {
        let vdat = document.getElementById('data').value
        let vloj = document.getElementById('loja').value
        verificarLancamentos(vdat, vloj)
        setMudou(true)
      }

      const consolidarCampos = () => {
        //setFlg2('block');
      }

      
    if (primeira) {
        buscarLojas()
        lefunc()
        setPrimeira(false)
    }


  return (

    <>

        <form className="Container" onSubmit={handleSubmit}>
            <Container>
                <ConteudoTitulo>
                    <div style={{ width: "100%", height: 48, backgroundColor: barra.corBarra, textAlign: "left",  }}>
                        <table width="99%">
                            <tbody>
                                <tr>
                                    <td width="20%" align="left" valign='top'>
                                        <Titulo><h4 style={{color: barra.corLetraBarra,}}>FECHAMENTO DIÁRIO</h4></Titulo>
                                    </td>
                                    <td width="10%" align="right" valign='top'>
                                        <label className="form" style={{fontSize: "120%", fontWeight: "700", textAlign: "right"}}>Data: </label>
                                    </td>
                                    <td width="10%" align="left" valign='top'>
                                        <input  type="date" id="data" name="data" placeholder="informe a data do fechamento" value={inputs.data} onChange={handleChange} 
                                        style={{border: "px solid #000", borderRadius: "15px", fontSize: "130%", marginLeft: "5px",}}/>
                                    </td>
                                    <td width="10%" align="right" valign='top'><label className="form" style={{fontSize: "120%", fontWeight: "700", textAlign: "right"}}>Loja: </label></td>
                                    <td width="10%" align="left" valign='top'>
                                        <select className="form" name="loja" id="loja" value={inputs.loja}  onChange={handleChange} 
                                            style={{border: "px solid #000", borderRadius: "15px", fontSize: "130%", marginLeft: "5px",}}>
                                                <option value="">Selecione a loja </option>
                                                    {lojas.map((option) => {
                                                    return <option value={option.value} key={option.value}>{option.label}</option>
                                                })}
                                        </select>                                           
                                    </td>
                                    <td width="10%" align="right" valign='top'>&nbsp;</td>
                                    <td width="5%" align="right" valign='top'>
                                        <Button onClick={limparCampos}  className="botao-bege" >
                                            LIMPAR</Button>
                                    </td>
                                    <td width="5%" align="center" valign='top'>
                                        {/*<Button onClick={salvarCampos}  name="enviar" className="botao-verde" style={{ width: "200px", display: flg4 }}>
                                             FECHAR DIA</Button>*/}
                                    </td>
                                    <td width="5%" align="right" valign='top'>&nbsp;</td>
                                    <td width="5%" align="center" valign='top'>
                                        <Button class="botao-laranja" onClick={verificarCampos} style={{display: flg2}} >VERIFICAR</Button>
                                    </td>
                                    <td width="5%" align="right" valign='top'>&nbsp;</td>
                                    <td width="5%" align="center" valign='top'>
                                        <Button className="botao-azul" id="consolida" onClick={consolidarCampos}  style={{display: flg3, width: "auto"}} >CONSOLIDAR</Button>
                                    </td>
                                </tr>   
                            </tbody>
                        </table>
                    </div>
                </ConteudoTitulo>
            </Container>
        </form>

        <form className="Container" onSubmit={handleSubmit}>
        <center>
        <div className="form-group">
                <div className="col-md-12">
                    <div className="row" style={{   borderColor: "black", borderStyle: "solid",borderRadius: 5, borderWidth: 1 }}>

{/* ====================================================================================================================================== */}     
                        <div className="row">

                                <div className="col-md-3" 
                                    style={{display: flg1, marginTop: "1%", backgroundColor: {corReceita}, border: "1px solid #000", textAlign: "center", fontSize: "1.5em", fontWeight: "600", color: "#202020"}}>
                                    LANÇAR ABERTURA E RECEITAS

                                    <div className="row" ><span style={{fontSize: "1.0em", fontWeight: "600", color: "#202020",}}></span>
                                        <div className="row" >
                                            <div className="form-control" style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Grupo:</h6></label>
                                                <select id="grpcredito" name="grpcredito" value={inputs.grpcredito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione o grupo de receitas</option>
                                                    {dadogc.map((grp) => {
                                                    return <option value={grp.iden} key={grp.iden} >{grp.nome}</option>
                                                    })}
                                                </select>
                                            </div>

                                
                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Conta:</h6></label>
                                                <select id="credito" name="credito" value={inputs.credito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de receita</option>
                                                    {dadoc.map((cta) => {
                                                    if (cta.grup === inputs.grpcredito){
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    }
                                                    })}
                                                </select>

                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Valor:</h6></label>
                                                <input  type="number" id="vlrcre" name="vlrcre" placeholder="" style={{width: "80px"}} value={inputs.vlrcre} onChange={handleChange} />
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita}, display: inputs.credito === '206' ?('block'):('none'),}}>
                                                <select id="funcionario" name="funcionario" value={inputs.funcionario} onChange={handleChange} style={{ marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de receita</option>
                                                    {funcionarios.map((cta) => {
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    })}
                                                </select>
                                            </div>


                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Observações:</h6></label>
                                                <input  type="text" id="obscre" name="obscre" placeholder="" style={{width: "100%"}} value={inputs.obscre} onChange={handleChange} />
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <Button className="botao-verde" onClick={salvarReceita} style={{padding: "2px"}}>LANÇAR</Button>
                                            </div>                                    
                                        </div>                                       
                                    </div>
                                </div>
                                <div className="col-md-3" 
                                    style={{display: flg5, marginTop: "1%", backgroundColor: {corReceita}, border: "1px solid #000", textAlign: "center", fontSize: "1.5em", fontWeight: "600", color: "#202020"}}>
                                    ACERTOS ABERTURA E RECEITAS

                                    <div className="row" ><span style={{fontSize: "1.0em", fontWeight: "600", color: "#202020",}}></span>
                                        <div className="row" >
                                            <div className="form-control" style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Grupo:</h6></label>
                                                <select id="grpcredito" name="grpcredito" value={inputs.grpcredito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione o grupo de receitas</option>
                                                    {dadogc.map((grp) => {
                                                    return <option value={grp.iden} key={grp.iden} >{grp.nome}</option>
                                                    })}
                                                </select>
                                            </div>

                                
                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Conta:</h6></label>
                                                <select id="credito" name="credito" value={inputs.credito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de receita</option>
                                                    {dadoc.map((cta) => {
                                                    if (cta.grup === inputs.grpcredito){
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    }
                                                    })}
                                                </select>
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Valor:</h6></label>
                                                <input  type="number" id="vlrcre" name="vlrcre" placeholder="" style={{width: "80px"}} value={inputs.vlrcre} onChange={handleChange} />
                                            </div>


                                            <div className="form-control"  style={{backgroundColor: {corReceita}, display: inputs.credito === '206' ?('block'):('none'),}}>
                                                <select id="funcionario" name="funcionario" value={inputs.funcionario} onChange={handleChange} style={{ marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de receita</option>
                                                    {funcionarios.map((cta) => {
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    })}
                                                </select>
                                            </div>                                            

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Observações:</h6></label>
                                                <input  type="text" id="obscre" name="obscre" placeholder="" style={{width: "100%"}} value={inputs.obscre} onChange={handleChange} />
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <Button className="botao-verde" onClick={salvarReceitaAC} style={{width: "200px", padding: "2px"}}>LANÇAR ACERTO</Button>
                                            </div>                                    
                                        </div>                                       
                                    </div>
                                </div>
{/* 
                                    <div className="form-control" style={{textAlign: "right",backgroundColor: "#AACCFC"}}>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>DINHEIRO</h6></label>
                                        <input className="form"  type="number" id="dinheiro" name="dinheiro" placeholder="" value={inputs.dinheiro} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%", textAlign: 'right', }}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>CARTÃO DÉBITO</h6></label>
                                        <input className="form"  type="number" id="debito" name="debito" placeholder="" value={inputs.debito} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%", textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>CARTÃO CRÉDITO</h6></label>
                                        <input className="form"  type="number" id="credito" name="credito" placeholder="" value={inputs.credito} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%", textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>CRÉDITO PARCELADO</h6></label>
                                        <input className="form"  type="number" id="parcelado" name="parcelado" placeholder="" value={inputs.parcelado} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px",width: "40%", textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>CHEQUES</h6></label>
                                        <input className="form"  type="number" id="cheque" name="cheque" placeholder="" value={inputs.cheque} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%",textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>PIX</h6></label>
                                        <input className="form"  type="number" id="pix" name="pix" placeholder="" value={inputs.pix} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%",textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>IFOOD</h6></label>
                                        <input className="form"  type="number" id="ifood" name="ifood" placeholder="" value={inputs.ifood} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px",width: "40%", textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>CASH BACK</h6></label>
                                        <input className="form"  type="number" id="cash" name="cash" placeholder="" value={inputs.cash} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%",textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>VALE-PRESENTE</h6></label>
                                        <input className="form"  type="number" id="presente" name="presente" placeholder="" value={inputs.presente} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%",textAlign: 'right',}}/>
                                        <label className="form" style={{width: "50%", }}><h6 style={{fontSize: "12px", color: "black", }}>DIVERSOS</h6></label>
                                        <input className="form"  type="number" id="diversos" name="diversos" placeholder="" value={inputs.diversos} onChange={handleChange} 
                                        style={{marginLeft: "5px", borderRadius: "5px", width: "40%",textAlign: 'right',}}/>                                        
                                        
                                    </div> 
*/}


                                <div className="col-md-1">
                                </div>        

                                <div className="col-md-4"><span style={{display: flg1, fontSize: "1.5  em", fontWeight: "600", color: "#202020",}}>RECEITAS E DESPESAS - LANÇAMENTOS</span>
                                        <table width="100%" border="1" style={{color: "#202020", }} >
                                            <tbody>
                                                <tr>
                                                    <td align="center">Valor apurado</td>
                                                    <td align="center">Valor informado</td>
                                                    <td align="center">Valor Saldo</td>
                                                </tr>
                                                {dadosR.map( (item) =>
                                        
                                                    <tr key={item.ident}>
                                                        <td align="center"><span style={{color: "#202020", }}>{item.valoa}</span></td>
                                                        <td align="center"><span style={{color: "#202020", }}>{item.valoi}</span></td>
                                                        <td align="center"><span style={{color: item.color, }}>{item.difer}</span></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <table width="100%" border="1" >
                                            <tbody>
                                                {dados.map( (item) =>
                                        
                                                    <tr key={item.ident}>
                                                        <td align="left"><span style={{color: item.color, }}>
                                                            <Button       onClick={() => {verDia(item.descr, item.valor);}} name="forma" style={{backgroundColor: "transparent", border: "0px", color: item.color, height: "20px", }} >{item.descr}</Button>
                                                            </span></td>
                                                        <td align="right"><span style={{color: item.color, }}>{item.valor}</span></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <br />
                                        <Button onClick={salvarCampos}  name="enviar" className="botao-verde" style={{ width: "400px", display: flg4 }}>CONCLUIR LANÇAMENTOS NO DIA</Button>

                                </div>

                                <div className="col-md-1">
                                </div>        

                                <div className="col-md-3" style={{display: flg1, marginTop: "1%", backgroundColor: {corDespesa}, border: "1px solid #000", textAlign: "center", fontWeight: "700", }}>
                                    <div className="row" ><span style={{fontSize: "1.5em", fontWeight: "600", color: "#202020",}}>LANÇAR DESPESAS E DEPÓSITOS</span>
                                        <div className="row" >
                                            <div className="form-control" style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Grupo:</h6></label>
                                                <select id="grpdebito" name="grpdebito" value={inputs.grpdebito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione o grupo de despesas</option>
                                                    {dadogd.map((grp) => {
                                                    return <option value={grp.iden} key={grp.iden} >{grp.nome}</option>
                                                    })}
                                                </select>
                                            </div>

                                
                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Conta:</h6></label>
                                                <select id="debito" name="debito" value={inputs.debito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de despesa</option>
                                                    {dadod.map((cta) => {
                                                    if (cta.grup === inputs.grpdebito){
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    }
                                                    })}
                                                </select>
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Valor:</h6></label>
                                                <input  type="number" id="vlrdeb" name="vlrdeb" placeholder="" style={{width: "80px"}} value={inputs.vlrdeb} onChange={handleChange} />
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Observações:</h6></label>
                                                <input  type="text" id="obsdeb" name="obsdeb" placeholder="" style={{width: "100%"}} value={inputs.obs} onChange={handleChange} />
                                            </div>


                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <Button className="botao-vermelho" onClick={salvarDespesa} style={{padding: "2px"}}>LANÇAR</Button>
                                            </div>                                    
                                        </div>                                       
                                    </div>

                                    <div className="row" style={{backgroundColor: "transparent", height: "5px",}}>
                                            <br /><br /><br />
                                    </div>                                      



                                </div>                              
                                <div className="col-md-3" style={{display: flg5, marginTop: "1%", backgroundColor: {corDespesa}, border: "1px solid #000", textAlign: "center", fontWeight: "700", }}>
                                    <div className="row" ><span style={{fontSize: "1.5em", fontWeight: "600", color: "#202020",}}>ACERTOS DESPESAS E DEPÓSITOS</span>
                                        <div className="row" >
                                            <div className="form-control" style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Grupo:</h6></label>
                                                <select id="grpdebito" name="grpdebito" value={inputs.grpdebito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione o grupo de despesas</option>
                                                    {dadogd.map((grp) => {
                                                    return <option value={grp.iden} key={grp.iden} >{grp.nome}</option>
                                                    })}
                                                </select>
                                            </div>

                                
                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Conta:</h6></label>
                                                <select id="debito" name="debito" value={inputs.debito} onChange={handleChange} style={{marginTop: "5px" }}>
                                                    <option value="">Selecione a conta de despesa</option>
                                                    {dadod.map((cta) => {
                                                    if (cta.grup === inputs.grpdebito){
                                                        return <option value={cta.iden} key={cta.iden} >{cta.nome}</option>
                                                    }
                                                    })}
                                                </select>
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Valor:</h6></label>
                                                <input  type="number" id="vlrdeb" name="vlrdeb" placeholder="" style={{width: "80px"}} value={inputs.vlrdeb} onChange={handleChange} />
                                            </div>

                                            <div className="form-control"  style={{backgroundColor: {corReceita},}}>
                                                <label><h6 style={{fontSize: "12px", color: "black", }}>Observações:</h6></label>
                                                <input  type="text" id="obsdeb" name="obsdeb" placeholder="" style={{width: "100%"}} value={inputs.obs} onChange={handleChange} />
                                            </div>


                                            <div className="form-control"  style={{backgroundColor: {corDespesa},}}>
                                                <Button className="botao-vermelho" onClick={salvarDespesaAC} style={{width: "200px", padding: "2px"}}>LANÇAR ACERTO</Button>
                                            </div>                                    
                                        </div>                                       
                                    </div>

                                    <div className="row" style={{backgroundColor: "transparent", height: "5px",}}>
                                            <br /><br /><br />
                                    </div>                                      



                                </div>                              
                            </div>

{/* ====================================================================================================================================== */}                                


{/* ====================================================================================================================================== */}                                

                            <div className="col-md-4" style={{display: 'block',}}></div>

                            <div className="col-md-4" style={{display: 'block',}}></div>

{/* ====================================================================================================================================== */}                                



{/* ====================================================================================================================================== */}     

                    </div>
                </div>
        </div>    
        </center>
        </form>  
        
        <Modal show={mostrarPix} onHide={handleClose} size='lg' dialogClassName="modal-90w"  aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title><span style={{color: "#202020", fontSize: "80%", }}>Operações PIX<br />Valor lançado R$ {valorpix} </span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <table width="100%" border="1" >
                        <tbody>
                                {pix.map( (item) =>
                                    <tr key={item.ident}>
                                        <td align="left"> <span style={{color: "#0000FF", }} >{item.descr}</span></td>
                                        <td align="right"><span style={{color: "#0000FF", }}>{item.valor}</span></td>
                                        <td align="right"><span style={{color: "#000000", }}>{item.parci}</span></td>
                                    </tr>
                                )}
                        </tbody>                            
                </table>
                <br />
        </Modal.Body>
        <Modal.Footer>
            <hr></hr>
        </Modal.Footer>
        </Modal>          
    </>
  )
}

export default FechamentoDiario