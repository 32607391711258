import { useState, useEffect } from 'react';
import { getPontos } from './Ler_dados';
//import Pontogrf from './Dados_grafico';
import ChartBar from './ChartBar';
import { useContext  } from 'react';
import { AuthContext } from "../../src/contexts/Auth"

function Barra1( grf) {

    const barra = useContext(AuthContext)
    const empr = barra.cempresa
    const graf = grf

    const [data, setData] = useState([]);

  useEffect(() => {
    getPontos(barra.dataInicial, barra.dataFinal, empr, graf, "BARRA", barra.loja)
      .then(data => setData(data))
      .catch(err => alert(err.response ? err.response.data : err.message))
  }, [barra.dataInicial,barra.dataFinal, barra.loja, empr, graf])

  return (
    <>
      <ChartBar data={data} />
    </>
  );
}

export default Barra1;